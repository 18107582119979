<template>
  <ModalLayout small title="Download modal">
    <div class="rounded-t-[10px] bg-white px-[24px] pt-[41px] sm:px-[40px]">
      <div
        @click.prevent="$emit('close')"
        class="absolute right-[24px] top-[36px] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full border border-gpx_gray-800 sm:right-[40px]"
      >
        <XMarkIcon
          class="heroicon-stroke-w-1.4 h-[20px] w-[20px] text-gpx_gray-200"
          @click="$emit('close')"
        />
      </div>
      <div class="border-b border-b-gpx_gray-800 pb-[18px]">
        <div class="mb-[2px] text-sm font-medium text-gpx_gray-200">
          Download
        </div>
        <div class="text-gpx_black-100 text-2xl font-semibold">
          {{ params.title }}
        </div>
      </div>
      <div class="mt-[25px] text-sm text-gpx_gray-100">
        {{ params.body }}
      </div>
    </div>
    <div
      class="flex w-full gap-x-[16px] rounded-b-[10px] bg-white px-[24px] py-[32px] sm:px-[40px]"
    >
      <UIButtonNew class="w-full" @click="$emit('close')">
        Got it!
      </UIButtonNew>
    </div>
  </ModalLayout>
</template>

<script>
import { XMarkIcon } from '@heroicons/vue/24/outline';
import ModalLayout from '@/layouts/ModalLayout.vue';

export default {
  components: {
    XMarkIcon,
    ModalLayout,
  },

  emits: ['close'],
  props: {
    params: Object,
  },
};
</script>
