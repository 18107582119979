import L from 'leaflet';

const options = {
  shadowUrl: `${window.location.origin}/markers/shadow.svg`,
  shadowAnchor: [12, 40],
  iconAnchor: [14, 36],
  popupAnchor: [0, -31],
  tooltipAnchor: [0, -31],
};

export const DEFAULT_ICONS = {
  blue: new L.Icon({
    iconUrl: `${window.location.origin}/markers/default/blue.svg`,
    ...options,
  }),
  green: new L.Icon({
    iconUrl: `${window.location.origin}/markers/default/green.svg`,
    ...options,
  }),
  gray: new L.Icon({
    iconUrl: `${window.location.origin}/markers/default/gray.svg`,
    ...options,
  }),
  yellow: new L.Icon({
    iconUrl: `${window.location.origin}/markers/default/yellow.svg`,
    ...options,
  }),
  red: new L.Icon({
    iconUrl: `${window.location.origin}/markers/default/red.svg`,
    ...options,
  }),
};

export const GPS_ICONS = {
  blue: new L.Icon({
    iconUrl: `${window.location.origin}/markers/gps/blue.svg`,
    ...options,
  }),
  green: new L.Icon({
    iconUrl: `${window.location.origin}/markers/gps/green.svg`,
    ...options,
  }),
  gray: new L.Icon({
    iconUrl: `${window.location.origin}/markers/gps/gray.svg`,
    ...options,
  }),
  yellow: new L.Icon({
    iconUrl: `${window.location.origin}/markers/gps/yellow.svg`,
    ...options,
  }),
  red: new L.Icon({
    iconUrl: `${window.location.origin}/markers/gps/red.svg`,
    ...options,
  }),
};

export const CELL_ICONS = {
  blue: new L.Icon({
    iconUrl: `${window.location.origin}/markers/cell/blue.svg`,
    ...options,
  }),
  green: new L.Icon({
    iconUrl: `${window.location.origin}/markers/cell/green.svg`,
    ...options,
  }),
  gray: new L.Icon({
    iconUrl: `${window.location.origin}/markers/cell/gray.svg`,
    ...options,
  }),
  yellow: new L.Icon({
    iconUrl: `${window.location.origin}/markers/cell/yellow.svg`,
    ...options,
  }),
  red: new L.Icon({
    iconUrl: `${window.location.origin}/markers/cell/red.svg`,
    ...options,
  }),
};

export const WIFI_ICONS = {
  blue: new L.Icon({
    iconUrl: `${window.location.origin}/markers/wifi/blue.svg`,
    ...options,
  }),
  green: new L.Icon({
    iconUrl: `${window.location.origin}/markers/wifi/green.svg`,
    ...options,
  }),
  gray: new L.Icon({
    iconUrl: `${window.location.origin}/markers/wifi/gray.svg`,
    ...options,
  }),
  yellow: new L.Icon({
    iconUrl: `${window.location.origin}/markers/wifi/yellow.svg`,
    ...options,
  }),
  red: new L.Icon({
    iconUrl: `${window.location.origin}/markers/wifi/red.svg`,
    ...options,
  }),
};

export const BLE_ICONS = {
  blue: new L.Icon({
    iconUrl: `${window.location.origin}/markers/ble/blue.svg`,
    ...options,
  }),
  green: new L.Icon({
    iconUrl: `${window.location.origin}/markers/ble/green.svg`,
    ...options,
  }),
  gray: new L.Icon({
    iconUrl: `${window.location.origin}/markers/ble/gray.svg`,
    ...options,
  }),
  yellow: new L.Icon({
    iconUrl: `${window.location.origin}/markers/ble/yellow.svg`,
    ...options,
  }),
  red: new L.Icon({
    iconUrl: `${window.location.origin}/markers/ble/red.svg`,
    ...options,
  }),
};

export const BLE_ANCHOR_ICONS = {
  blue: new L.Icon({
    iconUrl: `${window.location.origin}/markers/ble-anchor/blue.svg`,
    shadowUrl: `${window.location.origin}/markers/shadow.png`,
    iconAnchor: [16, 42],
    popupAnchor: [1, -34],
    tooltipAnchor: [1, -34],
  }),
};
