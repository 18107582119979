<template>
  <div class="relative">
    <button
      class="flex w-full cursor-pointer justify-between gap-x-[24px] rounded-[6px] border border-gpx_gray-700 py-[14px] pl-[22px] pr-[16px]"
      @click="toggleDropdown"
    >
      <span class="inline-flex text-gpx_sm text-lms_black-100">{{
        current.name
      }}</span>
      <ChevronDownIcon class="h-[20px] w-[20px] text-gpx_gray-200" />
    </button>
    <UISelectMenu
      v-if="showDropdown"
      :options="options"
      :selected="selected"
      @close="selectOption($event)"
    />
  </div>
</template>

<script>
import UISelectMenu from '@/components/ui/SelectMenu/UISelectMenu.vue';
import { ChevronDownIcon } from '@heroicons/vue/24/outline';

export default {
  name: 'UITabsSelect',

  props: {
    tabs: {
      required: true,
      type: Array,
    },
    current: {
      type: [String, Object],
      required: true,
    },
  },

  components: {
    ChevronDownIcon,
    UISelectMenu,
  },

  data() {
    return {
      id: null,
      showDropdown: false,
      selected: {},
    };
  },

  computed: {
    options() {
      return this.tabs.map((e) => e.name);
    },
  },

  mounted() {
    this.id = this._uid;
    if (this.current) {
      this.selected = this.current;
    }
  },

  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectOption(option) {
      const route = this.tabs.find((tab) => tab.name === option)?.route;
      this.$router.push({ name: route });
      setTimeout(() => {
        this.showDropdown = false;
      }, 500);
    },
  },
};
</script>
