<template>
  <KeepAlive>
    <ShipmentListGeneral v-if="isGeneral" :shipments="shipments" />
  </KeepAlive>
  <KeepAlive>
    <ShipmentListArchived v-if="isArchived" :shipments="shipments" />
  </KeepAlive>
</template>

<script>
import { setTitle } from '@/utils/title';
import { mapGetters } from 'vuex';
import ShipmentListGeneral from '@/components/shipment/list/ShipmentListGeneral.vue';
import { SHIPMENT_CATEGORY } from '@/config/constants';
import ShipmentListArchived from '@/components/shipment/list/ShipmentListArchived.vue';

export default {
  components: {
    ShipmentListGeneral,
    ShipmentListArchived,
  },

  computed: {
    ...mapGetters({
      shipments: 'shipment/filtered',
      activeTab: 'shipment/activeTab',
    }),
    isGeneral() {
      return this.activeTab === SHIPMENT_CATEGORY.general;
    },
    isArchived() {
      return this.activeTab === SHIPMENT_CATEGORY.archived;
    },
  },

  created() {
    setTitle('All Shipments');
  },
};
</script>
