<template>
  <UISelect
    :disabled="isEmpty(currentAddress)"
    placeholder="No addresses"
    :options="addresses"
    :current="currentAddress || {}"
    can-delete
    no-caret
    @onSelect="onSelect"
    @onDelete="onDelete"
  />
</template>

<script>
import * as _ from 'lodash';
import { mapActions } from 'vuex';
import { useToast } from 'vue-toastification';

export default {
  props: {
    addresses: {
      type: Array,
    },
  },

  computed: {
    currentAddress() {
      return this.addresses.filter((item) => Boolean(item.is_default))[0];
    },
  },

  methods: {
    isEmpty: _.isEmpty,
    ...mapActions({
      setDefaultAddress: 'address/setAccountDefault',
      deleteAddress: 'address/delete',
    }),
    onSelect(item) {
      const toast = useToast();
      if (
        !_.isEqual(item, this.currentAddress) &&
        item.id &&
        item.addressable_id
      ) {
        this.setDefaultAddress({
          accountId: item.addressable_id,
          addressId: item.id,
          params: {
            is_default: 1,
          },
        }).then(() => {
          toast.success('New default address has been set');
        });
      }
    },
    onDelete(item) {
      const toast = useToast();
      this.deleteAddress({
        accountId: item.addressable_id,
        addressId: item.id,
      })
        .then(() => {
          toast.success('Address has been deleted');
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    },
  },
};
</script>

<style></style>
