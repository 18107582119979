import service from '@/services';
import { addOrUpdateItems, processAndReturn, removeItem } from '../helpers';
import { ADD, BATCH_ADD_OR_UPDATE, REMOVE } from '../mutation-types';

export const state = {
  items: [],
};

export const getters = {
  all: (state) => state.items,
  byId: (state) => (id) => state.items.find((item) => item.id === id),
  byIds: (state) => (ids) =>
    ids.map((id) => state.items.find((item) => item.id === id)),
};

export const mutations = {
  [ADD]: (state, items) => (state.items = items),
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [REMOVE]: (state, item) => removeItem(state, item),
};

export const actions = {
  fetchByAccountId: (store, { accountId, params }) => {
    return service
      .get(`/accounts/${accountId}/devices`, { params })
      .then((res) => processAndReturn(res, 'deviceMeta'));
  },
  fetchByAccountIdAndId: (store, { id, params }) => {
    return service
      .get(`/devices/${id}`, { params })
      .then((res) => processAndReturn(res, 'deviceMeta'));
  },
  fetchByUserId: (store, { id, params }) => {
    return service
      .get(`/users/${id}/devices`, { params })
      .then((res) => processAndReturn(res, 'deviceMeta'));
  },
  updateById: (store, { id, params }) => {
    return service.put(`/devices/${id}`, params).then((res) => {
      processAndReturn(res, 'deviceMeta');
    });
  },
  updateDeviceUsers: (store, { id, params }) => {
    return service
      .put(`/devices/${id}/users`, params)
      .then((res) => processAndReturn(res, 'deviceMeta'));
  },
};
