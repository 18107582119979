<template>
  <div
    class="inline-flex max-w-full items-center rounded bg-bg-1 px-[6px] py-[4px] text-lgmx_black-300"
  >
    <DocumentIcon
      class="mr-1 h-[14px] w-[14px] shrink-0 self-start text-lgmx_slate-800"
      :class="[{ 'self-start': isNoteMultiline }]"
    />
    <div
      v-if="isNoteMultiline"
      class="whitespace-pre-line text-[11px] leading-4"
    >
      {{ note }}
    </div>
    <div v-else class="min-w-0 truncate text-[11px] leading-4">
      {{ note }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    note: String,
  },

  computed: {
    isNoteMultiline() {
      return /\r|\n/.exec(this.note);
    },
  },
};
</script>
