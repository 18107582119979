<template>
  <div class="mb-[24px] rounded-b-[10px] bg-white px-[24px] sm:px-[40px]">
    <UIDragAndDropImages
      class="mb-3"
      v-model="images"
      :preload-images="preloadImages"
      :file-size-limit="1 * 1024 * 1024"
      :disabled="isSavingBleAnchor || isUploadingImages"
      @on-remove-preload-image="onRemovePreloadImage"
    />
    <UITextarea
      placeholder="Your note here"
      v-model="notes"
      :disabled="bleAnchorHasBeenSaved"
    />
    <div class="flex justify-end gap-x-4 pb-[32px] pt-6">
      <div v-if="isSavingBleAnchor || isUploadingImages" class="w-full">
        <UIButtonNew
          v-if="isSavingBleAnchor"
          size="lg"
          disabled
          :is-loading="true"
          is-loading-text="Saving anchor, please wait..."
          class="w-full"
        />
        <UIButtonNew
          v-if="isUploadingImages"
          size="lg"
          disabled
          :is-loading="true"
          is-loading-text="Uploading images, please wait..."
          class="w-full"
        />
      </div>
      <div v-else>
        <div v-if="!bleAnchorHasBeenSaved" class="flex justify-end gap-x-4">
          <UIButtonNew
            variant="tertiary"
            size="lg"
            class="w-[124px]"
            @click="$emit('onCancel')"
          >
            Back
          </UIButtonNew>
          <UIMenuButton
            v-if="!isSavingBleAnchor"
            size="lg"
            mainLabel="Save & Add More"
            :options="getSaveActions()"
            @click="handleSaveAndAddMore"
          />
          <UIButtonNew
            v-else
            size="lg"
            :is-loading="true"
            is-loading-text="Saving anchor..."
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['onConfirmSaveAndAddMore', 'onConfirmSaveAndClose', 'onCancel'],
  props: {
    isSavingBleAnchor: Boolean,
    isUploadingImages: Boolean,
    bleAnchorHasBeenSaved: Boolean,
    bleAnchor: Object,
  },

  data() {
    return {
      images: [],
      preloadImages: [],
      deletedImageIds: [],
      notes: '',
    };
  },

  mounted() {
    if (this.bleAnchor) {
      this.preloadImages = this.bleAnchor?.images;
      this.notes = this.bleAnchor?.notes;
    }
  },

  methods: {
    getSaveActions() {
      return [
        {
          icon: 'CheckIcon',
          label: 'Save & Close',
          tooltip: 'Save anchor and close modal',
          action: {
            mode: 'fn',
            fn: () => {
              this.handleSaveAndClose();
            },
          },
        },
      ];
    },
    onRemovePreloadImage(imageId) {
      this.preloadImages = this.preloadImages.filter(
        (image) => image.id !== imageId,
      );
      this.deletedImageIds.push(imageId);
    },
    handleSaveAndAddMore() {
      this.$emit('onConfirmSaveAndAddMore', {
        images: this.images,
        notes: this.notes,
        deletedImageIds: this.deletedImageIds,
      });
    },
    handleSaveAndClose() {
      this.$emit('onConfirmSaveAndClose', {
        images: this.images,
        notes: this.notes,
        deletedImageIds: this.deletedImageIds,
      });
    },
  },
};
</script>
