<template>
  <img
    :src="logo"
    :alt="alt"
    :class="is_b2b_platform ? 'h-[18px] md:h-[28px]' : 'h-[30px] md:h-[40px]'"
  />
</template>
<script>
import GpxLogo from '@/assets/gpx-logo.svg';
import LogistimaticsLogo from '@/assets/logo.webp';
import LogistimaticsForbidden from '@/assets/logo.png';

import { is_b2b_platform } from '@/utils/b2b-account';

export default {
  props: {
    forbidden: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    is_b2b_platform() {
      return is_b2b_platform;
    },
    logo() {
      if (is_b2b_platform) {
        return GpxLogo;
      }

      if (this.forbidden) {
        return LogistimaticsForbidden;
      }

      return LogistimaticsLogo;
    },
    alt() {
      let alt = 'Logistimatics';
      if (is_b2b_platform) {
        alt = 'GPX';
      }

      if (this.forbidden) {
        alt += ' Forbidden';
      }

      return alt;
    },
  },
};
</script>
