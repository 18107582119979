<template>
  <div class="mb-[24px] rounded-b-[10px] bg-white px-[24px] sm:px-[40px]">
    <div class="mb-[2px] rounded-t-md bg-bg-1 px-6 py-[17px] text-sm">
      <span class="mr-2 font-normal text-slate-500">Name:</span>
      <span class="font-medium">{{ name }}</span>
    </div>
    <div class="rounded-b-md bg-bg-1 px-6 py-[17px] text-sm">
      <span class="mr-2 font-normal text-slate-500">Serial:</span>
      <span class="font-medium">
        {{ device?.serial }}
      </span>
    </div>
    <div class="flex justify-between gap-x-4 pb-[32px] pt-6">
      <UIButtonNew
        variant="tertiary"
        size="lg"
        class="w-[124px]"
        @click="$emit('onCancel')"
      >
        Back
      </UIButtonNew>
      <UIButtonNew @click="$emit('onConfirm')" class="w-full">
        Confirm
      </UIButtonNew>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['onConfirm', 'onCancel'],
  props: {
    name: String,
    device: Object,
  },
};
</script>
