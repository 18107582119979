<template>
  <div class="min-h-[48px] w-[12px] border border-r-0 border-gpx_gray-500">
    <div
      class="absolute left-[-16.5px] top-1/2 flex h-[32px] w-[32px] cursor-pointer p-[6px] -translate-y-1/2"
      @click="deleteRuleItem(type, id)"
    >
      <div
        class="flex h-[20px] w-[20px] items-center justify-center rounded-full border border-gpx_red-200 bg-white text-gpx_red-200 shadow-[0_0_0_8px_rgba(255,255,255,1)] transition-colors hover:bg-gpx_red-200 hover:text-white"
      >
        <MinusIcon class="h-[12px] w-[12px]" />
      </div>
    </div>
  </div>
</template>

<script>
import { MinusIcon } from '@heroicons/vue/24/outline';

export default {
  name: 'RulesBracket',
  inject: ['deleteRuleItem'],
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      validator(v) {
        return ['condition', 'action'].includes(v);
      },
      required: false,
      default: 'condition',
    },
  },
  components: { MinusIcon },
};
</script>
