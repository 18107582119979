<template>
  <!-- Condition Fields -->
  <TrackersSearch
    v-if="type === 'tracker_list'"
    :selected="modelValue.values"
    @onChange="handleTrackersListChange"
  />
  <template v-if="type === 'tracker_name'">
    <UIInput label="Tracker Name Contains" placeholder="Asset" v-model="name" />
  </template>
  <template v-if="type === 'message_content'">
    <UISelect
      label="Message Contains"
      placeholder="Select value"
      :options="messageContents"
      :current="selectedMessage || {}"
      v-model="selectedMessage"
    />
  </template>
  <GeofenceField
    v-if="type === 'geofence'"
    :address="modelValue.address"
    :triggers="modelValue.triggers"
    @onChange="handleGeofenceValuesChange"
  />
  <EventTypeField
    v-if="type === 'event_type'"
    :types="modelValue.types"
    @onChange="handleEventTypesChange"
  />

  <!-- // Condition Fields -->
  <!-- Action Fields -->
  <SendCommandField
    v-if="type === 'send_command_to_tracker'"
    :model="modelValue.tracker_type"
    :command="modelValue.command"
    @onChange="handleSendCommandChange"
  />

  <template v-if="type === 'send_webhook'">
    <div class="flex flex-col gap-y-[11px]">
      <UIInput
        label="Send to URL"
        placeholder="e.g.: https://example.com"
        v-model="url"
      />
      <div class="text-gpx_xs font-medium text-gpx_gray-300">
        Your integration partner should provide the webhook URL to use.
      </div>
    </div>
  </template>
  <SendMessageField
    v-if="type === 'send_text_message'"
    :value="modelValue"
    @onChange="handleSendMessageChange"
  />
  <SendEmailField
    v-if="type === 'send_email'"
    :value="modelValue"
    @onChange="handleSendMessageChange"
  />
  <!-- // Action Fields -->
</template>

<script>
import { mapGetters } from 'vuex';
import TrackersSearch from './Fields/TrackersSearch.vue';
import GeofenceField from './Fields/GeofenceField.vue';
import EventTypeField from './Fields/EventTypeField.vue';
import SendCommandField from './Fields/SendCommandField.vue';
import SendMessageField from './Fields/SendMessageField.vue';
import SendEmailField from './Fields/SendEmailField.vue';
export default {
  name: 'RulesFieldsSet',
  emits: ['update:modelValue'],
  components: {
    TrackersSearch,
    GeofenceField,
    EventTypeField,
    SendCommandField,
    SendMessageField,
    SendEmailField,
  },
  props: {
    type: {
      type: [String || null],
      required: false,
    },
    modelValue: {
      type: [String, Number, Array, Object],
      required: false,
    },
  },

  data() {
    return {
      name: null,
      selectedMessage: null,
      sendTextMessage: {
        phone_number: null,
        message: null,
        timezone_id: null,
      },
      sendEmail: {
        emails: null,
        subject: null,
        body: null,
        timezone_id: null,
      },
      url: null,
    };
  },

  computed: {
    ...mapGetters({
      models: 'model/all',
      commands: 'command/all',
    }),
    messageContents() {
      return [
        {
          value: 'position',
          label: 'Position information',
        },
        {
          value: 'alarms.disassemble',
          label: 'Tamper alarm',
        },
      ];
    },
    sendTextMessageValues() {
      return {
        phone_number: this.sendTextMessage.phone_number,
        message: this.sendTextMessage.message,
        timezone_id: this.sendTextMessage.timezone?.value,
      };
    },
    sendEmailValues() {
      return {
        email_addresses: this.sendEmail.emails?.split(','),
        subject: this.sendEmail.subject,
        body: this.sendEmail.body,
        timezone_id: this.sendEmail.timezone?.value,
      };
    },
  },

  mounted() {
    if (!this.modelValue) {
      return;
    }
    if (this.type === 'tracker_name') {
      this.name = this.modelValue.value;
    }

    if (this.type === 'message_content') {
      this.selectedMessage = this.messageContents.find(
        (content) => content.value == this.modelValue.value,
      );
    }

    if (this.type === 'send_webhook') {
      this.url = this.modelValue.url;
    }
  },

  watch: {
    name(value) {
      this.$emit('update:modelValue', { value });
    },
    selectedMessage(message) {
      this.$emit('update:modelValue', { value: message?.value });
    },
    url() {
      this.$emit('update:modelValue', { url: this.url });
    },
    sendTextMessage: {
      handler() {
        this.$emit('update:modelValue', this.sendTextMessageValues);
      },
      deep: true,
    },
    sendEmail: {
      handler() {
        this.$emit('update:modelValue', this.sendEmailValues);
      },
      deep: true,
    },
  },

  methods: {
    handleTrackersListChange(trackers) {
      this.$emit('update:modelValue', {
        values: trackers?.map(({ value }) => value),
      });
    },
    handleGeofenceValuesChange(values) {
      this.$emit('update:modelValue', values);
    },
    handleSendCommandChange(values) {
      this.$emit('update:modelValue', values);
    },
    handleSendMessageChange(values) {
      this.$emit('update:modelValue', values);
    },
    handleEventTypesChange(types) {
      this.$emit('update:modelValue', {
        types,
      });
    },
  },
};
</script>
