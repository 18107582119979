import _ from 'lodash';

const processStrategy = (devicePositionHistory) => {
  const relations = {};
  const omit = [];
  return {
    ..._.omit(devicePositionHistory, omit),
    ..._.pickBy(relations, _.identity),
  };
};

export default {
  key: 'devicePositionHistory',
  definition: {},
  processStrategy,
};
