import service from '@/services';
import { BATCH_ADD_OR_UPDATE, ADD } from '../mutation-types';
import { all, processAndReturn, addOrUpdateItems } from '../helpers';

export const state = {
  items: [],
};

export const getters = { all };

export const mutations = {
  [ADD]: (state, items) => (state.items = items),
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
};

export const actions = {
  fetch: (store, params = {}) => {
    params.per_page = 200;
    return service
      .get('/models', { params })
      .then((res) => processAndReturn(res, 'model'));
  },
};
