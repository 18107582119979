import * as _ from 'lodash';
import L from 'leaflet';
import router from '@/router';
import * as layerUtils from '@/utils/map/layer';
import { getGoogleMapsLink } from '@/utils/map/google';
import { formatDuration, formatSpeed, formatTime } from '@/filters';
import { fixColorMap, tracksLegacyColors } from '@/config/constants';
import Geohash from '@/utils/geo-hash';
import * as chargeUtils from '@/utils/map/charge';
import { celsiusToFahrenheit } from '@/utils/temperature';

/* Convert to feature collection */
export function convertToPositionPointFC({ path, name }) {
  const features = path.map((point) => ({
    geometry: {
      type: 'Point',
      coordinates: point.location,
    },
    type: 'Feature',
    properties: {
      name: 'Position',
      popupContent: getPositionPopupContent(point, name),
      speed: formatSpeed(point.speed),
      fix: _.get(point, 'fix', 'unknown'),
      accuracy: _.get(point, 'accuracy', 0) / 1000,
    },
    id: point.timestamp,
  }));
  return {
    type: 'FeatureCollection',
    features,
  };
}

export function convertToStopCircleFC(history) {
  const features = history.map((point) => ({
    geometry: {
      type: 'Point',
      coordinates: point.location,
    },
    type: 'Feature',
    properties: {
      name: 'Stop',
      tooltipContent: getStopTooltipContent(point),
      radius: point?.radius ?? 0.1,
    },
    id: point.timestamp,
  }));
  return {
    type: 'FeatureCollection',
    features,
  };
}

function contentTemperatureAndHumidity(position) {
  const temperature = position?.temperature;
  const humidity = position?.humidity;

  let temperatureContent = '';
  if (temperature) {
    temperatureContent = `<div>
        Temperature: <span>${celsiusToFahrenheit(temperature)}°</span>
      </div>`;
  }

  let humidityContent = '';
  if (humidity) {
    humidityContent = `<div>
        Humidity: <span>${humidity}%</span>
      </div>`;
  }

  return temperatureContent + humidityContent;
}

/* Popup & Tooltip content creators */
function getPositionPopupContent(point, name) {
  const updatedAt = formatTime(point.timestamp, 'E MMM d, yyyy h:mm aaa');
  const speed = formatSpeed(point.speed);
  const fix = _.get(point, 'fix', 'unknown');
  const location = _.get(point, 'location', []);
  const address = _.get(point, 'address', null);
  const link = `
    <div class='text-[10px]'>
      <a href='/geofences/create?area=true' target='_self' onclick='event.preventDefault(); vrouter.push("/geofences/create?label=${address}&lat=${location[1]}&lng=${location[0]}");'>Create geofence</a>
    </div>`;

  return `
  <div>
    <div class="text-[10px]">
      <div class="font-medium text-xs">${name}</div>
      <div>
        <a href=${getGoogleMapsLink({
          lat: location[1],
          lng: location[0],
        })} target="_blank">${point.address}</a>
      </div>
      <div class="flex items-center">
        ${updatedAt}
        <span class="mx-1">-</span>
        ${speed}mph(${fix})
        <span class="mx-1">-</span>
        ${chargeUtils.getChargeEl(point.battery)}
      </div>
      ${contentTemperatureAndHumidity(point)}
      ${link}
    </div>
  </div>
  `;
}

function getStopTooltipContent(point) {
  const startTime = point.start_time;
  const endTime = point.end_time;
  const duration =
    startTime && endTime
      ? formatDuration(startTime, endTime)
      : 'unknown period';
  const from = startTime ? formatTime(startTime, 'M/d h:mm aaa') : null;
  const to = endTime ? formatTime(endTime, 'M/d h:mm aaa') : null;
  const fromToLabel = from && to ? `${from} to ${to}` : 'Cannot load stops';
  return `
  <div class="text-[10px]">
    <div class="font-medium">Stopped for ${duration}</div>
    <div>${fromToLabel}</div>
  </div>
  `;
}

function getLineTooltipContent(position) {
  const name = _.get(position, 'name', '');
  const startPoint = position.path.at(0);
  const endPoint = position.path.at(-1);
  const updatedAtStart = startPoint.timestamp
    ? formatTime(startPoint.timestamp, 'MMM d, h:mm aaa')
    : '-';
  const updatedAtEnd = endPoint.timestamp
    ? formatTime(endPoint.timestamp, 'MMM d, h:mm aaa')
    : '-';
  return `
    <div>
      <div class="font-medium text-xs">${name}</div>
      <div class="text-[10px]">
        ${updatedAtStart} - ${updatedAtEnd}
      </div>
    </div>
  `;
}

/* GeoJSON creators */
export function createPositionLine({ position, color, map }) {
  const { id, path, current_position } = position;
  const ownCoordinates = path.map((p) => p.location);
  const lastMileCoordinates = _.get(current_position, 'location', []);
  const coordinates = getCoordinatesWithLastMile({
    ownCoordinates,
    lastMileCoordinates,
  });

  const geoJson = L.geoJSON(
    {
      type: 'LineString',
      properties: {
        tooltipContent: getLineTooltipContent(position),
      },
      coordinates,
    },
    {
      style: {
        color,
        weight: 3,
        opacity: 0.8,
        pane: 'tracksGroup',
      },
      onEachFeature: (feature, layer) => {
        layer.bindTooltip(feature.properties.tooltipContent, {
          sticky: true,
          interactive: true,
        });
        layer.on('mouseover', function () {
          map.closePopup();
          this.setStyle({
            color: '#fff',
            weight: 7,
          });
        });
        layer.on('mouseout', function () {
          geoJson.resetStyle(this);
        });
        layer.on('click', () => {
          router.push({ name: 'DeviceDetailsView', params: { id } });
        });
      },
    },
  );
  return geoJson;
}

export function createPositionPointsAndExtractFixes(fc) {
  const currentCellfixes = {};
  const currentWififixes = {};
  const cellFixes = L.featureGroup([]);
  const wifiFixes = L.featureGroup([]);
  const { id } = layerUtils.useLocalStorageLayer();

  const geoJSONPoints = L.geoJSON(fc, {
    onEachFeature: (feature, layer) => {
      layer.bindPopup(feature.properties.popupContent, {
        closeButton: false,
        minWidth: 200,
      });
    },
    pointToLayer: (point, latlng) => {
      /**
       * New Geohash inner precision - 9
       * Legacy value - 8
       */
      const hash = Geohash.encode(latlng.lat, latlng.lng, 9);
      const { speed, fix, accuracy } = point.properties;
      const { color, fillColor } = getPositionStyle(id, speed);

      if (fix === 'cell') {
        if (accuracy > 10 && !currentCellfixes[hash]) {
          currentCellfixes[hash] = true;
          const fix = L.circle([latlng.lat, latlng.lng], {
            radius: accuracy,
            color: fixColorMap.cell,
            weight: 1,
            opacity: 0.2,
            fillColor: fixColorMap.cell,
            fillOpacity: 0.07,
            pane: 'cellfixes',
          });
          cellFixes.addLayer(fix);
        }
      }

      if (fix === 'wifi') {
        if (accuracy > 10 && !currentWififixes[hash]) {
          currentWififixes[hash] = true;
          const fix = L.circle([latlng.lat, latlng.lng], {
            radius: accuracy,
            color: fixColorMap.wifi,
            weight: 1,
            opacity: 0.3,
            fillColor: fixColorMap.wifi,
            fillOpacity: 0.15,
            pane: 'wififixes',
          });
          wifiFixes.addLayer(fix);
        }
      }

      return L.circleMarker(latlng, {
        stroke: true,
        opacity: 0.9,
        weight: 1,
        radius: 6,
        fill: true,
        color,
        fillColor,
        fillOpacity: 1,
        pane: 'positions',
        className: 'track-group-' + fix,
      });
    },
  });
  return {
    cellFixes,
    wifiFixes,
    geoJSONPoints,
  };
}

export function createStopCircles(fc) {
  return L.geoJSON(fc, {
    onEachFeature: (feature, layer) => {
      layer.bindTooltip(feature.properties.tooltipContent, {
        permanent: true,
        direction: 'bottom',
      });
    },
    pointToLayer: (point, latlng) => {
      const { radius = 100 } = point.properties;
      return L.circle(latlng, {
        radius,
        color: 'black',
        weight: 1,
        pane: 'stops',
      });
    },
  });
}

/* Color helpers */
export function getPositionStyle(layerId, speed) {
  if (layerId === 'satellite') {
    return {
      color: '#222',
      fillColor: speed <= 0.5 ? '#C10202' : speed <= 4 ? '#FF9A35' : '#fff',
    };
  }
  if (layerId === 'map') {
    return {
      color: '#fff',
      fillColor: speed <= 0.5 ? '#C10202' : speed <= 4 ? '#FF9A35' : '#222',
    };
  }
  throw Error(`${layerId} is not supported`);
}

export function createColors() {
  return Object.keys(tracksLegacyColors).map((c) => tracksLegacyColors[c]);
}

function getCoordinatesWithLastMile({ ownCoordinates, lastMileCoordinates }) {
  if (_.isEmpty(lastMileCoordinates)) {
    return ownCoordinates;
  }
  return _.isEqual(ownCoordinates.at(-1), lastMileCoordinates)
    ? ownCoordinates
    : [...ownCoordinates, lastMileCoordinates];
}
