<template>
  <div
    class="vertical-scroll-mask flex max-h-[300px] flex-col gap-y-4 overflow-y-scroll py-2"
  >
    <template v-for="(messages, date) in grouppedMessages" :key="date">
      <div class="mb-2 text-center text-gray-750">{{ date }}</div>
      <DeviceMessage
        v-for="message in messages"
        :key="message.id"
        :text="message.text"
        :time="message.time"
        :type="message.type"
        :status="message.status"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as _ from 'lodash';
import DeviceMessage from '@/components/device/_common/DeviceMessage.vue';
import { formatTime } from '@/filters';

export default {
  components: {
    DeviceMessage,
  },

  props: {
    deviceId: {
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      messagesByDeviceId: 'message/byDeviceId',
    }),
    deviceMessages() {
      return _.sortBy(this.messagesByDeviceId(+this.deviceId), 'created_at');
    },
    formattedMessages() {
      return _.map(this.deviceMessages, (message) => {
        const date = new Date(message.created_at);
        return {
          id: message.id,
          text:
            message.translation ||
            message.message_to_device ||
            message.message_from_device,
          type: message.message_to_device ? 'out' : 'in',
          time: formatTime(date, 'h:mmaaa') || 'N/A',
          date: formatTime(date, 'MMMM do') || 'N/A',
          status: message.status,
        };
      });
    },
    grouppedMessages() {
      return _.groupBy(this.formattedMessages, (message) => message.date);
    },
  },

  beforeMount() {
    this.fetchMessagesByDeviceId({ id: this.deviceId });
  },

  methods: {
    ...mapActions({
      fetchMessagesByDeviceId: 'message/fetchByDeviceId',
    }),
  },
};
</script>
