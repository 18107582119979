<template>
  <div class="flex flex-col rounded-[6px] border border-gpx_gray-800">
    <router-link
      v-for="link in links"
      :key="link.id"
      to="/"
      class="group flex items-center justify-between border-b border-b-gpx_gray-800 px-[22px] py-[13px] first-of-type:pt-[14px] last-of-type:border-b-0"
    >
      <div class="flex flex-col">
        <span class="text-gpx_sm font-medium text-lms_black-500">{{
          link.name
        }}</span>
        <span class="text-gpx_xs text-gpx_gray-100">{{ link.desc }}</span>
      </div>
      <div class="relative">
        <div
          class="absolute right-[-4px] top-1/2 h-[28px] w-[28px] rounded-full bg-bg-3 opacity-0 transition-opacity duration-300 ease-out -translate-y-1/2 group-hover:opacity-100"
        ></div>
        <ArrowSmallRightIcon
          class="heroicon-stroke-w-1.4 relative h-[20px] w-[20px] text-gpx_gray-300 group-hover:text-lms_red-900"
        />
      </div>
    </router-link>
  </div>
</template>

<script>
import { ArrowSmallRightIcon } from '@heroicons/vue/24/outline';

export default {
  name: 'UILinkGroup',
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  components: {
    ArrowSmallRightIcon,
  },
};
</script>
