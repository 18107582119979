<template>
  <div>
    <div class="mb-[2px] flex items-baseline gap-x-2">
      <div class="text-[15px] font-semibold text-lms_black-300">
        {{ name }}
      </div>
      <div class="flex items-center">
        <UICounter :count="count" />
        <UIIconButton
          class="absolute right-[9px] top-[11px] ml-[6px] md:right-[16px]"
          icon="EllipsisVerticalIcon"
          @click="$emit('setEditItemId', props.group?.id)"
        >
          <UIActions
            v-if="props.editItemId === props.group?.id"
            :id="'group-actions-' + props.group?.id"
            v-click-outside="() => $emit('resetEditItemId')"
            :editItemId="props.editItemId"
            :actions="getUIButtonActions()"
          />
        </UIIconButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import {
  COMMON_DELETE_ERROR,
  COMMON_DELETE_SUCCESS,
  MESSAGE403,
} from '@/config/constants';
import { useGroup } from '@/composables/useGroup.ts';

defineEmits(['setEditItemId', 'resetEditItemId']);
const props = defineProps({
  group: Object,
  editItemId: Number,
});
const store = useStore();
const toast = useToast();

const { getById, deleteGroup } = useGroup();

const accountId = store.getters['auth/accountId'];
const canManageGroups = store.getters['auth/canManageGroups'];
const name = computed(() => props.group?.name);
const count = computed(() => props.group?.devices_count);

function getUIButtonActions() {
  const editGroupAction = {
    icon: 'PencilSquareIcon',
    label: 'Edit',
    action: {
      mode: 'fn',
      fn: (id) => {
        if (!canManageGroups) {
          toast.error(MESSAGE403);
          return;
        }
        const group = getById(id);
        store.dispatch('modal/open', {
          name: 'DeviceGroupModal',
          params: {
            actionType: 'swap',
            id,
            name: group.name,
            device_ids: group.device_ids,
          },
        });
      },
    },
  };

  const deleteGroupAction = {
    icon: 'TrashIcon',
    label: 'Delete',
    action: {
      mode: 'fn',
      fn: (id) => {
        if (!canManageGroups) {
          toast.error(MESSAGE403);
          return;
        }
        const group = getById(id);
        store.dispatch('modal/open', {
          name: 'CommonConfirmationModal',
          params: {
            actionLabel: 'Delete Group',
            title: group.name,
            body: 'Are you sure you want to delete this group?',
            onOk: () => deleteGroup(accountId, id),
            successMessage: COMMON_DELETE_SUCCESS,
            errorMessage: COMMON_DELETE_ERROR,
          },
        });
      },
    },
  };
  return [editGroupAction, deleteGroupAction];
}
</script>
