<template>
  <UIInput
    label="Send text to"
    placeholder="Your phone number"
    v-model="form.phone_number"
    type="tel"
  />
  <div class="flex flex-col gap-y-[11px]">
    <UITextarea
      label="Message to send"
      placeholder="Message to be sent"
      v-model="form.message"
    />
    <div class="text-gpx_xs font-medium text-gpx_gray-300">
      You can use the following placeholders:<br />
      &lcub;&lcub;name&rcub;&rcub; for the tracker name<br />
      &lcub;&lcub;time&rcub;&rcub; for the message sent time<br />
      &lcub;&lcub;map&rcub;&rcub; for a Google Maps link to the tracker
      location<br />
      &lcub;&lcub;address&rcub;&rcub; for the tracker’s address (if available)
    </div>
  </div>
  <UICombobox
    label="Using timezone"
    placeholder="Select timezone"
    ref="timezoneSelect"
    :options="timezones"
    :openOnFocus="true"
    v-model="form.timezone"
  />
</template>
<script>
import { isEmpty } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SendMessageField',
  emits: ['onChange'],
  props: {
    value: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      form: {
        phone_number: null,
        message: null,
        timezone: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      timezones: 'settings/timezones',
    }),
  },
  async mounted() {
    await this.fetchTimezones();
    if (!this.value || isEmpty(this.value)) {
      return;
    }

    this.populateTimezoneSelect();
    this.form.phone_number = this.value.phone_number;
    this.form.message = this.value.message;
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.onChange();
      },
    },
  },
  methods: {
    ...mapActions({
      fetchTimezones: 'settings/fetchTimezones',
    }),
    populateTimezoneSelect() {
      const item = this.timezones.find(
        (timezone) => timezone.value === this.value.timezone_id,
      );
      if (!item) {
        return;
      }
      this.form.timezone = item;
      this.$refs.timezoneSelect.setSelectedOptionQueitly(item);
    },
    onChange() {
      this.$emit('onChange', {
        phone_number: this.form.phone_number,
        message: this.form.message,
        timezone_id: this.form.timezone?.value,
      });
    },
  },
};
</script>
