<template>
  <div class="z-0 size-full bg-gray-500" id="map" />
</template>

<script>
import * as _ from 'lodash';
import L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-draw';
import { toRaw } from 'vue';
import { mapGetters } from 'vuex';
import * as shapeUtils from './utils/shape';
import * as markerUtils from './utils/marker';
import * as legendUtils from './utils/legend';
import * as geojsonUtils from './utils/geo-json';
import * as layerUtils from '@/utils/map/layer';
import { clusterOptions } from '@/components/device/map/utils/marker';
import { mapCenter } from '@/config/constants';

export default {
  props: {
    view: String,
  },

  data() {
    return {
      dbClickTimer: null,
      center: mapCenter,
      map: null,
      mainLayers: [],
      overlays: {},
      layerControl: null,
      marker: null,
      markers: null,
      shapes: L.featureGroup(),
      paths: L.featureGroup(),
      pathPoints: L.featureGroup(),
    };
  },

  computed: {
    ...mapGetters({
      canUseBleFeature: 'auth/canUseBleFeature',

      shipments: 'shipment/filtered',
      shipmentById: 'shipment/byId',
      publicShipment: 'shipment/publicShipment',
    }),
    uuid() {
      return this.$route.params.uuid;
    },
    shipment() {
      if (this.uuid) {
        return this.publicShipment;
      }
      const shipmentId = +this.$route.params.id;
      return this.shipmentById(shipmentId);
    },
  },

  watch: {
    view: {
      handler(newView, oldView) {
        this.setActiveLayer(newView, oldView);
      },
    },
    shipments: {
      deep: true,
      handler(n, o) {
        if (this.view === 'cluster') {
          if (_.isEqual(n, o)) return;
          this.updatePositionsShapesTracks();
        }
      },
    },
  },

  mounted() {
    this.setupLeafletMap();
    this.setupLegend();
    this.setActiveLayer(this.view);
  },

  methods: {
    setupLeafletMap() {
      const mainLayers = layerUtils.getLayers();
      const { id } = layerUtils.useLocalStorageLayer();
      this.mainLayers = mainLayers;

      const overlays = {
        'Show start/end': toRaw(this.shapes),
        'Show tracks': toRaw(this.paths),
      };
      this.overlays = overlays;

      this.map = L.map('map', {
        layers: [mainLayers[id]],
        center: toRaw(this.center),
        zoomControl: false,
        zoom: 5,
        minZoom: 1,
      });

      L.control.zoom({ position: 'topright' }).addTo(toRaw(this.map));
      this.layerControl = L.control
        .layers(toRaw(this.mainLayers), toRaw(overlays), {
          position: 'topleft',
        })
        .addTo(toRaw(this.map));

      this.listenZoom();
      this.listenOverLayChange(overlays);
      this.listenLayerChange();
    },

    setActiveLayer(newView, oldView) {
      if (newView === 'cluster') {
        if (oldView === 'single') {
          toRaw(this.shapes).clearLayers();
          if (this.marker) {
            toRaw(this.map).removeLayer(toRaw(this.marker));
            this.marker = null;
          }
          toRaw(this.paths).clearLayers();
          toRaw(this.pathPoints).clearLayers();
        }
        this.setupCluster();
        this.setupClusterStartEnd();
        this.setupClustePath();
        this.setClusterView();
      }
      if (newView === 'single') {
        if (oldView === 'cluster') {
          toRaw(this.shapes).clearLayers();
          toRaw(this.markers).clearLayers();
          toRaw(this.map).removeLayer(toRaw(this.markers));
          toRaw(this.paths).clearLayers();
        }
        this.setupSingle();
        this.setupSingleStartEnd();
        this.setupSinglePath();
        this.setSingleView();
      }
    },
    setupLegend() {
      if (this.uuid) return;
      const legend = legendUtils.createLegend();
      legend.addTo(this.map);
    },

    setupCluster() {
      this.markers = L.markerClusterGroup(clusterOptions);
      this.shipments.forEach((shipment) => this.createClusterMarker(shipment));
    },
    setupSingle() {
      this.createSingleMarker({ shipment: this.shipment });
    },

    setupClusterStartEnd() {
      this.shipments.forEach((shipment) =>
        this.createClusterShape({ shipment }),
      );
    },
    setupSingleStartEnd() {
      this.createSingleShape({ shipment: this.shipment });
    },

    setupClustePath() {
      const geoJsonLines = this.shipments
        .filter((shipment) => Boolean(shipment.path))
        .map((shipment) => geojsonUtils.convertShipmentToGeoJson({ shipment }));
      geoJsonLines.forEach((l) => toRaw(l).addTo(toRaw(this.paths)));
    },
    setupSinglePath() {
      if (!this.shipment.path) return;
      const geoJsonLine = geojsonUtils.convertShipmentToGeoJson({
        shipment: this.shipment,
      });
      const featureCollection = geojsonUtils.convertToPositionPointFC({
        path: this.shipment.path,
      });
      this.pathPoints = L.geoJSON(featureCollection, {
        onEachFeature: geojsonUtils.onEachPositionPointFeature,
        pointToLayer: (point, latlng) =>
          geojsonUtils.createPosition(point, latlng),
      });
      toRaw(geoJsonLine).addTo(toRaw(this.paths));
    },

    setClusterView() {
      const { is: isPaths } = layerUtils.useLocalStorageOverlay('paths');
      const { is: isStartEnd } = layerUtils.useLocalStorageOverlay('startend');

      if (_.isEmpty(this.shipments)) return;

      toRaw(this.map).addLayer(toRaw(this.markers));
      let bounds = toRaw(this.markers).getBounds();
      if (isPaths) {
        toRaw(this.paths).addTo(toRaw(this.map));
      }
      if (isStartEnd) {
        toRaw(this.shapes).addTo(toRaw(this.map));
        bounds = toRaw(this.shapes).getBounds();
      }

      toRaw(this.map).fitBounds(bounds, { maxZoom: 12 });
    },
    setSingleView() {
      const { is: isPaths } = layerUtils.useLocalStorageOverlay('paths');
      const { is: isStartEnd } = layerUtils.useLocalStorageOverlay('startend');

      if (this.marker) {
        toRaw(this.map).addLayer(toRaw(this.marker));
      }
      let bounds;
      if (isPaths) {
        toRaw(this.paths).addTo(toRaw(this.map));
        if (!_.isEmpty(this.paths._layers)) {
          bounds = toRaw(this.paths).getBounds();
        }
      }
      if (isStartEnd) {
        toRaw(this.shapes).addTo(toRaw(this.map));
        bounds = toRaw(this.shapes).getBounds();
      }

      if (bounds) {
        toRaw(this.map).fitBounds(bounds, {
          maxZoom: 12,
        });
      } else {
        toRaw(this.map).setView(toRaw(this.marker)._latlng, 15);
      }
    },

    createClusterMarker(shipment) {
      const getShipment = () => this.shipmentById(shipment.id);
      if (!shipment.current_position) return;
      const content = markerUtils.getPopupContent({ shipment });
      const marker = markerUtils.createMarker(shipment, {
        isNewIcon: this.canUseBleFeature,
      });

      marker
        .bindPopup(content, {
          closeButton: false,
          minWidth: 200,
        })
        .openPopup()
        .on('mouseover', function () {
          const shipment = getShipment();
          const content = markerUtils.getPopupContent({ shipment });
          this.getPopup().setContent(content);
          this.openPopup();
        })
        .on('click', (event) => {
          if (this.dbClickTimer !== null) {
            return;
          }
          this.dbClickTimer = setTimeout(() => {
            this.dbClickTimer = null;
            this.handleClusterMarkerClick({ event, shipment });
          }, 200);
        })
        .on('dblclick', () => {
          clearTimeout(this.dbClickTimer);
          this.dbClickTimer = null;
        });

      toRaw(this.markers).addLayer(marker);
    },
    createSingleMarker({ shipment }) {
      if (!shipment.current_position) return;
      const content = markerUtils.getPopupContent({ shipment });

      this.marker = markerUtils.createMarker(shipment, {
        isNewIcon: this.canUseBleFeature,
      });

      this.marker.bindPopup(content, {
        closeButton: false,
        minWidth: 200,
      });
    },

    createClusterShape({ shipment }) {
      const fromShape = shapeUtils.createShape({
        geofenceId: shipment.from_geofence_id,
        geofence: shipment.from_geofence,
        address: shipment.from_address,
      });
      const toShape = shapeUtils.createShape({
        geofenceId: shipment.to_geofence_id,
        geofence: shipment.to_geofence,
        address: shipment.to_address,
      });

      const fromTooltip = shapeUtils.createTooltip(
        {
          geofenceId: shipment.from_geofence_id,
          geofence: shipment.from_geofence,
          address: shipment.from_address,
        },
        'from',
      );
      const toTooltip = shapeUtils.createTooltip(
        {
          geofenceId: shipment.to_geofence_id,
          geofence: shipment.to_geofence,
          address: shipment.to_address,
        },
        'to',
      );
      fromShape.addTo(toRaw(this.shapes));
      toShape.addTo(toRaw(this.shapes));
      fromTooltip.openOn(toRaw(this.shapes));
      toTooltip.openOn(toRaw(this.shapes));
    },
    createSingleShape({ shipment }) {
      const fromShape = shapeUtils.createShape({
        geofenceId: shipment.from_geofence_id,
        geofence: shipment.from_geofence,
        address: shipment.from_address,
      });
      const toShape = shapeUtils.createShape({
        geofenceId: shipment.to_geofence_id,
        geofence: shipment.to_geofence,
        address: shipment.to_address,
      });

      const fromTooltip = shapeUtils.createTooltip(
        {
          geofenceId: shipment.from_geofence_id,
          geofence: shipment.from_geofence,
          address: shipment.from_address,
        },
        'from',
      );
      const toTooltip = shapeUtils.createTooltip(
        {
          geofenceId: shipment.to_geofence_id,
          geofence: shipment.to_geofence,
          address: shipment.to_address,
        },
        'to',
      );
      fromShape.addTo(toRaw(this.shapes));
      toShape.addTo(toRaw(this.shapes));
      fromTooltip.openOn(toRaw(this.shapes));
      toTooltip.openOn(toRaw(this.shapes));
    },

    handleClusterMarkerClick({ event }) {
      this.$router.push({
        name: 'ShipmentDetailsView',
        params: {
          id: event.target.options.id,
        },
      });
    },

    updatePositionsShapesTracks() {
      toRaw(this.markers).clearLayers();
      toRaw(this.shapes).clearLayers();
      toRaw(this.paths).clearLayers();
      this.setupCluster();
      this.setupClusterStartEnd();
      this.setupClustePath();
      this.setClusterView();
    },

    managePathsPoints() {
      if (toRaw(this.map).getZoom() >= 6) {
        toRaw(this.pathPoints).addTo(toRaw(this.paths));
      } else {
        toRaw(this.paths).removeLayer(this.pathPoints);
      }
    },

    /*
     * Map Listeners
     */
    listenZoom() {
      toRaw(this.map).on('zoomend', () => {
        if (this.pathPoints) {
          this.managePathsPoints();
        }
      });
    },

    listenLayerChange() {
      const { set } = layerUtils.useLocalStorageLayer();
      toRaw(this.map).on('baselayerchange', (e) => {
        set(e.name);
      });
    },
    listenOverLayChange() {
      const { set: setStartEndValue } =
        layerUtils.useLocalStorageOverlay('startend');
      const { set: setPathsValue } = layerUtils.useLocalStorageOverlay('paths');

      toRaw(this.map).on('overlayadd', (e) => {
        if (e.name === 'Show start/end') {
          setStartEndValue(true);
        }
        if (e.name === 'Show tracks') {
          setPathsValue(true);
        }
      });
      toRaw(this.map).on('overlayremove', (e) => {
        if (e.name === 'Show start/end') {
          setStartEndValue(false);
        }
        if (e.name === 'Show tracks') {
          setPathsValue(false);
        }
      });
    },
  },
};
</script>
