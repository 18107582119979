<template>
  <label
    class="flex cursor-pointer gap-x-[14px] border-t border-t-gpx_gray-700 px-[24px] first-of-type:border-t-0 first-of-type:pt-[21px]"
    :class="{ 'pb-[21px] pt-[19px]': !narrow }"
  >
    <input
      type="radio"
      class="hidden"
      :checked="checked"
      :value="selected"
      @click="$emit('update:modelValue', $event.target.value)"
    />
    <span
      class="mt-[1px] flex h-[24px] w-[24px] flex-none items-center justify-center rounded-full bg-bg-3"
    >
      <CheckIcon
        v-if="checked"
        class="heroicon-stroke-w-2 h-[16px] w-[16px] text-lms_black-500"
      />
    </span>
    <span class="inline-flex flex-col">
      <span class="text-gpx_black-300 inline-block text-sm font-semibold">
        {{ label }}
      </span>
      <span class="inline-block text-sm text-gpx_gray-100">
        {{ description }}
      </span>
    </span>
  </label>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/24/outline';

export default {
  name: 'UIRadioGroupOption',
  components: {
    CheckIcon,
  },

  props: {
    label: {},
    description: {},
    modelValue: {},
    selected: {},
    narrow: {
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    checked() {
      return this.selected === this.modelValue;
    },
  },
};
</script>
