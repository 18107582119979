<template>
  <div class="pt-[25px]">
    <div class="mb-[14px] text-[13px] leading-5 text-lgmx_black-200">
      Look for a small opening in the front of the car charger, located above
      the USB charging ports.
    </div>

    <div class="mb-[18px] flex justify-center">
      <img :src="svg1" alt="Lift up the cover" />
    </div>

    <div class="text-[13px] leading-5 text-lgmx_black-200">
      You'll feel some resistance but using a small, flat head screwdriver or
      your keys, insert into the slot and lift up.
    </div>

    <div class="flex justify-between gap-x-4 pb-[18px] pt-[26px]">
      <UIButtonNew
        variant="tertiary"
        size="lg"
        class="w-[124px]"
        @click="$emit('onCancel')"
      >
        Back
      </UIButtonNew>
      <UIButtonNew @click="$emit('onConfirm')" class="w-full">
        Next
      </UIButtonNew>
    </div>
  </div>
</template>

<script>
import Step2Svg1 from './assets/step-2.svg';

export default {
  emits: ['onConfirm', 'onCancel'],

  computed: {
    svg1() {
      return Step2Svg1;
    },
  },
};
</script>
