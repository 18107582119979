import * as _ from 'lodash';
import service from '@/services';
import {
  BATCH_ADD_OR_UPDATE,
  REMOVE,
  ADD,
  SET_SEARCH,
  SET_FILTER,
  UPDATE_RESULTS,
  SET_MOBILE_ACTIVE_TAB,
  SET_ACTIVE_TAB,
  SET_SELECTED_IDS,
} from '../mutation-types';
import {
  addOrUpdateItems,
  removeItem,
  controller,
  processAndReturn,
  setAbortController,
  getAbortMutations,
} from '../helpers';
import { downloadFile } from '@/utils/blob';
import { isDev } from '@/config/environment';
import { DEVICE_CATEGORY } from '@/config/constants';
import {
  getFilterResults,
  getSearchAndFilterResults,
  getSearchResults,
} from '@/utils/device-filter-legacy';

export const state = {
  controller: null,
  items: [],
  search: '',
  filter: {
    haveNotReportedDays: null,
    battery: null,
    missedUpdate: null,
    includeCellfixes: null,
    haveMovedDays: null,
    haveNotMovedDays: null,
  },
  results: {
    devices: [],
    device_ids: [],
  },
  selectedIds: [],
  activeTab: DEVICE_CATEGORY.allTrackers,
  mobileActiveTab: 'right',
};

export const getters = {
  controller,
  all: (state) => state.items,
  results: (state) => state.results,
  selectedIds: (state) => state.selectedIds,
  byId: (state) => (id) => state.items.find((item) => item.id === id),
  byIds: (state) => (ids) =>
    ids.map((id) => state.items.find((item) => item.id === id)),
  search: (state) => state.search,
  filter: (state) => state.filter,
  isAtLeastOneFilterApplied: (state, getters) =>
    _.values(getters.filter).some((f) => !_.isNull(f)),
  devicesFromMap: (state) => state.devicesFromMap,
  // UI getters
  activeTab: (state) => state.activeTab,
  mobileActiveTab: (state) => state.mobileActiveTab,
};

export const mutations = {
  ...getAbortMutations('device'),
  [ADD]: (state, items) => (state.items = items),
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [REMOVE]: (state, item) => removeItem(state, item),
  [SET_SEARCH]: (state, search) => (state.search = search),
  [SET_FILTER]: (state, filter) => (state.filter = filter),
  [UPDATE_RESULTS]: (state, results) => (state.results = results),
  UPDATE_NAME_AND_NOTE: (state, payload) => {
    const item = state.items.find((item) => item.id === payload.id);
    item.name = payload.name;
    item.note = payload.note;
  },
  [SET_ACTIVE_TAB]: (state, tab) => (state.activeTab = tab),
  [SET_MOBILE_ACTIVE_TAB]: (state, tab) => (state.mobileActiveTab = tab),
  [SET_SELECTED_IDS]: (state, ids) => (state.selectedIds = ids),
  BACKUP_DEVICES_FROM_MAP: (state, devices) => (state.devicesFromMap = devices),
};

export const actions = {
  setSearch: (store, search) => store.commit(SET_SEARCH, search),
  setFilter: (store, filter) => store.commit(SET_FILTER, filter),
  updateResults: (store) => {
    const devices = store.getters['all'];
    const search = store.getters['search'].toLowerCase();
    const isFilters = store.getters['isAtLeastOneFilterApplied'];
    const filter = store.getters['filter'];
    let results = { devices, device_ids: null };

    if (search && isFilters) {
      results = getSearchAndFilterResults(devices, { search, filter });
    }
    if (isFilters && !search) {
      results = getFilterResults(devices, filter);
    }
    if (search && !isFilters) {
      results = getSearchResults(devices, search);
    }

    store.commit(UPDATE_RESULTS, results);
  },
  setMobileActiveTab: (store, tab) => store.commit(SET_MOBILE_ACTIVE_TAB, tab),
  setActiveTab: (store, tab) => store.commit(SET_ACTIVE_TAB, tab),
  setSelectedIds: (store, ids) => {
    store.commit(SET_SELECTED_IDS, ids);
  },
  backupDevicesFromMap: (store, devices) => {
    store.commit('BACKUP_DEVICES_FROM_MAP', devices);
  },
  fetchByAccountId: (store, { accountId, params }) => {
    const controller = setAbortController({
      entity: 'device',
      store,
    });
    return service
      .get(`/accounts/${accountId}/device-list`, {
        params,
        signal: controller.signal,
      })
      .then((res) => {
        if (res && res.status === 499) return res;
        store.commit(ADD, res.data.data);
        store.commit(UPDATE_RESULTS, {
          devices: res.data.data,
          device_ids: null,
        });
        return res;
      });
  },
  fetchBleSupportedDevices: (store) => {
    const accountId = store.rootGetters['auth/accountId'];
    return service.get(`/accounts/${accountId}/device-list`, {
      params: { ble_support: true },
    });
  },
  fetchByAccountIdAndId: (store, { accountId, id, params }) => {
    return service.get(`/accounts/${accountId}/devices/${id}`, { params });
  },
  fetchByCurrentAccountId: (store, { params }) => {
    const accountId = store.rootGetters['auth/accountId'];
    return service
      .get(`/accounts/${accountId}/devices`, { params })
      .then((res) => processAndReturn(res, 'device'));
  },
  fetchNetworkStatusById: (store, { id, params }) => {
    if (isDev()) return { data: { data: 'staging' } };
    return service.get(`/devices/${id}/network-status`, { params });
  },
  downloadByAccountId: (store, { accountId, fileName, params }) => {
    return service
      .post(`/accounts/${accountId}/device-list`, params, {
        responseType: 'blob',
      })
      .then((res) => downloadFile({ res, fileName, extension: 'csv' }));
  },
  downloadReportPositions: (store, { params }) => {
    return service
      .post('/report-builder/', params)
      .then((res) => res.data.data);
  },
};
