import * as _ from 'lodash';
import { addMinutes, differenceInDays } from 'date-fns';

export function getSearchResults(items, search) {
  return items.reduce(
    (acc, item) => {
      if (deviceMatchesSearch(item, search)) {
        if (_.isNull(acc.device_ids)) {
          acc.device_ids = [];
        }
        acc.devices.push(item);
        acc.device_ids.push(item.id);
      }
      return acc;
    },
    { devices: [], device_ids: null },
  );
}

export function getFilterResults(items, filter) {
  return items.reduce(
    (acc, item) => {
      if (deviceFilterLegacy(item, filter)) {
        if (_.isNull(acc.device_ids)) {
          acc.device_ids = [];
        }
        acc.devices.push(item);
        acc.device_ids.push(item.id);
      }
      return acc;
    },
    { devices: [], device_ids: null },
  );
}

export function getSearchAndFilterResults(items, { search, filter }) {
  return items.reduce(
    (acc, item) => {
      if (
        deviceMatchesSearch(item, search) &&
        deviceFilterLegacy(item, filter)
      ) {
        acc.devices.push(item);
        acc.device_ids.push(item.id);
      }
      return acc;
    },
    { devices: [], device_ids: [] },
  );
}

export function deviceFilterLegacy(item, filter) {
  if (deviceWithinReportInterval(item, filter?.haveNotReportedDays)) {
    return false;
  } else if (deviceWithinBatteryPercentage(item, filter?.battery)) {
    return false;
  } else if (deviceUpdatedInTime(item, filter?.missedUpdate)) {
    return false;
  } else if (
    deviceHaveNotMovedInXDays(
      item,
      filter?.haveMovedDays,
      filter?.includeCellfixes,
    )
  ) {
    return false;
  } else if (
    deviceHaveMovedInXDays(
      item,
      filter?.haveNotMovedDays,
      filter?.includeCellfixes,
    )
  ) {
    return false;
  }
  return true;
}

export function deviceMatchesSearch(device, search) {
  const searchFields = _.compact([
    device.name?.toLowerCase(),
    device.serial,
    device.current_position?.address.toLowerCase(),
  ]);
  return searchFields.some((field) => field.includes(search));
}

function deviceWithinReportInterval(device, haveNotReportedDays) {
  if (_.isNull(haveNotReportedDays)) return false;
  const lastReportedAt = device.current_position?.timestamp;
  return lastReportedAt
    ? differenceInDays(new Date(), new Date(lastReportedAt)) >=
        haveNotReportedDays
    : false;
}

function deviceWithinBatteryPercentage(device, battery) {
  if (_.isNull(battery)) return false;
  const deviceBattery = parseInt(device.current_position?.battery, 10);
  if (isNaN(deviceBattery)) return true;
  return deviceBattery > battery;
}

function deviceUpdatedInTime(device, missedUpdate) {
  if (_.isNull(missedUpdate)) return false;
  const expectedReportMinutes = _.get(device, 'expected_report_minutes');
  if (!expectedReportMinutes) {
    return true;
  }
  const updatedAt = _.get(device, 'current_position.timestamp', null);
  const lastChanceToUpdateAt = addMinutes(
    new Date(updatedAt),
    expectedReportMinutes,
  );
  return new Date() <= lastChanceToUpdateAt;
}

function deviceHaveNotMovedInXDays(device, x, includeCellfixes) {
  if (_.isNull(x)) return false;
  const lastMovemementProp = includeCellfixes
    ? 'last_movement_time'
    : 'last_movement_time_without_cell';
  const lastMovementTime = device.current_position?.[lastMovemementProp];
  return lastMovementTime
    ? differenceInDays(new Date(), new Date(lastMovementTime)) > x
    : false;
}

function deviceHaveMovedInXDays(device, x, includeCellfixes) {
  if (_.isNull(x)) return false;
  const lastMovemementProp = includeCellfixes
    ? 'last_movement_time'
    : 'last_movement_time_without_cell';
  const lastMovementTime = device.current_position?.[lastMovemementProp];
  return lastMovementTime
    ? differenceInDays(new Date(), new Date(lastMovementTime)) < x
    : false;
}
