import { is_b2b_platform } from '@/utils/b2b-account';

export function setTitle(title) {
  let platform = 'GPX';
  if (!is_b2b_platform) {
    platform = 'Logistimatics';
  }
  let pageTitle = `${platform} – We Track Everything`;

  if (title) {
    pageTitle = `${title} - ${platform}`;
  }

  document.title = pageTitle;
}
