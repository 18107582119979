<template>
  <section>
    <ManageSubscriptionsTable
      v-if="accountId && !isLoading"
      :accountId="accountId"
    />
  </section>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import ManageSubscriptionsTable from '@/components/manage/subscription/list/ManageSubscriptionsTable.vue';
import { setTitle } from '@/utils/title';
import { useDevice } from '@/composables/useDevice.ts';
import { onMounted, ref } from 'vue';
const store = useStore();

const accountId = store.getters['auth/accountId'];
setTitle('Manage Subscriptions');

const { fetchByAccountId } = useDevice();

const isLoading = ref(true);

onMounted(async () => {
  await fetchByAccountId(accountId, {});
  isLoading.value = false;
});
</script>
