<template>
  <SlideBar :is-first-active="isSearchOpen">
    <template #first>
      <CommonListSearch
        ref="searchRef"
        placeholder="Search Shipments..."
        v-model="searchQuery"
        @close="closeSearch"
      />
    </template>
    <template #second>
      <UITabs :items="tabs" v-model="activeTab" />
      <UIIconButton
        icon="MagnifyingGlassIcon"
        class="ml-auto"
        tooltip="Search shipments"
        @click="openSearch"
      />
    </template>
  </SlideBar>
</template>

<script setup>
import * as _ from 'lodash';
import { ref, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRouteQuery } from '@vueuse/router';
import SlideBar from '@/components/_common/SlideBar.vue';
import { SHIPMENT_CATEGORY } from '@/config/constants';
import * as events from '@/events';
import CommonListSearch from '@/components/_common/CommonListSearch.vue';

const store = useStore();

// Tabs
const tabs = [
  { key: SHIPMENT_CATEGORY.general, label: 'General' },
  { key: SHIPMENT_CATEGORY.archived, label: 'Archived' },
];
const activeTab = ref(store.getters['shipment/activeTab']);
watch(activeTab, (n) => {
  store.dispatch('shipment/setActiveTab', n);
});

// Search
const searchRef = ref(null);
const isSearchOpen = ref(false);
const searchQuery = useRouteQuery('search', '');
watchEffect(() => {
  searchRef.value?.inputRef.focus();
});
watch(searchQuery, _.debounce(handleSearch, 100), {
  immediate: true,
  flush: 'post',
});
function handleSearch(value) {
  if (value && !isSearchOpen.value) {
    openSearch();
  }
  store.dispatch('shipment/setSearch', value);
  searchQuery.value = value;
  events.trigger(events.names.SHIPMENT_SEARCHED, { query: value });
}
function openSearch() {
  isSearchOpen.value = true;
}
function closeSearch() {
  isSearchOpen.value = false;
  searchQuery.value = '';
}
</script>
