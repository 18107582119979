import service from '@/services';
import { BATCH_ADD_OR_UPDATE, REMOVE, ADD } from '../mutation-types';
import { addOrUpdateItems, processAndReturn, removeItem } from '../helpers';

export const state = {
  items: [],
};

export const getters = {
  all: (state) => state.items,
  byId: (state) => (id) => state.items.find((item) => item.id === id),
  byIds: (state) => (ids) =>
    state.items.filter((item) => ids.indexOf(item.id) > -1),
  byAccountId: (state) => (id) =>
    state.items.filter((item) => {
      return item.account_id === id;
    }),
};

export const mutations = {
  [ADD]: (state, items) => (state.items = items),
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [REMOVE]: (state, item) => removeItem(state, item),
};

export const actions = {
  fetchByAccountId: (store, { accountId, params }) => {
    return service
      .get(`/accounts/${accountId}/orders`, { params })
      .then((res) => processAndReturn(res, 'order'));
  },
  fetchById: (store, { orderId, params }) => {
    return service
      .get(`/orders/${orderId}`, { params })
      .then((res) => processAndReturn(res, 'order'));
  },
  fetchBySubscriptionId: (store, { subscriptionId, params }) => {
    return service.get(`/subscriptions/${subscriptionId}/orders`, { params });
  },
};
