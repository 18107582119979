<template>
  <div v-if="loading" class="mt-8 flex justify-center">
    <UISpinner size="xl" />
  </div>
  <section v-else>
    <div class="flex items-end justify-between">
      <HeadingSection
        class="pt-[25px]"
        :desc="`Placed on ${orderDate}`"
        :heading="`Order #${order.id}`"
      />
      <UIMenuButton
        v-if="allowedToPay"
        class="mb-1"
        mainIcon="CurrencyDollarIcon"
        mainLabel="Pay Now"
        @click="handlePayNow(orderId)"
        :options="payNowActions"
      />
      <UIButton
        v-else-if="allowedToViewInvoice"
        label="View Invoice"
        size="medium"
        @click="handleViewInvoice(orderId)"
      />
    </div>

    <div
      class="mt-[19px] flex flex-col items-start gap-y-[3px] sm:flex-row sm:gap-x-[12px] sm:gap-y-0"
    >
      <div
        class="pt-px text-gpx_xs font-medium text-gpx_gray-200 sm:text-gpx_sm"
      >
        Status:
      </div>
      <UIBadge :status="order.status" />
    </div>

    <div class="mt-[64px] px-[24px] sm:px-0" v-if="orderItems">
      <div class="flex flex-col items-end gap-y-[8px]">
        <div
          v-for="item in orderItems"
          :key="item.id"
          class="relative flex w-full justify-between rounded-[6px] border border-gpx_gray-800 pl-[20px] pr-[32px]"
        >
          <div
            class="flex w-full items-start justify-between"
            :to="{ name: 'device.show', params: { id: item.id } }"
          >
            <div class="flex gap-x-[16px]">
              <div class="flex items-start justify-center pt-[16px]">
                <img
                  v-show="!imageLoading"
                  class="max-h-[64px] max-w-[64px]"
                  :src="item.product.thumbnail.path"
                  :alt="item.product.thumbnail.alias"
                />
              </div>
              <div class="pb-[23px] pt-[25px]">
                <div class="text-gpx_black-500 text-sm font-semibold">
                  {{ item.product.title }}
                </div>
                <div class="mt-[10px] flex flex-col gap-y-[2px]">
                  <div class="flex gap-x-[12px]">
                    <div
                      class="text-gpx_black-500 text-xs font-medium slashed-zero"
                    >
                      <span class="font-regular text-gpx_gray-200">SKU:</span>
                      {{ item.product.sku }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col items-end gap-y-[9px] pt-[24px]">
              <div class="flex flex-col items-end">
                <div class="text-xs leading-[16px] text-gpx_gray-100" />
                <div class="text-gpx_black-300 text-[18px] leading-[22px]">
                  <span>$</span>
                  <span class="font-bold">
                    {{ formatMoney(item.actual_price, true).main }}
                  </span>
                  <span class="text-[12px]">
                    {{ formatMoney(item.actual_price, true).dec }}
                  </span>
                </div>
              </div>
              <div class="text-sm text-gpx_gray-100">
                x{{ item.quantity }}, $
                <span class="font-semibold">
                  {{
                    formatMoney(item.actual_price * item.quantity, true).main
                  }}
                </span>
                <span class="text-[12px]">
                  {{ formatMoney(item.actual_price * item.quantity, true).dec }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-[11px] flex flex-row">
          <div class="flex flex-col items-end">
            <div class="pr-[40px] text-xs font-medium text-gpx_gray-300">
              Hardware subtotal:
            </div>
            <div
              class="mt-[6px] pr-[40px] text-xs font-medium text-gpx_gray-300"
            >
              Subscription subtotal:
            </div>
            <div
              class="mt-[6px] pr-[40px] text-xs font-medium text-gpx_gray-300"
            >
              Live audio hours subtotal:
            </div>
            <div
              class="mt-[6px] pr-[40px] text-xs font-medium text-gpx_gray-300"
            >
              Shipping:
            </div>
            <div
              class="mt-[6px] pr-[40px] text-xs font-medium text-gpx_gray-300"
            >
              Tax:
            </div>
            <div
              v-if="order.summary.promo_code"
              class="mt-[6px] pr-[40px] text-xs font-medium text-gpx_gray-300"
            >
              Promo discount:
            </div>
            <div
              class="text-gpx_black-500 mt-[19px] w-full border-t border-t-gpx_gray-800 pr-[40px] pt-[14px] text-right text-base font-semibold"
            >
              Grand Total:
            </div>
          </div>
          <div class="flex flex-col items-end">
            <div class="pr-[32px] text-xs font-medium text-gpx_gray-300">
              ${{ formatMoney(order.summary.hardware_total) }}
            </div>
            <div
              class="mt-[6px] pr-[32px] text-xs font-medium text-gpx_gray-300"
            >
              ${{ formatMoney(order.summary.subscription_total) }}
            </div>
            <div
              class="mt-[6px] pr-[32px] text-xs font-medium text-gpx_gray-300"
            >
              ${{ formatMoney(order.summary.audio_hour_total) }}
            </div>
            <div
              class="mt-[6px] pr-[32px] text-xs font-medium text-gpx_gray-300"
            >
              ${{ formatMoney(order.summary.shipping_total) }}
            </div>
            <div
              class="mt-[6px] pr-[32px] text-xs font-medium text-gpx_gray-300"
            >
              ${{ formatMoney(order.summary.tax) }}
            </div>
            <div
              v-if="order.summary.promo_code"
              class="mt-[6px] pr-[32px] text-xs font-medium text-gpx_gray-300"
            >
              <template
                v-if="order.summary.promo_code.type === 'free_hardware'"
              >
                {{ order.summary.promo_code.amount }} free hardware
              </template>
              <template v-else>
                <span
                  class="font-normal"
                  v-if="order.summary.promo_code.type === 'dollar'"
                >
                  $
                </span>
                <span class="font-normal" v-else>%</span>
                {{ order.summary.promo_code.amount }}
              </template>
            </div>
            <div
              class="text-gpx_black-500 mt-[19px] border-t border-t-gpx_gray-800 pr-[32px] pt-[14px] text-base font-bold"
            >
              <span class="font-normal">$</span>
              {{ formatMoney(order.summary.grand_total) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <ManageOrderDetailsSubscriptionsTable
      class="!mt-[63px] mb-[35px] border-t border-t-gpx_gray-800"
      :orderId="orderId"
    />

    <div
      v-if="shippingAddress || billingAddress"
      class="!mt-[59px] mb-[35px] flex flex-col items-start gap-8 border-t border-t-gpx_gray-800 pt-[34px] sm:flex-row"
    >
      <div v-if="billingAddress" class="w-full">
        <HeadingSection
          class="mt-0 pt-0"
          desc="View billing address"
          heading="Billing Address"
        />
        <UICardAddress
          :address="[
            `${billingAddress.line1}, ${billingAddress.line2}`,
            `${billingAddress.city}, ${billingAddress.state} ${billingAddress.zip}`,
          ]"
          :name="`${billingAddress.first_name} ${billingAddress.last_name}`"
          :phone="billingAddress.phone"
        />
      </div>
      <div v-if="shippingAddress" class="w-full">
        <HeadingSection
          class="mt-0 pt-0"
          desc="View shipping address"
          heading="Shipping Address"
        />
        <UICardAddress
          :address="[
            `${shippingAddress.line1}, ${shippingAddress.line2}`,
            `${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.zip}`,
          ]"
          :name="`${shippingAddress.first_name} ${shippingAddress.last_name}`"
          :phone="shippingAddress.phone"
        />
      </div>
    </div>
  </section>
</template>

<script>
import formatDate from 'date-fns/format';
import { mapActions, mapGetters } from 'vuex';
import { formatMoney } from '@/filters';
import { useToast } from 'vue-toastification';
import HeadingSection from '@/components/_common/Heading/HeadingSection.vue';
import ManageOrderDetailsSubscriptionsTable from '@/components/manage/order/details/ManageOrderDetailsSubscriptionsTable.vue';
import UICardAddress from '@/components/ui/UICardAddress.vue';
import UISpinner from '@/components/ui/UISpinner.vue';
import UIBadge from '@/components/ui/UIBadge.vue';
import { setTitle } from '@/utils/title';
import { PAYABLE_ORDER_STATUSES } from '@/config/constants';
import { useDevice } from '@/composables/useDevice';

export default {
  components: {
    HeadingSection,
    UICardAddress,
    UISpinner,
    UIBadge,
    ManageOrderDetailsSubscriptionsTable,
  },
  setup() {
    const toast = useToast();
    const { fetchByAccountId } = useDevice();
    return { toast, fetchByAccountId };
  },
  data() {
    return {
      loading: true,
      imageLoading: false,
      publishingOrder: false,
    };
  },
  computed: {
    ...mapGetters({
      orderById: 'order/byId',
      accountId: 'auth/accountId',
    }),
    allowedToPay() {
      return PAYABLE_ORDER_STATUSES.includes(this.order.status);
    },
    allowedToViewInvoice() {
      return !['draft', 'cart'].includes(this.order.status);
    },
    payNowActions() {
      const payNowAction = {
        icon: 'CurrencyDollarIcon',
        label: 'Pay Now',
        action: {
          mode: 'fn',
          fn: () => {
            return this.handlePayNow(this.orderId);
          },
        },
      };
      const viewInvoiceAction = {
        icon: 'ClipboardDocumentListIcon',
        label: 'View Invoice',
        action: {
          mode: 'fn',
          fn: () => {
            return this.handleViewInvoice(this.orderId);
          },
        },
      };
      if (this.allowedToPay) {
        return [payNowAction, viewInvoiceAction];
      }
      return [viewInvoiceAction];
    },
    orderId() {
      return parseInt(this.$route.params.id);
    },
    order() {
      return this.orderById(this.orderId);
    },
    orderDate() {
      if (!this.order) {
        return '';
      }
      return formatDate(new Date(this.order.created_at), 'MMMM dd, yyyy');
    },
    orderItems() {
      if (!this.order || !this.order.orderItems) {
        return null;
      }

      return this.order.orderItems;
    },
    billingAddress() {
      if (!this.order || !this.order.billingAddress) {
        return undefined;
      }
      return this.order.billingAddress;
    },
    shippingAddress() {
      if (!this.order || !this.order.shippingAddress) {
        return undefined;
      }
      return this.order.shippingAddress;
    },
  },
  created() {
    setTitle(`Order #${this.orderId}`);
  },
  async mounted() {
    await Promise.all([
      this.fetchOrder({
        orderId: this.orderId,
        params: {
          include:
            'account,billingAddress,shippingAddress,invoiceItems,payments',
        },
      }),
      this.fetchByAccountId(this.accountId, {}),
    ]);
    this.loading = false;
  },

  methods: {
    formatMoney,
    ...mapActions({
      fetchOrder: 'order/fetchById',
      openModal: 'modal/open',
    }),
    handlePayNow(orderId) {
      this.openModal({
        name: 'ManageOrderInvoiceModal',
        params: {
          mode: 'pay',
          orderId,
        },
      });
    },
    handleViewInvoice(orderId) {
      this.openModal({
        name: 'ManageOrderInvoiceModal',
        params: {
          mode: 'view',
          orderId,
        },
      });
    },
  },
};
</script>
