<template>
  <div
    class="flex items-center justify-between border-y border-lgmx_gray-200 py-[12px] text-[13px] text-lgmx_gray-900"
  >
    <div v-if="total === 0">No results</div>
    <div v-else>{{ from }} to {{ to }} / {{ total }} {{ resultsLabel }}</div>
    <div class="flex items-center text-lgmx_slate-500">
      <span class="px-[6px] text-lgmx_black-100">
        {{ page }} / {{ lastPage }}
      </span>
      <button
        class="p-[4px] disabled:text-lgmx_slate-100"
        :disabled="!hasPrevPage"
        @click="goFirst"
      >
        <ChevronDoubleLeftIcon class="h-[18px] w-[18px]" />
      </button>
      <button
        class="p-[4px] disabled:text-lgmx_slate-100"
        :disabled="!hasPrevPage"
        @click="goPrev"
      >
        <ChevronLeftIcon class="h-[18px] w-[18px]" />
      </button>
      <button
        class="p-[4px] disabled:text-lgmx_slate-100"
        :disabled="!hasNextPage"
        @click="goNext"
      >
        <ChevronRightIcon class="h-[18px] w-[18px]" />
      </button>
      <button
        class="p-[4px] disabled:text-lgmx_slate-100"
        :disabled="!hasNextPage"
        @click="goLast"
      >
        <ChevronDoubleRightIcon class="h-[18px] w-[18px]" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: Object,
  },

  computed: {
    from() {
      const { pageSize, page } = this.modelValue;
      return pageSize * (page - 1) + 1;
    },
    to() {
      const { pageSize, page } = this.modelValue;
      if (this.total < 10) return this.total;
      return pageSize * page;
    },
    total() {
      return this.modelValue.total;
    },
    page() {
      if (this.total === 0) return '-';
      return this.modelValue.page;
    },
    lastPage() {
      if (this.total === 0) return '-';
      return this.modelValue.lastPage;
    },
    hasNextPage() {
      return this.modelValue.page < this.modelValue.lastPage;
    },
    hasPrevPage() {
      return this.modelValue.page > 1;
    },
    resultsLabel() {
      if (this.total === 1) return 'result';
      return 'results';
    },
  },

  methods: {
    goToPage(page) {
      this.$emit('update:modelValue', {
        ...this.modelValue,
        page,
      });
    },
    goFirst() {
      this.goToPage(1);
    },
    goPrev() {
      this.goToPage(this.modelValue.page - 1);
    },
    goNext() {
      this.goToPage(this.modelValue.page + 1);
    },
    goLast() {
      this.goToPage(this.modelValue.lastPage);
    },
  },
};
</script>
