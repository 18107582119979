<template>
  <div class="pt-[31px]">
    <div class="mb-[14px] flex justify-center">
      <img :src="svg1" alt="Close SIM door" />
    </div>

    <div class="text-[13px] leading-5 text-lgmx_black-200">
      From here you can re-fasten the SIM door, making sure that it is closed
      and locked in properly when the new SIM is inserted. Re-fasten the SIM
      door by pushing it down and sliding it up, away from the charging port.
    </div>

    <div class="flex justify-between gap-x-4 pb-[18px] pt-[26px]">
      <UIButtonNew
        variant="tertiary"
        size="lg"
        class="w-[124px]"
        @click="$emit('onCancel')"
      >
        Back
      </UIButtonNew>
      <UIButtonNew @click="$emit('onConfirm')" class="w-full">
        Next
      </UIButtonNew>
    </div>
  </div>
</template>

<script>
import Step7Svg1 from './assets/step-7.svg';

export default {
  emits: ['onConfirm', 'onCancel'],

  computed: {
    svg1() {
      return Step7Svg1;
    },
  },
};
</script>
