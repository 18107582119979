import _ from 'lodash';

const processStrategy = (subscription) => {
  const relations = {};
  const omit = [];
  return { ..._.omit(subscription, omit), ..._.pickBy(relations, _.identity) };
};

export default {
  key: 'subscription',
  definition: {},
  processStrategy,
};
