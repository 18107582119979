<template>
  <form class="mb-4 max-w-[664px]" @submit.prevent="handleSubmit">
    <div class="mb-4">
      <label class="mb-2 inline-block" for="name">Name</label>
      <VInput
        id="name"
        type="text"
        v-model="form.name"
        class="block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
        autocomplete="name"
      />
    </div>
    <div class="mb-4">
      <label class="mb-2 inline-block" for="email">Email</label>
      <VInput
        id="email"
        type="email"
        v-model="form.email"
        inputmode="email"
        autocomplete="email"
      />
    </div>
    <div class="mb-4">
      <label class="mb-2 inline-block" for="phone">Phone</label>
      <VInput
        id="phone"
        type="tel"
        v-model="form.phone"
        v-maska
        data-maska="(###) ###-####"
        inputmode="tel"
        autocomplete="tel"
      />
    </div>
    <div class="mb-4">
      <VSelect variant="block" v-model="form.timezone_id" :options="timezones">
        <span class="mb-2 inline-block">Time zone</span>
      </VSelect>
    </div>
    <div class="text-right">
      <VButton
        type="submit"
        variant="outline-secondary"
        class="w-40"
        :isLoading="isSubmitting"
        loadingText="Updating..."
        :isDisabled="isSubmitDisabled"
      >
        Update account
      </VButton>
    </div>
  </form>
</template>

<script>
import { vMaska } from 'maska';
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import VButton from '@/components/ui/VButton.vue';
import VInput from '@/components/ui/VInput.vue';
import VSelect from '@/components/ui/VSelect.vue';
import { COMMON_UPDATE_SUCCESS } from '@/config/constants';

export default {
  directives: { maska: vMaska },
  components: {
    VButton,
    VInput,
    VSelect,
  },

  data() {
    return {
      isSubmitting: false,
      form: {
        name: '',
        email: '',
        phone: '',
        timezone_id: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      timezones: 'settings/timezones',
    }),
    isSubmitDisabled() {
      return this.isSubmitting || !this.form.email || !this.form.timezone_id;
    },
  },

  created() {
    this.form.name = this.user.name;
    this.form.email = this.user.email;
    this.form.phone = this.user.phone;
    this.form.timezone_id = this.user.timezone_id;
    this.fetchTimezones();
  },

  methods: {
    ...mapActions({
      updateMe: 'auth/updateMe',
      updateCurrentUser: 'auth/updateCurrentUser',
      fetchTimezones: 'settings/fetchTimezones',
    }),
    handleSubmit() {
      const toast = useToast();
      const { form } = this;
      this.isSubmitting = true;
      this.updateMe(form)
        .then((res) => {
          this.isSubmitting = false;
          this.updateCurrentUser(res.data.data);
          toast.success(COMMON_UPDATE_SUCCESS);
        })
        .catch((err) => {
          console.error(err);
          this.isSubmitting = false;
        });
    },
  },
};
</script>

<style></style>
