<script setup lang="ts">
import * as _ from 'lodash';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import 'vue3-circle-progress/dist/circle-progress.css';
import CircleProgress from 'vue3-circle-progress';
import ModalLayout from '@/layouts/ModalLayout.vue';
import Step1 from './Step1.vue';
import Step2 from './Step2.vue';
import Step3 from './Step3.vue';
import { computed, onMounted, reactive } from 'vue';
import { useGroup } from '@/composables/useGroup.ts';

const toast = useToast();
const store = useStore();
const groupStore = useGroup();

const { storeGroup, updateGroup, addDevices, swapDevices, removeDevices } =
  groupStore;

defineEmits(['close']);

const props = defineProps<{
  params: any;
}>();

const data = reactive({
  isSubmitting: false,
  shouldShowCloseTooltip: false,
  step: 1,
  groupName: '',
  initGroupName: '',
  selected: [],
});

const accountId = store.getters['auth/accountId'];
const circlePercentage = computed(() => {
  if (data.step === 1) return 33;
  if (data.step === 2) return 66;
  if (data.step === 3) return 100;
  return 0;
});

onMounted(() => {
  data.step = _.get(props.params, 'step', 1);
  data.groupName = data.initGroupName = _.get(props.params, 'name', '');
  data.selected = [..._.get(props.params, 'device_ids', [])];
});

const closeModal = () => {
  store.dispatch('modal/close', 'DeviceGroupModal');
};

const safeCloseClick = () => {
  if (data.groupName.length) {
    data.shouldShowCloseTooltip = true;
    return;
  }
  closeModal();
};

const confirmOnTooltip = () => {
  data.shouldShowCloseTooltip = false;
  closeModal();
};

const discardOnTooltip = () => {
  data.shouldShowCloseTooltip = false;
};

const goToStep = (step) => {
  data.step = step;
};

const confirmStepOne = () => {
  goToStep(2);
};

const confirmStepTwo = (selectedDevices) => {
  goToStep(3);
  data.selected = selectedDevices;
};

const discardStepTwo = (selectedDevices) => {
  goToStep(1);
  data.selected = selectedDevices;
};

const confirmStepThree = async (reviewedDevices) => {
  data.selected = reviewedDevices;
  data.isSubmitting = true;
  if (props.params.actionType === 'create') {
    storeGroup(accountId, {
      name: data.groupName,
      device_ids: data.selected,
    })
      .then(() => {
        toast.success(`${data.groupName} has been created`);
        closeModal();
      })
      .catch((e) => {
        console.log(e);
        data.isSubmitting = false;
        toast.error(e.response?.data?.message || 'An error occurred');
      });
  }

  if (['add', 'swap'].includes(props.params.actionType)) {
    const updateName = () => {
      if (data.initGroupName === data.groupName) {
        return Promise.resolve();
      }

      return updateGroup(accountId, props.params.id, {
        name: data.groupName,
      });
    };

    if (props.params.actionType === 'add') {
      const add = () =>
        addDevices(accountId, props.params.id, {
          device_ids: reviewedDevices,
        });

      //@TODO Done synchronously to correctly send the information to Vitally on the server.
      try {
        await add();
        await updateName();
        closeModal();
        toast.success(`${data.groupName} has been updated`);
      } catch (e) {
        data.isSubmitting = false;
        toast.error(e.response?.data?.message || 'An error occurred');
      }
    }

    if (props.params.actionType === 'swap') {
      let swapOrDelete;
      if (reviewedDevices.length) {
        swapOrDelete = () =>
          swapDevices(accountId, props.params.id, {
            device_ids: reviewedDevices,
          });
      } else {
        swapOrDelete = () => removeDevices(accountId, props.params.id, {});
      }

      try {
        await swapOrDelete();
        await updateName();
        closeModal();
        toast.success(`${data.groupName} has been updated`);
      } catch (e) {
        data.isSubmitting = false;
        toast.error(e.response?.data?.message || 'An error occurred');
      }
    }
  }
};

const discardStepThree = (reviewedDevices) => {
  goToStep(2);
  data.selected = reviewedDevices;
};
</script>
<template>
  <ModalLayout title="Creating new Group">
    <div
      class="flex rounded-t-[10px] bg-white px-[24px] pb-[26px] pt-[28px] sm:px-[40px] sm:pt-[30px]"
    >
      <div class="relative mr-[15px] h-[50px] w-[50px] shrink-0">
        <div
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          <span class="text-[19px] font-semibold leading-7 text-lgmx_black-200">
            {{ data.step }}
          </span>
          <span class="text-[13px] leading-5 text-lgmx_gray-900">/3</span>
        </div>
        <CircleProgress
          :percent="circlePercentage"
          :size="50"
          :border-width="6"
          :border-bg-width="6"
          fill-color="#77D770"
          empty-color="#F1F3F5"
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </div>
      <div class="mr-[24px] overflow-hidden">
        <div class="text-[13px] leading-5 text-lgmx_gray-800">
          <span v-if="data.step === 1">Name your group</span>
          <span v-if="data.step === 2">Add trackers to the group</span>
          <span v-if="data.step === 3">Creating new</span>
        </div>
        <div class="text-ellipsis whitespace-nowrap">
          <div
            class="overflow-hidden text-ellipsis text-[24px] font-semibold leading-7 text-lgmx_black-600"
          >
            {{ data.groupName || 'Tracker Group' }}
          </div>
        </div>
      </div>
      <UITooltipConfirm
        :disabled="!data.groupName.length || data.isSubmitting"
        text="Discard all changes?"
        class="ml-auto"
        :shown="data.shouldShowCloseTooltip"
        @on-confirm="confirmOnTooltip"
        @on-cancel="discardOnTooltip"
      >
        <button
          class="flex h-[32px] w-[32px] shrink-0 cursor-pointer items-center justify-center rounded-full bg-lgmx_gray-100"
          @click="safeCloseClick"
        >
          <XMarkIcon
            class="heroicon-stroke-w-1.4 h-[16px] w-[16px] text-lgmx_gray-800"
          />
        </button>
      </UITooltipConfirm>
    </div>
    <Step1
      v-if="data.step === 1"
      v-model="data.groupName"
      @on-confirm="confirmStepOne"
      @on-cancel="safeCloseClick"
    />
    <Step2
      v-if="data.step === 2"
      :selected="data.selected"
      @on-confirm="confirmStepTwo"
      @on-cancel="discardStepTwo"
    />
    <Step3
      v-if="data.step === 3"
      :is-submitting="data.isSubmitting"
      :selected="data.selected"
      @on-confirm="confirmStepThree"
      @on-cancel="discardStepThree"
    />
  </ModalLayout>
</template>
