import _ from 'lodash';

const processStrategy = (group) => {
  const relations = {};
  const omit = [];
  return { ..._.omit(group, omit), ..._.pickBy(relations, _.identity) };
};

export default {
  key: 'group',
  definition: {},
  processStrategy,
};
