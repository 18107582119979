<template>
  <div class="text-[13px] leading-5 text-lgmx_black-100">
    {{ qty }} {{ noun }} will be added
  </div>
</template>

<script>
export default {
  props: {
    qty: [Number, String],
    entity: String,
  },

  computed: {
    noun() {
      return this.qty === 1 ? this.entity : `${this.entity}s`;
    },
  },
};
</script>
