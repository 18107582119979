<template>
  <BaseLayout>
    <div class="mb-10 flex justify-between border-b border-gpx_gray-800 pb-4">
      <div>
        <h1 class="text-2xl font-medium leading-tight">Utilities</h1>
      </div>
      <div class="flex items-center gap-x-3">
        <span class="inline-block text-sm">Filter Actions:</span>
        <VInput
          class="min-w-[200px]"
          size="sm"
          type="text"
          placeholder="Start typing action name..."
          v-model="filter"
        />
      </div>
    </div>
    <div
      class="mt-6 grid grid-flow-dense grid-cols-3 items-start gap-x-[32px] gap-y-[40px]"
    >
      <UtilitySection
        v-for="(item, idx) in filteredItems"
        :key="`util-${idx}`"
        :heading="item.label"
      >
        <component :is="item.form" />
      </UtilitySection>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout.vue';
import UtilitySection from '@/components/utility/UtilitySection.vue';
import UtilityProvisionForm from '@/components/utility/UtilityProvisionForm.vue';
import UtilityReserveForm from '@/components/utility/UtilityReserveForm.vue';
import UtilitySetupForm from '@/components/utility/UtilitySetupForm.vue';
import UtilityAssignForm from '@/components/utility/UtilityAssignForm.vue';
import UtilityDeactivateForm from '@/components/utility/UtilityDeactivateForm.vue';
import UtilityChangeDeviceTypeForm from '@/components/utility/UtilityChangeDeviceTypeForm.vue';
import UtilityAddRemoveMinutesForm from '@/components/utility/UtilityAddRemoveMinutesForm.vue';
import UtilitySetSubscriptionForm from '@/components/utility/UtilitySetSubscriptionForm.vue';
import UtilityRefreshPhoneNumberForm from '@/components/utility/UtilityRefreshPhoneNumberForm.vue';
import UtilityReserveRangeForm from '@/components/utility/UtilityReserveRangeForm.vue';
import UtilityUnassignSubscriptionForm from '@/components/utility/UtilityUnassignSubscriptionForm.vue';
import UtilityUnassignDeviceForm from '@/components/utility/UtilityUnassignDeviceForm.vue';
import UtilityAssignRangeForm from '@/components/utility/UtilityAssignRangeForm.vue';
import UtilitySendCommandsForm from '@/components/utility/UtilitySendCommandsForm.vue';
import UtilityGetDeviceMessagesForm from '@/components/utility/UtilityGetDeviceMessagesForm.vue';
import UtilitySwapSIMForm from '@/components/utility/UtilitySwapSIMForm.vue';
import UtilitySendBulkCommandForm from '@/components/utility/UtilitySendBulkCommandForm.vue';
import UtilitySetFlagsForm from '@/components/utility/UtilitySetFlagsForm.vue';
import UtilityClearDeviceForm from '@/components/utility/UtilityClearDeviceForm.vue';

export default {
  name: 'UtilityView',
  components: {
    BaseLayout,
    UtilitySection,
    UtilityProvisionForm,
    UtilityReserveForm,
    UtilitySetupForm,
    UtilityAssignForm,
    UtilityDeactivateForm,
    UtilityChangeDeviceTypeForm,
    UtilityAddRemoveMinutesForm,
    UtilitySetSubscriptionForm,
    UtilityRefreshPhoneNumberForm,
    UtilityReserveRangeForm,
    UtilityUnassignSubscriptionForm,
    UtilityUnassignDeviceForm,
    UtilityAssignRangeForm,
    UtilitySendCommandsForm,
    UtilityGetDeviceMessagesForm,
    UtilitySwapSIMForm,
    UtilitySendBulkCommandForm,
    UtilitySetFlagsForm,
    UtilityClearDeviceForm,
  },
  data() {
    return {
      filter: null,
      items: [
        { label: 'Provision', form: 'UtilityProvisionForm' },
        { label: 'Reserve', form: 'UtilityReserveForm' },
        { label: 'Setup', form: 'UtilitySetupForm' },
        { label: 'Assign', form: 'UtilityAssignForm' },
        { label: 'Deactivate', form: 'UtilityDeactivateForm' },
        { label: 'Change Device Type', form: 'UtilityChangeDeviceTypeForm' },
        { label: 'Add/Remove Minutes', form: 'UtilityAddRemoveMinutesForm' },
        { label: 'Set Subscription', form: 'UtilitySetSubscriptionForm' },
        {
          label: 'Refresh Phone Number',
          form: 'UtilityRefreshPhoneNumberForm',
        },
        { label: 'Reserve Range', form: 'UtilityReserveRangeForm' },
        {
          label: 'Unassign Subscription',
          form: 'UtilityUnassignSubscriptionForm',
        },
        {
          label: 'Unassign Device',
          form: 'UtilityUnassignDeviceForm',
        },
        {
          label: 'Assign Range',
          form: 'UtilityAssignRangeForm',
        },
        {
          label: 'Send Commands',
          form: 'UtilitySendCommandsForm',
        },
        {
          label: 'Get Device Messages',
          form: 'UtilityGetDeviceMessagesForm',
        },
        {
          label: 'Swap SIM',
          form: 'UtilitySwapSIMForm',
        },
        {
          label: 'Send Bulk Command',
          form: 'UtilitySendBulkCommandForm',
        },
        {
          label: 'Set Flags',
          form: 'UtilitySetFlagsForm',
        },
        {
          label: 'Clear Device',
          form: 'UtilityClearDeviceForm',
        },
      ],
    };
  },
  computed: {
    filteredItems() {
      if (this.filter) {
        const lowerCaseFilter = this.filter.toLowerCase();
        return this.items.filter((item) =>
          item.label.toLowerCase().includes(lowerCaseFilter),
        );
      } else {
        return this.items;
      }
    },
  },
};
</script>
