import isNull from 'lodash/isNull';

export const uuidv4 = () => {
  return `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );
};

/**
 * Exposes vue-router to use it with leaflet.js
 * @param {object} router Vue3 router object
 */
export const exposeRouter = (router) => {
  window.vrouter = router;
};

export const isEmpty = (value) => isNull(value) || String(value).length === 0;
