<template>
  <UIBadgeNew
    :variant="variant"
    class="gap-x-[5px] px-[10px] pb-[4px] pt-[5px]"
  >
    <span
      class="text-[8px]"
      :class="[
        {
          ' text-lgmx_red-200': variant === BADGE_VARIANT.error,
          ' text-lgmx_yellow-200': variant === BADGE_VARIANT.warning,
          ' text-lgmx_green-200': variant === BADGE_VARIANT.success,
          ' text-lgmx_gray-200': variant === BADGE_VARIANT.default,
        },
      ]"
      >●</span
    >
    <slot />
  </UIBadgeNew>
</template>

<script>
import UIBadgeNew from '../UIBadgeNew/UIBadgeNew.vue';
import { BADGE_VARIANT } from './utils';

export default {
  props: {
    variant: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      BADGE_VARIANT: BADGE_VARIANT,
    };
  },
  components: { UIBadgeNew },
};
</script>
