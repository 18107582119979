<template>
  <DeviceList v-if="resultDeviceIds.length > 0" :device-ids="resultDeviceIds" />
  <LoaderBlock v-if="isLoading" />
  <NoData
    v-else-if="resultDeviceIds.length === 0 && !isLoading"
    title="There are no trackers"
    subtitle="Your trackers list is empty"
  />
</template>

<script setup>
import DeviceList from '@/components/device/list/DeviceList.vue';
import LoaderBlock from '@/components/_common/LoaderBlock.vue';
import NoData from '@/components/_common/NoData.vue';

import { useDevice } from '@/composables/useDevice';
import { storeToRefs } from 'pinia';
import { setTitle } from '@/utils/title';

setTitle('All Trackers');

const { resultDeviceIds, isLoading } = storeToRefs(useDevice());
</script>
