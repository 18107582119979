<template>
  <div class="flex flex-col gap-y-[16px]">
    <UICheckbox
      label="Geofence Enter"
      size="medium"
      ref="geofence_enter"
      v-model="eventTypes.geofence_enter"
    />
    <UICheckbox
      label="Geofence Exit"
      size="medium"
      ref="geofence_exit"
      v-model="eventTypes.geofence_exit"
    />
    <UICheckbox
      label="Low battery"
      size="medium"
      ref="low_battery"
      v-model="eventTypes.low_battery"
    />
    <UICheckbox
      label="Tamper Alert"
      size="medium"
      ref="tamper_alert"
      v-model="eventTypes.tamper_alert"
    />
    <UICheckbox
      label="Shipment Start"
      size="medium"
      ref="shipment_start"
      v-model="eventTypes.shipment_begin"
    />
    <UICheckbox
      label="Shipment End"
      size="medium"
      shipment_end="shipment_end"
      v-model="eventTypes.shipment_end"
    />
    <UICheckbox
      label="Shipment Late"
      size="medium"
      :reference-error="shipment_late"
      v-model="eventTypes.shipment_late"
    />
    <UICheckbox
      label="Shipment Nofification"
      size="medium"
      ref="shipment_notification"
      v-model="eventTypes.shipment_notify"
    />
  </div>
</template>
<script>
export default {
  name: 'EventTypeField',
  emits: ['onChange'],
  props: {
    types: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      eventTypes: {
        geofence_enter: false,
        geofence_exit: false,
        low_battery: false,
        tamper_alert: false,
        shipment_begin: false,
        shipment_end: false,
        shipment_late: false,
        shipment_notify: false,
      },
    };
  },
  mounted() {
    this.types?.forEach((type) => {
      this.$refs[type.replace('-', '_')].setCheckQuietly(true);
    });
  },
  watch: {
    eventTypes: {
      handler() {
        const eventTypesValue = Object.keys(this.eventTypes).reduce(
          (acc, key) => {
            if (!this.eventTypes[key]) {
              return acc;
            }
            return [...acc, key.replace('_', '-')];
          },
          [],
        );

        this.$emit('onChange', eventTypesValue);
      },
      deep: true,
    },
  },
};
</script>
