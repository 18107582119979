<template>
  <BaseLayout>
    <div class="flex flex-wrap">
      <div class="mb-5 w-full sm:mb-0 sm:w-1/4">
        <h4 class="text-2xl font-medium leading-tight">Account</h4>
      </div>
      <div class="w-full sm:w-3/4 sm:pl-8">
        <AccountDetailsForm />
      </div>
    </div>

    <VDivider class="my-4" />

    <div class="flex flex-wrap">
      <div class="mb-5 w-full sm:mb-0 sm:w-1/4">
        <h4 class="text-2xl font-medium leading-tight">Password</h4>
      </div>
      <div class="w-full sm:w-3/4 sm:pl-8">
        <AccountPasswordForm />
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout.vue';
import VDivider from '@/components/ui/VDivider.vue';
import AccountDetailsForm from '@/components/user-account/UserAccountDetailsForm.vue';
import AccountPasswordForm from '@/components/user-account/UserAccountPasswordForm.vue';
import { setTitle } from '@/utils/title';

export default {
  components: {
    BaseLayout,
    VDivider,
    AccountDetailsForm,
    AccountPasswordForm,
  },

  created() {
    setTitle('Account Settings');
  },
};
</script>

<style></style>
