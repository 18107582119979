<template>
  <div
    class="flex h-16 w-16 items-center justify-center rounded-full"
    :class="[
      {
        'bg-lgmx_green-100': variant === INDICATOR_VARIANT.success,
        'bg-lgmx_red-100': variant === INDICATOR_VARIANT.error,
      },
    ]"
  >
    <CheckIcon
      v-if="variant === INDICATOR_VARIANT.success"
      class="h-8 w-8 stroke-lgmx_green-200"
    />
    <XMarkIcon
      v-if="variant === INDICATOR_VARIANT.error"
      class="h-8 w-8 stroke-lgmx_red-200"
    />
  </div>
</template>

<script>
import { INDICATOR_VARIANT } from './utils.js';

export default {
  props: {
    variant: {
      type: String,
      default: INDICATOR_VARIANT.success,
      validator: (variant) => Object.keys(INDICATOR_VARIANT).includes(variant),
    },
  },
  computed: {
    INDICATOR_VARIANT() {
      return INDICATOR_VARIANT;
    },
  },
};
</script>
