import * as _ from 'lodash';

export function getCardLabel(card) {
  return `${_.upperFirst(card.brand)}, *${card.last4}, expires ${_.padStart(
    card.exp_month,
    2,
    '0',
  )}/${card.exp_year}`;
}

export function getStripeElementsAppearance() {
  return {
    variables: {
      fontFamily: 'Inter, sans-serif',
      colorText: '#81959c',
      spacingGridRow: '27px',
      spacingGridColumn: '32px',
    },
    rules: {
      '.Label': {
        marginBottom: '3px',
        color: '#818c9c',
        fontSize: '12px',
        fontWeight: '500',
        letterSpacing: '0.01em',
        lineHeight: '18px',
      },
      '.Input': {
        padding: '14px 22px',
        border: 'none',
        borderRadius: '6px',
        backgroundColor: '#f4f4f6',
        color: '#3b3a3a',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '20px',
      },
    },
  };
}
