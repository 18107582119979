<script setup>
import { ref } from 'vue';
import UIIconButton from '../ui/UIIconButton.vue';

defineEmits(['update:modelValue', 'close']);
defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: true,
  },
});

const inputRef = ref(null);

defineExpose({ inputRef });
</script>
<template>
  <MagnifyingGlassIcon
    class="mr-[14px] h-[18px] min-w-[18px] text-gpx_gray-600"
  />
  <input
    ref="inputRef"
    :placeholder="placeholder"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    type="text"
    class="w-full border-none py-0 text-lgmx_19 font-semibold leading-7 text-lgmx_black-600 placeholder:text-lgmx_slate-300 focus:ring-transparent"
  />
  <UIIconButton icon="XMarkIcon" @click="$emit('close')" />
</template>
