import _ from 'lodash';

const processStrategy = (dashboardDwell) => {
  const relations = {};
  const omit = [];
  return {
    ..._.omit(dashboardDwell, omit),
    ..._.pickBy(relations, _.identity),
  };
};

export default {
  key: 'dashboardDwell',
  definition: {},
  processStrategy,
};
