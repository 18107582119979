<template>
  <LoaderBlock v-if="isLoading" />
  <NoData
    v-else-if="searchedIds.length === 0 && groups.size === 0"
    title="There are no Groups"
    subtitle="Create your first group for streamlined asset management"
  >
    <UIButtonNew icon="SquaresPlusIcon" @click="handleCreateClick">
      Add Group
    </UIButtonNew>
  </NoData>
  <GroupList v-else :group-ids="searchedIds" />
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import GroupList from '@/components/device/list/GroupList.vue';
import { setTitle } from '@/utils/title';
import NoData from '@/components/_common/NoData.vue';
import { useToast } from 'vue-toastification';
import { MESSAGE403 } from '@/config/constants';
import * as events from '@/events';
import { useGroup } from '@/composables/useGroup.ts';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import LoaderBlock from '@/components/_common/LoaderBlock.vue';

setTitle('All Groups');

const isLoading = ref(true);
const toast = useToast();
const store = useStore();

const groupStore = useGroup();
const { searchedIds, groups } = storeToRefs(groupStore);
const { fetchByAccountId } = groupStore;

const canManageGroups = store.getters['auth/canManageGroups'];
const accountId = store.getters['auth/accountId'];

onMounted(async () => {
  await fetchByAccountId(accountId, {
    exclude_devices: 1,
  });
  isLoading.value = false;

  events.trigger(events.names.ALL_GROUPS_VIEWED);
});

const handleCreateClick = () => {
  if (!canManageGroups) {
    toast.error(MESSAGE403);
    return;
  }
  store.dispatch('modal/open', {
    name: 'DeviceGroupModal',
    params: { actionType: 'create' },
  });
};
</script>
