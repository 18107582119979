<template>
  <NoData
    v-if="shipments.length === 0 && !fetching"
    title="There are no archived shipments"
  >
  </NoData>
  <div class="h-full" v-else>
    <CommonSearchBarSafeArea />
    <CommonListGrayArea>
      <UICheckboxNew
        id="select_all_archived"
        class="mr-4"
        :checked="
          isIndeterminate || selectedIds.length === props.shipments.length
        "
        :indeterminate="isIndeterminate"
        @change="selectAll($event.target.checked)"
      />
      <label
        for="select_all_archived"
        class="cursor-pointer text-[11px] font-semibold uppercase leading-5 text-lgmx_slate-400"
      >
        Select All
      </label>
    </CommonListGrayArea>
    <DynamicScroller
      class="h-[calc(100%-48px)] sm:h-[calc(100%-64px)]"
      :items="props.shipments"
      :min-item-size="98"
      key-field="id"
      listClass="ds-shipment-list"
      itemClass="ds-shipment-list-item"
    >
      <template v-slot="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :size-dependencies="[item.name, item.address]"
          :data-index="index"
        >
          <div class="border-b border-gray-100">
            <VLink
              block
              :to="`/shipments/${item.id}`"
              class="bg-white hover:bg-gray-100"
            >
              <div class="flex py-[15px] pl-6 pr-[48px] md:pl-12 md:pr-[56px]">
                <div @click.stop="" class="mr-4 flex pt-[3px]">
                  <UICheckboxNewVModel :value="item.id" v-model="selectedIds" />
                </div>
                <ShipmentListItem
                  class="w-full overflow-hidden"
                  :shipment="item"
                  :actions="itemActions"
                  :edit-item-id="editItemId"
                  @setEditItemId="setEditItemId"
                  @resetEditItemId="resetEditItemId"
                />
              </div>
            </VLink>
          </div>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
  </div>
  <CommonBottomButtonSection v-if="isBottomButton">
    <UIMenuButton
      v-if="selectedIds.length"
      mainIcon="ArchiveBoxIcon"
      mainLabel="Unarchive"
      @click="handleUnarchive({ ids: selectedIds })"
      :options="menuButtonActions"
      fullWidth
    />
  </CommonBottomButtonSection>
</template>

<script setup>
import * as _ from 'lodash';
import VLink from '@/components/ui/VLink.vue';
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import NoData from '@/components/_common/NoData.vue';
import ShipmentListItem from './ShipmentListItem.vue';
import CommonSearchBarSafeArea from '@/components/_common/CommonSearchBarSafeArea.vue';
import CommonBottomButtonSection from '@/components/_common/CommonBottomButtonSection.vue';
import { useSetItemIdOnDynamicList } from '@/use/use-set-item-id-on-recycle-list';
import CommonListGrayArea from '@/components/_common/CommonListGrayArea.vue';
import {
  COMMON_DELETE_ERROR,
  COMMON_DELETE_SUCCESS,
  COMMON_UNARCHIVE_ERROR,
  COMMON_UNARCHIVE_SUCCESS,
  SHIPMENT_CATEGORY,
} from '@/config/constants';
import { getNoun, getPronoun } from '@/utils/noun';
import * as events from '@/events';

const archived = SHIPMENT_CATEGORY.archived;
const props = defineProps({
  shipments: Array,
});

const store = useStore();
const router = useRouter();
const isBottomButton = computed(() => selectedIds.value.length);
const fetching = computed(() => store.getters['shipment/fetching']);

const { editItemId, setEditItemId, resetEditItemId } =
  useSetItemIdOnDynamicList('shipment');

const itemActions = [
  {
    icon: 'PencilSquareIcon',
    label: 'Edit',
    action: {
      mode: 'fn',
      fn: (id) => {
        router.push({
          name: 'ShipmentEditView',
          params: { id },
        });
      },
    },
  },
  {
    icon: 'ArchiveBoxIcon',
    label: 'Unarchive',
    action: {
      mode: 'fn',
      fn: (id) => {
        handleUnarchive({
          ids: [id],
        });
      },
    },
  },
  {
    icon: 'TrashIcon',
    label: 'Delete',
    action: {
      mode: 'fn',
      fn: (id) => {
        handleDelete({ ids: [id] });
      },
    },
  },
];
const menuButtonActions = [
  {
    icon: 'ArchiveBoxIcon',
    label: 'Unarchive',
    action: {
      mode: 'fn',
      fn: () => handleUnarchive({ ids: selectedIds.value }),
    },
  },
  {
    icon: 'XMarkIcon',
    label: 'Delete',
    action: {
      mode: 'fn',
      fn: () => handleDelete({ ids: selectedIds.value }),
    },
  },
];

const isIndeterminate = computed(() => {
  return (
    selectedIds.value.length > 0 &&
    selectedIds.value.length < props.shipments.length
  );
});
const selectedIds = computed({
  get() {
    return store.getters['shipment/selectedIdsByCategory'](archived);
  },
  set(ids) {
    store.dispatch('shipment/setSelectedIds', {
      key: archived,
      ids,
    });
  },
});
function selectAll(checked) {
  if (checked) {
    store.dispatch('shipment/setSelectedIds', {
      key: archived,
      ids: props.shipments.map((item) => item.id),
    });
  } else {
    store.dispatch('shipment/setSelectedIds', { key: archived, ids: [] });
  }
}

function handleUnarchive({ ids }) {
  const pronoun = getPronoun(ids);
  const noun = getNoun(ids, 'shipment');
  store.dispatch('modal/open', {
    name: 'CommonConfirmationModal',
    params: {
      actionLabel: 'Unarchive',
      title: _.upperFirst(noun),
      body: `Are you sure you want to unarchive ${pronoun} ${noun}?`,
      onOk: () => store.dispatch('shipment/bulkUnarchive', { params: { ids } }),
      callback: resetShipmentsSelection,
      successMessage: COMMON_UNARCHIVE_SUCCESS,
      errorMessage: COMMON_UNARCHIVE_ERROR,
    },
  });
}

function handleDelete({ ids }) {
  const pronoun = getPronoun(ids);
  const noun = getNoun(ids, 'shipment');
  store.dispatch('modal/open', {
    name: 'CommonConfirmationModal',
    params: {
      actionLabel: 'Delete',
      title: `(Archived) ${_.upperFirst(noun)}`,
      body: `Are you sure you want to delete ${pronoun} ${noun}?`,
      onOk: () =>
        store.dispatch('shipment/bulkDelete', {
          params: { ids, key: 'archived' },
        }),
      callback: resetShipmentsSelection,
      successMessage: COMMON_DELETE_SUCCESS,
      errorMessage: COMMON_DELETE_ERROR,
    },
  });
}

function resetShipmentsSelection() {
  store.dispatch('shipment/resetSelectedIds');
}

onMounted(() => {
  events.trigger(events.names.SHIPMENT_ARCHIVED_LIST_VIEWED);
});
</script>

<style>
.ds-shipment-list {
  @apply !overflow-visible;
}
</style>
