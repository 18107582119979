import service, { publicService } from '@/services';
import {
  BATCH_ADD_OR_UPDATE,
  REMOVE,
  ADD,
  BATCH_REVERSED_ADD_OR_UPDATE,
  SET_MOBILE_ACTIVE_TAB,
} from '../mutation-types';
import { addOrUpdateItems, processAndReturn, removeItem } from '../helpers';
import * as events from '@/events';

export const state = {
  items: [],
  mobileActiveTab: 'right',
};

export const getters = {
  all: (state) => state.items,
  byId: (state) => (id) => state.items.find((item) => item.id === id),
  byIds: (state) => (ids) =>
    state.items.filter((item) => ids.indexOf(item.id) > -1),
  byAccountId: (state) => (id) =>
    state.items.filter((item) => {
      return item.account_id === id;
    }),

  // UI getters
  mobileActiveTab: (state) => state.mobileActiveTab,
};

export const mutations = {
  [ADD]: (state, items) => (state.items = items),
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [BATCH_REVERSED_ADD_OR_UPDATE]: (state, items) =>
    addOrUpdateItems(state, items, undefined, undefined, { reversed: true }),
  [REMOVE]: (state, item) => removeItem(state, item),
  [SET_MOBILE_ACTIVE_TAB]: (state, tab) => (state.mobileActiveTab = tab),
};

export const actions = {
  setMobileActiveTab: (store, tab) => store.commit(SET_MOBILE_ACTIVE_TAB, tab),
  fetchByAccountId: (store, { accountId, params }) => {
    return service
      .get(`/accounts/${accountId}/reports`, { params })
      .then((res) => processAndReturn(res, 'report'));
  },
  fetchByAccountIdAndId: (store, { accountId, id, params }) => {
    return service
      .get(`/accounts/${accountId}/reports/${id}`, { params })
      .then((res) => processAndReturn(res, 'report'));
  },
  store: (store, { accountId, params }) => {
    return service
      .post(`/accounts/${accountId}/reports`, params)
      .then((res) => {
        events.trigger(events.names.REPORT_CREATED, {
          report: res.data.data,
        });
        return processAndReturn(res, 'report', { reversed: true });
      });
  },
  update: (store, { accountId, id, params }) => {
    return service
      .put(`/accounts/${accountId}/reports/${id}`, params)
      .then((res) => processAndReturn(res, 'report'));
  },
  delete: (store, { accountId, id }) => {
    return service.delete(`/accounts/${accountId}/reports/${id}`).then(() => {
      events.trigger(events.names.REPORT_DELETED, {
        report: id,
      });
      store.commit(REMOVE, { id });
    });
  },
  fetchByUuid: (store, { uuid, params }) => {
    return publicService.get(`/public-reports/${uuid}`, { params });
  },
  download: (store, { id, params }) => {
    return service
      .get(`reports/${id}/export`, {
        params,
      })
      .then(() => {
        store.dispatch(
          'modal/open',
          {
            name: 'DownloadConfirmationModal',
            params: {
              title: 'Report PDF Download',
              body: 'Your PDF is being generated. Once ready, you will receive an email notification with the download link',
            },
          },
          { root: true },
        );
      });
  },
  publicDownload: (store, { uuid, type, params }) => {
    return publicService
      .get(`/public-reports/${uuid}/export/${type}`, {
        params,
      })
      .then(() => {
        store.dispatch(
          'modal/open',
          {
            name: 'DownloadConfirmationModal',
            params: {
              title: 'Report PDF Download',
              body: 'Your PDF is being generated. Once ready, you will receive an email notification with the download link',
            },
          },
          { root: true },
        );
      });
  },
};
