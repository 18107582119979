<template>
  <UITooltip :text="tooltip" :placement="tooltipPlacement" :disabled="!tooltip">
    <button
      class="flex h-8 w-8 items-center justify-center rounded-sm text-lgmx_slate-300 hover:bg-bg-1 hover:text-lgmx_gray-800 disabled:hover:bg-transparent disabled:hover:text-lgmx_slate-300"
      :class="[{ 'hover:bg-transparent': isLoading }]"
      @click="click"
      @mouseenter="emits('mouseenter')"
      :disabled="disabled"
    >
      <UISpinnerNew v-if="isLoading" size="sm" />
      <component v-else :is="icon" class="h-4 w-4 text-inherit" />
      <slot />
    </button>
  </UITooltip>
</template>

<script setup>
import { useAttrs } from 'vue';
import UISpinnerNew from './UISpinnerNew/UISpinnerNew.vue';

const attrs = useAttrs();
const emits = defineEmits(['click', 'mouseenter']);

defineProps({
  icon: {
    type: String,
    required: true,
  },
  tooltip: {
    type: String,
    default: '',
  },
  tooltipPlacement: {
    type: String,
    defaul: 'top',
  },
  disabled: Boolean,
  isLoading: Boolean,
});

function click($event) {
  if (attrs.type !== 'submit') {
    $event.preventDefault();
  }
  emits('click');
}
</script>
