export function resetLocalStorageKeys(prefix) {
  if (prefix === 'all') {
    return window.localStorage.clear();
  }
  Object.keys(window.localStorage)
    .filter((key) => key.startsWith(prefix))
    .forEach((key) => window.localStorage.removeItem(key));
}

export function resetSessionStorageKeys(prefix) {
  if (prefix === 'all') {
    return window.sessionStorage.clear();
  }
  Object.keys(window.sessionStorage)
    .filter((key) => key.startsWith(prefix))
    .forEach((key) => window.sessionStorage.removeItem(key));
}
