<template>
  <div
    class="relative flex flex-col gap-y-[27px] pb-[13px] pl-[32px] pt-[11px] sm:pl-[40px]"
  >
    <RulesBracket :id="id" :type="type" class="absolute inset-y-0 left-0" />
    <UISelect
      v-if="shouldShowOperator"
      label="Operator"
      :current="selectedOperator || {}"
      v-model="selectedOperator"
      :options="operators"
      placeholder="Rule Operator"
    />
    <UISelect
      :label="label"
      :placeholder="placeholder"
      :current="selectedType || {}"
      v-model="selectedType"
      :options="types"
    />
    <RulesFieldsSet
      v-if="selectedType"
      :type="selectedType?.value"
      v-model="values"
    />
  </div>
</template>

<script>
import omit from 'lodash/omit';
import RulesBracket from '@/components/rules/RulesBracket.vue';
import RulesFieldsSet from '@/components/rules/RulesFieldsSet.vue';

export default {
  name: 'RulesItem',
  components: { RulesBracket, RulesFieldsSet },
  emits: ['input'],
  props: {
    ...RulesBracket.props,
    value: {
      type: Object,
      required: true,
    },
    shouldShowOperator: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedType: null,
      selectedOperator: null,
      values: [],
      conditionTypes: [
        { value: 'tracker_list', label: 'Filter by list of trackers' },
        { value: 'tracker_name', label: 'Filter tracker by name' },
        { value: 'message_content', label: 'Filter by message content' },
        { value: 'geofence', label: 'Filter by geofence' },
        { value: 'event_type', label: 'Filter by event type' },
      ],
      actionTypes: [
        { value: 'send_command_to_tracker', label: 'Send command to tracker' },
        { value: 'send_webhook', label: 'Send webhook' },
        { value: 'send_text_message', label: 'Send text message' },
        { value: 'send_email', label: 'Send email' },
      ],
    };
  },
  watch: {
    values() {
      this.emitInput();
    },
    selectedType() {
      this.emitInput();
    },
    selectedOperator() {
      this.emitInput();
    },
  },
  computed: {
    label() {
      return `${this.type.charAt(0).toUpperCase()}${this.type.slice(1)} Type`;
    },
    placeholder() {
      return `Select ${this.type} type`;
    },
    types() {
      if (this.type === 'condition') {
        return this.conditionTypes;
      }
      if (this.type === 'action') {
        return this.actionTypes;
      }
      return null;
    },
    operators() {
      return [
        { label: 'AND', value: 'and' },
        { label: 'OR', value: 'or' },
      ];
    },
  },
  mounted() {
    this.selectedType = this.types.find(
      (type) => type.value === this.value?.type,
    );

    const matchAgainst = this.value?.operator || 'and';
    this.selectedOperator = this.operators.find(
      (operator) => operator.value === matchAgainst,
    );

    this.values = omit(this.value, 'id', 'type');
  },
  methods: {
    emitInput() {
      this.$emit('input', {
        ...this.values,
        id: this.id,
        type: this.selectedType?.value,
        operator: this.selectedOperator?.value,
      });
    },
  },
};
</script>
