<template>
  <AppHeader />
  <div class="relative z-[1] flex w-full flex-col sm:flex-row">
    <ReportDetailsBar
      :isLoading="isDownloadingPDF"
      @downloadPDF="downloadPDF"
    />
  </div>
  <main class="sm:flex">
    <div
      class="h-layout-mobile sm:h-layout-tablet md:h-layout-desktop hidden overflow-scroll sm:block sm:w-4/12"
      :class="[{ '!block': mobileActiveTab === 'left' }]"
    >
      <CommonSearchBarSafeArea />
      <CommonCollapse title="Report Info" class="mb-1" :initial-open="true">
        <template #button>
          <UITooltip text="Delete this report" class="inline">
            <VButton
              class="ml-4"
              @click.stop="handleDelete"
              size="xs"
              variant="outline-white"
              :isLoading="isDeleting"
              loadingText="Deleting..."
              :isDisabled="isDeleting"
            >
              Delete
            </VButton>
          </UITooltip>
        </template>
        <CommonProperty name="Tracker">{{ deviceName }}</CommonProperty>
        <CommonProperty name="From">{{ startAt }}</CommonProperty>
        <CommonProperty name="To">{{ endAt }}</CommonProperty>
      </CommonCollapse>
      <CommonCollapse title="Addresses" class="mb-1" contentClass="!p-0">
        <template v-if="positionsHistory?.length" #button>
          <UITooltip text="Download spreadsheet" class="inline">
            <VButton
              class="ml-4"
              @click.stop="downloadAddresses"
              size="xs"
              variant="outline-white"
            >
              Download
            </VButton>
          </UITooltip>
        </template>
        <template v-if="positionsHistory?.length">
          <div class="max-h-[300px] overflow-y-scroll">
            <div
              v-for="address in positionsHistory"
              :key="address.timestamp"
              class="border-b border-[#dee2e6] px-5 py-[12px] odd:bg-[#f2f2f2] last-of-type:border-b-0"
            >
              <div>{{ formatReportDate(address.timestamp) }}</div>
              <div>{{ address.address }}</div>
            </div>
          </div>
        </template>
        <div v-else class="bg-[#f2f2f2] px-5 py-[12px]">
          No addresses for this date range
        </div>
      </CommonCollapse>
      <CommonCollapse title="Alerts" contentClass="!p-0">
        <template v-if="alerts.length" #button>
          <UITooltip text="Download spreadsheet">
            <VButton
              class="ml-4"
              @click.stop="downloadAlerts"
              size="xs"
              variant="outline-white"
            >
              Download
            </VButton>
          </UITooltip>
        </template>
        <template v-if="alerts.length">
          <div class="max-h-[300px] overflow-y-scroll">
            <div
              v-for="alert in alerts"
              :key="alert.id"
              class="border-b border-[#dee2e6] px-5 py-[12px] odd:bg-[#f2f2f2] last-of-type:border-b-0"
            >
              <div>{{ formatReportDate(alert.created_at) }}</div>
              <div>{{ alert.description }}</div>
              <div>{{ alert.event_type }}</div>
            </div>
          </div>
        </template>
        <div v-else class="bg-[#f2f2f2] px-5 py-[12px]">
          No alerts for this date range
        </div>
      </CommonCollapse>
    </div>
    <div
      class="h-layout-mobile sm:h-layout-tablet md:h-layout-desktop hidden sm:block sm:w-8/12"
      :class="[{ '!block': mobileActiveTab === 'right' }]"
    >
      <ReportMap
        :key="id"
        :positionsHistory="positionsHistory"
        :stopsHistory="stopsHistory"
      />
    </div>
    <MobileLayoutSwitch type="report" />
  </main>
</template>

<script>
import * as _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import AppHeader from '@/components/_common/AppHeader.vue';
import ReportDetailsBar from '@/components/report/details/ReportDetailsBar.vue';
import CommonProperty from '@/components/_common/CommonProperty.vue';
import CommonCollapse from '@/components/_common/CommonCollapse.vue';
import ReportMap from '@/components/report/map/ReportMap.vue';
import { formatTime, formatTimeAgo } from '@/filters';
import { setTitle } from '@/utils/title';
import { getFileName } from '@/utils/blob';
import { COMMON_DELETE_ERROR, COMMON_DELETE_SUCCESS } from '@/config/constants';
import MobileLayoutSwitch from '@/components/_common/MobileLayoutSwitch.vue';
import CommonSearchBarSafeArea from '@/components/_common/CommonSearchBarSafeArea.vue';

const toast = useToast();

export default {
  components: {
    AppHeader,
    ReportDetailsBar,
    CommonProperty,
    ReportMap,
    CommonCollapse,
    MobileLayoutSwitch,
    CommonSearchBarSafeArea,
  },

  data() {
    return {
      isDownloadingPDF: false,
      isDeleting: false,
      alerts: [],
    };
  },

  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
      mobileActiveTab: 'report/mobileActiveTab',
      reportById: 'report/byId',
      date: 'devicePositionHistory/date',
      positionHistoryByIdAndDate: 'devicePositionHistory/byIdAndDate',
      stopHistoryByIdAndDate: 'deviceStopHistory/byIdAndDate',
    }),
    id() {
      return +this.$route.params.id;
    },
    report() {
      return this.reportById(this.id);
    },
    reportDate() {
      return { dateFrom: this.report.start_at, dateTo: this.report.end_at };
    },
    deviceId() {
      return _.get(this.report, 'device.id', null);
    },
    deviceName() {
      return _.get(this.report, 'device.name', 'No name');
    },
    startAt() {
      const startAt = _.get(this.report, 'start_at', null);
      if (startAt) {
        return this.formatReportDate(startAt);
      }
      return '-';
    },
    endAt() {
      const endAt = _.get(this.report, 'end_at', null);
      if (endAt) {
        return this.formatReportDate(endAt);
      }
      return '-';
    },
    addresses() {
      return this.report.positions || [];
    },
    positionsHistory() {
      return this.positionHistoryByIdAndDate(this.deviceId, this.date);
    },
    stopsHistory() {
      return this.stopHistoryByIdAndDate(this.deviceId, this.date);
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },

  created() {
    setTitle(`Report for ${this.deviceName}`);
  },

  beforeMount() {
    this.setHistoryDate(this.reportDate);
  },

  mounted() {
    this.fetchHistory(this.reportDate);
    this.loadAlerts();
  },

  unmounted() {
    this.resetPositionDate();
    this.resetStopDate();
  },

  methods: {
    formatTimeAgo,
    ...mapActions({
      setPositionDate: 'devicePositionHistory/setDate',
      resetPositionDate: 'devicePositionHistory/resetDate',
      setStopDate: 'deviceStopHistory/setDate',
      resetStopDate: 'deviceStopHistory/resetDate',
      fetchPositionHistory: 'devicePositionHistory/fetchByAccountId',
      fetchStopHistory: 'deviceStopHistory/fetchByAccountId',
      fetchDeviceEvents: 'deviceEvent/fetch',
      downloadReport: 'report/download',
      downoladPositions: 'devicePositionHistory/downloadAll',
      downloadEvents: 'deviceEvent/downloadById',
      delete: 'report/delete',
    }),
    setHistoryDate(date) {
      this.setPositionDate(date);
      this.setStopDate(date);
    },
    fetchHistory(date) {
      this.fetchPositionHistory({
        accountId: this.accountId,
        id: this.deviceId,
        params: {
          ...date,
        },
      });
      this.fetchStopHistory({
        accountId: this.accountId,
        id: this.deviceId,
        params: {
          ...date,
          format: 'zulu',
        },
      });
    },
    loadAlerts() {
      this.fetchDeviceEvents({
        deviceId: this.deviceId,
        params: {
          date_from: this.reportDate.dateFrom,
          date_to: this.reportDate.dateTo,
        },
      }).then((res) => {
        this.alerts = res.data.data;
      });
    },
    handleDelete() {
      if (window.confirm('Are you sure you want to delete this report?')) {
        this.isDeleting = true;
        this.delete({ accountId: this.accountId, id: this.id })
          .then(() => {
            toast.success(COMMON_DELETE_SUCCESS);
            this.$router.replace({ name: 'ReportListView' });
          })
          .catch(() => {
            this.isDeleting = false;
            toast.error(COMMON_DELETE_ERROR);
          });
      }
    },
    downloadPDF() {
      this.isDownloadingPDF = true;
      this.downloadReport({
        id: this.id,
        params: {
          offset: new Date().getTimezoneOffset(),
        },
      }).finally(() => (this.isDownloadingPDF = false));
    },
    downloadAddresses() {
      this.downoladPositions({
        accountId: this.accountId,
        id: this.deviceId,
        fileName: getFileName({
          prefix: `addresses_${this.deviceName}`,
          dateFrom: this.reportDate.dateFrom,
          dateTo: this.reportDate.dateTo,
        }),
        params: {
          download: true,
          offset: new Date().getTimezoneOffset(),
          date_from: this.reportDate.dateFrom,
          date_to: this.reportDate.dateTo,
        },
      });
    },
    downloadAlerts() {
      this.downloadEvents({
        id: this.deviceId,
        fileName: getFileName({
          prefix: `alerts_${this.deviceName}`,
          dateFrom: this.reportDate.dateFrom,
          dateTo: this.reportDate.dateTo,
        }),
        params: {
          download: true,
          offset: new Date().getTimezoneOffset(),
          date_from: this.reportDate.dateFrom,
          date_to: this.reportDate.dateTo,
        },
      });
    },
    formatReportDate(date) {
      if (!date) return '-';
      return formatTime(date, 'E, MMM do, yyyy h:mmaaa');
    },
  },
};
</script>

<style></style>
