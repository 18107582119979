<script setup lang="ts">
import * as _ from 'lodash';
import formatDate from 'date-fns/format';
import { useStore } from 'vuex';
import { formatSubscriptionType, formatMoney, formatTime } from '@/filters';
import ManageSubscriptionDetailsOrdersTable from '@/components/manage/subscription/details/ManageSubscriptionDetailsOrdersTable.vue';
import HeadingSection from '@/components/_common/Heading/HeadingSection.vue';
import UIBadge from '@/components/ui/UIBadge.vue';
import UISpinner from '@/components/ui/UISpinner.vue';
import { setTitle } from '@/utils/title';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useDevice } from '@/composables/useDevice.ts';

const route = useRoute();
const store = useStore();

const { getById, addDevice } = useDevice();

const loading = ref(true);

setTitle('Manage Subscription');

const subscriptionId = computed(() => +route.params.id);
const subscription = computed(() => {
  const subscription = store.getters['subscription/byId'](subscriptionId.value);
  if (!subscription) {
    return;
  }
  return subscription;
});
const price = computed(() => _.get(subscription.value, 'actual_price', 0));
const startDate = computed(() => {
  if (!subscription.value) {
    return '';
  }
  return formatDate(new Date(subscription.value.start_at), 'MMMM dd, yyyy');
});
const nextPaymentOrCancelDate = computed(() => {
  if (!subscription.value || !subscription.value.renewal_date) {
    return '-';
  }
  if (subscription.value.status === 'pending-renewal') {
    return '-';
  }
  return formatTime(subscription.value.renewal_date);
});

const device = computed(() => {
  if (!subscription.value) {
    return {};
  }

  const firstDeviceId = subscription.value.devices[0].id;

  const device = getById(firstDeviceId);
  const product = device?.model?.product;
  return {
    ...device,
    id: device?.id,
    title: device?.name,
    thumbnail: product?.thumbnail?.path,
    status: device?.status,
    serial: device?.serial || '-',
    imei: device?.imei || '-',
  };
});

const lastOrderDate = computed(() => {
  if (!subscription.value || !subscription.value?.most_recent_order) {
    return '';
  }

  return formatDate(
    new Date(subscription.value.most_recent_order.created_at),
    'MMMM dd, yyyy',
  );
});

const subscriptionType = computed(() => {
  if (!subscription.value) {
    return '';
  }
  return formatSubscriptionType(months.value);
});

const isSubscriptionCancelled = computed(
  () => subscription.value.status === 'cancelled',
);

const isSubscriptionPendingCancellation = computed(
  () => subscription.value.status === 'pending-cancellation',
);

const actions = computed(() => {
  if (
    !subscription.value ||
    ['pending', 'pending-renewal', 'archived'].includes(
      subscription.value?.status,
    )
  ) {
    return;
  }

  if (subscription.value.status === 'cancelled') {
    return {
      label: 'Reactivate Subscription',
      action: {
        mode: 'callback',
        callback: handleOpenReactivationModal.bind(this),
      },
    };
  }

  if (subscription.value.status === 'pending-cancellation') {
    return {
      label: 'Activate Subscription',
      action: {
        mode: 'callback',
        callback: handleOpenActivateModal.bind(this),
      },
    };
  }

  return {
    label: 'Cancel Subscription',
    action: {
      mode: 'callback',
      callback: handleOpenCancelModal.bind(this),
    },
  };
});

const months = computed(
  () =>
    subscription.value.most_recent_order?.months || subscription.value?.months,
);

const loadPage = () => {
  if (!subscriptionId.value) return;
  store
    .dispatch('subscription/fetchById', {
      subscriptionId: subscriptionId.value,
      params: {
        include: 'devices,price',
      },
    })
    .finally(() => {
      const sub = store.getters['subscription/byId'](subscriptionId.value);
      for (const device of sub?.devices) {
        addDevice(device);
      }
      loading.value = false;
    });
};

const showModal = (modal) => {
  alert(modal);
};

const handleOpenActivateModal = () => {
  store.dispatch('modal/open', {
    name: 'ManageSubscriptionActivateModal',
    params: {
      subscriptionId: subscriptionId.value,
    },
  });
};

const handleOpenCancelModal = () => {
  store.dispatch('modal/open', {
    name: 'ManageSubscriptionCancelModal',
    params: {
      subscriptionId: subscriptionId.value,
    },
  });
};

const handleOpenReactivationModal = () => {
  store.dispatch('modal/open', {
    name: 'ManageSubscriptionReactivateModal',
    params: {
      subscriptionId: subscriptionId.value,
    },
  });
};

watch(
  () => subscriptionId.value,
  () => loadPage(),
);

onMounted(() => loadPage());
</script>

<template>
  <div v-if="loading" class="mt-8 flex justify-center">
    <UISpinner size="xl" />
  </div>
  <section v-if="!loading && subscription">
    <HeadingSection
      class="mb-[19px] pt-[25px]"
      :desc="`Since ${startDate}`"
      heading="Subscription"
      :button="actions"
    />
    <div
      class="mt-[19px] flex flex-col items-start gap-y-[3px] sm:flex-row sm:gap-x-[12px] sm:gap-y-0"
    >
      <div
        class="pt-px text-gpx_xs font-medium text-gpx_gray-200 sm:text-gpx_sm"
      >
        Status:
      </div>
      <UIBadge :status="subscription.status" />
    </div>
    <div
      class="mt-[32px] grid grid-cols-[auto,_1fr] gap-y-[2px] overflow-hidden rounded-[6px] text-gpx_sm"
    >
      <template v-if="lastOrderDate">
        <div class="bg-bg-1 py-[17px] pl-[16px] text-gpx_gray-100 sm:pl-[32px]">
          Last order date:
        </div>
        <div
          class="inline-flex bg-bg-1 px-[20px] py-[17px] font-medium text-lms_black-300"
        >
          {{ lastOrderDate }}
        </div>
      </template>
      <template v-if="!isSubscriptionCancelled">
        <div class="bg-bg-1 py-[17px] pl-[16px] text-gpx_gray-100 sm:pl-[32px]">
          <span v-if="isSubscriptionPendingCancellation">Cancelling:</span>
          <span v-else>Next payment date:</span>
        </div>
        <div class="bg-bg-1 px-[20px] py-[17px] font-medium text-lms_black-300">
          {{ nextPaymentOrCancelDate }}
        </div>
      </template>
    </div>
    <HeadingSection
      class="!mt-[59px] mb-[19px] border-t border-t-gpx_gray-800 pt-[34px]"
      desc="View the subscription detailing"
      heading="Subscription Items"
      @open-modal="showModal($event)"
    />
    <div
      class="relative flex w-full justify-between rounded-[6px] border border-gpx_gray-800 pl-[20px] pr-[32px]"
    >
      <div class="flex w-full items-start justify-between">
        <div class="flex gap-x-[16px]">
          <div
            v-if="device.thumbnail"
            class="flex items-start justify-center pt-[16px]"
          >
            <img
              class="max-h-[48px] max-w-[48px] sm:max-h-[64px] sm:max-w-[64px]"
              :src="device.thumbnail"
              :alt="device.title"
            />
          </div>
          <div class="pb-[23px] pt-[25px]">
            <div class="mb-[10px] text-gpx_sm font-semibold text-lms_black-100">
              {{ device.title || '–' }}
            </div>
            <div class="flex flex-col gap-y-[2px]">
              <div class="flex gap-x-[12px]">
                <div
                  class="text-gpx_xs font-medium slashed-zero text-lms_black-100"
                >
                  <span class="text-gpx_gray-200">Serial:&nbsp;</span>
                  {{ device.serial }}
                </div>
                <div
                  class="text-gpx_xs font-medium slashed-zero text-lms_black-100"
                >
                  <span class="text-gpx_gray-200">IMEI:&nbsp;</span>
                  {{ device.imei }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col items-end gap-y-[11px] pt-[24px]">
          <div class="flex flex-col items-end">
            <div class="text-gpx_xs leading-[16px] text-gpx_gray-100">
              {{ subscriptionType }}
            </div>
            <div class="text-[18px] leading-[22px] text-lms_black-400">
              <span>$</span>
              <span class="font-bold">
                {{ formatMoney(price, true).main }}
              </span>
              <span class="text-[12px]">
                {{ formatMoney(price, true).dec }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ManageSubscriptionDetailsOrdersTable :subscriptionId="subscriptionId" />
  </section>
</template>
