<template>
  <AnonLayout>
    <AnonFormWrapper title="Login">
      <form @submit.prevent="handleSubmit">
        <div class="mb-4">
          <label class="mb-2 inline-block" for="email">Email</label>
          <VInput id="email" type="email" v-model="email" />
        </div>
        <div class="mb-4">
          <label class="mb-2 inline-block" for="password">Password</label>
          <VInput id="password" type="password" v-model="password" />
          <span class="mt-1 block text-right">
            <router-link
              to="/forgot"
              class="text-[13px] text-red-400 hover:text-red-700 hover:underline"
            >
              Forgot your password?
            </router-link>
          </span>
        </div>
        <UIButtonNew
          type="submit"
          :is-loading="isSubmitting"
          is-loading-text="Logging in..."
          class="mb-2 w-full"
        >
          Login
        </UIButtonNew>
      </form>
    </AnonFormWrapper>
  </AnonLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import NProgress from 'nprogress';
import AnonLayout from '@/layouts/AnonLayout.vue';
import AnonFormWrapper from '@/components/_common/AnonFormWrapper.vue';
import VInput from '@/components/ui/VInput.vue';
import UIButtonNew from '@/components/ui/UIButtonNew/UIButtonNew.vue';

export default {
  components: {
    AnonLayout,
    AnonFormWrapper,
    VInput,
    UIButtonNew,
  },

  data() {
    return {
      email: '',
      password: '',
      isSubmitting: false,
    };
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/check',
    }),
  },

  watch: {
    isLoggedIn() {
      if (this.isLoggedIn) {
        this.$router.push({ name: 'DeviceListView' });
      }
    },
  },

  mounted() {
    if (this.isLoggedIn) {
      this.$router.push({ name: 'DeviceListView' });
    }
  },

  methods: {
    ...mapActions({
      login: 'auth/login',
      logout: 'auth/logout',
    }),
    handleSubmit() {
      const toast = useToast();
      NProgress.start();
      this.isSubmitting = true;

      this.login({
        credentials: {
          email: this.email,
          password: this.password,
        },
        next: this.$route.query.next,
      }).catch((e) => {
        toast.error(e.response?.data?.message || 'An error occurred.');
        NProgress.done();
        this.isSubmitting = false;
      });
    },
  },
};
</script>

<style></style>
