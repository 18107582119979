<template>
  <div class="pt-[25px]">
    <div class="mb-[14px] text-[13px] leading-5 text-lgmx_black-200">
      Put all six screws back in the holes and tighten.
    </div>

    <div class="mb-[14px] flex justify-center">
      <img :src="svg1" alt="Put screws back" />
    </div>

    <div class="mb-[18px] text-[13px] leading-5 text-lgmx_black-200">
      Congratulations! You have replaced your SIM card. Make sure your device is
      powered on.
    </div>
    <div class="text-[13px] leading-5 text-lgmx_black-200">
      Once your Mobile 200G is powered on, press Swap SIM.
    </div>

    <div class="flex justify-between gap-x-4 pb-[18px] pt-[26px]">
      <UIButtonNew
        variant="tertiary"
        size="lg"
        class="w-[124px]"
        @click="$emit('onCancel')"
      >
        Back
      </UIButtonNew>
      <UIButtonNew
        @click="handleSwapClick"
        :is-loading="isLoading"
        class="w-full"
      >
        Swap SIM
      </UIButtonNew>
    </div>
  </div>
</template>

<script>
import { sleep } from '@/utils/sleep';
import Step8Svg1 from './assets/step-8.svg';

export default {
  emits: ['onConfirm', 'onCancel'],

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    svg1() {
      return Step8Svg1;
    },
  },

  methods: {
    async handleSwapClick() {
      this.isLoading = true;
      await sleep(3000);
      this.isLoading = false;
      this.$emit('onConfirm');
    },
  },
};
</script>
