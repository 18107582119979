<template>
  <div
    class="relative hidden gap-x-[32px] after:absolute after:bottom-0 after:left-0 after:-z-10 after:w-full after:border-b after:border-b-gpx_gray-700 md:flex"
  >
    <TabsNavItem
      v-for="tab in tabs"
      :key="`tab-${tab.name}`"
      :tab="tab"
      :current="activeTab"
    />
  </div>
  <UITabsSelect class="w-full md:hidden" :tabs="tabs" :current="activeTab" />
</template>

<script>
import TabsNavItem from '@/components/ui/TabsNav/UITabsNavItem.vue';
import UITabsSelect from '@/components/ui/TabsNav/UITabsSelect.vue';

export default {
  name: 'TabsNav',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Object,
      required: false,
    },
  },
  components: {
    UITabsSelect,
    TabsNavItem,
  },
};
</script>
