<template>
  <UITooltip text="Auto-refresh the map" placement="bottom">
    <UIButtonNew
      v-if="isEnabled"
      size="sm"
      variant="secondary"
      icon="ArrowPathIcon"
      @click="emits('disable')"
      class="w-auto md:w-[74px]"
    >
      On
    </UIButtonNew>

    <UIButtonNew
      v-else
      size="sm"
      variant="tertiary"
      icon="ArrowPathIcon"
      @click="emits('enable')"
      class="w-auto md:w-[74px]"
    >
      Off
    </UIButtonNew>
  </UITooltip>
</template>

<script setup>
const emits = defineEmits(['enable', 'disable']);
defineProps({
  isEnabled: {
    type: Boolean,
    default: false,
    required: true,
  },
});
</script>
