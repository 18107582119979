import * as events from '@/events';

events.subscribe(events.names.USER_LOADED, (data) => {
  window.dataLayer.push({
    event: events.names.USER_LOADED,
    data,
  });
});

events.subscribe(events.names.LOGGED_IN, () => {
  window.dataLayer.push({
    event: events.names.LOGGED_IN,
  });
});

events.subscribe(events.names.LOGGED_OUT, (data) => {
  window.dataLayer.push({
    event: events.names.LOGGED_OUT,
    paramaters: data,
  });
});

events.subscribe(events.names.ALL_TRACKERS_VIEWED, (data) => {
  window.dataLayer.push({
    event: events.names.ALL_TRACKERS_VIEWED,
    paramaters: data,
  });
});

events.subscribe(events.names.TRACKER_VIEWED, (data) => {
  window.dataLayer.push({
    event: events.names.TRACKER_VIEWED,
    device: data,
  });
});

events.subscribe(events.names.TRACKER_NAME_EDITED, (data) => {
  window.dataLayer.push({
    event: events.names.TRACKER_NAME_EDITED,
    device: data,
  });
});

events.subscribe(events.names.TRACKER_NAME_EDIT_CANCELLED, (data) => {
  window.dataLayer.push({
    event: events.names.TRACKER_NAME_EDIT_CANCELLED,
    device: data,
  });
});

events.subscribe(events.names.TRACKERS_SEARCHED, (data) => {
  window.dataLayer.push({
    event: events.names.TRACKERS_SEARCHED,
    searchQuery: data.query,
  });
});

events.subscribe(events.names.SHIPMENT_CREATED, (data) => {
  window.dataLayer.push({
    event: events.names.SHIPMENT_CREATED,
    shipment: data,
  });
});

events.subscribe(events.names.SHIPMENT_VIEWED, (data) => {
  window.dataLayer.push({
    event: events.names.SHIPMENT_VIEWED,
    shipment: data,
  });
});

events.subscribe(events.names.RULE_CREATED, (data) => {
  window.dataLayer.push({
    event: events.names.RULE_CREATED,
    rule: data,
  });
});

events.subscribe(events.names.COMMAND_SENT_TO_TRACKER, (data) => {
  window.dataLayer.push({
    event: events.names.COMMAND_SENT_TO_TRACKER,
    command: data,
  });
});

events.subscribe(events.names.GEOFENCE_LIST_VIEWED, (data) => {
  window.dataLayer.push({
    event: events.names.GEOFENCE_LIST_VIEWED,
    paramaters: data,
  });
});

events.subscribe(events.names.GEOFENCE_CREATED, (data) => {
  window.dataLayer.push({
    event: events.names.GEOFENCE_CREATED,
    paramaters: data,
  });
});

events.subscribe(events.names.GEOFENCE_DELETED, (data) => {
  window.dataLayer.push({
    event: events.names.GEOFENCE_DELETED,
    geofence: data,
  });
});

events.subscribe(events.names.GEOFENCE_VIEWED, (data) => {
  window.dataLayer.push({
    event: events.names.GEOFENCE_VIEWED,
    geofence: data,
  });
});

events.subscribe(events.names.GET_HELP_CLICKED, (data) => {
  window.dataLayer.push({
    event: events.names.GET_HELP_CLICKED,
    paramaters: data,
  });
});
