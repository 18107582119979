<template>
  <div class="pt-[25px]">
    <div class="mb-[14px] text-[13px] leading-5 text-lgmx_black-200">
      Congratulations! You have replaced your SIM card. Plug it into your car
      and turn your car on so it has power.
    </div>

    <div class="mb-[14px] flex justify-center">
      <img :src="svg1" alt="Put screws back" />
    </div>

    <div class="text-[13px] leading-5 text-lgmx_black-200">
      Once your Car Charger is plugged in, press Swap SIM. This process can take
      a couple of minutes to finish.
    </div>

    <div class="flex justify-between gap-x-4 pb-[18px] pt-[26px]">
      <UIButtonNew
        variant="tertiary"
        size="lg"
        class="w-[124px]"
        @click="$emit('onCancel')"
      >
        Back
      </UIButtonNew>
      <UIButtonNew
        @click="handleConfirm"
        :is-loading="isLoading"
        class="w-full"
      >
        Swap SIM
      </UIButtonNew>
    </div>
  </div>
</template>

<script>
import { sleep } from '@/utils/sleep';
import Step7Svg1 from './assets/step-7.svg';

export default {
  emits: ['onConfirm', 'onCancel'],

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    svg1() {
      return Step7Svg1;
    },
  },

  methods: {
    async handleConfirm() {
      this.isLoading = true;
      await sleep(3000);
      this.isLoading = false;
      this.$emit('onConfirm');
    },
  },
};
</script>
