<template>
  <VLink
    type="button"
    variant="primary"
    class="inline-flex items-center text-sm"
    @click="emits('click')"
  >
    <ChevronLeftIcon class="mr-2 h-4 w-4" />
    {{ props.label }}
  </VLink>
</template>

<script setup>
const emits = defineEmits(['click']);
const props = defineProps({
  label: {
    type: String,
    required: true,
  },
});
</script>
