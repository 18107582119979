<template>
  <input
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    type="range"
    class="accent-gray-750 disabled:cursor-not-allowed"
  />
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: ['modelValue'],
};
</script>
