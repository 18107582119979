import { BATCH_ADD_OR_UPDATE, REMOVE, UNSET_DEFAULT } from '../mutation-types';
import { addOrUpdateItems, processAndReturn, removeItem } from '../helpers';
import service from '@/services';
import * as events from '@/events';

export const state = {
  items: [],
};

export const getters = {
  all: (state) => state.items,
  byId: (state) => (id) => state.items.find((item) => item.id === id),
  byAccountId: (state) => (id) =>
    state.items.filter((item) => {
      return item.addressable_type === 'Account' && item.addressable_id === id;
    }),
};

export const mutations = {
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [REMOVE]: (state, item) => removeItem(state, item),
  [UNSET_DEFAULT]: (state, category) => {
    const index = state.items.findIndex(
      (item) => item.category === category && item.is_default === 1,
    );
    if (~index) {
      state.items[index].is_default = 0;
    }
  },
};

export const actions = {
  fetchByAccountId: (store, { accountId, params }) => {
    return service
      .get(`/accounts/${accountId}/addresses`, { params })
      .then((res) => processAndReturn(res, 'address'));
  },
  storeUnderAccountId: ({ commit }, { accountId, params }) => {
    const isNewDefault = Boolean(params.is_default);
    const category = params.category;
    return service
      .post(`/accounts/${accountId}/addresses`, params)
      .then((res) => {
        if (isNewDefault) {
          commit(UNSET_DEFAULT, category);
        }
        events.trigger(events.names.ADDRESS_CREATED, {
          address: res.data.data,
        });
        processAndReturn(res, 'address');
      });
  },
  setAccountDefault: (store, { accountId, addressId, params }) => {
    return service
      .put(`/accounts/${accountId}/addresses/${addressId}`, params)
      .then((res) => {
        events.trigger(events.names.ADDRESS_SET_AS_DEFAULT, {
          address: res.data.data,
        });
        return processAndReturn(res, 'address');
      });
  },
  delete: (store, { accountId, addressId }) => {
    return service
      .delete(`/accounts/${accountId}/addresses/${addressId}`)
      .then(() => {
        store.commit(REMOVE, { id: addressId });
        events.trigger(events.names.ADDRESS_DELETED, {
          address: addressId,
        });
      });
  },
};
