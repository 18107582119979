<template>
  <div class="pt-[31px]">
    <div class="mb-[15px] flex justify-center">
      <img :src="svg1" alt="replacement kit" />
    </div>
    <div
      class="mb-3 text-[15px] font-semibold uppercase leading-6 text-lgmx_black-200"
    >
      You will need
    </div>
    <div class="px-6">
      <ul
        class="list-disc text-[15px] leading-6 text-lgmx_black-200 marker:text-[32px] marker:text-lgmx_slate-100"
      >
        <li>
          <span class="relative bottom-[5px]">Your Car Charger tracker</span>
        </li>
        <li>
          <span class="relative bottom-[5px]">
            Flat head screwdriver, key, tweezers or similar item to pop the
            device cover off
          </span>
        </li>
        <li>
          <span class="relative bottom-[5px]"
            >Your new SIM card we sent to you</span
          >
        </li>
        <li>
          <span class="relative bottom-[5px]">
            Ability to plug device into your car to power it on to finalize SIM
            replacement
          </span>
        </li>
      </ul>
    </div>
  </div>
  <div class="flex justify-between gap-x-4 pb-[18px] pt-[24px]">
    <UIButtonNew
      variant="tertiary"
      size="lg"
      class="w-[124px]"
      @click="$emit('onCancel')"
    >
      Back
    </UIButtonNew>
    <UIButtonNew @click="$emit('onConfirm')" class="w-full">
      Continue
    </UIButtonNew>
  </div>
</template>

<script>
import Step1Svg1 from './assets/step-1.svg';

export default {
  emits: ['onConfirm', 'onCancel'],
  computed: {
    svg1() {
      return Step1Svg1;
    },
  },
};
</script>
