<template>
  <CommonGoBack label="Cancel" @click="goBack" />
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import CommonGoBack from '@/components/_common/CommonGoBack.vue';

const route = useRoute();
const router = useRouter();

function goBack() {
  router.push({
    name: 'ShipmentDetailsView',
    params: { id: route.params.id },
  });
}
</script>
