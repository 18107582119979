<template>
  <div class="overflow-hidden rounded-lg border border-gray-400">
    <div
      v-if="productPlan.month"
      class="relative -mb-px flex border-b border-gray-400 py-5 pl-[19[x]] pr-5 sm:pl-6 sm:pr-[21px]"
    >
      <div class="flex-1">
        <label class="relative flex items-center space-x-5" for="monthly">
          <span
            class="flex h-7 w-7 items-center justify-center rounded-full border border-gray-500"
            :class="{ 'bg-red-300': !isAnnual }"
          >
            <CheckIcon v-if="!isAnnual" class="block h-5 w-5 text-white" />
          </span>

          <span>
            <span class="block font-semibold text-black">Monthly</span>
            <span class="block text-gray-700">Recurring subscription</span>
          </span>
          <input
            v-model="period"
            id="monthly"
            :value="1"
            type="radio"
            class="hidden"
          />
        </label>
      </div>
      <div>
        <div class="pl-[15px] font-semibold capitalize text-black">
          ${{ formatMoney(productPlan.month.price) }}
        </div>
      </div>
    </div>
    <div
      v-if="productPlan.year"
      class="relative -mb-px flex items-center border-b border-gray-700 py-5 pl-[19[x]] pr-5 sm:pl-6 sm:pr-[21px]"
    >
      <div class="flex-1">
        <label class="relative flex items-center space-x-5" for="annual">
          <span
            class="flex h-7 w-7 items-center justify-center rounded-full border border-gray-500"
            :class="{ 'bg-red-300': isAnnual }"
          >
            <CheckIcon v-if="isAnnual" class="block h-5 w-5 text-white" />
          </span>

          <span>
            <span class="block font-semibold text-black">Annual</span>
            <span class="block text-gray-700">Plan with a discount</span>
          </span>
          <input
            v-model="period"
            name="plan_id"
            id="annual"
            :value="12"
            type="radio"
            class="hidden"
          />
        </label>
      </div>
      <div class="flex flex-col items-end">
        <div class="pl-[15px] font-semibold capitalize text-black">
          ${{ formatMoney(productPlan.year.price) }}
        </div>
        <span
          v-if="productPlan.month"
          class="mt-[2px] inline-block rounded-md bg-yellow-300 px-3 py-1 text-xs font-semibold text-black translate-x-1"
        >
          You save ${{
            formatMoney(productPlan.month.price * 12 - productPlan.year.price)
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { PERIOD_MONTH, PERIOD_YEAR } from '@/config/constants';
import * as _ from 'lodash';
import { CheckIcon } from '@heroicons/vue/24/outline';

import { formatMoney } from '@/utils/format-money';
export default {
  name: 'SelectPlan',
  emits: ['update:modelValue'],
  expose: ['getProductPlans', 'getSelectedPlan'],
  components: {
    CheckIcon,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Number,
      required: true,
    },
  },
  computed: {
    period: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    productPlan() {
      return {
        [PERIOD_MONTH]: _.filter(this.product.plans[0].prices, (plan) => {
          return plan.months === 1;
        }).pop(),
        [PERIOD_YEAR]: _.filter(this.product.plans[0].prices, (plan) => {
          return plan.months === 12;
        }).pop(),
      };
    },
    isAnnual() {
      return this.period === 12;
    },
  },
  methods: {
    formatMoney,
  },
};
</script>
