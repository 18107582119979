<template>
  <div class="flex h-screen w-full items-center justify-center">
    <div
      class="flex max-w-xl flex-col items-center space-y-10 p-20 text-center"
    >
      <div
        class="flex h-20 w-20 items-center justify-center rounded-full bg-teal-200 text-teal-700"
      >
        <CheckIcon class="block h-14 w-14" />
      </div>
      <p class="text-lg font-medium">Thank you for your payment</p>
      <p class="text-gray-750">
        Your request has been processed successfully and an email has been sent
        with order details.
      </p>
      <router-link class="border-b text-gpx_red-200" to="/trackers">
        Go back to dashboard
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { CheckIcon } from '@heroicons/vue/24/outline';
import { setTitle } from '@/utils/title';

export default {
  components: {
    CheckIcon,
  },

  mounted() {
    const paymentIntentId = this.$route.query.payment_intent;
    if (paymentIntentId) {
      this.refreshPaymentIntent(paymentIntentId);
    }
  },
  created() {
    setTitle();
  },
  methods: {
    ...mapActions({
      refreshPaymentIntent: 'orders/refreshPaymentIntent',
    }),
  },
};
</script>
