<template>
  <div class="pl-5 pr-3 pt-5 md:px-8">
    <div class="mb-3 flex justify-between md:mb-4 md:items-center">
      <div>
        <h1 class="mb-[2px] text-2xl font-semibold text-lgmx_black-600">
          Custom Reports
        </h1>
      </div>
    </div>
  </div>

  <iframe v-if="iframeSrc" class="px-2 w-full h-full" :src="iframeSrc" frameborder="0"></iframe>
</template>

<script setup>
import { setTitle } from '@/utils/title';
import { useStore } from 'vuex';
import { onMounted } from 'vue';

const store = useStore();
const settings = store.getters['auth/accountSettings'];

onMounted(() => {
  setTitle('Dashboards | Custom');
});

let iframeSrc = settings.embed_report_src;

function openHowItWorksModal() {
  store.dispatch('modal/open', { name: 'DashboardsDwellHowItWorksModal' });
}
</script>
