import { BATCH_ADD_OR_UPDATE, REMOVE } from '../mutation-types';
import { addOrUpdateItems, processAndReturn, removeItem } from '../helpers';
import service from '@/services';
import { downloadFile } from '@/utils/blob';

export const state = {
  items: [],
};

export const getters = {
  all: (state) => state.items,
  byId: (state) => (id) => state.items.find((item) => item.id === id),
  byIds: (state) => (ids) =>
    state.items.filter((item) => ids.indexOf(item.id) > -1),
  byOrderId: (state) => (id) =>
    state.items.filter((item) => {
      return item.order_id === id;
    }),
};

export const mutations = {
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [REMOVE]: (state, item) => removeItem(state, item),
};

export const actions = {
  fetchByOrderId: (store, { orderId, params }) => {
    return service
      .get(`/orders/${orderId}/payments`, { params })
      .then((res) => processAndReturn(res, 'payment'));
  },
  fetchInvoice(_, { orderId, paymentId }) {
    let url;
    if (paymentId) {
      url = `/payments/${paymentId}/generate-pdf`;
    } else {
      url = `/orders/${orderId}/generate-pdf`;
    }
    return service.get(url, { responseType: 'blob' }).then((res) => {
      let fileName = `Order #${orderId}`;
      if (paymentId) {
        fileName += ` Payment #${paymentId}`;
      }
      downloadFile({ res, fileName, extension: 'pdf' });
    });
  },
};
