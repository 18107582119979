<template>
  <div
    class="sticky top-0 z-[40] flex w-full items-center gap-x-[2px] border py-4 pl-6 pr-[22px] text-[13px] font-medium leading-5 md:px-12"
    :class="[
      {
        'border-lgmx_red-100 bg-lgmx_red-100 text-lgmx_red-300':
          variant === NOTIFICATION_VARIANT.error,
        'border-lgmx_yellow-120 bg-lgmx_yellow-120 text-lgmx_yellow-300':
          variant === NOTIFICATION_VARIANT.warning,
        'border-lgmx_green-100 bg-lgmx_green-100 text-lgmx_green-300':
          variant === NOTIFICATION_VARIANT.success,
        'border-lgmx_gray-200 bg-white text-lgmx_black-100':
          variant === NOTIFICATION_VARIANT.default,
      },
    ]"
  >
    <component v-if="Boolean(icon)" :is="icon" class="mr-[9px] h-6 w-6" />
    <slot />
  </div>
</template>

<script>
import { NOTIFICATION_VARIANT } from './utils';

export default {
  props: {
    icon: String,
    variant: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      NOTIFICATION_VARIANT: NOTIFICATION_VARIANT,
    };
  },
};
</script>
