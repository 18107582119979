<template>
  <CommonFiltersButton :variant="filterButtonVariant" @click="toggleIsOpen" />
  <div
    v-if="isOpen"
    class="absolute right-1/2 top-full z-10 mt-2 w-40 rounded border border-gray-400 bg-white px-4 py-2 translate-x-1/2"
    v-click-outside="toggleIsOpen"
  >
    <div class="mb-[2px] text-sm capitalize text-gray-750">Filters</div>
    <div class="flex flex-col gap-y-1.5">
      <VCheckboxArray
        v-for="(value, name) in shipmentStatusColorMap"
        :value="name"
        v-model="status"
        :key="`filter-${name}`"
      >
        <VBadge :status="name" />
      </VCheckboxArray>
    </div>
  </div>
</template>

<script setup>
import * as _ from 'lodash';
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import clickOutside from 'click-outside-vue3';
import nprogress from 'nprogress';
import CommonFiltersButton from '@/components/_common/CommonFiltersButton.vue';
import VBadge from '@/components/ui/VBadge.vue';
import VCheckboxArray from '@/components/ui/VCheckboxArray.vue';
import { usePersonalLocalStorage } from '@/use/use-personal-local-storage';
import { shipmentStatusColorMap } from '@/config/constants';

const store = useStore();
const vClickOutside = clickOutside.directive;
const isOpen = ref(false);
const toggleIsOpen = () => (isOpen.value = !isOpen.value);

const filterButtonVariant = computed(() =>
  store.getters['shipment/isAtLeastOneFilterApplied']
    ? 'secondary'
    : 'tertiary',
);

const statusFilter = usePersonalLocalStorage('shipment/status', []);
const status = ref(statusFilter.value);
const allowedStatuses = Object.keys(shipmentStatusColorMap);
if (!statusFilter.value.every((f) => allowedStatuses.includes(f))) {
  statusFilter.value = [];
}
watch(status, (n) => {
  statusFilter.value = !_.isEmpty(n) ? n : [];
});

const filter = computed(() => ({
  status: _.isNull(statusFilter.value) ? null : statusFilter.value,
}));

let setFilter = () => {
  nprogress.start();
  store.dispatch('shipment/setFilter', filter.value);
  nprogress.done();
};
setFilter = _.debounce(setFilter, 200);

watch(() => filter, setFilter, {
  deep: true,
  immediate: true,
});
</script>
