import service from '@/services';

export const state = {
  data: [],
};

export const mutations = {};

export const actions = {
  getPaymentIntent: (state, id) => {
    return service.post(`/orders/${id}/payment-intent`).then((res) => {
      return res.data.data;
    });
  },
  fetchByUUID: (state, { uuid, params }) => {
    return service.get(`/public-orders/${uuid}`, { params });
  },
  requestACHPayment: (state, { orderId, paymentId, params }) => {
    return service.put(`/orders/${orderId}/payments/${paymentId}`, params);
  },
};
