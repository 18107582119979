<template>
  <div
    class="pl-6 pr-4 sm:w-4/12 sm:border-r sm:border-lgmx_gray-100 md:pl-12 md:pr-6"
  >
    <ListBar class="h-[64px] sm:h-[48px] sm:translate-y-2" />
  </div>
  <div class="hidden justify-between px-4 py-2 sm:flex sm:w-8/12 md:pr-12">
    <div v-if="activeTab === 'geofences'" class="ml-auto flex gap-2">
      <UIButtonNew
        size="sm"
        variant="tertiary"
        icon="ArrowDownTrayIcon"
        class="w-1/2 sm:w-auto"
        @click="downloadGeofences"
      >
        Current locations
      </UIButtonNew>
      <div class="w-1/2 sm:w-auto">
        <VDatePickerRange
          :showTools="false"
          v-model="date"
          :maxDate="true"
          tooltip="Select range"
          utc
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import VDatePickerRange from '@/components/ui/VDatePickerRange.vue';
import ListBar from './ListBar.vue';

const store = useStore();
const toast = useToast();

const date = ref(null);

const accountId = store.getters['auth/accountId'];
const activeTab = computed(() => store.getters['geofence/activeTab']);

const downloadGeofences = () => {
  store
    .dispatch('geofence/downloadAll', {
      accountId,
      params: {
        download: true,
        offset: new Date().getTimezoneOffset(),
        date_from: date.value[0],
        date_to: date.value[1],
      },
    })
    .then(() => {
      store.dispatch('modal/open', {
        name: 'DownloadConfirmationModal',
        params: {
          title: 'Geofence Events',
          body: 'Your CSV is being generated. Once ready, you will receive an email notification with the download link',
        },
      });
    })
    .catch((e) => {
      toast.error(e.response?.data?.message || 'An error occurred');
    });
};
</script>

<style></style>
