<template>
  <button
    class="inline-flex items-center justify-center rounded-[0.25rem] border border-transparent text-center text-base font-normal leading-[1.5] outline-none transition-all disabled:pointer-events-none disabled:bg-none disabled:opacity-60"
    :class="btnClass"
    :disabled="isDisabled"
  >
    <svg
      :width="spinnerSize"
      :height="spinnerSize"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="inline animate-spin"
      v-show="isLoading"
    >
      <path
        d="M17.0001 9C17.0001 4.58176 13.4185 1.00006 9.00021 1.00006C6.86484 1.00006 4.92488 1.83669 3.49023 3.20004"
        stroke-width="1"
      />
    </svg>
    <span
      class="transition-[padding] duration-[400]"
      :class="{ 'pl-2': isLoading }"
    >
      <span v-if="isLoading && loadingText">{{ loadingText }}</span>
      <slot v-else />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'normal',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: undefined,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    btnClass() {
      return `${this.variant} ${this.size}`;
    },
    spinnerSize() {
      return this.size === 'sm' ? '10' : '18';
    },
  },
};
</script>

<style scoped>
.primary {
  @apply text-white border-red-400 bg-red-400 bg-gradient-to-b from-red-300 to-red-400 shadow-btn
  hover:bg-red-500 hover:from-red-350 hover:to-red-500 hover:border-red-700
  focus:ring-red-300/50 focus:ring-3
  active:bg-red-700 active:bg-none active:border-red-750 active:shadow-activeBtn;
}
.primary > svg > path {
  @apply stroke-white;
}

.outline-primary {
  @apply text-red-600 border-red-600
  hover:text-white hover:bg-red-600
  focus:ring-red-600/50 focus:ring-3
  active:text-white active:bg-red-600 active:shadow-activeBtn;
}

.secondary {
  @apply text-white border-gray-750 bg-gray-750 bg-gradient-to-b from-gray-700 to-gray-750 shadow-btn
  hover:bg-gray-850 hover:from-gray-720 hover:to-gray-850
  focus:ring-gray-750/50 focus:ring-3
  active:bg-gray-800 active:bg-none active:border-gray-950 active:shadow-activeBtn;
}
.outline-secondary {
  @apply text-gray-750 border-gray-750
  hover:text-white hover:bg-gray-750
  focus:ring-gray-750/50 focus:ring-3
  active:shadow-activeBtn;
}
.outline-secondary > svg > path {
  @apply stroke-gray-750;
}

.outline-white {
  @apply text-white border-white ring-0 outline-0
  hover:text-black hover:bg-white focus:ring-0;
}

.normal {
  @apply py-[0.375rem] px-[0.75rem];
}
.sm {
  @apply py-1 px-2 h-8 text-sm;
}
.xs {
  @apply py-1 px-2 h-6 text-sm;
}
</style>
