<template>
  <div
    class="relative flex h-[100dvh] items-center justify-center overflow-hidden"
  >
    <AppLogo
      class="pointer-events-none absolute top-[32px]"
      :forbidden="true"
    />
    <div class="relative z-10 flex flex-col items-center">
      <div class="relative">
        <span
          class="text-shadow mb-[2px] select-none text-[120px] font-black leading-[145px] tracking-[.01em] text-white"
          style="
            font-feature-settings:
              'salt' on,
              'ss02' on;
          "
        >
          403
        </span>
        <span
          class="absolute left-0 top-0 z-10 select-none bg-maintenance-pattern bg-clip-text bg-repeat text-[120px] font-black leading-[145px] tracking-[.01em] text-transparent"
          style="
            font-feature-settings:
              'salt' on,
              'ss02' on;
          "
          >403</span
        >
      </div>
      <div
        class="mb-[10px] text-center text-gpx_2xl font-bold text-lms_black-500"
      >
        Access Denied
      </div>
      <div class="mb-[43px] text-center text-gpx_sm text-lms_black-100">
        The page you are trying to reach has restricted access.<br />
        Please refer to technical support.
      </div>
      <UIButton label="Go Back" @click="$router.go(-1)" />
    </div>
  </div>
</template>

<script>
import AppLogo from '@/components/_common/AppLogo.vue';
import { setTitle } from '@/utils/title';

export default {
  components: { AppLogo },

  created() {
    setTitle();
  },
};
</script>
