import { isEmpty } from '@/helpers.js';

export const fahrenheitToCelsius = (value?: number) => {
  if (isEmpty(value)) {
    return null;
  }
  return ((value - 32) * (5 / 9)).toFixed(2);
};

export const celsiusToFahrenheit = (value?: number) => {
  if (isEmpty(value)) {
    return null;
  }
  return (value * (9 / 5) + 32).toFixed(2);
};
