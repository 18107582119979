<template>
  <div
    :class="{ 'bg-gray-300': selected === account.id }"
    class="group flex items-center justify-between border-b border-b-gpx_gray-800 px-[22px] py-[13px] first-of-type:pt-[14px] last-of-type:border-b-0"
    role="button"
  >
    <div class="flex flex-col">
      <span
        :class="{ 'font-bold': selected === account.id }"
        class="text-gpx_sm font-medium text-lms_black-500"
      >
        {{ account.name }}
      </span>
      <span class="text-gpx_xs capitalize text-gpx_gray-100">
        {{ account.desc }}
      </span>
    </div>
    <div :class="{ hidden: selected === account.id }" class="relative">
      <div
        class="absolute right-[-4px] top-1/2 h-[28px] w-[28px] rounded-full bg-bg-3 opacity-0 transition-opacity duration-300 ease-out -translate-y-1/2 group-hover:opacity-100"
      />
      <ArrowSmallRightIcon
        class="heroicon-stroke-w-1.4 relative h-[20px] w-[20px] text-gpx_gray-300 group-hover:text-lms_red-900"
      />
    </div>
  </div>
</template>

<script>
import { ArrowSmallRightIcon } from '@heroicons/vue/24/outline';

export default {
  name: 'VAccountListItem',
  props: {
    account: {
      type: Object,
      required: true,
    },
    selected: {
      type: Number,
      required: true,
    },
  },
  components: {
    ArrowSmallRightIcon,
  },
};
</script>
