<template>
  <div
    class="relative flex cursor-pointer items-center justify-between py-[7px] pl-[22px] pr-[20px] after:absolute after:left-[12px] after:top-0 after:h-full after:w-[calc(100%-24px)] after:rounded-[4px] after:bg-transparent hover:after:bg-bg-3"
    :class="{ '!flex-col !items-start !justify-start !pl-[28px]': extended }"
    @click="$emit('select', option)"
  >
    <span class="z-10 text-xs font-semibold leading-5 text-lgmx_black-100">{{
      label
    }}</span>
    <div
      v-if="extended"
      class="flex gap-x-[6px] text-[12px] font-normal leading-3 text-lgmx_black-100"
    >
      <div
        v-for="(item, index) in option.properties"
        :key="`${item}–${index}`"
        class="z-10 flex"
        style="
          font-feature-settings:
            'salt' on,
            'ss02' on;
        "
      >
        <span>{{ index }}</span
        >&nbsp;<span>{{ item }}</span>
      </div>
    </div>
    <CheckIcon
      v-if="selected"
      class="heroicon-stroke-w-1.8 absolute right-[20px] top-[8px] z-10 h-[16px] w-[16px] text-gpx_gray-300"
    />

    <button
      v-if="!selected && canDelete"
      role="button"
      @click.stop="deleteItem"
    >
      <TrashIcon
        class="heroicon-stroke-w-1 absolute right-[20px] top-[8px] z-10 h-[16px] w-[16px] text-gpx_red-200 hover:text-gpx_red-100"
      />
    </button>
  </div>
</template>

<script>
import { CheckIcon, TrashIcon } from '@heroicons/vue/24/outline';

export default {
  name: 'UISelectMenuItem',
  components: {
    CheckIcon,
    TrashIcon,
  },

  emits: ['onDelete', 'select'],

  props: {
    selected: Boolean,
    option: {
      type: [String, Number, Object],
      required: true,
    },
    canDelete: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      extended: false,
      id: null,
    };
  },

  computed: {
    value() {
      return typeof this.option === 'object' ? this.option.value : this.option;
    },
    label() {
      return typeof this.option === 'object' ? this.option.label : this.option;
    },
  },

  mounted() {
    if (typeof this.option === 'object' && this.option.properties) {
      this.extended = true;
    }
    this.id = this._uid;
  },

  methods: {
    deleteItem() {
      this.$parent.$parent.$emit('onDelete', this.option);
    },
  },
};
</script>
