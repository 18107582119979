<template>
  <div @dragover="!disabled && dragover" @dragleave="dragleave" @drop="drop">
    <div v-if="preloadImages.length" class="mb-3 inline-flex flex-wrap gap-3">
      <div v-for="image in preloadImages" :key="image.id">
        <div class="relative rounded-lg border border-lgmx_gray-300 p-6">
          <img :src="image.path" class="h-[100px] w-auto rounded" />
          <button
            @click="$emit('onRemovePreloadImage', image.id)"
            title="Remove file"
            class="group absolute right-0 top-0 flex h-7 w-7 shrink-0 items-center justify-center rounded-full bg-white -translate-y-1/2 translate-x-1/2"
          >
            <div
              class="flex h-[18px] w-[18px] items-center justify-center rounded-full bg-gpx_red-300 group-hover:bg-lgmx_terracota-100"
            >
              <XMarkIcon class="h-3 w-3 text-lgmx_terracota-700" />
            </div>
          </button>
        </div>
      </div>
    </div>
    <div v-if="files.length" class="mb-3 inline-flex flex-wrap gap-3">
      <div v-for="file in files" :key="file.name">
        <div class="relative rounded-lg border border-lgmx_gray-300 p-6">
          <img :src="generateURL(file)" class="h-[100px] w-auto rounded" />
          <button
            @click="remove(files.indexOf(file))"
            title="Remove file"
            class="group absolute right-0 top-0 flex h-7 w-7 shrink-0 items-center justify-center rounded-full bg-white -translate-y-1/2 translate-x-1/2"
          >
            <div
              class="flex h-[18px] w-[18px] items-center justify-center rounded-full bg-gpx_red-300 group-hover:bg-lgmx_terracota-100"
            >
              <XMarkIcon class="h-3 w-3 text-lgmx_terracota-700" />
            </div>
          </button>
        </div>
      </div>
    </div>
    <div
      class="mb-3 flex items-center justify-center rounded-lg border border-dashed border-lgmx_gray-300 py-5"
      :class="[
        { 'border-gpx_porto-800': isDragging },
        { 'bg-bg-1/50': disabled },
      ]"
    >
      <input
        type="file"
        multiple
        name="file"
        id="file-input"
        class="hidden"
        @change="onChange"
        ref="file"
        accept=".jpg,.jpeg,.png"
      />
      <label
        for="file-input"
        class="flex cursor-pointer flex-col items-center"
        :class="[{ 'pointer-events-none': isDragging || disabled }]"
      >
        <div
          class="mb-[7px] flex h-12 w-12 items-center justify-center rounded-full bg-bg-1"
        >
          <DocumentArrowDownIcon
            class="h-[22px] w-[22px] text-slate-500 -translate-y-px translate-x-px"
          />
        </div>
        <div class="text-center text-xs text-gpx_gray-200">
          <div v-if="isDragging">
            Release<br />
            to drop files<br />
            here
          </div>
          <div v-else>
            Drag and drop<br />
            your images or<br />
            <span
              class="relative text-lgmx_terracota-700 after:absolute after:bottom-[-3px] after:left-0 after:h-px after:w-full after:bg-lgmx_terracota-200 hover:cursor-pointer"
            >
              browse
            </span>
          </div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
  emits: ['update:modelValue', 'onRemovePreloadImage'],
  props: {
    disabled: Boolean,
    modelValue: Array,
    fileSizeLimit: Number,
    preloadImages: Array,
  },

  data() {
    return {
      isDragging: false,
      files: [],
    };
  },

  methods: {
    onChange() {
      [...this.$refs.file.files].forEach((file) => {
        if (file.size < this.fileSizeLimit) {
          this.files.push(file);
        } else {
          if (this.fileSizeLimit) {
            toast.error(
              `${file.name} size exceeds the maximum of ${
                this.fileSizeLimit / 1024 / 1024
              } Mb`,
              {
                timeout: 10000,
              },
            );
          }
        }
      });
      this.$emit('update:modelValue', this.files);
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    remove(i) {
      this.files.splice(i, 1);
    },
    generateURL(file) {
      const fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 100);
      return fileSrc;
    },
  },
};
</script>
