<template>
  <div class="flex flex-col gap-y-2">
    <span>Date Range</span>
    <VDatePickerRange :show-tools="false" />
  </div>
  <VCombobox label="Device" size="sm" placeholder="Select..." :options="[]" />
  <VButton type="submit" class="w-full" variant="primary">
    Get Messages
  </VButton>
</template>

<script>
import VCombobox from '@/components/ui/VCombobox.vue';
export default {
  name: 'UtilityAssignRangeForm',
  components: {
    VCombobox,
  },
};
</script>
