<template>
  <CommonNotification
    v-if="notification"
    :label="notification.label"
    :value="notification.value"
    :desc="notification.desc"
    :button="notification.button"
  />
  <main
    class="mx-auto max-w-[1200px] grid-cols-12 gap-x-[40px] text-lms_black-100"
  >
    <slot />
  </main>
</template>

<script>
import CommonNotification from '@/components/_common/CommonNotification.vue';

export default {
  components: {
    CommonNotification,
  },
  props: {
    notification: {
      type: Object,
      required: false,
      default: null,
    },
  },
};
</script>
