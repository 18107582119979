import _ from 'lodash';

const processStrategy = (payment) => {
  const relations = {
    order: _.get(payment, 'order'),
  };
  const omit = [];
  return { ..._.omit(payment, omit), ..._.pickBy(relations, _.identity) };
};

export default {
  key: 'payment',
  definition: {
    order: 'order',
  },
  processStrategy,
};
