<template>
  <div
    class="w-full border border-gpx_gray-800 py-[11px] pl-[24px] pr-[18px] sm:rounded-b-[6px] sm:py-0 sm:pl-[32px] sm:pr-[26px]"
  >
    <div class="relative flex justify-between sm:py-[11px]">
      <div class="flex items-center">
        <div class="text-gpx_xs font-semibold text-lms_black-100 sm:mr-[12px]">
          Entries:
        </div>
        <div>
          <select
            id="entries"
            name="entries"
            class="mr-auto cursor-pointer rounded-md border-0 bg-white py-[7px] pl-3 pr-[32px] text-gpx_xs text-lms_black-100 focus:ring-0 sm:mr-0 sm:bg-gpx_gray-900"
            v-model="form.per_page"
          >
            <option
              :value="perPage"
              v-for="perPage in resultsPerPage"
              :key="perPage"
            >
              {{ perPage }}
            </option>
          </select>
        </div>
        <div class="ml-7 hidden text-gpx_xs text-gpx_gray-100 sm:block">
          Showing {{ meta.from || 0 }} to {{ meta.to }} of
          {{ meta.total }} results
        </div>
      </div>
      <div class="ml-[12px] flex items-center space-x-[4px]">
        <div
          @click="firstPage"
          class="flex h-[32px] w-[32px] items-center justify-center"
          role="button"
        >
          <ChevronDoubleLeftIcon
            :class="{
              'text-gpx_gray-400': !hasPrevPage,
              'text-lms_black-100': hasPrevPage,
            }"
            class="h-[20px] w-[20px]"
          />
        </div>
        <div
          @click="prevPage"
          class="flex h-[32px] w-[32px] items-center justify-center"
          role="button"
        >
          <ChevronLeftIcon
            :class="{
              'text-gpx_gray-400': !hasPrevPage,
              'text-lms_black-100': hasPrevPage,
            }"
            class="h-[20px] w-[20px]"
          />
        </div>
        <div class="text-center text-gpx_xs text-gpx_gray-100">
          {{ meta.current_page }} of&nbsp;{{ meta.last_page }}
        </div>
        <div
          @click="nextPage"
          class="flex h-[32px] w-[32px] items-center justify-center"
          role="button"
        >
          <ChevronRightIcon
            :class="{
              'text-gpx_gray-400': !hasNextPage,
              'text-lms_black-100': hasNextPage,
            }"
            class="h-[20px] w-[20px]"
          />
        </div>
        <div
          @click="lastPage"
          class="flex h-[32px] w-[32px] items-center justify-center"
          role="button"
        >
          <ChevronDoubleRightIcon
            :class="{
              'text-gpx_gray-400': !hasNextPage,
              'text-lms_black-100': hasNextPage,
            }"
            class="h-[20px] w-[20px]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/vue/24/outline';
import { TABLE_RESULTS_PER_PAGE } from '@/config/constants';

export default {
  name: 'DataTableNav',
  components: {
    ChevronDoubleLeftIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDoubleRightIcon,
  },

  props: {
    modelValue: Object,
    meta: Object,
  },

  data() {
    return {
      resultsNum: null,
      form: {
        per_page: TABLE_RESULTS_PER_PAGE[0],
        page: 1,
      },
    };
  },

  computed: {
    navCollapsed() {
      return this.$store.state.ui.navCollapsed;
    },
    resultsPerPage() {
      return TABLE_RESULTS_PER_PAGE;
    },
    hasNextPage() {
      return this.form.page < this.meta.last_page;
    },
    hasPrevPage() {
      return this.form.page > 1;
    },
  },

  watch: {
    modelValue: {
      deep: true,
      handler() {
        if (!_.isEqual(this.modelValue, this.form)) this.reset();
      },
    },
    form: {
      deep: true,
      handler() {
        if (!_.isEqual(this.modelValue, this.form)) {
          this.$emit('update:modelValue', this.form);
        }
      },
    },
    meta: {
      deep: true,
      handler(n) {
        if (_.isNull(n.from)) {
          this.firstPage();
        }
      },
    },
  },

  beforeMount() {
    this.reset();
  },

  methods: {
    reset() {
      this.form = _.assign({}, this.form, this.modelValue);
    },
    nextPage() {
      if (this.hasNextPage) this.form.page = this.form.page + 1;
    },
    prevPage() {
      if (this.hasPrevPage) this.form.page = this.form.page - 1;
    },
    firstPage() {
      if (this.hasPrevPage) this.form.page = 1;
    },
    lastPage() {
      if (this.hasNextPage) this.form.page = this.meta.last_page;
    },
  },
};
</script>
