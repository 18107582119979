<template>
  <Datepicker
    ref="datepicker"
    autoApply
    v-model="inputValue"
    :time-picker-component="VTimePickerSingle"
  >
    <template #trigger>
      <div class="relative">
        <label
          v-if="label"
          class="mb-[3px] block text-xs font-medium text-gpx_gray-200"
        >
          {{ label }}
        </label>
        <div class="relative flex">
          <input
            :placeholder="placeholder"
            :value="previewText"
            class="text-gpx_black-500 w-full cursor-pointer rounded-[6px] border-0 bg-bg-3 px-[22px] py-[14px] text-sm placeholder:text-gpx_gray-300 focus:outline-0 focus:ring-0"
            :class="[{ 'bg-red-150': meta.touched && !meta.valid }]"
            readonly
          />
          <div
            class="absolute right-0 top-0 flex h-full cursor-pointer items-center justify-center px-[16px]"
          >
            <CalendarIcon
              class="heroicon-stroke-w-1.4 h-[20px] w-[20px] text-gpx_porto-500"
            />
          </div>
        </div>
      </div>
    </template>
  </Datepicker>
</template>

<script setup>
import { computed, toRef, ref } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import { useField } from 'vee-validate';
import { CalendarIcon } from '@heroicons/vue/24/outline';
import VTimePickerSingle from '@/components/ui/VTimePickerSingle.vue';
import { formatTime } from '@/filters';

const props = defineProps({
  name: String,
  value: String,
  label: String,
  placeholder: String,
  validateFn: Function,
});

const name = toRef(props, 'name');

const { value: inputValue, meta } = useField(name, props.validateFn, {
  initialValue: props.value,
});

const datepicker = ref(null);

const previewText = computed(() => {
  if (inputValue.value) {
    return formatTime(new Date(inputValue.value), 'MM/dd/yyyy @ h:mm a');
  }
  return '';
});
</script>
