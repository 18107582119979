<template>
  <div class="mb-[24px] rounded-b-[10px] bg-white px-[24px] sm:px-[40px]">
    <UIInput
      class="mb-[24px]"
      label="Group Name"
      :modelValue="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <div class="flex justify-between gap-x-4 pb-[32px]">
      <UIButtonNew
        variant="tertiary"
        size="lg"
        class="w-[124px]"
        @click="$emit('onCancel')"
      >
        Cancel
      </UIButtonNew>
      <UIButtonNew
        :disabled="!modelValue"
        @click="handleContinueClick"
        class="w-full"
      >
        Continue
      </UIButtonNew>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue', 'onConfirm', 'onCancel'],
  props: {
    step: Number,
    modelValue: String,
  },

  methods: {
    handleContinueClick() {
      this.$emit('onConfirm');
    },
  },
};
</script>
