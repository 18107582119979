import _ from 'lodash';

const processStrategy = (position) => {
  const relations = {
    devices: _.get(position, 'devices'),
  };
  const omit = [];
  return {
    ..._.omit(position, omit),
    ..._.pickBy(relations, _.identity),
  };
};

export default {
  key: 'position',
  definition: {
    devices: ['device'],
  },
  processStrategy,
};
