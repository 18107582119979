<template>
  <div class="flex flex-col items-center">
    <UIIndicator variant="error" class="mb-[21px] pt-[2px]" />
    <div class="mb-[18px] text-center text-[19px] font-semibold leading-6">
      Oops. It looks like something went wrong.
    </div>
    <div class="mb-4 px-6">
      <ul
        class="list-disc text-[15px] leading-6 text-lgmx_black-200 marker:text-[32px] marker:text-lgmx_slate-100"
      >
        <li>
          <span class="relative bottom-[5px]">
            Make sure your tracker is powered on and your car is turned on.
          </span>
        </li>
        <li>
          <span class="relative bottom-[5px]">
            Hit try again to resend the Swap SIM command.
          </span>
        </li>
      </ul>
    </div>
    <div class="text-[15px] leading-6">
      Still having issues? Contact us for support.
    </div>
    <div class="flex w-full justify-between gap-x-4 pb-[10px] pt-[26px]">
      <UIButtonNew
        variant="tertiary"
        size="lg"
        class="w-full"
        @click="$emit('onCancel')"
      >
        I need help
      </UIButtonNew>
      <UIButtonNew size="lg" @click="$emit('onConfirm')" class="w-full">
        Try Again
      </UIButtonNew>
    </div>
  </div>
</template>
