<template>
  <ModalLayout title="Invite New User">
    <div class="rounded-t-[10px] bg-white pt-[41px]">
      <div
        @click.prevent="$emit('close')"
        class="absolute right-[24px] top-[36px] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full border border-gpx_gray-800 sm:right-[40px]"
      >
        <XMarkIcon
          class="heroicon-stroke-w-1.4 h-[20px] w-[20px] text-gpx_gray-200"
        />
      </div>
      <div
        class="mx-[24px] border-b border-b-gpx_gray-800 pb-[18px] sm:mx-[40px]"
      >
        <div class="mb-[2px] text-gpx_sm font-medium text-gpx_gray-200">
          Inviting
        </div>
        <div class="text-gpx_2xl font-semibold text-lms_black-500">
          New User
        </div>
      </div>
      <div class="px-[24px] sm:px-[40px]">
        <div class="mt-[35px] flex flex-col space-y-[18px]">
          <UIInput
            :placeholder="'Mark Twain'"
            v-model="form.name"
            label="Name"
          ></UIInput>
          <UIInput
            :placeholder="'mark.twain@gmail.com'"
            v-model="form.email"
            label="Email"
          ></UIInput>
        </div>
        <div class="mt-[30px]">
          <div class="mb-[3px] text-gpx_base font-semibold text-lms_black-400">
            User Role
          </div>
          <div class="text-gpx_sm text-gpx_gray-100">
            Grant user access to all or a specific devices.
          </div>
        </div>
        <UIRadioGroup class="mt-[19px]">
          <UIRadioGroupOption
            v-model="form.role"
            :selected="'admin'"
            :label="'Administrator'"
            :description="'Access to all devices, can invite users'"
          />
          <UIRadioGroupOption
            v-model="form.role"
            :selected="'all'"
            :label="'All Devices'"
            :description="'Access to all devices, can not invite users'"
          />
          <UIRadioGroupOption
            v-model="form.role"
            :selected="'devices'"
            :label="'Specific Devices'"
            :description="'Access only specific devices'"
          />
        </UIRadioGroup>
        <DeviceAutocomplete
          v-if="form.role === 'devices'"
          class="mt-[29px]"
          v-model="form.devices"
        />
      </div>
    </div>
    <div class="rounded-b-[10px] bg-white px-[24px] py-[32px] sm:px-[40px]">
      <UIButton
        :loading="isInvitingUser"
        :disabled="isInvitingUser"
        :label="isInvitingUser ? 'Inviting' : 'Invite User'"
        @click="handleInviteUser"
      />
    </div>
  </ModalLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import ModalLayout from '@/layouts/ModalLayout.vue';
import DeviceAutocomplete from '@/components/device/_common/DeviceAutocomplete.vue';
import UIRadioGroup from '@/components/ui/RadioGroup/UIRadioGroup.vue';
import UIRadioGroupOption from '@/components/ui/RadioGroup/UIRadioGroupOption.vue';
import UIInput from '@/components/ui/UIInput.vue';
import UIButton from '@/components/ui/UIButton.vue';

export default {
  components: {
    ModalLayout,
    XMarkIcon,
    UIRadioGroup,
    UIRadioGroupOption,
    UIInput,
    UIButton,
    DeviceAutocomplete,
  },

  emits: ['close'],

  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
      activeModal: 'modal/active',
    }),
    formRole() {
      return this.form.role;
    },
  },

  watch: {
    formRole(selectedRole) {
      if (selectedRole != 'devices') {
        this.form.devices = [];
      }
    },
  },

  beforeMount() {
    this.form.account_id = this.accountId;
  },

  data() {
    return {
      form: {
        account_id: null,
        name: '',
        email: '',
        role: 'all',
        devices: [],
      },
      isInvitingUser: false,
    };
  },

  methods: {
    ...mapActions({
      inviteUser: 'user/invite',
    }),
    handleInviteUser() {
      const toast = useToast();
      this.isInvitingUser = true;
      this.inviteUser({
        accountId: this.accountId,
        params: this.form,
      })
        .then(() => {
          toast.success('The user was successfully invited');
          this.activeModal.params.onSuccess();
          this.$emit('close');
        })
        .catch((e) => {
          if (e.response?.data) {
            toast.error(e.response.data.message);
          } else {
            toast.error('An error ocurred. Please try again later.');
          }
        })
        .finally(() => (this.isInvitingUser = false));
    },
  },
};
</script>
