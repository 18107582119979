<template>
  <div class="rounded-[8px] border border-gpx_gray-800">
    <h2
      class="mx-[-1px] mb-4 mt-[-1px] rounded-t-[8px] border-inherit bg-bg-1 px-[24px] py-[16px] text-lg font-medium"
    >
      {{ heading }}
    </h2>
    <div class="flex flex-col gap-y-[16px] px-[24px] pb-[24px] text-sm">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UtilitySection',
  props: {
    heading: {
      type: String,
      required: true,
    },
  },
};
</script>
