import { BATCH_ADD_OR_UPDATE, REMOVE } from '../mutation-types';
import {
  addOrUpdateItems,
  all,
  byId,
  byIds,
  processAndReturn,
  removeItem,
} from '../helpers';
import service from '@/services';
import * as _ from 'lodash';

export const state = {
  items: [],
};

export const getters = {
  all,
  byId,
  byIds,
  byModelId: (state) => (id) =>
    _.filter(state.items, (item) => item.model_id === +id),
};

export const mutations = {
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [REMOVE]: (state, item) => removeItem(state, item),
};

export const actions = {
  fetchByModelId: (_, { modelId }) => {
    const params = {
      search: { model_id: { is: modelId } },
    };
    return service
      .get('commands', { params })
      .then((res) => processAndReturn(res, 'command'));
  },
  fetchByDeviceId: (_, { deviceId, params }) => {
    return service
      .get(`devices/${deviceId}/commands`, { params })
      .then((res) => processAndReturn(res, 'command'));
  },
};
