<template>
  <VCombobox label="Device" size="sm" placeholder="Select..." :options="[]" />
  <VInput
    size="sm"
    type="text"
    label="Subscription ID"
    placeholder="Chargebee/Woocommerce ID"
  />
  <VCheckbox>
    <span class="flex-1">Roll Over Subscription</span>
  </VCheckbox>
  <VButton type="submit" class="w-full" variant="primary">
    Set Subscription
  </VButton>
</template>

<script>
import VCombobox from '@/components/ui/VCombobox.vue';
export default {
  name: 'UtilitySetSubscriptionForm',
  components: {
    VCombobox,
  },
};
</script>
