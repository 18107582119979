<template>
  <div class="rounded border-gray-400 bg-lgmx_gray-100 px-4 py-3">
    <b class="text-lgmx_slate-700">{{ props.description }}</b>
    <i class="text-[14px]"> ( {{ happenedAt }} ) </i>
  </div>
</template>

<script setup>
import * as _ from 'lodash';
import { computed } from 'vue';
import { formatTime } from '@/filters';

const props = defineProps({
  description: {
    type: String,
    required: true,
  },
  date: {
    type: String,
    required: true,
  },
});

const happenedAt = computed(() => {
  if (_.isNull(props.date)) return '-';
  return formatTime(props.date, 'MMM d, yyyy h:mm aa');
});
</script>
