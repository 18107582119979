<template>
  <div
    class="relative w-full rounded-md bg-lgmx_gray-100 py-2 pl-4 pr-2 text-lgmx_black-500"
  >
    <div
      class="absolute left-0 top-[-10px] h-[10px] w-full bg-gradient-to-b from-transparent to-white"
    />
    <div class="relative flex justify-between">
      <div class="flex items-center">
        <div class="mr-3 hidden text-[13px] font-medium sm:block">Entries:</div>
        <select
          id="entries"
          name="entries"
          class="mr-auto cursor-pointer rounded-md border-0 bg-white py-[7px] pl-[11px] pr-7 text-[13px] leading-5 focus:ring-0"
          v-model="pageSizeSelect"
        >
          <option
            :value="pageSize"
            v-for="pageSize in props.pageSizeOptions"
            :key="pageSize"
          >
            {{ pageSize }}
          </option>
        </select>
        <div
          class="ml-6 hidden text-[13px] leading-5 text-lgmx_gray-900 sm:block"
        >
          {{ from }} to {{ to }} / {{ props.total }}
          {{ resultsLabel }}
        </div>
      </div>

      <div class="ml-[12px] flex items-center space-x-[4px]">
        <div class="mr-1 text-center text-[13px] leading-5 text-lgmx_black-100">
          {{ props.currentPage }} / {{ props.pageCount }}
        </div>
        <UIIconButton
          icon="ChevronDoubleLeftIcon"
          :disabled="props.isFirstPage"
          @click="first()"
        />
        <UIIconButton
          icon="ChevronLeftIcon"
          :disabled="props.currentPage === 1"
          @click="prev()"
        />
        <UIIconButton
          icon="ChevronRightIcon"
          :disabled="props.currentPage === props.pageCount"
          @click="next()"
        />
        <UIIconButton
          icon="ChevronDoubleRightIcon"
          :disabled="props.isLastPage"
          @click="last()"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

const emits = defineEmits([
  'first',
  'prev',
  'next',
  'last',
  'changePage',
  'changePageSize',
]);

const props = defineProps({
  isFirstPage: Boolean,
  isLastPage: Boolean,
  total: {
    type: Number,
    required: true,
  },
  currentPage: {
    type: Number,
    required: true,
  },
  pageCount: {
    type: Number,
    required: true,
  },
  currentPageSize: {
    type: Number,
    required: true,
  },
  pageSizeOptions: {
    type: Array,
    required: true,
  },
});

const pageSizeSelect = ref(props.currentPageSize);

watch(pageSizeSelect, (value) => {
  emits('changePageSize', value);
});

const from = computed(() => {
  return props.currentPage * props.currentPageSize - props.currentPageSize + 1;
});

const to = computed(() => {
  if (props.total < props.currentPage * props.currentPageSize) {
    return props.total;
  }
  return props.currentPage * props.currentPageSize;
});

const resultsLabel = computed(() => {
  if (props.total === 1) {
    return 'result';
  }
  return 'results';
});

function first() {
  emits('changePage', 1);
}
function prev() {
  emits('changePage', props.currentPage - 1);
}
function next() {
  emits('changePage', props.currentPage + 1);
}
function last() {
  emits('changePage', props.pageCount);
}
</script>
