<script setup lang="ts">
import * as _ from 'lodash';
import Table from './table/Table.vue';
import TablePagination from './table/TablePagination.vue';
import TableResult from './table/TableResult.vue';
import { useDevice } from '@/composables/useDevice';
import { computed, onMounted, reactive, watch } from 'vue';

defineEmits(['onConfirm', 'onCancel']);

const props = defineProps<{
  isSubmitting: boolean;
  selected: any[];
}>();

const { getById } = useDevice();

const data = reactive({
  selectedDevices: [],
  isSearchOpen: false,
  search: '',
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    lastPage: 1,
  },
});

const matchesSearch = (device, search) => {
  const searchFields = _.compact([device.name?.toLowerCase(), device.serial]);
  return searchFields.some((field) => field.includes(search));
};

const searchedDevices = computed(() => {
  return data.selectedDevices.map((id) => {
    const device = getById(id);
    if (matchesSearch(device, data.search)) {
      return device;
    }
  });
});

const paginatedDevices = computed(() => {
  const { page, pageSize } = data.pagination;
  return searchedDevices.value.slice(pageSize * (page - 1), pageSize * page);
});

onMounted(() => {
  if (props.selected.length) {
    data.selectedDevices = props.selected;
  }
});

watch(
  () => data.search,
  () => {
    data.pagination.page = 1;
  },
);

watch(
  () => searchedDevices.value,
  (newDevices) => {
    const { pageSize } = data.pagination;
    data.pagination.total = newDevices.length;
    data.pagination.lastPage =
      newDevices.length % pageSize === 0
        ? newDevices.length / pageSize
        : Math.floor(newDevices.length / pageSize + 1);
  },
  {
    immediate: true,
  },
);

const selectDevice = ({ id, checked }) => {
  if (checked) {
    data.selectedDevices.push(id);
  } else {
    data.selectedDevices = data.selectedDevices.filter((item) => item !== id);
  }
};

const selectDevices = (ids) => {
  data.selectedDevices = [...new Set([...data.selectedDevices, ...ids])];
};

const selectAll = () => {
  data.selectedDevices = searchedDevices.value.map((item) => item.id);
};

const selectNone = () => {
  data.selectedDevices = [];
};
</script>

<template>
  <div class="bg-white">
    <Table
      :can-search="Boolean(props.selected.length)"
      mode="delete"
      v-model="data.search"
      :searched-devices="searchedDevices"
      :paginated-devices="paginatedDevices"
      :selected-devices="data.selectedDevices"
      @select-device="selectDevice"
      @select-devices="selectDevices"
      @select-all="selectAll"
      @select-none="selectNone"
    />
    <div>
      <TablePagination
        class="pl-[24px] pr-[17px] sm:pl-[40px] sm:pr-[32px]"
        v-model="data.pagination"
      />
    </div>
    <div v-if="data.selectedDevices.length" class="pt-[18px]">
      <TableResult
        class="px-[24px] sm:px-[40px]"
        :qty="data.selectedDevices.length"
        entity="tracker"
      />
    </div>
  </div>
  <div
    class="mb-[24px] rounded-b-[10px] bg-white px-[24px] pt-[24px] sm:px-[40px]"
  >
    <div class="flex justify-between gap-x-4 pb-[32px]">
      <UIButtonNew
        :disabled="isSubmitting"
        variant="tertiary"
        size="lg"
        class="w-[188px]"
        @click="$emit('onCancel', data.selectedDevices)"
      >
        Edit List
      </UIButtonNew>
      <UIButtonNew
        :is-loading="isSubmitting"
        is-loading-text="Saving"
        @click="$emit('onConfirm', data.selectedDevices)"
        class="w-full"
      >
        <span v-if="data.selectedDevices.length">Save Group</span>
        <span v-else>Save Blank</span>
      </UIButtonNew>
    </div>
  </div>
</template>
