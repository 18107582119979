<template>
  <UIOfflineOverlay />
  <DeviceReplaceSimNotification />
  <ImitationBar />
  <RouterView />
  <ModalManager />
</template>

<script setup>
import { onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useFavicon } from '@vueuse/core';
import ModalManager from '@/components/_common/modals/ModalManager.vue';
import ImitationBar from '@/components/_common/ImitationBar.vue';
import DeviceReplaceSimNotification from './components/device/_common/DeviceReplaceSimNotification.vue';
import { is_b2b_platform } from '@/utils/b2b-account';
import * as events from '@/events';

const route = useRoute();

onMounted(() => {
  setFavicon();
  setTheme();
});

watch(
  () => route.name,
  (to, from) => {
    if (!from && !to) {
      return;
    }

    events.trigger(events.names.PAGE_VIEWED, {
      to,
      from,
    });
  },
  {
    immediate: true,
  },
);

function setTheme() {
  is_b2b_platform
    ? document.body.classList.add('b2b')
    : document.body.classList.remove('b2b');
}

function setFavicon() {
  const icon = useFavicon();
  icon.value = is_b2b_platform
    ? 'gpx-favicon-192.png'
    : 'logistimatics-favicon-192.png';
}
</script>
