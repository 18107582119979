import {
  ArchiveBoxIcon,
  ArchiveBoxArrowDownIcon,
  ArrowDownCircleIcon,
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ArrowRightIcon,
  ArrowUpRightIcon,
  ArrowUturnLeftIcon,
  Bars3Icon,
  CalendarDaysIcon,
  ChartPieIcon,
  CheckIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronDoubleLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
  ChevronUpIcon,
  ChevronUpDownIcon,
  ClockIcon,
  Cog6ToothIcon,
  CurrencyDollarIcon,
  DocumentIcon,
  DocumentArrowDownIcon,
  ExclamationCircleIcon,
  EllipsisVerticalIcon,
  FlagIcon,
  ListBulletIcon,
  MapIcon,
  MapPinIcon,
  MagnifyingGlassIcon,
  MagnifyingGlassCircleIcon,
  MinusCircleIcon,
  PencilSquareIcon,
  RectangleStackIcon,
  SignalIcon,
  SignalSlashIcon,
  SquaresPlusIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import {
  BoltIcon,
  BoltSlashIcon,
  ExclamationTriangleIcon,
} from '@heroicons/vue/24/solid';

export const registerIcons = (app) => {
  app.component('ArchiveBoxIcon', ArchiveBoxIcon);
  app.component('ArchiveBoxArrowDownIcon', ArchiveBoxArrowDownIcon);
  app.component('ArrowDownCircleIcon', ArrowDownCircleIcon);
  app.component('ArrowDownTrayIcon', ArrowDownTrayIcon);
  app.component('ArrowPathIcon', ArrowPathIcon);
  app.component('ArrowRightIcon', ArrowRightIcon);
  app.component('ArrowUpRightIcon', ArrowUpRightIcon);
  app.component('ArrowUturnLeftIcon', ArrowUturnLeftIcon);
  app.component('Bars3Icon', Bars3Icon);
  app.component('BoltIcon', BoltIcon);
  app.component('BoltSlashIcon', BoltSlashIcon);
  app.component('CalendarDaysIcon', CalendarDaysIcon);
  app.component('ChartPieIcon', ChartPieIcon);
  app.component('CheckIcon', CheckIcon);
  app.component('CheckCircleIcon', CheckCircleIcon);
  app.component('ChevronDownIcon', ChevronDownIcon);
  app.component('ClockIcon', ClockIcon);
  app.component('Cog6ToothIcon', Cog6ToothIcon);
  app.component('ChevronLeftIcon', ChevronLeftIcon);
  app.component('ChevronDoubleLeftIcon', ChevronDoubleLeftIcon);
  app.component('ChevronRightIcon', ChevronRightIcon);
  app.component('ChevronDoubleRightIcon', ChevronDoubleRightIcon);
  app.component('ChevronUpIcon', ChevronUpIcon);
  app.component('ChevronUpDownIcon', ChevronUpDownIcon);
  app.component('CurrencyDollarIcon', CurrencyDollarIcon);
  app.component('DocumentIcon', DocumentIcon);
  app.component('DocumentArrowDownIcon', DocumentArrowDownIcon);
  app.component('ExclamationCircleIcon', ExclamationCircleIcon);
  app.component('ExclamationTriangleIcon', ExclamationTriangleIcon);
  app.component('EllipsisVerticalIcon', EllipsisVerticalIcon);
  app.component('FlagIcon', FlagIcon);
  app.component('ListBulletIcon', ListBulletIcon);
  app.component('MapIcon', MapIcon);
  app.component('MapPinIcon', MapPinIcon);
  app.component('MagnifyingGlassIcon', MagnifyingGlassIcon);
  app.component('MagnifyingGlassCircleIcon', MagnifyingGlassCircleIcon);
  app.component('MinusCircleIcon', MinusCircleIcon);
  app.component('PencilSquareIcon', PencilSquareIcon);
  app.component('RectangleStackIcon', RectangleStackIcon);
  app.component('SignalIcon', SignalIcon);
  app.component('SignalSlashIcon', SignalSlashIcon);
  app.component('SquaresPlusIcon', SquaresPlusIcon);
  app.component('TrashIcon', TrashIcon);
  app.component('XMarkIcon', XMarkIcon);
};
