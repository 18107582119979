<template>
  <div class="flex gap-x-[32px]">
    <div class="basis-1/4 pt-1.5">{{ name }}</div>
    <div class="basis-3/4">
      <slot />
    </div>
  </div>
</template>

<script>
/**
 * TODO
 * get rid of that component
 */
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
