<template>
  <div class="relative">
    <label
      v-if="label"
      class="mb-[3px] block text-gpx_xs font-medium text-gpx_gray-200"
      :for="id"
    >
      {{ label }}
    </label>
    <div class="flex">
      <select
        :name="name"
        :value="selectValue"
        @change="handleChange"
        @blur="handleBlur"
        class="w-full cursor-pointer rounded-[6px] border-0 bg-bg-3 bg-none px-[22px] py-[14px] text-gpx_sm text-lms_black-100 placeholder:text-gpx_gray-300 focus:outline-0 focus:ring-0"
      >
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { useField } from 'vee-validate';
import { toRef } from 'vue';

export default {
  props: {
    name: String,
    initialValue: Object,
    options: Array,
    label: String,
    id: String,
  },

  setup(props) {
    const name = toRef(props, 'name');

    const {
      value: selectValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(name, undefined, {
      initialValue: props.initialValue?.value,
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      selectValue,
      meta,
    };
  },
};
</script>

<style></style>
