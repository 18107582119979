<template>
  <AppHeader class="!bg-lgmx_gray-100" />
  <div
    class="flex h-[calc(100dvh-53px)] gap-x-6 bg-lgmx_gray-100 px-3 pb-3 md:h-[calc(100dvh-73px)] md:px-6 md:pb-6"
  >
    <div class="hidden w-full max-w-[224px] pt-10 md:flex md:flex-col">
      <div class="flex flex-col pl-2 pr-1">
        <div class="mb-6 pl-4 text-2xl font-semibold text-lgmx_black-600">
          Reports
        </div>
        <div class="mb-4">
          <CommonSidebarLink to="/dashboards/dwell" icon="ClockIcon">
            Dwell
          </CommonSidebarLink>
          <CommonSidebarLink v-if="canViewCustomReport" to="/dashboards/analytics" icon="ChartPieIcon">
            Analytics
          </CommonSidebarLink>
          <!-- <UITooltip class="w-full" text="Coming Soon!" placement="top">
            <CommonSidebarLink
              disabled
              to="/dashboards/geofence"
              icon="MapIcon"
            >
              Geofence
            </CommonSidebarLink>
          </UITooltip>
          <UITooltip class="w-full" text="Coming Soon!" placement="top">
            <CommonSidebarLink
              disabled
              to="/dashboards/asset-location"
              icon="MapPinIcon"
            >
              Asset Location
            </CommonSidebarLink>
          </UITooltip> -->
        </div>
        <!-- <div>
          <UITooltip class="w-full" text="Coming Soon!" placement="top">
            <CommonSidebarLink
              disabled
              to="/dashboards/settings"
              icon="Cog6ToothIcon"
            >
              Settings
            </CommonSidebarLink>
          </UITooltip>
        </div> -->
      </div>
      <div class="mt-auto">
        <router-view name="disclaimer" />
      </div>
    </div>
    <div class="flex w-full min-w-0 flex-col rounded-xl bg-white">
      <router-view />
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/_common/AppHeader.vue';
import CommonSidebarLink from '@/components/_common/CommonSidebarLink.vue';
import { mapGetters } from 'vuex'

export default {
  components: {
    AppHeader,
    CommonSidebarLink,
  },
  computed: {
    ...mapGetters({
      canViewCustomReport: 'auth/canViewCustomReport'
    })
  }
};
</script>
