<template>
  <header
    class="sticky top-0 z-10 flex items-center justify-between border-b border-lgmx_gray-100 bg-white py-[6px] pl-6 pr-[14px] md:relative md:flex md:px-12 md:py-4"
  >
    <div class="flex items-center justify-between sm:mr-[126px]">
      <router-link to="/">
        <AppLogo class="max-w-none" />
      </router-link>
    </div>
    <template v-if="!isGpxAdminPage">
      <NavMobileNavigationButton
        v-if="isLoggedIn"
        :isOpen="isMobileNavOpen"
        @setOpen="setIsMobileNavOpen(!isMobileNavOpen)"
      />
      <NavDesktopNavigation v-if="isLoggedIn" />
    </template>
    <template v-if="!isGpxAdminPage">
      <NavMobileNavigation
        v-if="isLoggedIn && isMobileNavOpen"
        @setOpen="setIsMobileNavOpen"
      />
    </template>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AppLogo from './AppLogo.vue';
import NavMobileNavigationButton from './nav/NavMobileNavigationButton.vue';
import NavDesktopNavigation from './nav/NavDesktopNavigation.vue';
import NavMobileNavigation from './nav/NavMobileNavigation.vue';
import { isRouteForGpxAdmin } from '@/utils/rules';

export default {
  components: {
    NavMobileNavigationButton,
    NavDesktopNavigation,
    NavMobileNavigation,
    AppLogo,
  },

  computed: {
    ...mapGetters({
      isMobileNavOpen: 'settings/isMobileNavOpen',
      isLoggedIn: 'auth/check',
    }),
    isGpxAdminPage() {
      return isRouteForGpxAdmin(this.$route);
    },
  },

  methods: {
    ...mapActions({
      setIsMobileNavOpen: 'settings/setIsMobileNavOpen',
    }),
  },
};
</script>
