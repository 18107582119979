<script setup lang="ts">
import { onMounted, reactive, ref, toRaw } from 'vue';
import { useToast } from 'vue-toastification';
import CommonProperty from '@/components/_common/CommonProperty.vue';
import VInput from '@/components/ui/VInput.vue';
import VButton from '@/components/ui/VButton.vue';
import { useDevice } from '@/composables/useDevice.ts';
import {
  celsiusToFahrenheit,
  fahrenheitToCelsius,
} from '@/utils/temperature.ts';

const toast = useToast();
const deviceStore = useDevice();
const { fetchTriggersById, updateTriggersById } = deviceStore;

const props = defineProps<{
  deviceId: number;
}>();

const form = reactive({
  max_temperature: null,
  min_temperature: null,
  min_humidity: null,
  max_humidity: null,
});

const isLoading = ref(true);

onMounted(async () => {
  const data = await fetchTriggersById(props.deviceId);
  for (const field in form) {
    if (['max_temperature', 'min_temperature'].includes(field)) {
      form[field] = celsiusToFahrenheit(data[field]);
    } else {
      form[field] = data[field];
    }
  }

  isLoading.value = false;
});

const handleUpdate = async () => {
  if (isLoading.value) return;

  isLoading.value = true;
  try {
    let payload = { ...toRaw(form) };
    for (const field in payload) {
      if (['max_temperature', 'min_temperature'].includes(field)) {
        payload[field] = fahrenheitToCelsius(payload[field]);
      }
    }

    await updateTriggersById({ device_id: props.deviceId, ...payload });
    toast.success('Temperature and humidity SLAs updated');
  } catch (e) {
    toast.error(e.response?.data?.message || 'An error occurred.');
  }
  isLoading.value = false;
};
</script>
<template>
  <form @submit.prevent="handleUpdate" class="pb-4">
    <CommonProperty name="Min Temperature (F)">
      <VInput
        v-model="form.min_temperature"
        type="number"
        step="any"
        size="sm"
        class="mr-2 flex-1"
        placeholder="-999"
      />
    </CommonProperty>
    <CommonProperty name="Max Temperature (F)">
      <VInput
        v-model="form.max_temperature"
        type="number"
        step="any"
        size="sm"
        class="mr-2 w-1/3 flex-1"
        placeholder="999"
      />
    </CommonProperty>

    <CommonProperty name="">
      <p class="mt-1 text-right text-xs">Leave blank to disable an SLA</p>
    </CommonProperty>

    <CommonProperty name="Min Humidity (%)">
      <VInput
        v-model="form.min_humidity"
        type="number"
        size="sm"
        class="mr-2 flex-1"
        placeholder="0"
      />
    </CommonProperty>
    <CommonProperty name="Max Humidity (%)">
      <VInput
        v-model="form.max_humidity"
        type="number"
        size="sm"
        class="mr-2 flex-1"
        placeholder="100"
      />
    </CommonProperty>

    <CommonProperty name="">
      <p class="mt-1 text-xs">Leave blank to disable an SLA</p>
    </CommonProperty>

    <CommonProperty name="">
      <VButton
        class="mt-2 w-full sm:w-auto"
        :isLoading="isLoading"
        :isDisabled="isLoading"
      >
        Save
      </VButton>
    </CommonProperty>
  </form>
</template>
