<template>
  <div>
    <div
      class="mb-[31px] flex cursor-pointer items-center justify-between border-b border-b-gpx_gray-800 py-[10px]"
      @click="triggerCollapse"
    >
      <h2 class="text-gpx_lg font-semibold text-lms_black-400">
        {{ heading }}
      </h2>
      <ChevronDownIcon
        class="heroicon-stroke-w-1.2 h-[20px] w-[20px] origin-center text-gpx_gray-200 transition-transform will-change-transform"
        :class="{ 'rotate-180': collapsed }"
      />
    </div>
    <div v-show="!collapsed" class="flex flex-col gap-y-[27px]">
      <slot name="content" />
      <slot name="button" />
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon } from '@heroicons/vue/24/outline';

export default {
  name: 'RulesAccordion',
  components: { ChevronDownIcon },
  props: {
    heading: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      collapsed: false,
    };
  },
  methods: {
    triggerCollapse() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>
