import * as events from '@/events';
import mixpanel from 'mixpanel-browser';
import store from '../../store';
import { useLocalStorage } from '@vueuse/core';

function track(eventName, data) {
  // if imitation don't track
  const imitatingAdmin = useLocalStorage('imitating_admin', null);
  if (imitatingAdmin.value) {
    return;
  }

  const user = store.getters['auth/user'];
  if (user.is_gpx_admin) {
    return;
  }

  mixpanel.track(eventName, data);
}

events.subscribe(events.names.USER_LOADED, () => {
  const user = store.getters['auth/user'];
  const account = store.getters['auth/account'];

  mixpanel.identify(user.id);
  mixpanel.people.set({
    $email: user.email,
    user_name: user.name,
    user_id: user.id,
    account_id: account?.id,
    account_name: account?.title,
    is_gpx: account?.is_b2b_account,
  });
  track(events.names.USER_LOADED);
});

events.subscribe(events.names.LOGGED_OUT, () => {
  track(events.names.LOGGED_OUT);
  mixpanel.reset();
});

events.subscribe(events.names.CURRENT_ACCOUNT_CHANGED, () => {
  const account = store.getters['auth/account'];

  mixpanel.register({
    account_id: account.id,
    is_gpx: account.is_b2b_account,
  });

  track(events.names.CURRENT_ACCOUNT_CHANGED);
});

events.subscribe(events.names.ALL_TRACKERS_VIEWED, (data) => {
  track(events.names.ALL_TRACKERS_VIEWED, {
    paramaters: data,
  });
});

events.subscribe(events.names.TRACKER_VIEWED, (data) => {
  track(events.names.TRACKER_VIEWED, {
    device: data,
  });
});

events.subscribe(events.names.TRACKER_NAME_EDITED, (data) => {
  track(events.names.TRACKER_NAME_EDITED, {
    device: data,
  });
});

events.subscribe(events.names.TRACKER_NAME_EDIT_CANCELLED, (data) => {
  track(events.names.TRACKER_NAME_EDIT_CANCELLED, {
    device: data,
  });
});

events.subscribe(events.names.TRACKERS_SEARCHED, (data) => {
  track(events.names.TRACKERS_SEARCHED, {
    searchQuery: data.query,
  });
});

events.subscribe(events.names.TRACKERS_FILTERED, (data) => {
  track(events.names.TRACKERS_FILTERED, {
    filter: data.filter,
  });
});

events.subscribe(events.names.TRACKER_MAP_VIEW_CHANGED, (data) => {
  track(events.names.TRACKER_MAP_VIEW_CHANGED, {
    view: data.view,
  });
});

events.subscribe(events.names.SHIPMENT_CREATED, (data) => {
  track(events.names.SHIPMENT_CREATED, {
    shipment: data,
  });
});

events.subscribe(events.names.SHIPMENT_VIEWED, (data) => {
  track(events.names.SHIPMENT_VIEWED, {
    shipment: data,
  });
});

events.subscribe(events.names.RULE_CREATED, (data) => {
  track(events.names.RULE_CREATED, {
    rule: data,
  });
});

events.subscribe(events.names.COMMAND_SENT_TO_TRACKER, (data) => {
  track(events.names.COMMAND_SENT_TO_TRACKER, {
    command: data,
  });
});

events.subscribe(events.names.GEOFENCE_LIST_VIEWED, (data) => {
  track(events.names.GEOFENCE_LIST_VIEWED, {
    paramaters: data,
  });
});

events.subscribe(events.names.GEOFENCE_CREATED, (data) => {
  track(events.names.GEOFENCE_CREATED, {
    paramaters: data,
  });
});

events.subscribe(events.names.GEOFENCE_DELETED, (data) => {
  track(events.names.GEOFENCE_DELETED, {
    geofence: data,
  });
});

events.subscribe(events.names.GEOFENCE_VIEWED, (data) => {
  track(events.names.GEOFENCE_VIEWED, {
    geofence: data,
  });
});

events.subscribe(events.names.GET_HELP_CLICKED, (data) => {
  track(events.names.GET_HELP_CLICKED, {
    paramaters: data,
  });
});

events.subscribe(events.names.PAGE_VIEWED, (data) => {
  track(events.names.PAGE_VIEWED, data);
});

events.subscribe('*', (eventName, data) => {
  const listeningTo = [
    events.names.ALL_GROUPS_VIEWED,
    events.names.GROUP_VIEWED,
    events.names.GROUP_CREATED,
    events.names.GROUP_EDITED,
    events.names.GROUP_DELETED,
    events.names.GROUP_DEVICES_REMOVED,
    events.names.GROUP_DEVICES_SWAPPED,
    events.names.ALL_BLE_ANCHORS_VIEWED,
    events.names.BLE_ANCHOUR_CREATED,
    events.names.BLE_ANCHOUR_EDITED,
    events.names.BLE_ANCHOUR_DELETED,
    events.names.SHIPMENT_EDITED,
    events.names.SHIPMENT_DELETED,
    events.names.SHIPMENT_ENDED,
    events.names.SHIPMENTS_BULK_ARCHIVED,
    events.names.SHIPMENTS_BULK_UNARCHIVED,
    events.names.SHIPMENTS_BULK_DELETED,
    events.names.SHIPMENT_LIST_VIEWED,
    events.names.SHIPMENT_ARCHIVED_LIST_VIEWED,
    events.names.REPORT_CREATED,
    events.names.REPORT_DELETED,
    events.names.REPORT_VIEWED,
    events.names.RULE_EDITED,
    events.names.RULE_DELETED,
    events.names.USER_INVITED,
    events.names.USER_REMOVED,
    events.names.USER_EDITED,
    events.names.ADDRESS_CREATED,
    events.names.ADDRESS_SET_AS_DEFAULT,
    events.names.ADDRESS_DELETED,
    events.names.PAYMENT_METHOD_CREATION_INITIATED,
    events.names.PAYMENT_METHOD_DELETED,
    events.names.PAYMENT_METHOD_SET_AS_DEFAULT,
  ];

  if (!listeningTo.includes(eventName)) {
    return;
  }

  track(eventName, data);
});
