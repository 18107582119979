<template>
  <div
    class="fixed inset-0 z-50 overflow-y-auto"
    :class="{ 'pt-[56px]': full }"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex min-h-screen items-start justify-center px-[8px] pb-[40px] pt-[76px] text-center sm:items-center sm:px-0 sm:pt-0"
    >
      <div
        class="fixed inset-0 bg-lms_black-100 opacity-[48%]"
        @click.prevent="$emit('close')"
        aria-hidden="true"
      ></div>
      <div
        class="relative inline-block pb-0 text-left transition-all"
        :class="[
          large ? 'w-full sm:w-[560px]' : 'w-full sm:w-[400px]',
          { 'md:w-[908px]': full },
        ]"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    full: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
