import { formatTime } from '@/filters';

export const getFileName = ({ prefix, dateFrom, dateTo }) => {
  return (
    prefix.replace(' ', '-') +
    (dateFrom ? `_${formatTime(dateFrom, 'yyyy-MM-dd-HH-mm')}` : '') +
    (dateTo ? `_${formatTime(dateTo, 'yyyy-MM-dd-HH-mm')}` : '')
  );
};

export const downloadFile = ({ res, fileName, extension }) => {
  const blob = new Blob([res.data], {
    type: res.headers['content-type'],
  });
  const link = document.createElement('a');
  const file = extension ? `${fileName}.${extension}` : fileName;
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute('download', file);
  link.click();
  return res;
};
