import * as _ from 'lodash';
import service, { publicService } from '@/services';
import {
  addOrUpdateItems,
  processAndReturn,
  processEntities,
  removeItem,
  markAsArchivedAndReturn,
  markAsUnarchivedAndReturn,
} from '../helpers';
import {
  ADD,
  BATCH_ADD_OR_UPDATE,
  REMOVE,
  SET_ACTIVE_TAB,
  SET_FILTER,
  SET_MOBILE_ACTIVE_TAB,
  SET_SEARCH,
  SET_SELECTED_IDS,
} from '../mutation-types';
import { downloadFile } from '@/utils/blob';
import { SHIPMENT_CATEGORY } from '@/config/constants';
import * as events from '@/events';

export const state = {
  fetching: false,
  items: [],
  search: '',
  filter: {
    status: [],
  },
  activeTab: SHIPMENT_CATEGORY.general,
  mobileActiveTab: 'right',
  selectedIds: {},
};

export const getters = {
  all: (state) => state.items,
  byTabName: (state, getters) => (tabName) => {
    return getters['all'].filter((item) => {
      if (tabName === SHIPMENT_CATEGORY.general) {
        return !item.archived_at;
      }
      if (tabName === SHIPMENT_CATEGORY.archived) {
        return item.archived_at;
      }
      throw Error(`${tabName} is not supported`);
    });
  },
  byCategory: (state, getters) => (category) => {
    return getters['all'].filter((item) => {
      if (category === SHIPMENT_CATEGORY.general) {
        return !item.archived_at;
      }
      if (category === SHIPMENT_CATEGORY.archived) {
        return item.archived_at;
      }
      throw Error(`${category} is not supported`);
    });
  },
  filtered: (state, getters) => {
    const search = getters['search'].toLowerCase();
    const filter = getters['filter'];
    const filteredByTabName = getters['byTabName'](getters['activeTab']);
    return filteredByTabName.filter((item) => {
      let matched = false;
      if (item.name?.toLowerCase().includes(search)) {
        matched = true;
      }
      if (!_.isEmpty(filter.status) && !filter.status.includes(item.status)) {
        matched = false;
      }
      return matched;
    });
  },
  filteredByCategory: (state, getters) => (category) => {
    const search = getters['search'].toLowerCase();
    const filter = getters['filter'];
    const shipments = getters['byCategory'](category);
    return shipments.filter((item) => {
      let matched = false;
      if (item.name?.toLowerCase().includes(search)) {
        matched = true;
      }
      if (!_.isEmpty(filter.status) && !filter.status.includes(item.status)) {
        matched = false;
      }
      return matched;
    });
  },
  filteredGeneral: (state, getters) => {
    return getters['filteredByCategory'](SHIPMENT_CATEGORY.general);
  },
  filteredArchived: (state, getters) => {
    return getters['filteredByCategory'](SHIPMENT_CATEGORY.archived);
  },
  selectedIdsByCategory: (state) => (category) => {
    return state.selectedIds[category] || [];
  },
  selectedIdsGeneral: (state, getters) => {
    return getters['selectedIdsByCategory'](SHIPMENT_CATEGORY.general);
  },
  selectedIdsArchived: (state, getters) => {
    return getters['selectedIdsByCategory'](SHIPMENT_CATEGORY.archived);
  },
  byId: (state) => (id) => state.items.find((item) => item.id === id),
  byIds: (state) => (ids) =>
    state.items.filter((item) => ids.indexOf(item.id) > -1),
  fetching: (state) => state.fetching,
  search: (state) => state.search,
  filter: (state) => state.filter,
  isAtLeastOneFilterApplied: (state, getters) =>
    _.values(getters.filter).some((f) => !_.isEmpty(f)),
  selectedIdsByTabName: (state) => (category) => {
    return state.selectedIds[category] || [];
  },
  publicShipment: (state) => state.items[0],

  // UI getters
  activeTab: (state) => state.activeTab,
  mobileActiveTab: (state) => state.mobileActiveTab,
};

export const mutations = {
  [ADD]: (state, items) => (state.items = items),
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [REMOVE]: (state, item) => removeItem(state, item),
  SET_FETCHING: (state, fetching) => (state.fetching = fetching),
  [SET_SEARCH]: (state, search) => (state.search = search),
  [SET_FILTER]: (state, filter) => (state.filter = filter),
  [SET_ACTIVE_TAB]: (state, tab) => (state.activeTab = tab),
  [SET_MOBILE_ACTIVE_TAB]: (state, tab) => (state.mobileActiveTab = tab),
  [SET_SELECTED_IDS]: (state, { key, ids }) => (state.selectedIds[key] = ids),
};

export const actions = {
  setSearch: (store, search) => {
    store.commit(SET_SEARCH, search);
  },
  setFilter: (store, filter) => {
    store.commit(SET_FILTER, filter);
  },
  setMobileActiveTab: (store, tab) => store.commit(SET_MOBILE_ACTIVE_TAB, tab),
  setActiveTab: (store, tab) => store.commit(SET_ACTIVE_TAB, tab),
  setSelectedIds: (store, { key, ids }) => {
    store.commit(SET_SELECTED_IDS, { key, ids });
  },
  resetSelectedIds: (store) => {
    store.commit(SET_SELECTED_IDS, { key: SHIPMENT_CATEGORY.general, ids: [] });
    store.commit(SET_SELECTED_IDS, {
      key: SHIPMENT_CATEGORY.archived,
      ids: [],
    });
  },
  fetchByAccountId: (store, { accountId, params }) => {
    store.commit('SET_FETCHING', true);
    return service
      .get(`/accounts/${accountId}/shipments`, { params })
      .then((res) => store.commit(ADD, res.data.data))
      .finally(() => store.commit('SET_FETCHING', false));
  },
  fetchByAccountIdAndId: (store, { accountId, id, params }) => {
    return service
      .get(`/accounts/${accountId}/shipments/${id}`, { params })
      .then((res) => processAndReturn(res, 'shipment'));
  },
  fetchByUuid: (store, { uuid, params }) => {
    return publicService
      .get(`/public-shipments/${uuid}`, { params })
      .then((res) => processAndReturn(res, 'shipment'));
  },
  store: (store, { accountId, params }) => {
    return service
      .post(`/accounts/${accountId}/shipments`, params)
      .then((res) => {
        events.trigger(events.names.SHIPMENT_CREATED, params);
        return processAndReturn(res, 'shipment');
      });
  },
  update: (store, { accountId, id, params }) => {
    return service
      .put(`/accounts/${accountId}/shipments/${id}`, params)
      .then((res) => {
        events.trigger(events.names.SHIPMENT_EDITED, {
          shipment: res.data.data,
          params,
        });
        return processAndReturn(res, 'shipment');
      });
  },
  delete: (store, { accountId, id }) => {
    return service
      .delete(`/accounts/${accountId}/shipments/${id}`)
      .then((res) => {
        events.trigger(events.names.SHIPMENT_DELETED, {
          shipment: id,
        });
        store.commit(REMOVE, { id });
        return res;
      });
  },
  end: (store, { id, params }) => {
    const accountId = store.rootGetters['auth/accountId'];
    return service
      .post(`/accounts/${accountId}/shipments/${id}/finished`, params)
      .then((res) => {
        events.trigger(events.names.SHIPMENT_ENDED, {
          shipment: res.data.data,
        });
        return processAndReturn(res, 'shipment');
      });
  },
  bulkArchive: (store, { params }) => {
    const accountId = store.rootGetters['auth/accountId'];
    return service
      .post(`/accounts/${accountId}/shipments/bulk/archive`, params)
      .then((res) => {
        const shipmentsToArchive = store.getters['byIds'](params.ids);
        const archivedShipments = markAsArchivedAndReturn({
          items: shipmentsToArchive,
        });
        const rest = _.difference(
          store.getters['selectedIdsByTabName']('general'),
          params.ids,
        );
        store.commit(SET_SELECTED_IDS, { key: 'general', ids: rest });
        processEntities(archivedShipments, 'shipment');
        events.trigger(events.names.SHIPMENTS_BULK_ARCHIVED, {
          shipments: shipmentsToArchive,
        });
        return res;
      });
  },
  bulkUnarchive: (store, { params }) => {
    const accountId = store.rootGetters['auth/accountId'];
    return service
      .post(`/accounts/${accountId}/shipments/bulk/unarchive`, params)
      .then((res) => {
        const shipmentsToUnarchive = store.getters['byIds'](params.ids);
        const unarchivedShipments = markAsUnarchivedAndReturn({
          items: shipmentsToUnarchive,
        });
        const rest = _.difference(
          store.getters['selectedIdsByTabName']('archived'),
          params.ids,
        );
        store.commit(SET_SELECTED_IDS, { key: 'archived', ids: rest });
        processEntities(unarchivedShipments, 'shipment');
        events.trigger(events.names.SHIPMENTS_BULK_UNARCHIVED, {
          shipments: shipmentsToUnarchive,
        });
        return res;
      });
  },
  bulkDelete: (store, { params }) => {
    const accountId = store.rootGetters['auth/accountId'];
    return service
      .post(`/accounts/${accountId}/shipments/bulk/delete`, params)
      .then((res) => {
        params.ids.forEach((id) => store.commit(REMOVE, { id }));
        if (params.key) {
          const rest = _.difference(
            store.getters['selectedIdsByTabName'](params.key),
            params.ids,
          );
          store.commit(SET_SELECTED_IDS, { key: params.key, ids: rest });
        }
        events.trigger(events.names.SHIPMENTS_BULK_DELETED, {
          shipments: params.ids,
        });
        return res;
      });
  },
  download: (store, { accountId, id, fileName, params }) => {
    return service
      .get(`/accounts/${accountId}/shipments/${id}`, {
        responseType: 'blob',
        params,
      })
      .then((res) => downloadFile({ res, fileName, extension: 'csv' }));
  },
  downloadAll: (store, { accountId, fileName, params }) => {
    return service
      .get(`/accounts/${accountId}/shipments`, {
        responseType: 'blob',
        params,
      })
      .then((res) => downloadFile({ res, fileName, extension: 'csv' }));
  },
};
