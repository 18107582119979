<template>
  <VCombobox label="Device" size="sm" placeholder="Select..." :options="[]" />
  <VCombobox label="Customer" size="sm" placeholder="Select..." :options="[]" />
  <VButton type="submit" class="w-full" variant="primary"> Assign </VButton>
</template>

<script>
import VCombobox from '@/components/ui/VCombobox.vue';
export default {
  name: 'UtilityAssignForm',
  components: {
    VCombobox,
  },
};
</script>
