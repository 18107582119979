<template>
  <transition name="appear">
    <div
      v-if="isOverlay"
      class="absolute left-0 top-0 z-[99999999] h-screen w-full bg-black/50"
    >
      <div
        class="flex w-full items-center justify-center text-white transition-colors"
        :class="[
          { 'bg-lgmx_green-200/70': isOnline },
          { 'bg-lgmx_red-200/70': !isOnline },
        ]"
      >
        <SignalIcon
          v-if="isOnline"
          class="mr-[6px] h-3 w-3 sm:h-[18px] sm:w-[18px]"
        />
        <SignalSlashIcon
          v-if="!isOnline"
          class="mr-[6px] h-3 w-3 sm:h-[18px] sm:w-[18px]"
        />
        <span class="text-[10px] text-lgmx_gray-100 sm:text-[12px]">
          <span v-if="isOnline">Back online</span>
          <span v-if="!isOnline">Offline</span>
        </span>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { useOnline } from '@vueuse/core';
import { ref, watch } from 'vue';

const timeout = ref(false);
const isOnline = useOnline();
const isOverlay = ref(!isOnline.value);

watch(isOnline, (n) => {
  if (timeout.value) clearTimeout(timeout.value);
  timeout.value = setTimeout(() => {
    isOverlay.value = !n;
  }, 400);
});
</script>

<style scoped>
.appear-enter-active,
.appear-leave-active {
  @apply transition-opacity duration-200;
}
.appear-enter-from {
  @apply opacity-0;
}
.appear-leave-to {
  @apply opacity-0;
}
</style>
