<template>
  <UIBadgeNew :variant="variant">
    <template #icon>
      <BoltSlashIcon
        v-if="charge === 0"
        class="h-[14px] w-[14px]"
        :class="classes.bolt"
      />
      <BoltIcon v-else class="h-[14px] w-[14px]" :class="classes.bolt" />
    </template>
    <template #default>
      <span class="text-[13px] font-semibold">{{ charge }}%</span></template
    >
  </UIBadgeNew>
</template>

<script>
import { BADGE_VARIANT } from '@/components/ui/UIBadgeNew/utils';
import { BATTERY_THRESHOLD } from '@/config/constants';

export default {
  props: {
    charge: Number,
  },
  computed: {
    variant() {
      if (this.charge >= BATTERY_THRESHOLD.medium) {
        return BADGE_VARIANT.success;
      }
      if (
        this.charge >= BATTERY_THRESHOLD.low &&
        this.charge < BATTERY_THRESHOLD.medium
      ) {
        return BADGE_VARIANT.warning;
      }
      return BADGE_VARIANT.error;
    },
    classes() {
      if (this.charge >= BATTERY_THRESHOLD.medium) {
        return {
          bolt: 'text-lgmx_green-200',
        };
      }
      if (
        this.charge >= BATTERY_THRESHOLD.low &&
        this.charge < BATTERY_THRESHOLD.medium
      ) {
        return {
          bolt: 'text-lgmx_yellow-200',
        };
      }
      return {
        bolt: 'text-lgmx_red-200',
      };
    },
  },
};
</script>
