<template>
  <KeepAlive>
    <GeofenceList v-if="isGeofences" :geofences="geofences" />
  </KeepAlive>
  <KeepAlive>
    <BleAnchorList v-if="isBleAnchors" :bleAnchors="bleAnchors" />
  </KeepAlive>
</template>

<script>
import { mapGetters } from 'vuex';
import * as events from '@/events';
import { setTitle } from '@/utils/title';
import { GEOFENCE_CATEGORY } from '@/config/constants';
import GeofenceList from '@/components/geofence/list/GeofenceList.vue';
import BleAnchorList from '@/components/geofence/list/BleAnchorList.vue';

export default {
  components: {
    GeofenceList,
    BleAnchorList,
  },

  computed: {
    ...mapGetters({
      geofences: 'geofence/filtered',
      bleAnchors: 'bleAnchor/filtered',
      activeTab: 'geofence/activeTab',
    }),
    isGeofences() {
      return this.activeTab === GEOFENCE_CATEGORY.geofences;
    },
    isBleAnchors() {
      return this.activeTab === GEOFENCE_CATEGORY.bleAnchors;
    },
  },

  created() {
    setTitle('All Geofences');
  },

  mounted() {
    events.trigger(events.names.GEOFENCE_LIST_VIEWED);
  },
};
</script>

<style></style>
