<script setup lang="ts">
import CommonHelpLink from '@/components/_common/CommonHelpLink.vue';
import { useStore } from 'vuex';
import { useDevice } from '@/composables/useDevice.ts';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onMounted, reactive, ref } from 'vue';

const emit = defineEmits(['update:name', 'onConfirm']);

const props = defineProps<{
  editing: boolean;
  device: any;
  step?: number;
  name: string;
}>();

const store = useStore();
const deviceStore = useDevice();
const { bleDevices } = storeToRefs(deviceStore);
const { fetchBleSupportedDevices } = deviceStore;

const comboboxDevicesRef = ref(null);

const data = reactive({
  isLoading: true,
  selectedDevice: null,
  devices: [],
});

const options = computed(() => {
  const result = [];
  bleDevices.value.forEach((device) => {
    result.push({
      ...device,
      label: device.serial || '',
      value: device.id,
    });
  });

  return result;
});

onMounted(async () => {
  data.isLoading = true;

  await fetchBleSupportedDevices(store.getters['auth/accountId']);

  if (props.device) {
    bleDevices.value.set(props.device.id, props.device);
    comboboxDevicesRef.value.refreshOptions();
    nextTick(() => {
      handleSelectDevice({
        ...props.device,
        label: props.device.serial,
        value: props.device.id,
      });
    });
  }

  data.isLoading = false;
});

const handleSelectDevice = (device) => {
  data.selectedDevice = device;
};

const handleContinueClick = () => {
  emit('onConfirm', data.selectedDevice);
};
</script>
<template>
  <div class="mb-[24px] rounded-b-[10px] bg-white px-[24px] sm:px-[40px]">
    <UIInput
      class="mb-3"
      label="Anchor Name"
      placeholder="Anchor #1"
      :modelValue="name"
      @input="$emit('update:name', $event.target.value)"
    />
    <UICombobox
      class="mb-[6px]"
      label="Serial Number"
      placeholder="00000001"
      :loading="data.isLoading"
      :options="options"
      :model-value="data.selectedDevice"
      :disabled="props.editing"
      @update:modelValue="handleSelectDevice"
      ref="comboboxDevicesRef"
      openOnFocus
    />
    <div class="mb-8">
      <CommonHelpLink text="What is BLE Anchor" class="pt-1">
        <template #title>BLE Anchor</template>
        <template #description>
          An anchor is a fixed BLE device that helps improve location accuracy
          inside buildings. A BLE tag can be set as an anchor to improve the
          accuracy of location tracking for other BLE devices in the area. A
          BLE-enabled tracker can also be set as an anchor to act as a BLE
          gateway and display the locations of other BLE tags that are used as
          trackers.
        </template>
      </CommonHelpLink>
    </div>
    <div class="flex justify-between gap-x-4 pb-[32px]">
      <UIButtonNew
        size="lg"
        :disabled="!props.name || !data.selectedDevice"
        @click="handleContinueClick"
        class="w-full"
      >
        Continue
      </UIButtonNew>
    </div>
  </div>
</template>
