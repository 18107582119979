<template>
  <CommonGoBack label="All Shipments" @click="goBack" />
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import CommonGoBack from '@/components/_common/CommonGoBack.vue';

const router = useRouter();
const store = useStore();

function goBack() {
  router.push({
    name: 'ShipmentListView',
    query: { search: store.getters['shipment/search'] },
  });
}
</script>
