<template>
  <div>
    <UICombobox
      class=""
      :placeholder="'Start typing...'"
      :options="options"
      :selected-options="selectedOptions"
      @update:modelValue="handleSelected"
      label="Select Devices"
      multiple
    />
  </div>
</template>

<script>
import { map } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    value: Array,
    selectedOptions: Array,
  },
  emits: ['update:modelValue'],
  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
    }),
  },
  data() {
    return {
      selected: [],
    };
  },
  beforeMount() {
    if (this.selectedOptions) this.selected = this.selectedOptions;
  },
  methods: {
    ...mapActions({
      fetch: 'deviceMeta/fetchByAccountId',
    }),
    options({ query }) {
      return this.fetch({
        accountId: this.accountId,
        params: { query: query, limit: 25 },
      }).then((res) => {
        return map(res.data.data, (device) => {
          return { value: device.id, label: device.name };
        });
      });
    },
    handleSelected(devices) {
      this.$emit(
        'update:modelValue',
        map(devices, (device) => ({
          id: device.value,
          permissions: 'all',
        })),
      );
    },
  },
};
</script>
