<template>
  <VCombobox label="Customer" size="sm" placeholder="Select..." :options="[]" />
  <VCheckbox>
    <span class="flex-1">High Security</span>
  </VCheckbox>
  <VCheckbox>
    <span class="flex-1">Linked Accounts</span>
  </VCheckbox>
  <VCheckbox>
    <span class="flex-1">Shipments Enabled</span>
  </VCheckbox>
  <VCheckbox>
    <span class="flex-1">Rules Engine Enabled</span>
  </VCheckbox>
  <VInput size="sm" type="text" label="API Key" placeholder="001" />
  <VInput
    size="sm"
    type="text"
    label="Chargebee Customer ID"
    placeholder="001"
  />
  <VButton type="submit" class="w-full" variant="primary">
    Update Security Flags
  </VButton>
</template>

<script>
import VCombobox from '@/components/ui/VCombobox.vue';
export default {
  name: 'UtilitySetFlagsForm',
  components: {
    VCombobox,
  },
};
</script>
