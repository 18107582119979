import { BATCH_ADD_OR_UPDATE } from '../mutation-types';
import {
  all,
  byId,
  byIds,
  addOrUpdateItems,
  processAndReturn,
} from '../helpers';
import service from '@/services';

export const state = {
  items: [],
};

export const getters = { all, byIds, byId };

export const mutations = {
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
};

export const actions = {
  fetchById: (store, id) => {
    return service
      .get(`products/${id}`)
      .then((res) => processAndReturn(res, 'product'));
  },
};
