<template>
  <section>
    <ManageUsersTable v-if="accountId" :accountId="accountId" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { setTitle } from '@/utils/title';
import ManageUsersTable from '@/components/manage/user/ManageUsersTable.vue';

export default {
  components: { ManageUsersTable },

  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
    }),
  },

  created() {
    setTitle('Users Access');
  },
};
</script>
