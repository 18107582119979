<template>
  <div
    class="fixed left-0 top-0 z-[10000] h-screen w-72 bg-white font-medium text-black/50 shadow duration-100 ease-in-out md:hidden"
  >
    <div
      class="inline-flex h-full w-full flex-col gap-y-2 px-[24px]"
      ref="mobileNav"
    >
      <router-link to="/" class="inline-block py-[16px] md:py-0">
        <AppLogo class="max-w-none" />
      </router-link>
      <UILink to="/trackers" class="mt-3 text-lg">Trackers</UILink>
      <UILink to="/geofences" class="text-lg">Geofences</UILink>
      <UILink v-if="canViewDashboards" to="/dashboards" class="text-lg"
        >Dashboards</UILink
      >
      <UILink v-if="canTrackShipments" to="/shipments" class="text-lg"
        >Shipments</UILink
      >
      <UILink to="/alerts" class="text-lg">Alerts</UILink>
      <UILink to="/reports" class="text-lg">Reports</UILink>
      <UILink v-if="hasRulesEngine" to="/rules" class="text-lg">Rules</UILink>
      <UILink v-if="canManage" to="/manage" class="text-lg">Manage</UILink>
      <button @click.prevent="goToSettingsPage" class="mt-3 text-left text-lg">
        Settings
      </button>

      <button
        v-if="canSwitchAccounts"
        @click.prevent="openSwitchAccountModal"
        class="text-left text-lg"
      >
        Switch Accounts
      </button>
      <NavIntercomLauncher class="pb-2 text-lg md:px-5" />
      <button @click.prevent="handleLogout" class="mt-1 text-left text-lg">
        Logout
      </button>
    </div>
  </div>
</template>

<script>
import NProgress from 'nprogress';
import { mapActions, mapGetters } from 'vuex';
import AppLogo from '../AppLogo.vue';
import NavIntercomLauncher from '@/components/_common/nav/NavIntercomLauncher.vue';
import { ROLE } from '@/config/constants';

export default {
  components: {
    AppLogo,
    NavIntercomLauncher,
  },

  emits: ['setOpen'],
  props: {
    isOpen: {
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters({
      account: 'auth/account',
      user: 'auth/user',
      role: 'auth/role',
      canViewDashboards: 'auth/canViewDashboards',
    }),
    canTrackShipments() {
      return this.account?.can_track_shipments;
    },
    canSwitchAccounts() {
      return this.user.accounts?.length > 1;
    },
    hasRulesEngine() {
      return this.account?.has_rules_engine;
    },
    canManage() {
      return [ROLE.admin, ROLE.all].includes(this.role);
    },
  },

  created() {
    document.addEventListener('keydown', this.handleEscape);
  },

  unmounted() {
    document.removeEventListener('keydown', this.handleEscape);
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
      openModal: 'modal/open',
    }),
    handleEscape($event) {
      if ($event.key === 'Esc' || $event.key === 'Escape') {
        this.$emit('setOpen', false);
      }
    },
    goToSettingsPage() {
      this.$router.push({ name: 'AccountSettingsView' });
    },
    openSwitchAccountModal() {
      this.$emit('setOpen', false);
      this.openModal({ name: 'UserAccountSwitchModal' });
    },
    handleLogout() {
      NProgress.start();
      this.logout();
    },
  },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transform: translate3d(0, 0, 0);
}

.v-enter-from,
.v-leave-to {
  transform: translate3d(-100%, 0, 0);
}
</style>
