import cookies from 'js-cookie';

const isLocalHost = () => {
  const host = document.location.hostname;
  return host === 'localhost' || host === '127.0.0.1';
};

const findCookieDomain = () => {
  const host = document.location.hostname;

  if (isLocalHost()) {
    return host;
  }
  const parts = host.split('.');
  if (parts.length === 2) {
    return `.${host}`;
  }

  parts.shift();
  return `.${parts.join('.')}`;
};

const findCookiePrefix = (cookieDomain) => {
  if (isLocalHost()) {
    return '';
  }

  const parts = cookieDomain.split('.');
  if (parts.length == 3) {
    return '';
  }
  return parts[1];
};

const prependCookieName = (prefix, name) => {
  if (!prefix) {
    return name;
  }

  return `${prefix}-${name}`;
};

const domain = findCookieDomain();
const prefix = findCookiePrefix(domain);
const expires = 365;

const options = { domain, expires };

export const getCookie = (name) => {
  return cookies.get(prependCookieName(prefix, name), options);
};

export const setCookie = (name, value) => {
  cookies.set(prependCookieName(prefix, name), value, options);
};

export const removeCookie = (name) => {
  cookies.remove(prependCookieName(prefix, name), options);
};
