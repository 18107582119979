/**
 * Formats money the way you want to
 * @param {number} amount Amount of money in cents
 * @param {boolean} split Should we get main part and dec part separately
 * @param {number} [decimalCount = 2] Quantity of decimals
 * @param {string} [decimal = '.'] Decimal separator
 * @param {string} [thousands = '.'] Thousands separator
 * @example
 *  formatMoney(10000)
 */
export const formatMoney = (
  amount,
  split = false,
  decimalCount = 2,
  decimal = '.',
  thousands = ',',
) => {
  let newDecimalCount;
  let newAmount;
  newDecimalCount = Math.abs(decimalCount);
  newDecimalCount = isNaN(decimalCount) ? 2 : decimalCount;

  const negativeSign = amount < 0 ? '-' : '';

  const i = parseInt(
    (newAmount = Math.abs(Number(amount / 100) || 0).toFixed(newDecimalCount)),
    10,
  ).toString();
  const j = i.length > 3 ? i.length % 3 : 0;

  const result =
    negativeSign +
    (j ? i.substr(0, j) + thousands : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
    (newDecimalCount
      ? decimal +
        Math.abs(newAmount - i)
          .toFixed(newDecimalCount)
          .slice(2)
      : '');

  if (split) {
    const [main, dec] = result.split(decimal);
    return { main, dec: `${decimal}${dec}` };
  } else {
    return result;
  }
};
