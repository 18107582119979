<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { storeToRefs } from 'pinia';
import * as events from '@/events';

import DeviceListItem from '@/components/device/list/DeviceListItem.vue';
import CommonSearchBarSafeArea from '@/components/_common/CommonSearchBarSafeArea.vue';
import CommonListGrayArea from '@/components/_common/CommonListGrayArea.vue';
import VLink from '@/components/ui/VLink.vue';

import { setTitle } from '@/utils/title';
import { useSetItemIdOnDynamicList } from '@/use/use-set-item-id-on-recycle-list';
import { useDevice } from '@/composables/useDevice.ts';
import { usePosition } from '@/composables/usePosition.ts';

const store = useStore();
const account = store.getters['auth/account'];

setTitle('All Trackers');

const props = defineProps<{
  deviceIds: any[];
}>();

const deviceStore = useDevice();
const { selectedIds, devices } = storeToRefs(deviceStore);
const { setSelectedIds } = deviceStore;

const { getPositionByDeviceId } = usePosition();

const b2bStatuses = 'cancelled';
const defaultStatuses = 'active,pending,pending-renewal,pending-cancellation';

const { editItemId, setEditItemId, resetEditItemId } =
  useSetItemIdOnDynamicList('device');

const isSelectedAll = ref(false);

const computedSelectedIds = computed({
  get() {
    return selectedIds.value;
  },
  set(ids) {
    setSelectedIds(ids);
  },
});

onMounted(() => {
  if (selectedIds.value.length === props.deviceIds.length) {
    isSelectedAll.value = true;
  }

  events.trigger(events.names.ALL_TRACKERS_VIEWED);
});

const isIndeterminate = computed(() => {
  return (
    selectedIds.value.length > 0 &&
    selectedIds.value.length < props.deviceIds.length
  );
});

function selectAll(checked: boolean) {
  if (checked) {
    setSelectedIds(props.deviceIds);
  } else {
    setSelectedIds([]);
  }
}

function subscriptionStatus(device) {
  return device?.subscription_status || device?.subscription?.status;
}

function isEnabled(device) {
  const statuses = account.is_b2b_account
    ? defaultStatuses.concat(',', b2bStatuses)
    : defaultStatuses;

  return statuses.includes(subscriptionStatus(device));
}

const items = computed(() => {
  const result = [];
  props.deviceIds.forEach((id) => {
    const device = devices.value.get(id);
    if (!device) {
      return;
    }
    device.position = getPositionByDeviceId(id);
    result.push(device);
  });
  return result;
});
</script>

<template>
  <div class="h-full">
    <CommonSearchBarSafeArea />
    <CommonListGrayArea>
      <UICheckboxNew
        id="select_all_trackers"
        class="mr-4"
        :checked="
          isIndeterminate || selectedIds.length === props.deviceIds.length
        "
        :indeterminate="isIndeterminate"
        @change="selectAll($event.target.checked)"
      />
      <label
        for="select_all_trackers"
        class="cursor-pointer text-[11px] font-semibold uppercase leading-5 text-lgmx_slate-400"
      >
        Select All
      </label>
    </CommonListGrayArea>
    <DynamicScroller
      class="h-[calc(100%-48px)] sm:h-[calc(100%-64px)]"
      :items="items"
      :min-item-size="98"
      :emit-update="true"
      key-field="id"
      listClass="ds-device-list"
      itemClass="ds-device-list-item"
    >
      <template v-slot="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :size-dependencies="[
            item.name,
            subscriptionStatus(item),
            item.note,
            item?.position?.address,
          ]"
          :data-index="index"
        >
          <div class="border-b border-gray-100">
            <div
              v-if="!isEnabled(item)"
              class="flex py-[15px] pl-6 pr-[48px] md:pl-12 md:pr-[56px]"
            >
              <div @click.stop="" class="mr-4 flex pt-[3px]">
                <UICheckboxNewVModel
                  :value="item.id"
                  v-model="computedSelectedIds"
                />
              </div>
              <DeviceListItem
                class="w-full overflow-hidden"
                :device="item"
                :edit-item-id="editItemId"
                @setEditItemId="setEditItemId"
                @resetEditItemId="resetEditItemId"
              />
            </div>
            <VLink
              v-else
              block
              :to="`/trackers/${item.id}`"
              class="bg-white hover:bg-gray-100"
            >
              <div class="flex py-[15px] pl-6 pr-[48px] md:pl-12 md:pr-[56px]">
                <div @click.stop="" class="mr-4 flex pt-[3px]">
                  <UICheckboxNewVModel
                    :value="item.id"
                    v-model="computedSelectedIds"
                  />
                </div>
                <DeviceListItem
                  class="w-full overflow-hidden"
                  :device="item"
                  :edit-item-id="editItemId"
                  @setEditItemId="setEditItemId"
                  @resetEditItemId="resetEditItemId"
                />
              </div>
            </VLink>
          </div>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
  </div>
</template>

<style>
.ds-device-list {
  @apply !overflow-visible;
}
</style>
