<template>
  <div class="relative flex items-center">
    <button
      class="absolute left-0 top-0 h-[20px] w-[20px] rounded-[4px] bg-bg-3"
      :class="[
        { '!h-[24px] !w-[24px]': size === 'medium' },
        { 'cursor-default': disabled },
      ]"
      @click.prevent="check"
    >
      <input type="checkbox" :id="id" :value="checked" class="hidden" />
      <span
        v-if="checked"
        class="flex h-[20px] w-[20px] items-center justify-center rounded-[4px] bg-bg-3"
        :class="[{ '!h-[24px] !w-[24px]': size === 'medium' }]"
      >
        <CheckIcon
          class="heroicon-stroke-w-2 h-[16px] w-[16px] text-lms_black-500"
        />
      </span>
    </button>
    <label
      class="cursor-pointer select-none pl-[36px] text-gpx_xs text-gpx_gray-100"
      :class="[
        {
          'py-[2px] !text-gpx_sm font-medium !text-lms_black-100':
            size === 'medium',
        },
        { 'cursor-default': disabled },
      ]"
      :for="id"
      @click.prevent="check"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/24/outline';

export default {
  name: 'UICheckbox',
  components: { CheckIcon },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
    },
  },
  expose: ['setChecked', 'setCheckQuietly'],
  data() {
    return {
      id: null,
      checked: false,
    };
  },
  mounted() {
    this.id = this._uid;
    this.checked = this.value;
  },
  methods: {
    check() {
      if (this.disabled) return;
      this.setChecked(!this.checked);
    },
    setChecked(checked) {
      this.checked = checked;
      this.$emit('update:modelValue', checked);
    },
    setCheckQuietly(checked = true) {
      this.checked = checked;
    },
  },
};
</script>
