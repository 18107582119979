<template>
  <div
    class="mt-[34px] flex items-start justify-between"
    :class="{ 'flex-col gap-y-[12px]': !isEmpty(filter) }"
  >
    <div class="flex flex-col">
      <div
        class="text-gpx_md font-semibold text-lms_black-400 sm:mb-[2px] sm:text-lg"
      >
        {{ heading }}
      </div>
      <div class="text-sm text-gpx_gray-200">{{ desc }}</div>
    </div>
    <div
      v-if="button"
      @click="doAction(button.action)"
      class="mt-[4px] inline-flex cursor-pointer items-center gap-x-[4px] py-[2px]"
    >
      <component
        v-if="button.icon"
        :is="button.icon"
        class="h-[16px] w-[16px] text-lms_red-900"
      />
      <span class="inline-block text-sm font-medium text-lms_red-900">{{
        button.label
      }}</span>
    </div>
    <div v-if="!isEmpty(filter)" class="inline-flex pt-[5px]">
      <UIDataFilter v-if="!isEmpty(filter)" :filter="filter" class="w-full" />
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import UIDataFilter from '@/components/ui/UIDataFilter.vue';

export default {
  name: 'HeadingSection',
  props: {
    heading: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      required: true,
    },
    button: {
      type: Object,
      required: false,
    },
    search: {
      type: Object,
      required: false,
    },
    filter: {
      type: Object,
      required: false,
    },
  },

  components: {
    UIDataFilter,
  },

  methods: {
    isEmpty: _.isEmpty,
    doAction(action) {
      if (action.mode === 'callback') {
        action.callback();
      }
      if (action.mode === 'modal') {
        this.$emit('open-modal', action.modal);
      }
      if (action.mode === 'route') {
        this.$router.push(action.route);
      }
    },
  },
};
</script>
