<template>
  <form class="mb-4 max-w-[664px]" @submit.prevent="handleSubmit">
    <div class="mb-4">
      <label class="mb-2 inline-block" for="password">Password</label>
      <VInput
        id="password"
        type="password"
        v-model="form.password"
        name="password"
        autocomplete="new-password"
      />
      <span class="mt-1 block text-xs leading-snug text-gray-750"
        >Password must be at least 8 characters</span
      >
    </div>
    <div class="mb-4">
      <label
        class="mb-2 inline-block"
        for="confirm-password"
        name="confirm-password"
        >Confirm new password</label
      >
      <VInput
        id="confirm-password"
        type="password"
        v-model="form.password_confirmation"
        autocomplete="new-password"
      />
    </div>
    <div class="text-right">
      <VButton
        type="submit"
        variant="outline-secondary"
        class="w-40"
        :isLoading="isSubmitting"
        loadingText="Updating..."
        :isDisabled="isSubmitDisabled || isSubmitDisabledComputed"
      >
        Update password
      </VButton>
    </div>
  </form>
</template>

<script>
import VInput from '../ui/VInput.vue';
import VButton from '../ui/VButton.vue';
import { useToast } from 'vue-toastification';
import { PASSWORD_UPDATE_SUCCESS } from '@/config/constants';
import { mapActions } from 'vuex';

export default {
  components: { VInput, VButton },

  data() {
    return {
      isSubmitting: false,
      isSubmitDisabled: false,
      form: {
        password: '',
        password_confirmation: '',
      },
    };
  },
  computed: {
    isSubmitDisabledComputed() {
      const { password, password_confirmation } = this.form;
      if (this.isSubmitting) {
        return true;
      }
      if (password.length < 8) {
        return true;
      } else if (password !== password_confirmation) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      updatePassword: 'auth/updatePassword',
    }),
    handleSubmit() {
      const toast = useToast();
      const { form } = this;
      this.isSubmitting = true;
      this.updatePassword(form)
        .then(() => {
          this.isSubmitting = false;
          this.isSubmitDisabled = true;
          this.$router.push('/');
          toast.success(PASSWORD_UPDATE_SUCCESS, { timeout: 8000 });
        })
        .catch((err) => {
          console.error(err);
          this.isSubmitting = false;
        });
    },
  },
};
</script>

<style></style>
