<template>
  <label :class="variant">
    <span class="block sm:inline">
      <slot />
    </span>
    <select
      :value="modelValue"
      v-bind="{
        ...$attrs,
        onChange: ($event) => {
          let value = +$event.target.value;
          if (isNaN(value)) {
            value = $event.target.value;
          }
          $emit('update:modelValue', value);
        },
      }"
      class="pointer-events-auto h-10 w-full rounded-md border-gray-600 px-4 py-1 text-base text-gray-900 shadow-inner outline-none transition-all placeholder:text-gray-750 focus:border-red-250 focus:ring-3 focus:ring-red-400/25"
      :class="selectClass"
    >
      <option value="" disabled selected>Select one</option>
      <option
        v-for="option in options"
        :value="option.value"
        :key="option.value"
        :selected="option.value === modelValue"
      >
        {{ option.label }}
      </option>
    </select>
  </label>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'block',
    },
    size: {
      type: String,
      default: 'normal',
    },
    modelValue: {
      type: [Number, String],
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
  },

  computed: {
    selectClass() {
      return `${this.size}`;
    },
  },
};
</script>

<style scoped>
.sm {
  @apply h-8 text-sm rounded-[3px];
}
</style>
