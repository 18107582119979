<template>
  <VTooltip placement="bottom" :delay="{ show: 1000, hide: 100 }">
    <slot />
    <template #popper>
      <div v-if="typeof text === 'string'" class="px-[8px] py-[4px]">
        {{ text }}
      </div>
      <div
        v-if="Array.isArray(text)"
        class="flex flex-col items-center px-[8px] py-[4px]"
      >
        <div v-for="item in text" :key="item">{{ item }}</div>
      </div>
    </template>
  </VTooltip>
</template>

<script>
export default {
  props: {
    text: {
      type: [Array, String],
      required: true,
    },
  },
};
</script>
