<template>
  <UIInput
    label="Send email to"
    placeholder="To send emails to multiple users, separate the email addresses with a comma"
    v-model="form.emails"
  />

  <UIInput label="Subject" placeholder="Subject" v-model="form.subject" />

  <div class="flex flex-col gap-y-[11px]">
    <UITextarea
      label="Email body"
      placeholder="Email body"
      v-model="form.body"
    />
    <div class="text-gpx_xs font-medium text-gpx_gray-300">
      You can use the following placeholders:<br />
      &lcub;&lcub;name&rcub;&rcub; for the tracker name<br />
      &lcub;&lcub;time&rcub;&rcub; for the message sent time<br />
      &lcub;&lcub;map&rcub;&rcub; for a Google Maps link to the tracker
      location<br />
      &lcub;&lcub;address&rcub;&rcub; for the tracker’s address (if available)
    </div>
  </div>
  <UICombobox
    label="Using timezone"
    placeholder="Select timezone"
    ref="timezoneSelect"
    :options="timezones"
    :openOnFocus="true"
    v-model="form.timezone"
  />
</template>
<script>
import { isEmpty } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'SendEmailField',
  emits: ['onChange'],
  props: {
    value: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      form: {
        emails: null,
        subject: null,
        body: null,
        timezone: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      timezones: 'settings/timezones',
    }),
  },
  async mounted() {
    await this.fetchTimezones();

    if (!this.value || isEmpty(this.value)) {
      return;
    }

    this.populateTimezoneSelect();
    this.form.emails = this.value?.email_addresses?.join(',');
    this.form.subject = this.value.subject;
    this.form.body = this.value.body;
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.onChange();
      },
    },
  },
  methods: {
    ...mapActions({
      fetchTimezones: 'settings/fetchTimezones',
    }),
    populateTimezoneSelect() {
      const item = this.timezones.find(
        (timezone) => timezone.value === this.value.timezone_id,
      );
      if (!item) {
        return;
      }
      this.form.timezone = item;
      this.$refs.timezoneSelect.setSelectedOptionQueitly(item);
    },
    onChange() {
      this.$emit('onChange', {
        email_addresses: this.form.emails?.split(','),
        subject: this.form.subject,
        body: this.form.body,
        timezone_id: this.form.timezone?.value,
      });
    },
  },
};
</script>
