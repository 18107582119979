<template>
  <router-link
    :to="{ name: computedRoute }"
    class="whitespace-nowrap pb-[15px] pt-[5px] text-gpx_sm font-medium text-gpx_gray-100 hover:text-lms_red-900"
    :class="{ 'border-b-[2px] border-b-lms_red-900 !text-lms_red-900': active }"
  >
    {{ tab.name }}
  </router-link>
</template>

<script>
export default {
  name: 'TabsNavItem',
  props: {
    tab: {
      type: Object,
      required: true,
    },
  },
  computed: {
    computedRoute() {
      return this.tab.route ? this.tab.route : 'root';
    },
    currentRoute() {
      return this.$router.currentRoute.value.name;
    },
    active() {
      return (
        this.tab.route === this.currentRoute ||
        this.tab.relatedRoutes?.includes(this.currentRoute)
      );
    },
  },
};
</script>
