<template>
  <div class="relative flex gap-x-5">
    <button
      v-for="item in items"
      :key="item.key"
      @click="selectTab(item.key)"
      class="relative whitespace-nowrap text-lgmx_19 font-semibold leading-7 text-lgmx_slate-300"
      :class="{
        '!text-lgmx_black-600': modelValue === item.key,
      }"
    >
      {{ item.label }}
    </button>
  </div>
</template>

<script setup>
const emit = defineEmits(['update:modelValue']);
defineProps({
  items: Array,
  modelValue: String,
  noBorder: {
    type: Boolean,
    default: false,
  },
});

function selectTab(key) {
  emit('update:modelValue', key);
}
</script>
