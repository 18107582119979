import { defineStore, storeToRefs } from 'pinia';
import service from '@/services';
import { setAbortController } from '@/composables/useAbortController';
import { ref, computed } from 'vue';
import { downloadFile } from '@/utils/blob.js';
import { useFilter } from '@/composables/useFilter.ts';

export const useStopHistory = defineStore('stopHistory', () => {
  const filterStore = useFilter();
  const isLoading = ref(false);
  const stopHistory = ref(new Map<string, any>());

  const fetchStopHistoryByAccountAndDevice = async (
    accountId: number,
    deviceId: number,
    dateFrom?: string,
    dateTo?: string,
  ) => {
    const { dateFilter } = storeToRefs(filterStore);

    isLoading.value = true;
    const controller = setAbortController('stopHistory');

    if (!dateFrom) {
      dateFrom = dateFilter.value?.dateFrom;
      dateTo = dateFilter.value?.dateTo;
    }

    const params = { date_from: dateFrom, date_to: dateTo, format: 'zulu' };
    const response = await service.get(
      `/accounts/${accountId}/devices/${deviceId}/stops`,
      {
        params,
        signal: controller.signal,
      },
    );

    if ((response && response.status === 499) || !response?.data?.data) {
      isLoading.value = false;
      return response;
    }

    stopHistory.value.set(
      `${deviceId}$${dateFrom}$${dateTo}`,
      response.data.data,
    );

    isLoading.value = false;

    return response;
  };

  const downloadAll = (
    accountId: number,
    deviceId: number,
    fileName: string,
    params: any,
  ) => {
    return service
      .get(`/accounts/${accountId}/devices/${deviceId}/stops`, {
        responseType: 'blob',
        params,
      })
      .then((res) => downloadFile({ res, fileName, extension: 'csv' }));
  };

  const getStopHistoryByDateForDeviceId = computed(
    () => (deviceId: number, dateFrom: string, dateTo: string) => {
      return stopHistory.value.get(`${deviceId}$${dateFrom}$${dateTo}`);
    },
  );

  return {
    isLoading,
    stopHistory,

    fetchStopHistoryByAccountAndDevice,
    getStopHistoryByDateForDeviceId,
    downloadAll,
  };
});
