<template>
  <div
    class="mt-[35px] max-w-[360px] rounded-[8px] bg-bg-1 px-[32px] pb-[27px] pt-[22px] text-gpx_sm"
  >
    <div class="mb-[7px] text-gpx_base font-semibold text-lms_black-400">
      {{ name }}
    </div>
    <div class="flex flex-col gap-y-[4px]">
      <div
        v-for="(line, index) in address"
        :key="`address-line-${index}`"
        class="text-lms_black-100"
      >
        {{ line }}
      </div>
    </div>
    <div class="mt-[12px] flex flex-col gap-y-[8px]" v-if="phone || email">
      <a
        v-if="phone"
        class="inline-flex gap-x-[6px] font-medium text-lms_black-100"
        :href="`tel:${phone}`"
      >
        <PhoneIcon
          class="heroicon-stroke-w-1.4 h-[20px] w-[20px] text-lms_red-900"
        />
        <span>{{ phone }}</span>
      </a>
      <a
        v-if="email"
        class="inline-flex gap-x-[6px] font-medium text-lms_black-100"
        :href="`mailto:${email}`"
      >
        <EnvelopeIcon
          class="heroicon-stroke-w-1.4 h-[20px] w-[20px] text-lms_red-900"
        />
        <span>{{ email }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import { PhoneIcon, EnvelopeIcon } from '@heroicons/vue/24/outline';

export default {
  name: 'CardAddress',
  props: {
    name: {
      type: String,
      required: true,
    },
    address: {
      type: Array,
      required: true,
    },
    phone: {
      type: String,
      required: false,
    },
    email: {
      type: String,
      required: false,
    },
  },
  components: {
    PhoneIcon,
    EnvelopeIcon,
  },
};
</script>
