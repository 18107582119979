<template>
  <router-link v-if="type === 'link'" :class="linkClass">
    <slot />
  </router-link>
  <button v-else :class="linkClass">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'link',
    },
    block: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'default',
    },
  },

  computed: {
    linkClass() {
      return `${this.block ? 'block' : 'inline-block'} ${this.variant}`;
    },
  },
};
</script>

<style scoped>
a.router-link-active {
  @apply text-red-400 hover:text-red-400;
}
.default {
  @apply hover:text-black/70;
}
.primary {
  @apply text-red-550 hover:text-red-400;
}
</style>
