<template>
  <div class="pt-[25px]">
    <div class="mb-[14px] text-[13px] leading-5 text-lgmx_black-200">
      Once the screws are removed and the device is open, the metal sliding SIM
      door should be visible. The SIM door is a piece of metal covered by a
      piece of black plastic foam.
    </div>

    <div class="mb-[6px] flex justify-center">
      <img :src="svg1" alt="SIM door location" />
    </div>

    <div class="flex justify-between gap-x-4 pb-[18px] pt-[26px]">
      <UIButtonNew
        variant="tertiary"
        size="lg"
        class="w-[124px]"
        @click="$emit('onCancel')"
      >
        Back
      </UIButtonNew>
      <UIButtonNew @click="$emit('onConfirm')" class="w-full">
        Next
      </UIButtonNew>
    </div>
  </div>
</template>

<script>
import Step3Svg1 from './assets/step-3.svg';

export default {
  emits: ['onConfirm', 'onCancel'],

  computed: {
    svg1() {
      return Step3Svg1;
    },
  },
};
</script>
