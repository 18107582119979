import { useLocalStorage } from '@vueuse/core';
import { removeCookie } from '@/utils/cookie';

export function storeImitateFrom() {
  const params = new URLSearchParams(window.location.search);
  if (!params.has('imitate_from')) return;
  const imitateFrom = useLocalStorage('imitate_from', null);
  imitateFrom.value = params.get('imitate_from');
}

export function storeImitatingAdmin() {
  const params = new URLSearchParams(window.location.search);
  if (!params.has('admin')) return;
  useLocalStorage('imitating_admin', params.get('admin'));
}

export const removeImitateTokenIfNeeded = () => {
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.has('no_imitate')) {
    removeCookie('imitation_token');
    window.history.replaceState(null, '', window.location.pathname);
  }
};
