import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useFilter = defineStore('filter', () => {
  const dateFilter = ref({
    dateFrom: null,
    dateTo: null,
  });

  const overlayFilter = ref({
    cellaccuracy: false,
    wifiaccuracy: false,
    cellfixes: false,
    wififixes: false,
    gpsfixes: false,
    blefixes: false,
    tracksGroup: false,
    stops: false,
    positions: false,
  });

  const mapFixOverlay = {
    wifi: 'wififixes',
    ble: 'blefixes',
    gps: 'gpsfixes',
    cell: 'cellfixes',
  };

  const setDateFilter = (dateFrom: string, dateTo: string): void => {
    dateFilter.value.dateFrom = dateFrom;
    dateFilter.value.dateTo = dateTo;
  };

  const setOverlayVisible = (name: string, isShow: boolean): void => {
    overlayFilter.value[name] = isShow;
  };

  const isVisibleOverlay = computed(
    () => (fix) => !!overlayFilter.value[mapFixOverlay[fix]],
  );

  return {
    dateFilter,
    overlayFilter,

    setDateFilter,
    setOverlayVisible,
    isVisibleOverlay,
  };
});
