import L from 'leaflet';
import { getImageUrl } from '@/utils/cloudinary';

const ICON_ANCHOR_Y = 38;
const ICON_WIDTH = 24;
const ICON_HEIGHT = 38;
const MARKERS_ICONS_BASE_URL = 'logistimatics_webapp/map_markers';
const MARKER_SHADOW_URL = getImageUrl({
  publicId: `${MARKERS_ICONS_BASE_URL}/marker-shadow`,
});

const shadowUrl = MARKER_SHADOW_URL;
const popupAnchor = [0, -34];
const iconSize = [ICON_WIDTH, ICON_HEIGHT];
const iconAnchor = [12, ICON_ANCHOR_Y];
const tooltipAnchor = [0, -34];
const shadowSize = [ICON_HEIGHT, ICON_HEIGHT];

export const GPS_ICONS = {
  pink: new L.Icon({
    iconUrl: getImageUrl({
      publicId: `${MARKERS_ICONS_BASE_URL}/marker-icon-pink_3x`,
    }),
    shadowUrl,
    iconSize,
    iconAnchor,
    popupAnchor,
    tooltipAnchor,
    shadowSize,
  }),
  blue: new L.Icon({
    iconUrl: getImageUrl({
      publicId: `${MARKERS_ICONS_BASE_URL}/marker-icon-blue_3x`,
    }),
    shadowUrl,
    iconSize,
    iconAnchor,
    popupAnchor,
    tooltipAnchor,
    shadowSize,
  }),
  green: new L.Icon({
    iconUrl: getImageUrl({
      publicId: `${MARKERS_ICONS_BASE_URL}/marker-icon-green_3x`,
    }),
    shadowUrl,
    iconSize,
    iconAnchor,
    popupAnchor,
    tooltipAnchor,
    shadowSize,
  }),
  gray: new L.Icon({
    iconUrl: getImageUrl({
      publicId: `${MARKERS_ICONS_BASE_URL}/marker-icon-gray_3x`,
    }),
    shadowUrl,
    iconSize,
    iconAnchor,
    popupAnchor,
    tooltipAnchor,
    shadowSize,
  }),
  yellow: new L.Icon({
    iconUrl: getImageUrl({
      publicId: `${MARKERS_ICONS_BASE_URL}/marker-icon-orange_3x`,
    }),
    shadowUrl,
    iconSize,
    iconAnchor,
    popupAnchor,
    tooltipAnchor,
    shadowSize,
  }),
  red: new L.Icon({
    iconUrl: getImageUrl({
      publicId: `${MARKERS_ICONS_BASE_URL}/marker-icon-red_3x`,
    }),
    shadowUrl,
    iconSize,
    iconAnchor,
    popupAnchor,
    tooltipAnchor,
    shadowSize,
  }),
};

export const CELL_ICONS = {
  pink: new L.Icon({
    iconUrl: getImageUrl({
      publicId: `${MARKERS_ICONS_BASE_URL}/marker-icon-pink_3x`,
    }),
    shadowUrl,
    iconSize,
    iconAnchor,
    popupAnchor,
    tooltipAnchor,
    shadowSize,
  }),
  blue: new L.Icon({
    iconUrl: getImageUrl({
      publicId: `${MARKERS_ICONS_BASE_URL}/cell-icon-blue_3x`,
    }),
    shadowUrl,
    iconSize,
    iconAnchor,
    popupAnchor,
    tooltipAnchor,
    shadowSize,
  }),
  green: new L.Icon({
    iconUrl: getImageUrl({
      publicId: `${MARKERS_ICONS_BASE_URL}/cell-icon-green_3x`,
    }),
    shadowUrl,
    iconSize,
    iconAnchor,
    popupAnchor,
    tooltipAnchor,
    shadowSize,
  }),
  gray: new L.Icon({
    iconUrl: getImageUrl({
      publicId: `${MARKERS_ICONS_BASE_URL}/cell-icon-gray_3x`,
    }),
    shadowUrl,
    iconSize,
    iconAnchor,
    popupAnchor,
    tooltipAnchor,
    shadowSize,
  }),
  yellow: new L.Icon({
    iconUrl: getImageUrl({
      publicId: `${MARKERS_ICONS_BASE_URL}/cell-icon-orange_3x`,
    }),
    shadowUrl,
    iconSize,
    iconAnchor,
    popupAnchor,
    tooltipAnchor,
    shadowSize,
  }),
  red: new L.Icon({
    iconUrl: getImageUrl({
      publicId: `${MARKERS_ICONS_BASE_URL}/cell-icon-red_3x`,
    }),
    shadowUrl,
    iconSize,
    iconAnchor,
    popupAnchor,
    tooltipAnchor,
    shadowSize,
  }),
};

export const DEFAULT_ICONS = GPS_ICONS;
export const BLE_ICONS = CELL_ICONS;
export const WIFI_ICONS = CELL_ICONS;
