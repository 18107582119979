<script setup lang="ts">
import * as _ from 'lodash';
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouteQuery } from '@vueuse/router';
import CommonListSearch from '@/components/_common/CommonListSearch.vue';
import SlideBar from '@/components/_common/SlideBar.vue';
import { DEVICE_CATEGORY } from '@/config/constants';
import * as events from '@/events';
import { useRoute, useRouter } from 'vue-router';
import UIIconButton from '@/components/ui/UIIconButton.vue';
import { useDevice } from '@/composables/useDevice';
import { storeToRefs } from 'pinia';
import { useGroup } from '@/composables/useGroup.ts';

const route = useRoute();
const router = useRouter();
const store = useStore();
const isB2B = store.getters['auth/isB2B'];

const deviceStore = useDevice();
const { activeTab } = storeToRefs(deviceStore);
const { setSearch, applyFilter, setActiveTab } = deviceStore;

const { setSearch: setGroupSearch } = useGroup();

// Tabs
const tabs = [
  { key: DEVICE_CATEGORY.allTrackers, label: 'All Trackers' },
  { key: DEVICE_CATEGORY.groups, label: 'Groups' },
];
watch(activeTab, (n) => {
  setActiveTab(n);
  if (n === DEVICE_CATEGORY.allTrackers) {
    router.push({ name: 'DeviceListView' });
  }
  if (n === DEVICE_CATEGORY.groups) {
    router.push({ name: 'GroupListView' });
  }
});

watch(
  route,
  (n) => {
    if (n.name === 'DeviceListView') {
      activeTab.value = DEVICE_CATEGORY.allTrackers;
      return;
    }
    if (n.name === 'GroupListView') {
      activeTab.value = DEVICE_CATEGORY.groups;
      return;
    }
  },
  { immediate: true },
);

// Devices Search (with address bar query handling)
const devicesSearchRef = ref(null);
const isDevicesSearchOpen = ref(false);
const devicesSearchQuery = useRouteQuery('search', '');

watch(devicesSearchQuery, _.debounce(handleDevicesSearch, 300), {
  immediate: true,
  flush: 'post',
});
function handleDevicesSearch(value) {
  if (value && !isDevicesSearchOpen.value) {
    openDevicesSearch();
  }

  setSearch(value);
  applyFilter();
  devicesSearchQuery.value = value;
  events.trigger(events.names.TRACKERS_SEARCHED, { query: value });
}
function openDevicesSearch() {
  isDevicesSearchOpen.value = true;
}
function closeDevicesSearch() {
  isDevicesSearchOpen.value = false;
  devicesSearchQuery.value = '';
}

// Groups Search (with address bar query handling)
const groupsSearchRef = ref(null);
const isGroupsSearchOpen = ref(false);
const groupsSearchQuery = useRouteQuery('search', '');

watch(groupsSearchQuery, _.debounce(handleGroupsSearch, 100), {
  immediate: true,
  flush: 'post',
});

function handleGroupsSearch(value) {
  if (value && !isGroupsSearchOpen.value) {
    openGroupsSearch();
  }
  setGroupSearch(value);
  groupsSearchQuery.value = value;
  events.trigger(events.names.GROUPS_SEARCHED, { query: value });
}
function openGroupsSearch() {
  isGroupsSearchOpen.value = true;
}
function closeGroupsSearch() {
  isGroupsSearchOpen.value = false;
  groupsSearchQuery.value = '';
}

const isSearchOpen = computed(
  () =>
    (isDevicesSearchOpen.value &&
      activeTab.value === DEVICE_CATEGORY.allTrackers) ||
    (isGroupsSearchOpen.value && activeTab.value === DEVICE_CATEGORY.groups),
);
</script>
<template>
  <SlideBar :is-first-active="isB2B ? isSearchOpen : true">
    <template #first>
      <template v-if="activeTab === DEVICE_CATEGORY.allTrackers">
        <CommonListSearch
          ref="devicesSearchRef"
          placeholder="Search Trackers..."
          v-model="devicesSearchQuery"
          @close="closeDevicesSearch"
        />
      </template>
      <template v-if="activeTab === DEVICE_CATEGORY.groups">
        <CommonListSearch
          ref="groupsSearchRef"
          placeholder="Search Groups..."
          v-model="groupsSearchQuery"
          @close="closeGroupsSearch"
        />
      </template>
    </template>
    <template #second>
      <UITabs :items="tabs" v-model="activeTab" />
      <UIIconButton
        v-if="activeTab === DEVICE_CATEGORY.allTrackers"
        icon="MagnifyingGlassIcon"
        class="ml-auto"
        tooltip="Search trackers"
        @click="openDevicesSearch"
      />
      <UIIconButton
        v-if="activeTab === DEVICE_CATEGORY.groups"
        icon="MagnifyingGlassIcon"
        class="ml-auto"
        tooltip="Search groups"
        @click="openGroupsSearch"
      />
    </template>
  </SlideBar>
</template>
