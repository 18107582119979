<template>
  <div class="flex justify-end gap-x-[12px] p-[24px] sm:px-[40px]">
    <UIButton
      v-if="type === 'update'"
      :loadiing="deleting"
      :disabled="deleting"
      type="outline"
      size="medium"
      label="Delete"
      @click="deleteRule"
    />

    <UIButton
      :loading="saving"
      :disabled="saving"
      size="medium"
      label="Save"
      @click="saveRule"
    />
  </div>
</template>

<script>
export default {
  name: 'RulesFooter',
  inject: ['type', 'saving', 'deleting', 'saveRule', 'deleteRule'],
};
</script>
