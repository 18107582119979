<template>
  <ModalLayout title="New Address" large>
    <div class="rounded-[10px] bg-white pt-[41px]">
      <div
        @click.prevent="$emit('close')"
        class="absolute right-[24px] top-[36px] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full border border-gpx_gray-800 sm:right-[40px]"
      >
        <XMarkIcon
          class="heroicon-stroke-w-1.4 h-[20px] w-[20px] text-gpx_gray-200"
        />
      </div>
      <div
        class="mx-[24px] border-b border-b-gpx_gray-800 pb-[18px] sm:mx-[40px]"
      >
        <div class="mb-[2px] text-gpx_sm font-medium text-gpx_gray-200">
          Adding New
        </div>
        <div class="text-gpx_2xl font-semibold text-lms_black-500">Address</div>
      </div>
      <div class="mt-[36px] px-[24px] sm:px-[40px]">
        <UITabGroup class="w-full" :tabs="tabs" v-model="tabIndex" />
      </div>
      <div v-if="tabIndex === 0" class="mt-[26px]">
        <div class="flex flex-col gap-y-[3px] px-[24px] sm:px-[40px]">
          <div class="text-gpx_base font-semibold text-lms_black-400">
            Billing Address
          </div>
          <div class="text-gpx_sm text-gpx_gray-100">
            Enter your billing details
          </div>
        </div>
        <NewBillingAddressForm @close="$emit('close')" />
      </div>
      <div v-if="tabIndex === 1" class="mt-[26px]">
        <div class="flex flex-col gap-y-[3px] px-[24px] sm:px-[40px]">
          <div class="text-gpx_base font-semibold text-lms_black-400">
            Shipping address
          </div>
          <div class="text-gpx_sm text-gpx_gray-100">
            Enter your shipping details
          </div>
        </div>
        <NewShippingAddressForm @close="$emit('close')" />
      </div>
    </div>
  </ModalLayout>
</template>

<script>
import { XMarkIcon } from '@heroicons/vue/24/outline';
import ModalLayout from '@/layouts/ModalLayout.vue';
import UITabGroup from '@/components/ui/UITabGroup.vue';
import NewBillingAddressForm from '@/components/manage/address/modals/add/_common/NewBillingAddressForm.vue';
import NewShippingAddressForm from '@/components/manage/address/modals/add/_common/NewShippingAddressForm.vue';

export default {
  components: {
    XMarkIcon,
    ModalLayout,
    UITabGroup,
    NewBillingAddressForm,
    NewShippingAddressForm,
  },

  data() {
    return {
      tabs: ['Billing', 'Shipping'],
      tabIndex: 0,
    };
  },
};
</script>
