<template>
  <div class="relative">
    <label
      v-if="label"
      class="mb-[3px] block text-gpx_xs font-medium text-gpx_gray-200"
      :for="id"
    >
      {{ label }}
    </label>
    <div class="flex">
      <div class="relative flex grow">
        <input
          :id="id"
          :value="selected.label"
          :placeholder="placeholder"
          @keypress.prevent
          @click.prevent="openDropdown"
          class="w-full rounded-[6px] border-0 bg-bg-3 bg-none px-[22px] py-[14px] text-gpx_sm placeholder:text-gpx_gray-300 focus:outline-0 focus:ring-0"
          :class="[
            {
              'bg-white drop-shadow-[2px_4px_8px_0_rgba(232,_234,_237,_0.2)]':
                light,
            },
            {
              'text-blac cursor-pointer': !disabled,
              'cursor-not-allowed text-gpx_gray-600': disabled,
            },
            { 'caret-transparent': noCaret },
          ]"
        />
        <ChevronDownIcon
          class="heroicon-stroke-w-1.2 pointer-events-none absolute right-[16px] top-1/2 size-[20px] -translate-y-1/2"
          :class="{
            'text-gpx_gray-200': !disabled,
            'text-gpx_gray-600': disabled,
          }"
        />
      </div>
    </div>
    <UISelectMenu
      v-if="showDropdown"
      :options="options"
      :selected="selected"
      @close="selectOption($event)"
      :can-delete="canDelete"
    />
  </div>
</template>

<script>
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import UISelectMenu from '@/components/ui/SelectMenu/UISelectMenu.vue';
import * as _ from 'lodash';

export default {
  name: 'UISelect',
  emits: ['onSelect', 'onDelete', 'update:modelValue'],
  expose: ['setSelectedOptionQueitly'],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: true,
    },
    light: {
      required: false,
      default: false,
      type: Boolean,
    },
    options: {
      required: true,
      type: Array,
    },
    actions: {
      required: false,
      type: Object,
    },
    label: String,
    canDelete: {
      type: Boolean,
      required: false,
    },
    current: {
      type: Object,
      required: false,
    },
    noCaret: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ChevronDownIcon,
    UISelectMenu,
  },

  data() {
    return {
      id: null,
      showDropdown: false,
      selected: {},
      selectedMultiple: [],
    };
  },

  mounted() {
    this.id = this._uid;
    if (this.current) {
      this.selected = this.current;
    }
    this.selectedMultiple = this.selectedOptions;
  },

  watch: {
    current: {
      deep: true,
      handler() {
        this.selected = this.current;
      },
    },
  },

  methods: {
    openDropdown() {
      if (this.disabled) return;
      //if (_.isEmpty(this.current)) return;
      this.showDropdown = true;
    },
    selectOption(option) {
      if (_.includes(this.selectedMultiple, option)) {
        this.showDropdown = !this.showDropdown;
        return;
      }
      this.setSelectedOptionQueitly(option);
      this.$emit('onSelect', option);
      this.$emit('update:modelValue', option);
      this.showDropdown = !this.showDropdown;
    },
    removeSelected(item) {
      _.pull(this.selectedMultiple, item);
    },
    setSelectedOptionQueitly(option) {
      if (!this.multiple && !Array.isArray(option) && option !== null) {
        this.selected = option;
      } else {
        this.selectedMultiple?.push(option);
      }
    },
  },
};
</script>
