import { Form, Field } from 'vee-validate';
import {
  DynamicScroller,
  DynamicScrollerItem,
  RecycleScroller,
} from 'vue-virtual-scroller';
import UIActions from '@/components/ui/UIActions.vue';
import UIBadge from '@/components/ui/UIBadge.vue';
import UIBadgeNew from '@/components/ui/UIBadgeNew/UIBadgeNew.vue';
import UIButton from '@/components/ui/UIButton.vue';
import UIButtonNew from '@/components/ui/UIButtonNew/UIButtonNew.vue';
import UIButtonDropdown from '@/components/ui/UIButtonDropdown.vue';
import UICellInput from '@/components/ui/UICellInput/UICellInput.vue';
import UICheckboxNew from '@/components/ui/UICheckboxNew/UICheckboxNew.vue';
import UICheckboxNewVModel from '@/components/ui/UICheckboxNew/UICheckboxNewVModel.vue';
import UICheckbox from '@/components/ui/UICheckbox.vue';
import UICombobox from '@/components/ui/UICombobox.vue';
import UICounter from '@/components/ui/UICounter.vue';
import UIDotBadge from '@/components/ui/UIDotBadge/UIDotBadge.vue';
import UIDragAndDropImages from '@/components/ui/UIDragAndDropImages.vue';
import UIIconButton from '@/components/ui/UIIconButton.vue';
import UIIndicator from '@/components/ui/UIIndicator/UIIndicator.vue';
import UIInput from '@/components/ui/UIInput.vue';
import UIInputCard from '@/components/ui/UIInputCard.vue';
import UILink from '@/components/ui/UILink.vue';
import UILinkButton from '@/components/ui/UILinkButton.vue';
import UILinkGroup from '@/components/ui/UILinkGroup.vue';
import UIList from '@/components/ui/UIList/UIList.vue';
import UIListItem from '@/components/ui/UIList/UIListItem.vue';
import UILoader from '@/components/ui/UILoader.vue';
import UIMenuButton from '@/components/ui/UIMenuButton.vue';
import UINotificationNew from '@/components/ui/UINotificationNew/UINotificationNew.vue';
import UIOfflineOverlay from '@/components/ui/UIOfflineOverlay.vue';
import UISelect from '@/components/ui/UISelect.vue';
import UISpinner from '@/components/ui/UISpinner.vue';
import UISpinnerNew from '@/components/ui/UISpinnerNew/UISpinnerNew.vue';
import UISwitch from '@/components/ui/UISwitch/UISwitch.vue';
import UITab from '@/components/ui/UITab.vue';
import UITabs from '@/components/ui/UITabs.vue';
import UITabGroup from '@/components/ui/UITabGroup.vue';
import UITextarea from '@/components/ui/UITextarea.vue';
import UIToggle from '@/components/ui/UIToggle.vue';
import UITooltip from '@/components/ui/UITooltip.vue';
import UITooltipConfirm from '@/components/ui/UITooltipConfirm.vue';

import VButton from '@/components/ui/VButton.vue';
import VCheckbox from '@/components/ui/VCheckbox.vue';
import VCount from '@/components/ui/VCount.vue';
import VDatePickerRange from '@/components/ui/VDatePickerRange.vue';
import VDivider from '@/components/ui/VDivider.vue';
import VInput from '@/components/ui/VInput.vue';
import VInputRange from '@/components/ui/VInputRange.vue';
import VLink from '@/components/ui/VLink.vue';
import VLoader from '@/components/ui/VLoader.vue';
import VOverlayLoader from '@/components/ui/VOverlayLoader.vue';
import VSelect from '@/components/ui/VSelect.vue';

export const registerComponents = (app) => {
  app.component('VeeForm', Form);
  app.component('VeeField', Field);
  app.component('DynamicScroller', DynamicScroller);
  app.component('DynamicScrollerItem', DynamicScrollerItem);
  app.component('RecycleScroller', RecycleScroller);
  app.component('UIActions', UIActions);
  app.component('UIBadge', UIBadge);
  app.component('UIBadgeNew', UIBadgeNew);
  app.component('UIButton', UIButton);
  app.component('UIButtonNew', UIButtonNew);
  app.component('UIButtonDropdown', UIButtonDropdown);
  app.component('UICellInput', UICellInput);
  app.component('UICheckbox', UICheckbox);
  app.component('UICheckboxNew', UICheckboxNew);
  app.component('UICheckboxNewVModel', UICheckboxNewVModel);
  app.component('UICombobox', UICombobox);
  app.component('UICounter', UICounter);
  app.component('UIDotBadge', UIDotBadge);
  app.component('UIDragAndDropImages', UIDragAndDropImages);
  app.component('UIIconButton', UIIconButton);
  app.component('UIIndicator', UIIndicator);
  app.component('UIInput', UIInput);
  app.component('UIInputCard', UIInputCard);
  app.component('UILink', UILink);
  app.component('UILinkButton', UILinkButton);
  app.component('UILinkGroup', UILinkGroup);
  app.component('UIList', UIList);
  app.component('UIListItem', UIListItem);
  app.component('UILoader', UILoader);
  app.component('UIMenuButton', UIMenuButton);
  app.component('UINotificationNew', UINotificationNew);
  app.component('UIOfflineOverlay', UIOfflineOverlay);
  app.component('UISelect', UISelect);
  app.component('UISpinner', UISpinner);
  app.component('UISpinnerNew', UISpinnerNew);
  app.component('UISwitch', UISwitch);
  app.component('UITab', UITab);
  app.component('UITabs', UITabs);
  app.component('UITabGroup', UITabGroup);
  app.component('UITextarea', UITextarea);
  app.component('UIToggle', UIToggle);
  app.component('UITooltip', UITooltip);
  app.component('UITooltipConfirm', UITooltipConfirm);

  app.component('VButton', VButton);
  app.component('VCheckbox', VCheckbox);
  app.component('VCount', VCount);
  app.component('VDatePickerRange', VDatePickerRange);
  app.component('VDivider', VDivider);
  app.component('VInput', VInput);
  app.component('VInputRange', VInputRange);
  app.component('VLink', VLink);
  app.component('VLoader', VLoader);
  app.component('VOverlayLoader', VOverlayLoader);
  app.component('VSelect', VSelect);
};
