<template>
  <div class="relative flex flex-col gap-y-2">
    <label v-if="label">{{ label }}</label>
    <div
      class="relative flex"
      v-click-outside="() => (this.showDropdown = false)"
    >
      <input
        :id="id"
        :disabled="disabled"
        :placeholder="placeholder"
        v-model="query"
        @keyup="refreshOptions"
        @focus="refreshOptions"
        class="h-10 w-full text-ellipsis rounded-md border border-gray-600 px-4 py-1 text-base text-gray-900 shadow-inner outline-none transition-all placeholder:text-gray-750 focus:border-red-250 focus:ring-3 focus:ring-red-400/25"
        :class="[
          {
            'bg-red-150': hasError,
          },
          { 'cursor-not-allowed': disabled },
          { '!h-8 !rounded-[3px] !px-2 !text-sm': size === 'sm' },
        ]"
      />
      <div
        class="absolute right-0 top-0 flex h-full cursor-pointer items-center justify-center px-[10px]"
        :class="{ '!pointer-events-none !cursor-not-allowed': disabled }"
      >
        <Spinner v-if="fetching" />
        <ChevronDownIcon
          v-else
          class="heroicon-stroke-w-1.4 h-[20px] w-[20px] text-gpx_gray-500"
          :class="{ '!text-lms_red-900': showDropdown }"
          @click="showDropdown = !showDropdown"
        />
      </div>

      <UISelectMenu
        v-if="showDropdown"
        :options="filteredOptions"
        :selected="selected"
        @close="selectOption($event)"
      />
    </div>
    <div
      v-if="selectedMultiple.length > 0"
      class="mt-[10px] flex flex-wrap gap-[8px]"
    >
      <VRemove
        v-for="item in selectedMultiple"
        :key="`remove-${item.value}`"
        :label="item.label"
        :value="item.value"
        @remove="removeSelected(item)"
      />
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import vClickOutside from 'click-outside-vue3';
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import UISelectMenu from '@/components/ui/SelectMenu/UISelectMenu.vue';
import Spinner from '@/components/_common/Spinner.vue';
import VRemove from '@/components/ui/VRemove.vue';

export default {
  name: 'VCombobox',
  emits: ['update:modelValue'],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    size: {
      type: String,
      validator(v) {
        return ['sm', 'md'].includes(v);
      },
      default: 'sm',
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    options: [Array, Function, Object],
    selectedOptions: Array,
    multiple: Boolean,
    modelValue: {
      required: false,
      type: [Object, Array],
    },
  },
  components: {
    ChevronDownIcon,
    UISelectMenu,
    Spinner,
    VRemove,
  },
  data: () => {
    return {
      id: null,
      query: null,
      optionsCache: [],
      showDropdown: false,
      selected: null,
      selectedMultiple: [],
      fetching: false,
    };
  },
  computed: {
    noMatches() {
      return !this.fetching && this.query && this.filteredOptions.length === 0;
    },
    filteredOptions() {
      return this.optionsCache.filter((option) => {
        return _.some(this.selectedMultiple, option) === false;
      });
    },
  },
  methods: {
    refreshOptions() {
      const query = _.trim(this.query);
      // this.showDropdown = !!query;
      this.showDropdown = true;
      if (typeof this.options === 'function' && !this.fetching) {
        if (!query) {
          this.optionsCache = [];
          return;
        }

        this.fetching = true;
        this.options(query)
          .then((options) => {
            this.optionsCache = options;
            this.showDropdown = this.filteredOptions.length > 0;
          })
          .finally(() => (this.fetching = false));
        return;
      }

      if (Array.isArray(this.options)) {
        this.optionsCache = this.options.filter((option) => {
          return option.label.includes(query);
        });
      }
    },
    selectOption(option) {
      if (!option) {
        return;
      }
      if (_.some(this.selectedMultiple, option)) {
        this.showDropdown = !this.showDropdown;
        return;
      }

      if (this.showDropdown) {
        this.showDropdown = false;
      }

      if (this.multiple) {
        this.selectedMultiple.push(option);
        this.query = '';
        this.$emit('update:modelValue', this.selectedMultiple);
        return;
      }

      this.selected =
        typeof option === 'object' && !Array.isArray(option) && option !== null
          ? option.label
          : option;
      this.query = this.selected;
      this.$emit('update:modelValue', option);
    },
    removeSelected(item) {
      _.pull(this.selectedMultiple, item);
      this.$emit('update:modelValue', this.selectedMultiple);
    },
  },
  beforeMount() {
    this.id = this._uid;
    this.query = _.get(this.value, 'label', '');
    this.selectedMultiple = this.selectedOptions ? this.selectedOptions : [];

    if (this.multiple && this.selectedMultiple.length > 0) {
      this.$nextTick(() =>
        this.$emit('update:modelValue', this.selectedMultiple),
      );
    }
  },
};
</script>
