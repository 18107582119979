import service from '@/services';
import {
  addOrUpdateItems,
  controller,
  getAbortMutations,
  processEntities,
  setAbortController,
} from '../helpers';
import { BATCH_ADD_OR_UPDATE } from '../mutation-types';
import { downloadFile } from '@/utils/blob';

export const state = {
  controller: null,
  date: '',
  items: [],
};

export const getters = {
  controller,
  date: (state) => state.date,
  byId: (state) => (id) => state.items[id],
  byIdAndDate: (state) => (id, date) => {
    const found = state.items.find(
      (item) => item.device_id === id && item.date === date,
    );
    if (!found) return;
    return found.items;
  },
};

export const mutations = {
  ...getAbortMutations('deviceStopHistory'),
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  SET_DATE: (state, date) => (state.date = date),
  RESET_DATE: (state) => (state.date = ''),
};

export const actions = {
  setDate: (store, { dateFrom, dateTo }) => {
    store.commit('SET_DATE', `${dateFrom}$${dateTo}`);
  },
  resetDate: (store) => {
    store.commit('RESET_DATE');
  },
  fetchByAccountId: (
    store,
    { accountId, id, params: { dateFrom, dateTo, ...rest } },
  ) => {
    const controller = setAbortController({
      entity: 'deviceStopHistory',
      store,
    });

    const params = { date_from: dateFrom, date_to: dateTo, ...rest };
    return service
      .get(`/accounts/${accountId}/devices/${id}/stops`, {
        params,
        signal: controller.signal,
      })
      .then((res) => {
        if (res && res.status === 499) return res;
        const item = {
          id: `${id}$${dateFrom}$${dateTo}`,
          device_id: id,
          date: `${dateFrom}$${dateTo}`,
          items: res.data.data,
        };
        processEntities(item, 'deviceStopHistory');
        return res;
      });
  },
  downloadAll: (store, { accountId, id, fileName, params }) => {
    return service
      .get(`/accounts/${accountId}/devices/${id}/stops`, {
        responseType: 'blob',
        params,
      })
      .then((res) => downloadFile({ res, fileName, extension: 'csv' }));
  },
};
