<template>
  <button
    v-if="isOpen"
    class="fixed inset-0 bg-transparent"
    @click.stop="$emit('setOpen')"
  />
  <button
    @click.stop="$emit('setOpen')"
    class="z-10 flex items-center justify-center rounded-md p-2 md:hidden"
    :class="[{ '!bg-lgmx_gray-100': route.name.includes('Dashboards') }]"
  >
    <Bars3Icon class="h-6 w-6" />
  </button>
</template>

<script setup>
import { useRoute } from 'vue-router';

defineEmits(['setOpen']);
defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();
</script>

<style></style>
