export const getStyle = (map, layers) => {
  if (map.hasLayer(layers.satellite)) {
    return {
      color: 'white',
      pulseColor: 'red',
    };
  } else {
    return {
      color: 'red',
      pulseColor: 'black',
    };
  }
};
