<template>
  <CommonSearchBarSafeArea />
  <CommonCollapse :initial-open="true" title="Info" class="mb-1">
    <ShipmentEditRow name="Name" class="mb-3">
      <VInput
        size="sm"
        type="text"
        placeholder="Shipment Name"
        v-model="form.name"
      />
    </ShipmentEditRow>
    <ShipmentEditRow name="Start/End" class="mb-3">
      <VDatePickerRange
        :show-tools="false"
        :initialValue="preSelectedDate"
        :maxDate="false"
        v-model="date"
      />
    </ShipmentEditRow>
    <ShipmentEditRow name="Carrier" class="mb-3">
      <VInput
        size="sm"
        type="text"
        placeholder="Carrier"
        v-model="form.carrier"
      />
    </ShipmentEditRow>
    <ShipmentEditRow name="Tracker" class="mb-3">
      {{ deviceName }}
    </ShipmentEditRow>
    <ShipmentEditRow name="Begin location" class="mb-3">
      {{ fromAddress }}
    </ShipmentEditRow>
    <ShipmentEditRow name="End location" class="mb-3">
      <div>{{ toAddress }}</div>
    </ShipmentEditRow>
    <ShipmentEditRow name="Notification before end" class="mb-3">
      <VSelect
        size="sm"
        :options="notificationOptions"
        v-model="form.notify_at"
      />
    </ShipmentEditRow>
    <ShipmentEditRow name="Notify via email" class="mb-3">
      <VCombobox
        size="sm"
        placeholder="Select..."
        v-model="form.notification_emails"
        :selected-options="preSelectedEmails"
        :options="availableEmails"
        multiple
      />
    </ShipmentEditRow>
    <ShipmentEditRow name="Notify via SMS" class="mb-3">
      <VCombobox
        size="sm"
        placeholder="Select..."
        v-model="form.notification_phone_numbers"
        :selected-options="preSelectedPhones"
        :options="availablePhones"
        multiple
      />
    </ShipmentEditRow>
    <div class="mb-[30px] mt-[24px] grid grid-cols-2">
      <div>
        <UITooltip text="End shipment now">
          <UIButtonNew
            size="sm"
            variant="tertiary"
            @click="handleEnd"
            :is-loading="isEnding"
          >
            End
          </UIButtonNew>
        </UITooltip>
      </div>
      <div class="w-full">
        <UIButtonNew
          size="sm"
          class="ml-auto w-[120px]"
          :is-loading="isSaving"
          is-loading-text="Saving..."
          @click="handleSave"
        >
          Save
        </UIButtonNew>
      </div>
    </div>
  </CommonCollapse>
</template>

<script>
import * as _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import VCombobox from '@/components/ui/VCombobox.vue';
import ShipmentEditRow from '@/components/shipment/edit/ShipmentEditRow.vue';
import {
  COMMON_UPDATE_ERROR,
  COMMON_UPDATE_SUCCESS,
  SHIPMENT_END_SUCCESS,
} from '@/config/constants';
import notificationOptionsJSON from '@/assets/shipment-notification-options.json';
import { formatTime } from '@/filters';
import CommonSearchBarSafeArea from '@/components/_common/CommonSearchBarSafeArea.vue';
import CommonCollapse from '@/components/_common/CommonCollapse.vue';

const toast = useToast();

export default {
  components: {
    VCombobox,
    ShipmentEditRow,
    CommonSearchBarSafeArea,
    CommonCollapse,
  },

  data() {
    return {
      notificationOptions: notificationOptionsJSON,
      form: {
        name: null,
        carrier: null,
        notify_at: null,
        notification_emails: null,
        notification_phone_numbers: null,
      },
      date: null,
      isEnding: false,
      isSaving: false,
    };
  },

  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
      shipmentById: 'shipment/byId',
      users: 'user/all',
    }),
    id() {
      return +this.$route.params.id;
    },
    shipment() {
      return { ...this.shipmentById(this.id) };
    },
    deviceName() {
      return _.get(this.shipment, 'device.name', '-');
    },
    fromAddress() {
      const id = _.get(this.shipment, 'from_geofence_id', null);
      if (id) {
        return _.get(this.shipment, 'from_geofence.address', '-');
      } else {
        return _.get(this.shipment, 'from_address.name', '-');
      }
    },
    toAddress() {
      const id = _.get(this.shipment, 'to_geofence_id', null);
      if (id) {
        return _.get(this.shipment, 'to_geofence.address', '-');
      } else {
        return _.get(this.shipment, 'to_address.name', '-');
      }
    },
    preSelectedDate() {
      return [new Date(this.shipment.start_at), new Date(this.shipment.end_at)];
    },
    preSelectedEmails() {
      return this.shipment.notification_emails?.map((item) => ({
        value: item,
        label: item,
      }));
    },
    preSelectedPhones() {
      return this.shipment.notification_phone_numbers?.map((item) => ({
        value: item,
        label: item,
      }));
    },
    availableEmails() {
      return this.users
        .filter((item) => item.email)
        .map((item) => ({
          value: item.email,
          label: item.email,
        }));
    },
    availablePhones() {
      return this.users
        .filter((item) => item.phone)
        .map((item) => ({
          value: item.phone,
          label: item.phone,
        }));
    },
    isEndDisabled() {
      const { isEnding, isSaving } = this;
      return isEnding || isSaving;
    },
    isSaveDisabled() {
      const { isEnding, isSaving, form } = this;
      return isEnding || isSaving || !form.name;
    },
  },

  mounted() {
    this.fetchUsers();
    this.initForm();
  },

  methods: {
    ...mapActions({
      fetchUsers: 'user/fetch',
      update: 'shipment/update',
      end: 'shipment/end',
    }),
    initForm() {
      const { name, carrier, notify_at } = this.shipment;
      this.form = {
        name,
        carrier,
        notify_at,
      };
    },
    handleEnd() {
      this.isEnding = true;
      this.end({ id: this.id })
        .then(() => {
          toast.success(SHIPMENT_END_SUCCESS);
          this.$router.push({
            name: 'ShipmentDetailsView',
            params: { id: this.id },
          });
        })
        .catch(() => {
          this.isEnding = false;
        });
    },
    handleSave() {
      this.isSaving = true;
      const values = {
        ...this.form,
        start_at: formatTime(this.date[0], 'yyyy-MM-dd HH:mm:ss'),
        end_at: formatTime(this.date[1], 'yyyy-MM-dd HH:mm:ss'),
        notification_emails: _.isEmpty(this.form.notification_emails)
          ? null
          : this.form.notification_emails.map((item) => item.value),
        notification_phone_numbers: _.isEmpty(
          this.form.notification_phone_numbers,
        )
          ? null
          : this.form.notification_phone_numbers.map((item) => item.value),
      };
      this.update({ accountId: this.accountId, id: this.id, params: values })
        .then(() => {
          toast.success(COMMON_UPDATE_SUCCESS);
          this.$router.replace({
            name: 'ShipmentDetailsView',
            params: { id: this.id },
          });
        })
        .catch(() => toast.error(COMMON_UPDATE_ERROR))
        .finally(() => (this.isSaving = false));
    },
    handleCancel() {
      this.$router.push({
        name: 'ShipmentDetailsView',
        params: { id: this.id },
      });
    },
  },
};
</script>
