<template>
  <div class="pt-[25px]">
    <div class="mb-[14px] text-[13px] leading-5 text-lgmx_black-200">
      If your Mobile-200 has a magnet on the back, remove it first. To do so,
      lift up on the tab above the Push and slide the magnet in the direction of
      the arrow.
    </div>

    <div class="mb-[18px] flex justify-center">
      <img :src="svg1" alt="remove magnet" />
    </div>

    <div class="mb-[14px] text-[13px] leading-5 text-lgmx_black-200">
      To replace the SIM Card in your Mobile-200g, you will need to access the
      inside of the device by removing the six screws that keep it sealed.
    </div>

    <div class="mb-[18px] flex justify-center">
      <img :src="svg2" alt="remove screws" />
    </div>

    <div class="text-[13px] leading-5 text-lgmx_black-200">
      You will need the screwdriver we sent you to remove and re-fasten the
      device case.
    </div>

    <div class="flex justify-between gap-x-4 pb-[18px] pt-[26px]">
      <UIButtonNew
        variant="tertiary"
        size="lg"
        class="w-[124px]"
        @click="$emit('onCancel')"
      >
        Back
      </UIButtonNew>
      <UIButtonNew @click="$emit('onConfirm')" class="w-full">
        Next
      </UIButtonNew>
    </div>
  </div>
</template>

<script>
import Step2Svg1 from './assets/step-2-1.svg';
import Step2Svg2 from './assets/step-2-2.svg';

export default {
  emits: ['onConfirm', 'onCancel'],

  computed: {
    svg1() {
      return Step2Svg1;
    },
    svg2() {
      return Step2Svg2;
    },
  },
};
</script>
