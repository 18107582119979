<template>
  <div class="pt-[25px]">
    <div class="mb-[14px] text-[13px] leading-5 text-lgmx_black-200">
      Once you have removed your SIM card, place it so the cut off corner is in
      the top left. Make sure that the numbers are facing you and the metal on
      the SIM card faces the car charger.
    </div>

    <div class="mb-[18px] flex justify-center">
      <img :src="svg1" alt="place SIM card" />
    </div>

    <div class="mb-[14px] text-[13px] leading-5 text-lgmx_black-200">
      Fold the metal cover back over the new SIM card. Push it down and slide it
      up, away from the USB ports. If it is secure, you should be able to shake
      your car charger upside down and the SIM will remain in place.
    </div>

    <div class="mb-[6px] flex justify-center">
      <img :src="svg2" alt="fold the metal cover" />
    </div>

    <div class="flex justify-between gap-x-4 pb-[18px] pt-[26px]">
      <UIButtonNew
        variant="tertiary"
        size="lg"
        class="w-[124px]"
        @click="$emit('onCancel')"
      >
        Back
      </UIButtonNew>
      <UIButtonNew @click="handleConfirm" class="w-full">Next</UIButtonNew>
    </div>
  </div>
</template>

<script>
import Step5Svg1 from './assets/step-5-1.svg';
import Step5Svg2 from './assets/step-5-2.svg';

export default {
  emits: ['onConfirm', 'onCancel'],

  data() {
    return {
      innerIccid: null,
    };
  },

  computed: {
    svg1() {
      return Step5Svg1;
    },
    svg2() {
      return Step5Svg2;
    },
  },

  methods: {
    handleConfirm() {
      this.$emit('onConfirm', this.innerIccid);
    },
  },
};
</script>
