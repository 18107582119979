<template>
  <button
    type="button"
    @click="click"
    class="flex items-center justify-center rounded-md border font-medium leading-5"
    :class="[
      {
        'border-lgmx_terracota-700 bg-lgmx_terracota-700 text-white focus:bg-lgmx_terracota-800 active:bg-lgmx_terracota-800 disabled:border-lgmx_gray-200 disabled:bg-lgmx_gray-200 disabled:text-lgmx_gray-700':
          variant === BUTTON_VARIANT.main,
      },
      {
        'border-lgmx_terracota-700 bg-white text-lgmx_terracota-900 disabled:border-lgmx_gray-200 disabled:bg-white disabled:text-lgmx_gray-700':
          variant === BUTTON_VARIANT.destructive,
      },
      {
        'border-lgmx_slate-700 bg-lgmx_slate-700 text-white disabled:border-lgmx_gray-200 disabled:bg-lgmx_gray-200 disabled:text-lgmx_gray-700':
          variant === BUTTON_VARIANT.secondary,
      },
      {
        'border-lgmx_slate-100 bg-white text-lgmx_slate-900 disabled:border-lgmx_gray-200 disabled:text-lgmx_gray-700':
          variant === BUTTON_VARIANT.tertiary,
      },
      {
        'border-lgmx_gray-300 bg-white text-lgmx_black-100 disabled:border-bg-1 disabled:bg-bg-1':
          variant === BUTTON_VARIANT.quaternary,
      },
      {
        'border-lgmx_black-600 bg-black text-lgmx_gray-900 disabled:border-lgmx_black-600 disabled:bg-lgmx_black-600 disabled:text-white':
          variant === BUTTON_VARIANT.quinary,
      },
      {
        'py-[6px] pl-[10px] pr-[14px] text-[13px]':
          size === BUTTON_SIZE.sm && withLoading,
      },
      {
        'px-[14px] py-[5px] text-[13px]':
          size === BUTTON_SIZE.sm && !withLoading,
      },
      {
        'py-2 pl-6 pr-7 text-[13px]': size === BUTTON_SIZE.md && withLoading,
      },
      {
        'px-7 py-2 text-[13px]': size === BUTTON_SIZE.md && !withLoading,
      },
      {
        'py-[14px] pl-9 pr-10 text-[13px]':
          size === BUTTON_SIZE.lg && withLoading,
      },
      {
        'px-10 py-[14px] text-[13px]': size === BUTTON_SIZE.lg && !withLoading,
      },
      {
        'w-full rounded-b-[10px] rounded-t-none py-4 pl-9 pr-10 text-sm':
          size === BUTTON_SIZE.modal,
      },
    ]"
  >
    <UISpinnerNew
      v-if="isLoading"
      :size="spinnerSize"
      :variant="spinnerVariant"
    />
    <component
      :is="icon"
      v-if="!isLoading && icon"
      :class="[
        iconClass,
        { 'text-lgmx_slate-600': variant === BUTTON_VARIANT.quaternary },
      ]"
    />
    <span
      class="transition-[padding] duration-200"
      :class="[{ 'pl-1': withLoading }, { 'pl-2': isLoading || icon }]"
    >
      <span v-if="isLoading">{{ isLoadingText }}</span>
      <slot v-if="!isLoading" />
    </span>
  </button>
</template>

<script setup>
import { computed, useAttrs } from 'vue';
import UISpinnerNew from '../UISpinnerNew/UISpinnerNew.vue';
import { getSpinnerSize } from '../UISpinnerNew/utils';
import {
  buttonSizes,
  buttonVariants,
  BUTTON_SIZE,
  BUTTON_VARIANT,
  getButtonSpinnerSize,
  getButtonSpinnerVariant,
} from './utils';

const emits = defineEmits(['click']);
const attrs = useAttrs();

const props = defineProps({
  size: {
    type: String,
    default: BUTTON_SIZE.md,
    validator: (size) => buttonSizes.includes(size),
  },
  variant: {
    type: String,
    default: BUTTON_VARIANT.main,
    validator: (variant) => buttonVariants.includes(variant),
  },
  icon: {
    type: String,
  },
  isLoading: {
    type: Boolean,
    default: null,
  },
  isLoadingText: {
    type: String,
    default: 'Processing...',
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
});

const spinnerSize = computed(() => getButtonSpinnerSize(props.size));
const spinnerVariant = computed(() => getButtonSpinnerVariant(props.variant));
const iconSize = computed(() => getSpinnerSize(spinnerSize.value));
const iconClass = computed(
  () => `h-[${iconSize.value + 4}px] w-[${iconSize.value + 4}px]`,
);
const withLoading = computed(() => typeof props.isLoading === 'boolean');

function click($event) {
  if (attrs.type !== 'submit') {
    $event.preventDefault();
  }
  if (props.isLoading) return;
  emits('click');
}
</script>
