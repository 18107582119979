<template>
  <div class="bg-white">
    <Step1
      v-if="innerStep === 1"
      @on-confirm="goToStep(2)"
      @on-cancel="goToStep(0)"
    />
    <Step2
      v-show="innerStep === 2"
      @on-confirm="goToStep(3)"
      @on-cancel="goToStep(1)"
    />
    <Step3
      v-show="innerStep === 3"
      @on-confirm="goToStep(4)"
      @on-cancel="goToStep(2)"
    />
    <Step4
      v-show="innerStep === 4"
      @on-confirm="confirmStepFour"
      @on-cancel="goToStep(3)"
    />
    <Step5
      v-show="innerStep === 5"
      @on-confirm="goToStep(6)"
      @on-cancel="goToStep(4)"
    />
    <Step6
      v-show="innerStep === 6"
      @on-confirm="goToStep(7)"
      @on-cancel="goToStep(5)"
    />
    <Step7
      v-show="innerStep === 7"
      @on-confirm="confirmStepSeven"
      @on-cancel="goToStep(6)"
    />
  </div>
</template>

<script>
import Step1 from './Step1.vue';
import Step2 from './Step2.vue';
import Step3 from './Step3.vue';
import Step4 from './Step4.vue';
import Step5 from './Step5.vue';
import Step6 from './Step6.vue';
import Step7 from './Step7.vue';

export default {
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
  },

  emits: ['update:step', 'update:iccid', 'replaceAndRearm'],
  props: {
    step: Number,
    iccid: Object,
    device: Object,
  },

  data() {
    return {
      innerStep: 1,
    };
  },

  methods: {
    goToStep(n) {
      this.innerStep = n;
      this.$emit('update:step', n);
    },
    confirmStepFour(iccid) {
      this.goToStep(5);
      this.$emit('update:iccid', iccid);
    },
    confirmStepSeven() {
      this.goToStep(8);
      this.$emit('replaceAndRearm');
    },
  },
};
</script>
