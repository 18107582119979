<template>
  <section>
    <HeadingSection
      class="mb-[35px] pt-[25px]"
      desc="View or Reset your API Token"
      heading="API Access"
    />
    <div class="flex">
      <UIInput
        class="w-full"
        inputClass="!rounded-r-none"
        :modelValue="account?.api_token"
        readonly
      />
      <button
        class="flex items-center space-x-2 rounded-r-none border-x border-gray-400 bg-bg-3 px-6"
        @click="handleCopyApiToken"
      >
        <component :is="copyIcon" class="h-5 w-5" />
        <span>{{ isCopied ? 'Copied' : 'Copy' }}</span>
      </button>
      <button
        class="flex items-center space-x-2 rounded-r-[6px] bg-bg-3 px-6"
        :disabled="processing"
        @click="handleResetToken"
      >
        <ArrowPathIcon class="h-5 w-5" />
        <span>{{ processing ? 'Reseting...' : 'Reset' }}</span>
      </button>
    </div>
  </section>
</template>

<script>
import { setTitle } from '@/utils/title';
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import HeadingSection from '@/components/_common/Heading/HeadingSection.vue';
import {
  ArrowPathIcon,
  DocumentDuplicateIcon,
} from '@heroicons/vue/24/outline';
import { DocumentDuplicateIcon as DocumentDuplicateIconSolid } from '@heroicons/vue/24/solid';
import { copyTextToClipboard } from '@/utils/copy-to-clipboard';

export default {
  components: {
    HeadingSection,
    ArrowPathIcon,
    DocumentDuplicateIcon,
    DocumentDuplicateIconSolid,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      processing: false,
      isCopied: false,
    };
  },
  computed: {
    ...mapGetters({
      account: 'auth/account',
    }),
    copyIcon() {
      return this.isCopied ? DocumentDuplicateIconSolid : DocumentDuplicateIcon;
    },
  },
  created() {
    setTitle('Manage API Access');
  },
  methods: {
    ...mapActions({
      resetToken: 'auth/resetToken',
    }),
    handleCopyApiToken() {
      copyTextToClipboard(this.account.api_token);
      this.toast.success('Copied!');
      this.isCopied = true;
    },
    handleResetToken() {
      this.processing = true;
      this.resetToken({ accountId: this.account.id }).finally(() => {
        this.processing = false;
      });
    },
  },
};
</script>
