<template>
  <section>
    <HeadingSection
      class="mb-[35px] pt-[25px]"
      desc="Edit your billing settings"
      heading="Payment methods"
      :button="{
        label: 'Add New',
        action: {
          mode: 'callback',
          callback: this.openNewPaymentMethodModal.bind(this),
        },
      }"
    />
    <div v-if="fetching && !cards.length" class="flex items-center space-x-3">
      <UISpinner size="sm" />
      <span class="text-gray-700">Loading payment methods...</span>
    </div>
    <div v-if="!fetching && cards.length > 0" class="flex flex-wrap gap-[24px]">
      <div
        v-for="card in cards"
        :key="card.id"
        class="flex flex-col items-start gap-y-[11px]"
      >
        <UICreditCard
          :cardId="card.id"
          :digits="card.last4"
          :valid="card.exp"
          :type="card.brand"
        />
        <span
          v-if="card.expired"
          class="text-gpx_xs font-medium text-gpx_gray-100"
          >Credit card expired</span
        >
        <button
          v-if="!card.expired && !card.default"
          class="box-border flex items-center space-x-2 text-gpx_xs font-medium text-lms_black-300"
          :class="{
            'border-b border-b-lms_red-900': settingCardAsDefault !== card.id,
          }"
          @click="handleSettingCardAsDefault(card.id)"
          :disabled="settingCardAsDefault === card.id"
        >
          <template v-if="settingCardAsDefault === card.id">
            <UISpinner size="sm" />
            <span>Setting as default...</span>
          </template>
          <span v-else>Set default</span>
        </button>
        <span
          v-if="card.default"
          class="text-gpx_xs font-medium text-gpx_gray-100"
          >Default</span
        >
      </div>
    </div>
    <UIEmptyState
      v-if="!fetching && !cards.length"
      sub="No payment methods were found."
    />
  </section>
</template>

<script>
import HeadingSection from '@/components/_common/Heading/HeadingSection.vue';
import UICreditCard from '@/components/ui/UICreditCard.vue';
import { mapActions, mapGetters } from 'vuex';
import { differenceInDays } from 'date-fns';
import UISpinner from '@/components/ui/UISpinner.vue';
import UIEmptyState from '@/components/ui/UIEmptyState.vue';
import { setTitle } from '@/utils/title';

export default {
  components: {
    HeadingSection,
    UICreditCard,
    UISpinner,
    UIEmptyState,
  },

  data() {
    return {
      fetching: false,
      settingCardAsDefault: null,
      cards: [],
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
      paymentMethods: 'paymentMethod/all',
    }),
  },
  watch: {
    paymentMethods: {
      deep: true,
      handler() {
        this.cards = this.paymentMethods.map((card) => {
          const expiryDate = new Date(card.exp_year, card.month, 1);
          const hasCardExpired = differenceInDays(expiryDate, new Date()) > 0;

          return {
            ...card,
            exp: `${card.exp_month}/${card.exp_year}`,
            expired: hasCardExpired,
          };
        });
      },
    },
    accountId() {
      this.loadPage();
    },
  },
  created() {
    setTitle('Manage Payment Methods');
  },
  methods: {
    ...mapActions({
      openModal: 'modal/open',
      fetchPaymentMethods: 'paymentMethod/fetch',
      setPaymentMethodAsDefault: 'paymentMethod/setAsDefault',
      clearPaymentMethods: 'paymentMethod/clear',
      refreshSetupIntent: 'paymentMethod/refreshSetupIntent',
    }),
    showModal(modal) {
      this.$data[modal] = true;
    },
    openNewPaymentMethodModal() {
      this.openModal({
        name: 'ManagePaymentAddNewModal',
      });
    },
    handleSettingCardAsDefault(paymentMethodId) {
      this.settingCardAsDefault = paymentMethodId;
      this.setPaymentMethodAsDefault({
        accountId: this.accountId,
        paymentMethodId,
      }).then(() => {
        this.settingCardAsDefault = null;
      });
    },
    loadPage() {
      this.clearPaymentMethods();
      this.fetching = true;
      this.fetchPaymentMethods(this.accountId).finally(() => {
        this.fetching = false;
      });
    },
  },
  mounted() {
    this.loadPage();

    if (this.$route.query.setup_intent) {
      this.refreshSetupIntent({
        setupIntentId: this.$route.query.setup_intent,
      });
    }
  },
};
</script>
