<template>
  <div class="border-y border-lgmx_gray-200">
    <div class="hidden md:flex md:justify-between">
      <div
        class="flex h-[52px] w-[320px] items-center justify-between border-r border-lgmx_gray-200"
      >
        <input
          ref="inputRef"
          placeholder="Search geofences"
          v-model="searchModel"
          type="text"
          class="w-full border-none py-2 pl-0 text-[13px] font-medium leading-5 text-lgmx_black-600 placeholder:text-lgmx_gray-700 focus:ring-transparent"
        />
        <UIIconButton
          v-if="!searchModel"
          icon="MagnifyingGlassIcon"
          class="pr-3"
          disabled
        />
        <UIIconButton
          v-if="searchModel"
          icon="XMarkIcon"
          class="pr-3"
          tooltip="Clear search"
          @click="resetAndCloseSearch"
        />
      </div>
      <div class="flex items-center">
        <div class="ml-auto flex items-center gap-x-[10px]">
          <span
            class="mr-[10px] text-[13px] font-medium leading-4 text-lgmx_gray-900"
          >
            Period, days:
          </span>
          <UISwitch v-model="periodModel" :values="[30, 60, 90]" />
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between py-[7px] pl-6 md:hidden">
      <div
        v-if="!isSearchOpen"
        class="flex w-full items-center justify-between"
      >
        <div>
          <span
            class="mr-[10px] text-[13px] font-medium leading-4 text-lgmx_gray-900"
          >
            Days:
          </span>
          <UISwitch v-model="periodModel" :values="[30, 60, 90]" />
        </div>
        <div class="ml-auto">
          <UIIconButton icon="MagnifyingGlassIcon" @click="openSearch" />
        </div>
      </div>
      <div v-if="isSearchOpen" class="flex w-full items-center justify-between">
        <MagnifyingGlassIcon
          class="mr-[12px] h-[16px] min-w-[16px] text-gpx_gray-500"
        />
        <input
          ref="inputRef"
          placeholder="Search geofences"
          v-model="searchModel"
          type="text"
          class="w-full border-none py-0 text-[13px] font-medium leading-5 text-lgmx_black-600 placeholder:text-lgmx_gray-700 focus:ring-transparent"
        />
        <UIIconButton
          icon="XMarkIcon"
          class="ml-auto"
          @click="resetAndCloseSearch"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import UIIconButton from '@/components/ui/UIIconButton.vue';

const emits = defineEmits(['update:search', 'update:period']);
const props = defineProps({
  search: {
    type: String,
    default: '',
  },
  period: {
    type: Number,
    default: 30,
  },
});

const isSearchOpen = ref(props.search);

const searchModel = ref(props.search);
watch(searchModel, (value) => {
  emits('update:search', value);
});

const periodModel = ref(props.period);
watch(periodModel, (value) => {
  emits('update:period', value);
});

function openSearch() {
  isSearchOpen.value = true;
}
function resetAndCloseSearch() {
  isSearchOpen.value = false;
  searchModel.value = '';
}
</script>
