export const {
  MODE,
  VITE_APP_API_URL,
  VITE_APP_ADMIN_URL,
  VITE_APP_STRIPE_KEY,
  VITE_APP_B2B_URL,
  VITE_APP_B2C_URL,
  VITE_APP_CLOUDINARY_URL,
  VITE_APP_BUGSNAG_KEY,
  VITE_APP_BUGSNAG_STAGE = 'development',
  VITE_BUGSNAG_APP_VERSION,
  VITE_MIXPANEL_TOKEN,
  VITE_VITALLY_TOKEN,
} = import.meta.env || {};

export const isDev = () => MODE === 'development';
