<template>
  <div class="flex gap-x-[8px]">
    <div class="flex gap-x-[1px]" :class="{ 'gap-x-0': gray }">
      <div
        class="cursor-pointer rounded-l-[4px] bg-gpx_red-300 px-[6px] py-[4px]"
        :class="{
          '!border !border-r-0 !border-gpx_gray-800 !bg-transparent': gray,
        }"
        @click="$emit('remove', value)"
      >
        <XMarkIcon
          class="heroicon-stroke-w-1.6 h-[16px] w-[16px] text-gpx_red-200"
          :class="{ '!text-gpx_gray-500': gray }"
        />
      </div>
      <div
        class="rounded-r-[4px] bg-gpx_red-300 px-[12px] py-[3px] text-gpx_xs font-medium text-gpx_red-100"
        :class="{
          '!border !border-gpx_gray-800 !bg-transparent !text-lms_black-100':
            gray,
        }"
      >
        {{ label }}
      </div>
    </div>
  </div>
</template>

<script>
import { XMarkIcon } from '@heroicons/vue/24/outline';

export default {
  name: 'Remove',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    label: String,
    gray: Boolean,
  },
  components: {
    XMarkIcon,
  },
};
</script>
