import * as _ from 'lodash';
import service from '@/services';

export const state = {
  _app: {
    isMobile: false,
    isMobileNavOpen: false,
  },
  reports: {
    _app: {
      desktop: {},
      mobile: {
        activeTab: 'right',
      },
    },
  },
  user: {},
  variables: {},
};

export const getters = {
  isMobileNavOpen: (state) => state._app.isMobileNavOpen,
  mobileActiveTabByType: (state) => (type) => state[type]._app.mobile.activeTab,
  user: (state) => state.user,
  geofencesListActiveTab: (state) => state.geofences._app.list.activeTab,
  geofencesMobile: (state) => state.geofences._app.mobile,
  geofencesDesktop: (state) => state.geofences._app.desktop,
  shipmentsMobile: (state) => state.shipments._app.mobile,
  shipmentsDesktop: (state) => state.shipments._app.desktop,
  reportsMobile: (state) => state.reports._app.mobile,
  reportsDesktop: (state) => state.reports._app.desktop,
  timezones: (state) => {
    if (!state.variables?.timezones) {
      return [];
    }
    return state.variables.timezones.map((timezone) => ({
      value: timezone.id,
      label: timezone.identifier,
    }));
  },
};

export const mutations = {
  SET_USER_SETTINGS(state, data) {
    _.each(data, ({ code, value }) => {
      state.user[code] = value;
    });
  },
  SET_IS_MOBILE_NAV_OPEN(state, value) {
    state._app.isMobileNavOpen = value;
  },
  SET_MOBILE_ACTIVE_TAB(state, { type, tab }) {
    state[type]._app.mobile.activeTab = tab;
  },
  SET_LIST_ACTIVE_TAB(state, { type, tab }) {
    state[type]._app.list.activeTab = tab;
  },
  SET_VARIABLE(state, { key, value }) {
    state.variables[key] = value;
  },
};

export const actions = {
  fetchByAccountId: ({ commit }, { accountId, params }) => {
    return service
      .get(`/accounts/${accountId}/user-settings`, { params })
      .then((res) => {
        commit('SET_USER_SETTINGS', res.data.data);
      });
  },
  storeUnderAccountId: (store, { accountId, params }) => {
    return service.post(`/accounts/${accountId}/user-settings`, params);
  },
  fetchTimezones: ({ commit }) => {
    return service.get('/timezones').then((res) => {
      commit('SET_VARIABLE', { key: 'timezones', value: res.data.data });
    });
  },
  setIsMobileNavOpen: ({ commit }, value) => {
    commit('SET_IS_MOBILE_NAV_OPEN', value);
  },
  closeMobileNav: ({ state, commit }) => {
    if (state._app.isMobileNavOpen) {
      commit('SET_IS_MOBILE_NAV_OPEN', false);
    }
  },
  setMobileActiveTab: ({ commit }, tab) => {
    commit('SET_MOBILE_ACTIVE_TAB', tab);
  },
  setListActiveTab: ({ commit }, tab) => {
    commit('SET_LIST_ACTIVE_TAB', tab);
  },
};
