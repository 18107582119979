<template>
  <div
    class="pl-6 pr-4 sm:w-4/12 sm:border-r sm:border-lgmx_gray-100 md:pl-12 md:pr-6"
  >
    <ShipmentEditNavBar class="h-[64px] sm:h-[48px] sm:translate-y-2" />
  </div>
  <div class="hidden justify-between px-4 py-2 sm:flex sm:w-8/12 md:pr-12">
    <div class="flex gap-2">
      <UIButtonNew size="sm" variant="destructive" @click="onDeleteClick">
        Delete
      </UIButtonNew>
    </div>
  </div>
</template>

<script setup>
import NProgress from 'nprogress';
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useAutoRefresh } from '@/use/auto-refresh';
import ShipmentEditNavBar from './ShipmentEditNavBar.vue';
import { COMMON_DELETE_ERROR, COMMON_DELETE_SUCCESS } from '@/config/constants';

const toast = useToast();
const store = useStore();
const router = useRouter();
const route = useRoute();

const isLoading = ref(false);
const date = ref(null);

const accountId = store.getters['auth/accountId'];
const id = +route.params.id;
const shipment = store.getters['shipment/byId'](id);
const historyByIdAndDate = store.getters['shipmentPositionHistory/byIdAndDate'];

const currentDate = computed(
  () => store.getters['shipmentPositionHistory/date'],
);
const isPeriodInFuture = computed(() => date.value[1] > new Date());
const history = computed(() => historyByIdAndDate(id, currentDate.value));

const fetchShipmentByAccountIdAndId = (params) =>
  store.dispatch('shipment/fetchByAccountIdAndId', params);
const fetchPositions = () => store.dispatch('shipmentPosition/fetch');
const fetchPositionHistory = (params) =>
  store.dispatch('shipmentPositionHistory/fetch', params);
const setPositionHistoryDate = (date) =>
  store.dispatch('shipmentPositionHistory/setDate', date);

const refreshAll = () => {
  NProgress.start();

  const refreshFns = [
    fetchShipmentByAccountIdAndId({ accountId, id }),
    fetchPositions(),
  ];
  if (isPeriodInFuture.value) {
    refreshFns.push(
      fetchPositionHistory({
        id,
        params: {
          dateFrom: date.value[0],
          dateTo: date.value[1],
        },
      }),
    );
  }

  const promise = Promise.all(refreshFns);
  promise.finally(() => {
    NProgress.done();
  });
  return promise;
};

const { /*enable, disable,*/ pause, resume, isEnabled } =
  useAutoRefresh(refreshAll);

watch(date, (n) => {
  if (!n) return;
  if (isEnabled.value) {
    pause();
  }
  const [dateFrom, dateTo] = n;
  setPositionHistoryDate({ dateFrom, dateTo });
  if (history.value && !isPeriodInFuture.value) return;

  isLoading.value = true;
  fetchPositionHistory({
    id,
    params: {
      dateFrom,
      dateTo,
    },
  })
    .then((res) => {
      if (res.status === 200) isLoading.value = false;
    })
    .catch((e) => {
      toast.error(e.response?.data?.message || 'Error fetching dwell');
      isLoading.value = false;
    })
    .finally(() => {
      if (isEnabled.value) {
        resume();
      }
    });
});

function onDeleteClick() {
  store.dispatch('modal/open', {
    name: 'CommonConfirmationModal',
    params: {
      actionLabel: 'Delete',
      title: shipment.name,
      body: 'Are you sure you want to delete this shipment?',
      onOk: () => store.dispatch('shipment/delete', { accountId, id }),
      callback: () => router.replace({ name: 'ShipmentListView' }),
      successMessage: COMMON_DELETE_SUCCESS,
      errorMessage: COMMON_DELETE_ERROR,
    },
  });
}
</script>
