<template>
  <CommonGoBack v-if="!isGpxAdminPage" :label="backLabel" @click="goBack" />
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { isRouteForGpxAdmin } from '@/utils/rules';
import { computed } from 'vue';
import { usePrevRoute } from '@/use/use-prev-route';
import CommonGoBack from '@/components/_common/CommonGoBack.vue';
import { useDevice } from '@/composables/useDevice';
import { storeToRefs } from 'pinia';
import { useGroup } from '@/composables/useGroup.ts';

const route = useRoute();
const router = useRouter();
const isGpxAdminPage = isRouteForGpxAdmin(route);
const prevRoute = usePrevRoute();

const deviceStore = useDevice();
const { search } = storeToRefs(deviceStore);

const groupStore = useGroup();
const { search: groupSearch } = storeToRefs(groupStore);
const { getById } = groupStore;

const backLabel = computed(() => {
  if (prevRoute.name === 'GroupDetailsView') {
    const groupName = getById(+prevRoute.params.id)?.name;
    return groupName || 'Back';
  }
  if (prevRoute.name === 'GroupListView') {
    return 'All Groups';
  }

  return 'All Trackers';
});

function goBack() {
  if (prevRoute.name === 'GroupDetailsView') {
    router.back();
    return;
  }
  if (prevRoute.name === 'GroupListView') {
    router.push({
      name: 'GroupListView',
      query: { search: groupSearch.value },
    });
    return;
  }

  router.push({
    name: 'DeviceListView',
    query: { search: search.value },
  });
}
</script>
