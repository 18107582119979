<template>
  <div class="relative">
    <div
      v-if="isSearchOpen"
      class="absolute left-[24px] top-[15px] z-10 w-[66%] bg-bg-1 sm:left-[36px]"
      :class="[{ 'left-[63px] sm:left-[72px]': !shouldShowMagnifyGlassIcon }]"
    >
      <div class="flex items-center">
        <MagnifyingGlassIcon
          v-if="shouldShowMagnifyGlassIcon"
          class="mr-[16px] h-[18px] w-[18px] shrink-0 text-lgmx_slate-300"
        />
        <input
          ref="inputRef"
          type="text"
          placeholder="Search"
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
          class="w-5/6 border-none bg-transparent py-0 pl-0 text-[13px] leading-5 text-lgmx_black-200 placeholder:text-lgmx_slate-300 focus:ring-transparent"
        />
      </div>
    </div>
    <table class="table w-full">
      <thead class="bg-bg-1 tracking-wider">
        <tr>
          <th
            v-if="mode !== 'delete'"
            scope="col"
            class="relative w-[55px] pl-[24px] pr-[8px] sm:pl-[40px]"
          >
            <UICheckboxNew
              v-if="!modelValue"
              class="translate-y-[-2px]"
              :class="[{ 'z-20': !shouldShowMagnifyGlassIcon }]"
              :checked="
                indeterminate ||
                selectedDevices.length === searchedDevices.length
              "
              :indeterminate="indeterminate"
              @change="selectAll($event.target.checked)"
            />
            <UICheckboxNew
              v-else
              class="translate-y-[-2px]"
              :class="[{ 'z-20': !shouldShowMagnifyGlassIcon }]"
              :checked="isIndeterminateWithSearch || isCheckedAllWithSearch"
              :indeterminate="isIndeterminateWithSearch"
              @change="($event) => selectSearchedDevices($event)"
            />
          </th>
          <th
            scope="col"
            class="mr-[7px] min-w-[82px] items-center p-[8px] text-[11px] font-semibold uppercase leading-4 text-lgmx_slate-500"
            :class="[
              {
                'pl-[24px] sm:pl-[40px]': mode === 'delete',
              },
            ]"
          >
            serial #
            <ChevronUpDownIcon
              class="inline-flex h-[14px] w-[14px] opacity-0 -translate-y-px"
            />
          </th>
          <th
            scope="col"
            class="mr-[7px] min-w-[86px] items-center p-[8px] text-[11px] font-semibold uppercase leading-4 text-lgmx_slate-500"
          >
            tracker name
            <ChevronUpDownIcon
              class="inline-flex h-[14px] w-[14px] opacity-0 -translate-y-px"
            />
          </th>
          <th
            scope="col"
            class="flex items-center justify-end p-[8px] pr-[18px] text-lgmx_slate-300 sm:pr-[32px]"
          >
            <button
              @click="toggleSearch"
              class="p-[7px]"
              :class="[
                { 'pointer-events-none cursor-auto opacity-0': !canSearch },
              ]"
            >
              <XMarkIcon
                v-if="isSearchOpen"
                class="h-[18px] w-[18px] text-lgmx_slate-300"
              />
              <MagnifyingGlassIcon
                v-else
                class="h-[18px] w-[18px] text-lgmx_slate-300"
              />
            </button>
          </th>
        </tr>
      </thead>
      <tbody class="bg-white text-[13px] leading-5 text-lgmx_black-400">
        <tr class="h-[11px]"></tr>
        <tr v-if="!paginatedDevices.length && modelValue">
          <td colspan="4">
            <NoData
              :bg="false"
              title="No trackers found:"
              :subtitle="modelValue"
              class="py-[47px]"
            />
          </td>
        </tr>
        <tr v-if="!paginatedDevices.length && !modelValue">
          <td colspan="4">
            <NoData
              :bg="false"
              title="There are no items"
              subtitle="Table is empty"
              class="py-[47px]"
            />
          </td>
        </tr>

        <tr v-else v-for="device in paginatedDevices" :key="device.id">
          <td
            v-if="mode !== 'delete'"
            class="relative w-[55px] pl-[24px] pr-[8px] sm:pl-[40px]"
          >
            <UICheckboxNew
              :id="device.id"
              class="-translate-y-px"
              :value="device.id"
              :checked="selectedDevices.includes(device.id)"
              @change="($event) => handleSelectDevice($event, device.id)"
            />
          </td>
          <td
            class="whitespace-nowrap px-[8px] py-[6px] font-medium"
            :class="[{ 'pl-[24px] sm:pl-[40px]': mode === 'delete' }]"
          >
            <label :for="device.id" class="cursor-pointer">
              {{ device.serial }}
            </label>
          </td>
          <td class="whitespace-nowrap px-[8px] py-[6px]">
            <label :for="device.id" class="cursor-pointer">
              {{ truncate(device.name, { length: 20 }) }}
            </label>
          </td>
          <td
            v-if="mode === 'delete'"
            class="flex justify-end whitespace-nowrap px-[8px] py-[6px] pr-[28px] sm:pr-[40px]"
          >
            <TrashIcon
              class="h-[18px] w-[18px] shrink-0 cursor-pointer text-lgmx_slate-300"
              @click="deleteDevice(device.id)"
            />
          </td>
        </tr>
        <div class="h-[11px]"></div>
      </tbody>
    </table>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import truncate from 'lodash/truncate';
import NoData from '@/components/_common/NoData.vue';

export default {
  emits: [
    'update:modelValue',
    'selectDevice',
    'selectDevices',
    'selectAll',
    'selectNone',
  ],
  props: {
    canSearch: {
      type: Boolean,
      default: true,
    },
    mode: String,
    modelValue: String,
    searchedDevices: Array,
    selectedDevices: Array,
    paginatedDevices: Array,
  },

  data() {
    return {
      isSearchOpen: false,
      checked: false,
    };
  },
  computed: {
    isCheckedAllWithSearch() {
      return this.searchedDevices.every((item) =>
        this.selectedDevices.includes(item.id),
      );
    },
    isIndeterminateWithSearch() {
      if (this.isCheckedAllWithSearch) {
        return false;
      }
      return (
        this.selectedDevices.length > 0 &&
        this.searchedDevices.some((item) =>
          this.selectedDevices.includes(item.id),
        )
      );
    },
    indeterminate() {
      return (
        this.selectedDevices.length > 0 &&
        this.selectedDevices.length < this.searchedDevices.length
      );
    },
    shouldShowMagnifyGlassIcon() {
      if (this.mode === 'delete') return true;
      if (!this.paginatedDevices.length) return true;
      if (this.paginatedDevices.length && !this.modelValue) {
        return true;
      }
      return false;
    },
  },
  watch: {
    isSearchOpen: {
      handler(n) {
        if (n) {
          nextTick(() => {
            this.$refs.inputRef.focus();
          });
        }
      },
    },
  },
  methods: {
    truncate,
    selectAll(checked) {
      if (checked) {
        this.$emit('selectAll');
      } else {
        this.$emit('selectNone');
      }
    },
    selectSearchedDevices($event) {
      this.$emit('selectDevices', {
        checked: $event.target.checked,
        ids: this.searchedDevices.map((item) => item.id),
      });
    },
    handleSelectDevice($event, id) {
      this.$emit('selectDevice', {
        checked: $event.target.checked,
        id: id,
      });
    },
    deleteDevice(id) {
      this.$emit('selectDevice', {
        checked: false,
        id: id,
      });
    },
    toggleSearch() {
      this.$emit('update:modelValue', '');
      this.isSearchOpen = !this.isSearchOpen;
    },
  },
  components: { NoData },
};
</script>

<style></style>
