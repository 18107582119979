<template>
  <tr
    class="relative cursor-pointer after:absolute after:bottom-0 after:left-[24px] after:w-[calc(100%-48px)] after:border-b after:border-b-gpx_gray-800 last-of-type:after:hidden hover:bg-bg-3 sm:after:hidden"
  >
    <td
      v-for="(item, key, index) in dataCells"
      :key="index"
      class="h-[52px] py-0 pr-[40px] last-of-type:pr-[24px]"
      :class="{
        'static left-[calc(100vw-56px)] top-0 select-none !pr-[12px] sm:relative sm:left-0':
          key === 'edit',
      }"
    >
      <div
        v-if="key !== 'status' && key !== 'edit'"
        class="whitespace-nowrap text-gpx_sm text-lms_black-100"
        :class="{
          'pl-[24px] font-medium text-lms_black-500 sm:pl-[32px]': +index === 0,
        }"
        :key="index + '-' + data.id"
      >
        <!-- For 2 text lines case -->
        {{ Array.isArray(item) ? item[0] : item }}
        <div v-if="Array.isArray(item)" class="text-gpx_xs text-gpx_gray-100">
          {{ item[1] }}
        </div>
      </div>
      <div
        v-if="key === 'status'"
        class="flex gap-x-[12px]"
        :key="'hoverItem-' + data.id"
      >
        <UIBadge :status="item.status" />
        <div
          v-if="item.link"
          class="inline-flex items-center text-gpx_xs font-medium text-lms_red-900"
        >
          {{ item.link.prefix }}&nbsp;
          <router-link
            :to="item.link.to"
            class="relative inline-flex after:absolute after:bottom-0 after:left-0 after:w-full after:border-b after:border-b-lms_red-900"
            >{{ item.link.label }}</router-link
          >
        </div>
      </div>
      <div
        v-if="key === 'edit' && item"
        class="relative float-right flex h-[32px] w-[32px] cursor-pointer items-center justify-center sm:h-[48px] sm:w-[56px]"
        :class="{ 'after:opacity-100': editItemId === data.id }"
        :key="'edit-' + data.id"
        v-click-outside="closeEdit"
        @click.stop="toggleEdit(data.id)"
      >
        <EllipsisHorizontalIcon
          class="heroicon-stroke-w-1.4 pointer-events-none h-6 w-6 text-gpx_gray-400"
          :class="{ '!text-lms_red-900': editItemId === data.id }"
        />
        <div
          class="absolute right-0 top-full z-50 min-w-[200px] rounded-[6px] border border-gpx_gray-900 bg-white px-[14px] pb-[18px] pt-[19px] drop-shadow-ui translate-y-[8px]"
          v-if="editItemId === data.id"
        >
          <template v-for="item in editActions">
            <button
              :id="item.label"
              :key="item.label"
              @click.stop="($event) => doAction($event, item.action, data.id)"
              class="flex w-full cursor-pointer items-center rounded-[4px] px-[10px] py-[6px] hover:bg-bg-3"
              v-if="
                typeof item.shouldShow === 'function'
                  ? item.shouldShow(data)
                  : true
              "
            >
              <div class="mr-[12px] leading-none">
                <UISpinner
                  v-if="actionInProgress === item.label"
                  size="xs"
                  class="mx-[3px]"
                />
                <component
                  v-else
                  :is="item.icon"
                  class="heroicon-stroke-w-1.2 h-[20px] w-[20px] text-gpx_gray-200 group-hover:text-lms_red-900"
                />
              </div>
              <div
                class="whitespace-nowrap text-gpx_sm font-medium text-lms_black-400"
              >
                {{ item.label }}
              </div>
            </button>
          </template>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import _ from 'lodash';
import vClickOutside from 'click-outside-vue3';
import { useToast } from 'vue-toastification';
import {
  AdjustmentsHorizontalIcon,
  ArrowRightIcon,
  ClipboardDocumentListIcon,
  CurrencyDollarIcon,
  EllipsisHorizontalIcon,
  EyeIcon,
  KeyIcon,
  PauseIcon,
  PencilSquareIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';

const toast = useToast();

export default {
  name: 'DataTableItem',
  components: {
    AdjustmentsHorizontalIcon,
    ArrowRightIcon,
    ClipboardDocumentListIcon,
    CurrencyDollarIcon,
    EllipsisHorizontalIcon,
    EyeIcon,
    KeyIcon,
    PauseIcon,
    PencilSquareIcon,
    TrashIcon,
    XMarkIcon,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data: () => {
    return {
      editItem: null,
      hoverItem: null,
      actionInProgress: null,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    editActions: {
      type: Array,
      required: false,
    },
    editItemId: {
      type: Number,
    },
  },

  methods: {
    toggleEdit(id) {
      if (this.editItemId === id) {
        return this.$emit('setEditItemId', null);
      }
      this.$emit('setEditItemId', id);
    },
    closeEdit() {
      this.$emit('setEditItemId', null);
    },
    doAction($event, action, id) {
      if (action.mode === 'modal') {
        this.$parent.$emit('open-modal', action.modal);
      }
      if (action.mode === 'route') {
        this.$router.push(action.route);
      }
      if (action.mode === 'fn') {
        return action.fn(id);
      }
      if (action.mode === 'async-fn') {
        this.actionInProgress = $event.currentTarget.id;
        action
          .fn(id)
          .catch(() => {
            const actionName =
              typeof this.actionInProgress === 'string'
                ? this.actionInProgress.toLowerCase()
                : this.actionInProgress;
            toast.error(`Can't ${actionName}`);
          })
          .finally(() => {
            this.actionInProgress = null;
          });
      }
    },
  },
  computed: {
    dataCells() {
      return _.omit(this.data, ['id']);
    },
  },
};
</script>
