const controllers = new Map<string, AbortController>();
export const setAbortController = (entity: string) => {
  const controller = controllers.get(entity);
  if (controller) {
    controller.abort();
    controllers.delete(entity);
  }
  const instance = new AbortController();
  controllers.set(entity, instance);
  return instance;
};
