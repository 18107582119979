<template>
  <div v-if="showTools" class="hidden md:flex">
    <UIButtonNew
      class="rounded-r-none !px-2 translate-x-px"
      size="sm"
      variant="tertiary"
      @click="goBack"
      :disabled="isGoBackwardDisabled"
    >
      <ChevronLeftIcon class="h-[18px] w-[18px]" />
    </UIButtonNew>
    <UIButtonNew
      class="rounded-l-none !px-2"
      size="sm"
      variant="tertiary"
      @click="goForward"
      :disabled="isGoForwardDisabled"
    >
      <ChevronRightIcon class="h-[18px] w-[18px]" />
    </UIButtonNew>
  </div>

  <Datepicker
    ref="datepicker"
    v-model="date"
    :presetRanges="getPresetRanges()"
    :format="format"
    :previewFormat="previewFormat"
    :clearable="false"
    :minDate="minDate"
    :maxDate="maxDate"
    :weekStart="0"
    :alt-position="adjustPosition()"
    @internalModelChange="handleInternal"
    noHoursOverlay
    range
    multiCalendars
    enableSeconds
    utc
  >
    <template #time-picker="{ time, updateTime }">
      <VTimePickerRange
        :hours="time.hours"
        :minutes="time.minutes"
        :seconds="time.seconds"
        @updateTime="updateTime"
      />
    </template>
    <template #dp-input="{ value }">
      <UITooltip :text="tooltip">
        <UIButtonNew
          class="w-full"
          size="sm"
          variant="tertiary"
          icon="CalendarDaysIcon"
        >
          {{ value }}
        </UIButtonNew>
      </UITooltip>
    </template>
    <template #action-buttons>
      <div class="flex justify-end">
        <UIButtonNew
          class="mr-1 !px-2"
          size="sm"
          variant="tertiary"
          @click="closeMenu"
        >
          Cancel
        </UIButtonNew>
        <UIButtonNew
          class="!px-2"
          size="sm"
          :disabled="isSelectDisabled"
          @click="selectDate"
        >
          Select
        </UIButtonNew>
      </div>
    </template>
  </Datepicker>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Datepicker from '@vuepic/vue-datepicker';
import {
  subHours,
  subDays,
  addDays,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
  subWeeks,
  parseISO,
  intervalToDuration,
  addSeconds,
  sub,
  add,
} from 'date-fns';
import { formatTime } from '@/filters';
import { useToast } from 'vue-toastification';
import VTimePickerRange from './VTimePickerRange.vue';
import { breakpoints, RANGEPICKER_INTERVAL_WARNING } from '@/config/constants';
import UIButtonNew from './UIButtonNew/UIButtonNew.vue';

const toast = useToast();

const props = defineProps({
  modelValue: Array,
  initialValue: Array,
  maxDate: Boolean,
  utc: {
    type: Boolean,
    default: false,
  },
  showTools: {
    type: Boolean,
    default: true,
  },
  tooltip: {
    type: String,
    default: 'Select date',
  },
});

const emit = defineEmits(['update:modelValue']);

const date = ref();
const datepicker = ref(null);

const store = useStore();
const monthsLimit = store.getters['auth/accountSettingIsOn'](
  'unlimited_date_selection',
)
  ? 12
  : 3;
const minDate = subMonths(new Date(), monthsLimit).toISOString();

const maxDate = props.maxDate ? endOfDay(new Date()).toISOString() : null;

const isSelectDisabled = ref(false);
const isGoBackwardDisabled = ref(true);
const isGoForwardDisabled = ref(true);

const adjustPosition = () => {
  const viewportWidth = +window.innerWidth;
  if (viewportWidth <= breakpoints.sm) {
    return () => {
      return {
        top: '56px',
        left: '50%',
        transform: 'translateX(-50%)',
      };
    };
  }
};

const selectDate = () => {
  datepicker.value.selectDate();
  updateModel(date.value);
};

const closeMenu = () => {
  if (datepicker.value) {
    datepicker.value.closeMenu();
  }
};

const format = ([from, to]) => {
  return `${formatTime(from, 'MMM d')} - ${formatTime(to, 'MMM d')}`;
};
const previewFormat = ([from, to]) => {
  return `${formatTime(from, 'HH:mm, MMM d ')}
      - ${to ? formatTime(to, 'HH:mm, MMM d') : '...'}`;
};

const handleInternal = (innerDate) => {
  if (innerDate && innerDate.length === 2) {
    if (innerDate[0] > innerDate[1]) {
      toast.warning(RANGEPICKER_INTERVAL_WARNING, { timeout: 5000 });
      isSelectDisabled.value = true;
      return;
    }
    isSelectDisabled.value = false;
    return;
  }
  isSelectDisabled.value = true;
};

watch(date, (current) => {
  const startDate = new Date(current[0]);
  const endDate = new Date(current[1]);
  const diff = getDiff(startDate, endDate);

  isGoBackwardDisabled.value =
    sub(parseISO(current[1]), diff) < parseISO(minDate);
  isGoForwardDisabled.value =
    add(parseISO(current[1]), diff) > addSeconds(new Date(maxDate), 1);
});

onMounted(() => {
  if (props.initialValue) {
    date.value = props.initialValue;
    datepicker.value.selectDate();
    return updateModel(props.initialValue);
  }
  const startDate = getStartDate();
  const endDate = endOfDay(new Date()).toISOString();
  date.value = [startDate, endDate];
  updateModel(date.value);
});

const updateModel = (value) => {
  emit('update:modelValue', value);
};

const goBack = () => {
  const startDate = new Date(date.value[0]);
  const endDate = new Date(date.value[1]);
  const diff = getDiff(startDate, endDate);

  date.value = [
    sub(startDate, diff).toISOString(),
    sub(endDate, diff).toISOString(),
  ];
  updateModel(date.value);
};
const goForward = () => {
  const startDate = new Date(date.value[0]);
  const endDate = new Date(date.value[1]);
  const diff = getDiff(startDate, endDate);

  date.value = [
    add(startDate, diff).toISOString(),
    add(endDate, diff).toISOString(),
  ];
  updateModel(date.value);
};

const trackerPresetRanges = ref([
  { label: 'Today', range: [startOfDay(new Date()), endOfDay(new Date())] },
  {
    label: 'Yesterday',
    range: [
      startOfDay(subDays(new Date(), 1)),
      endOfDay(subDays(new Date(), 1)),
    ],
  },
  {
    label: 'Last Hour',
    range: [subHours(new Date(), 1), new Date()],
  },
  {
    label: 'Custom Range',
    range: [startOfWeek(new Date()), endOfDay(new Date())],
  },
]);
const shipmentsPresetRanges = ref([
  {
    label: 'This week',
    range: [startOfWeek(new Date()), endOfDay(new Date())],
  },
  {
    label: 'Last week',
    range: [
      startOfWeek(subWeeks(new Date(), 1)),
      addDays(endOfWeek(subWeeks(new Date(), 1)), 1),
    ],
  },
  {
    label: 'This month',
    range: [startOfMonth(new Date()), endOfDay(new Date())],
  },
  {
    label: 'Last month',
    range: [
      startOfMonth(subDays(startOfMonth(new Date()), 1)),
      endOfMonth(startOfMonth(subDays(startOfMonth(new Date()), 1))),
    ],
  },
  {
    label: 'Custom Range',
    range: [
      startOfMonth(subDays(startOfMonth(new Date()), 1)),
      endOfDay(new Date()),
    ],
  },
]);
const reportsPresetRanges = trackerPresetRanges;
const trackersPresetRanges = trackerPresetRanges;
function getStartDate() {
  const route = useRoute();
  if (route.name === 'DeviceListView') {
    return startOfDay(subDays(new Date(), 2)).toISOString();
  }
  if (route.name === 'GroupListView') {
    return startOfDay(subDays(new Date(), 2)).toISOString();
  }
  if (route.name === 'GroupDetailsView') {
    return startOfDay(subDays(new Date(), 2)).toISOString();
  }
  if (route.name === 'DeviceDetailsView') {
    return startOfDay(new Date()).toISOString();
  }
  if (route.name === 'GpxAdminDeviceDetailsView') {
    return startOfDay(new Date()).toISOString();
  }
  if (route.name === 'ShipmentListView') {
    return startOfMonth(subDays(startOfMonth(new Date()), 1));
  }
  if (route.name === 'ReportListView') {
    return startOfDay(new Date()).toISOString();
  }
  if (route.name === 'GeofenceListView') {
    return startOfDay(new Date()).toISOString();
  }
}
function getPresetRanges() {
  const route = useRoute();
  if (route.name === 'GpxAdminDeviceDetailsView')
    return trackerPresetRanges.value;
  if (route.name === 'DeviceListView') return trackersPresetRanges.value;
  if (route.name === 'DeviceDetailsView') return trackerPresetRanges.value;
  if (route.name === 'ShipmentListView') return shipmentsPresetRanges.value;
  if (route.name === 'ReportListView') return reportsPresetRanges.value;
}
function getDiff(startDate, endDate) {
  const diff = intervalToDuration({
    start: startDate,
    end: endDate,
  });
  if (diff.seconds === 59) {
    return intervalToDuration({
      start: startDate,
      end: addSeconds(endDate, 1),
    });
  }
  return diff;
}
</script>

<style>
.dp__cell_inner {
  @apply !h-7;
}
.dp__preset_range {
  @apply !px-[10px];
}
.dp__preset_ranges {
  @apply text-sm;
}
.dp__instance_calendar {
  @apply text-xs px-[2px];
}
.dp__range_end,
.dp__range_start,
.dp__active_date,
.dp__overlay_cell_active {
  @apply !bg-red-300;
}
.dp__inc_dec_button:hover {
  @apply !text-red-300;
}
.dp__today {
  @apply !border-red-300;
}
.dp__menu_index {
  @apply !z-[9999];
}
.dp__input_wrap {
  @apply w-full;
}
</style>
