import * as L from 'leaflet';
import * as _ from 'lodash';
import { reverseCoords } from '@/utils/coords';

export function createShape({ geofenceId, geofence, address }) {
  if (geofenceId) {
    if (geofence.is_irregular) {
      const c = geofence.geo_json.coordinates[0].map(reverseCoords);
      return L.polygon([c], {
        id: geofence.id,
        latlng: {
          lat: geofence.lat,
          lng: geofence.lng,
        },
        color: '#58CE50',
        weight: 2,
        opacity: 0.7,
        fillColor: '#fff',
        fillOpacity: 0.7,
      });
    } else {
      return L.circle([geofence.lat, geofence.lng], {
        id: geofence.id,
        radius: geofence?.radius ?? 0.1,
        latlng: {
          lat: geofence.lat,
          lng: geofence.lng,
        },
        color: '#58CE50',
        weight: 2,
        opacity: 0.7,
        fillColor: '#fff',
        fillOpacity: 0.7,
      });
    }
  } else {
    return L.circle([address.lat, address.lng], {
      id: address.address,
      radius: 0,
      latlng: {
        lat: address.lat,
        lng: address.lng,
      },
      color: '#58CE50',
      weight: 2,
      opacity: 0.7,
      fillColor: '#fff',
      fillOpacity: 0.7,
    });
  }
}

export function createTooltip({ geofenceId, geofence, address }, direction) {
  const name = geofenceId
    ? _.get(geofence, 'name', '-')
    : _.get(address, 'name');
  const lat = geofenceId ? geofence.lat : address.lat;
  const lng = geofenceId ? geofence.lng : address.lng;

  const content = getTooltipContent(name, direction);
  return L.tooltip({
    permanent: true,
    opacity: 0.9,
  })
    .on('click', () => {
      if (this.dbClickTimer !== null) {
        return;
      }
      this.dbClickTimer = setTimeout(() => {
        this.dbClickTimer = null;
      }, 200);
    })
    .on('dblclick', () => {
      clearTimeout(this.dbClickTimer);
      this.dbClickTimer = null;
    })
    .setLatLng(L.latLng([lat, lng]))
    .setContent(content);
}

export function getTooltipContent(name, direction) {
  return `
  <div class="text-[10px]">
    <span>${direction}: </span>
    <span class="font-medium">${name}</span>
  </div>
  `;
}
