import service from '@/services';
import {
  BATCH_ADD_OR_UPDATE,
  BATCH_REVERSED_ADD_OR_UPDATE,
  REMOVE,
  ADD,
} from '../mutation-types';
import { addOrUpdateItems, processAndReturn, removeItem } from '../helpers';
import { GEOFENCE_CATEGORY } from '@/config/constants';
import * as events from '@/events';

export const state = {
  items: [],
  search: '',
};

export const getters = {
  all: (state) => state.items,
  filtered: (_, getters) => {
    const search = getters['search'].toLowerCase();
    return getters['all'].filter((item) =>
      item.name?.toLowerCase().includes(search),
    );
  },
  byId: (state) => (id) => state.items.find((item) => item.id === id),
  byGeofenceId: (state) => (geofenceId) =>
    state.items.filter((item) => item.geofence_id === geofenceId),
  byIds: (state) => (ids) =>
    state.items.filter((item) => ids.indexOf(item.id) > -1),
  byAccountId: (state) => (id) =>
    state.items.filter((item) => {
      return item.account_id === id;
    }),
  search: (state) => state.search,

  // UI getters
  isBottomButton: (state, getters, rootState, rootGetters) => {
    return (
      getters.filtered.length &&
      rootGetters['geofence/activeTab'] === GEOFENCE_CATEGORY.bleAnchors
    );
  },
};

export const mutations = {
  [ADD]: (state, items) => (state.items = items),
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [BATCH_REVERSED_ADD_OR_UPDATE]: (state, items) =>
    addOrUpdateItems(state, items, undefined, undefined, { reversed: true }),
  [REMOVE]: (state, item) => removeItem(state, item),
  SET_SEARCH: (state, search) => (state.search = search),
};

export const actions = {
  setSearch: (store, search) => {
    store.commit('SET_SEARCH', search);
  },
  fetchByAccountId: (store, { accountId, params }) => {
    return service
      .get(`/accounts/${accountId}/anchors`, { params })
      .then((res) => processAndReturn(res, 'bleAnchor'));
  },
  store: (store, { params }) => {
    const accountId = store.rootGetters['auth/accountId'];
    return service
      .post(`/accounts/${accountId}/anchors`, params)
      .then((res) => {
        const anchor = res.data.data;
        events.trigger(events.names.BLE_ANCHOUR_CREATED, { anchor });
        return processAndReturn(res, 'bleAnchor', { reversed: true });
      });
  },
  update: (store, { params }) => {
    const accountId = store.rootGetters['auth/accountId'];
    return service
      .put(`/accounts/${accountId}/anchors/${params.id}`, params)
      .then((res) => {
        const anchor = res.data.data;
        events.trigger(events.names.BLE_ANCHOUR_EDITED, { anchor, params });
        return processAndReturn(res, 'bleAnchor', { reversed: true });
      });
  },
  storeImages: (store, { anchorId, params }) => {
    const accountId = store.rootGetters['auth/accountId'];
    return service.post(
      `/accounts/${accountId}/anchors/${anchorId}/images`,
      params,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  removeImage: (store, { anchorId, imageId }) => {
    const accountId = store.rootGetters['auth/accountId'];
    return service.delete(
      `/accounts/${accountId}/anchors/${anchorId}/images/${imageId}`,
    );
  },
  delete: (store, { accountId, id }) => {
    return service
      .delete(`/accounts/${accountId}/anchors/${id}`)
      .then((res) => {
        events.trigger(events.names.BLE_ANCHOUR_DELETED, { anchor: id });
        store.commit(REMOVE, { id });
        return res;
      });
  },
};
