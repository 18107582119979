const { VITE_APP_B2B_URL, VITE_APP_B2C_URL } = import.meta.env || {};

export const isB2BPlatform = () => {
  const currentHost = document.location.hostname;
  const b2bHost = new URL(VITE_APP_B2B_URL).hostname;
  return b2bHost === currentHost;
};

export const is_b2b_platform = isB2BPlatform();

export const getAccountRedirectionUrl = (account) => {
  if (!account) {
    return;
  }

  if (is_b2b_platform && !account.uses_gpx_app) {
    return VITE_APP_B2C_URL;
  }

  if (!is_b2b_platform && account.uses_gpx_app) {
    return VITE_APP_B2B_URL;
  }

  return;
};

export const getAccountIdFromURL = () => {
  const params = new URLSearchParams(window.location.search);

  if (!params.has('account')) {
    return false;
  }

  return params.get('account');
};
