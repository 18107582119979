<template>
  <UICombobox
    ref="comboboxRef"
    :label="label"
    :placeholder="placeholder"
    :value="inputValue"
    v-model="inputValue"
    :options="options"
    :disabled="disabled"
    :hasError="meta.touched && !meta.valid"
    :openOnFocus="openOnFocus"
    :multiple="multiple"
  />
</template>

<script setup>
import { onMounted, ref, toRef } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
  name: String,
  value: Object,
  label: String,
  placeholder: String,
  options: Array,
  disabled: Boolean,
  validateFn: Function,
  openOnFocus: Boolean,
  multiple: Boolean,
});

const comboboxRef = ref(null);
const name = toRef(props, 'name');
const { value: inputValue, meta } = useField(name, props.validateFn, {
  initialValue: props.value,
});

onMounted(() => {
  if (props.value) {
    comboboxRef.value.setSelectedOptionFromOutside(props.value);
  }
});
</script>

<style></style>
