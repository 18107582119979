<template>
  <div
    class="pl-6 pr-4 sm:w-4/12 sm:border-r sm:border-lgmx_gray-100 md:pl-12 md:pr-6"
  >
    <ShipmentListSearchBar class="h-[64px] sm:h-[48px] sm:translate-y-2" />
  </div>
  <div class="hidden justify-between px-4 py-2 sm:flex sm:w-8/12 md:pr-12">
    <div class="flex gap-2">
      <CommonRefreshIconButton
        :disabled="fetching"
        :is-loading="fetching"
        @click="forcedRefresh"
      />
      <CommonAutoRefreshButton
        class="hidden sm:block"
        :is-enabled="isEnabled"
        @enable="enable"
        @disable="disable"
      />
      <VDatePickerRange
        v-model="date"
        :showTools="false"
        :initialValue="date"
        tooltip="Select range for a start date"
      />
      <div class="relative">
        <ShipmentListFilters />
      </div>
    </div>
    <div class="hidden sm:flex">
      <UITooltip text="Download these shipments">
        <CommonDownloadIconButton type="shipments" @click="downloadShipments" />
      </UITooltip>
    </div>
  </div>
</template>

<script setup>
import NProgress from 'nprogress';
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { addDays, subDays } from 'date-fns';
import CommonRefreshIconButton from '@/components/_common/CommonRefreshIconButton.vue';
import CommonDownloadIconButton from '@/components/_common/CommonDownloadIconButton.vue';
import CommonAutoRefreshButton from '@/components/_common/CommonAutoRefreshButton.vue';
import ShipmentListFilters from './ShipmentListFilters.vue';
import ShipmentListSearchBar from './ShipmentListSearchBar.vue';
import { useAutoRefresh } from '@/use/auto-refresh';
import { getFileName } from '@/utils/blob';

const store = useStore();

const accountId = store.getters['auth/accountId'];
const fetching = computed(() => store.getters['shipment/fetching']);

const date = ref([subDays(new Date(), 15), addDays(new Date(), 15)]);
watch(date, (n, o) => {
  if (!n || !o) return;
  const [date_from, date_to] = n;
  fetchShipments({ date_from, date_to });
});

const fetchShipments = ({ date_from, date_to }) => {
  NProgress.start();
  return store
    .dispatch('shipment/fetchByAccountId', {
      accountId,
      params: {
        with_geofences: true,
        with_current_position: true,
        with_path: true,
        include: 'device',
        date_from,
        date_to,
      },
    })
    .finally(() => NProgress.done());
};

const refreshAll = () => {
  NProgress.start();

  const promise = Promise.all([
    fetchShipments({
      date_from: date.value[0],
      date_to: date.value[1],
    }),
  ]);
  promise.finally(() => {
    NProgress.done();
  });
  return promise;
};

const { enable, disable, isEnabled, forcedRefresh } =
  useAutoRefresh(refreshAll);

const downloadShipments = () => {
  store.dispatch('shipment/downloadAll', {
    accountId,
    fileName: getFileName({
      prefix: 'shipments',
      dateFrom: date.value[0],
      dateTo: date.value[1],
    }),
    params: {
      download: true,
      offset: new Date().getTimezoneOffset(),
      date_from: date.value[0],
      date_to: date.value[1],
    },
  });
};
</script>
