<template>
  <div :class="messageAttr.containerClass">
    <span
      class="inline-flex rounded-md p-2 text-white"
      :class="messageAttr.bubbleClass"
    >
      {{ text }}
    </span>
    <div class="pt-[3px] text-xs text-gray-750">
      {{ formattedStatus }} {{ time }}
    </div>
  </div>
</template>

<script>
import { startCase } from 'lodash';

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      request: true,
    },
  },

  computed: {
    formattedStatus() {
      return startCase(this.status.toLowerCase());
    },
    messageAttr() {
      if (this.type === 'out') {
        return {
          containerClass: 'text-right',
          bubbleClass: 'bg-gray-750',
        };
      } else {
        return {
          containerClass: 'text-left',
          bubbleClass: 'bg-red-400',
        };
      }
    },
  },
};
</script>

<style></style>
