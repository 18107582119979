<template>
  <div>
    <UICombobox
      label="Select Tracker"
      placeholder="My Tracker"
      :options="options"
      :value="modelValue"
      @update:modelValue="handleSelected"
      openOnFocus
    />
    <div class="flex justify-between gap-x-4 pb-[18px] pt-[24px]">
      <UIButtonNew
        variant="tertiary"
        size="lg"
        class="w-[124px]"
        @click="$emit('onCancel')"
      >
        Cancel
      </UIButtonNew>
      <UIButtonNew
        :disabled="!modelValue"
        @click="$emit('onConfirm')"
        class="w-full"
      >
        Continue
      </UIButtonNew>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  emits: ['update:modelValue', 'onConfirm', 'onCancel'],
  props: {
    modelValue: Object,
  },

  computed: {
    ...mapGetters({
      devices: 'simReplacement/devices',
    }),
    options() {
      return this.devices.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
        properties: {
          Serial: item.serial,
        },
      }));
    },
  },

  methods: {
    handleSelected(device) {
      this.$emit('update:modelValue', device);
    },
  },
};
</script>
