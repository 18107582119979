import { BATCH_ADD_OR_UPDATE, REMOVE } from '../mutation-types';
import {
  addOrUpdateItems,
  all,
  byId,
  byIds,
  processEntities,
  removeItem,
} from '../helpers';
import service from '@/services';
import { map, assign } from 'lodash';
import * as _ from 'lodash';

export const state = {
  items: [],
};

export const getters = {
  all,
  byId,
  byIds,
  byDeviceId: (state) => (id) =>
    _.filter(state.items, (item) => item.device_id === +id),
};

export const mutations = {
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [REMOVE]: (state, item) => removeItem(state, item),
};

export const actions = {
  fetchByDeviceId: (_, { id, params = {} }) => {
    params.includes = 'device';
    return service.get(`devices/${id}/dialogs`, { params }).then((res) => {
      /**
       * old backend limited by 30
       * https://app.mayvendev.com/p/logistimatics-gpx/search?q=1104#task-1104-activity
       */
      const data = res.data.data.slice(0, 100);
      const messages = map(data, (message) =>
        assign({}, message, { device_id: id }),
      );
      processEntities(messages, 'message');
    });
  },
  sendToDevice: (_, { id, payload }) => {
    return service.post(`devices/${id}/dialogs`, payload).then((res) => {
      processEntities(assign({}, res.data.data, { device_id: id }), 'message');
    });
  },
};
