<template>
  <div class="flex rounded-[32px] bg-bg-3 p-[4px]">
    <UITab
      v-for="(tab, index) in tabs"
      :key="'tab-' + index"
      :label="tab"
      :index="index"
      :selected="selectedIndex"
      @select="selectTab($event)"
    />
  </div>
</template>

<script>
import UITab from '@/components/ui/UITab.vue';

export default {
  name: 'UITabGroup',
  components: {
    UITab,
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      selectedIndex: 0,
    };
  },
  methods: {
    selectTab(index) {
      this.selectedIndex = index;
      this.$emit('update:modelValue', this.selectedIndex);
    },
  },
};
</script>
