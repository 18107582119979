import service from '@/services';
import { controller, getAbortMutations, setAbortController } from '../helpers';

export const state = {
  controller: null,
  date: '',
  items: [],
};

export const getters = {
  controller,
  all: (state) => state.items,
  date: (state) => state.date,
  byId: (state) => (id) => state.items[id],
};

export const mutations = {
  ...getAbortMutations('devicesPositionHistory'),
  ADD: (state, items) => (state.items = items),
  SET_DATE: (state, date) => (state.date = date),
  SET_CANCEL_TOKEN: (state, token) => (state.cancelSource = token),
  RESET_CANCEL_TOKEN: (state) => (state.cancelSource = null),
  CLEAR_ALL: (state) => (state.items = []),
};

export const actions = {
  setDate: (store, { dateFrom, dateTo }) => {
    store.commit('SET_DATE', `${dateFrom}$${dateTo}`);
  },
  clearAll: (store) => {
    store.commit('CLEAR_ALL');
  },
  fetchByAccountId: (
    store,
    { accountId, params: { dateFrom, dateTo, ids = [], ...rest } },
  ) => {
    const controller = setAbortController({
      entity: 'devicesPositionHistory',
      store,
    });

    const params = {
      date_from: dateFrom,
      date_to: dateTo,
      device_ids: ids,
      ...rest,
    };

    return service
      .post(`/accounts/${accountId}/positions`, params, {
        signal: controller.signal,
      })
      .then((res) => {
        if (res && res.status === 499) return res;
        store.commit('ADD', res.data.data);
        return res;
      });
  },
};
