<template>
  <div
    class="absolute right-0 z-50 min-w-[200px] rounded-[6px] border border-gpx_gray-900 bg-white px-[14px] py-3 drop-shadow-ui"
    :class="[
      { 'top-full drop-shadow-ui': direction === 'bottom' },
      { 'top-0 drop-shadow-ui-inversed': direction === 'top' },
    ]"
  >
    <button
      v-for="item in actions"
      :id="item.label"
      :key="item.label"
      @click.prevent.stop="
        ($event) => doAction($event, item.action, editItemId)
      "
      class="group flex w-full cursor-pointer rounded-[4px] px-[10px] py-[4px] hover:bg-bg-1"
    >
      <div class="mr-[12px]">
        <component
          :is="item.icon"
          class="heroicon-stroke-w-1.2 h-[20px] w-[20px] text-lgmx_slate-400 group-hover:text-lgmx_slate-600"
        />
      </div>
      <div
        class="whitespace-nowrap text-[13px] font-medium text-lgmx_black-300"
      >
        {{ item.label }}
      </div>
    </button>
  </div>
</template>

<script>
import {
  PencilSquareIcon,
  ArchiveBoxIcon,
  TrashIcon,
} from '@heroicons/vue/24/outline';

export default {
  components: {
    PencilSquareIcon,
    ArchiveBoxIcon,
    TrashIcon,
  },

  props: {
    editItemId: Number,
    actions: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      direction: 'bottom',
      actionInProgress: null,
    };
  },

  methods: {
    doAction($event, action, id) {
      if (action.mode === 'fn') {
        return action.fn(id);
      }
      if (action.mode === 'async') {
        this.actionInProgress = $event.currentTarget.id;
        action.fn(id).finally(() => (this.actionInProgress = null));
      }
    },
  },
};
</script>
