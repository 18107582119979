<template>
  <label :for="id" class="relative flex cursor-pointer items-center">
    <input type="checkbox" :id="id" :checked="modelValue" class="sr-only" />
    <span
      class="relative h-[26px] w-[44px] rounded-full bg-gpx_gray-600 after:absolute after:left-[2px] after:top-[2px] after:h-[22px] after:w-[22px] after:rounded-full after:bg-white after:shadow-[2px_2px_4px_0_rgba(108,_119,_137,_0.16)] after:transition"
      :class="[
        {
          '!after:shadow-[2px_2px_4px_0_rgba(73,_57,_106,_0.16)] !border-lms_red-900 !bg-lms_red-900 shadow-inner after:border-lms_red-900 after:translate-x-[calc(100%-4px)]':
            isChecked,
        },
        {
          '!h-[20px] !w-[36px] after:!h-[16px] after:!w-[16px]': size === 'sm',
        },
        {
          'bg-red-400 bg-gradient-to-b from-red-300 to-red-400 after:!translate-x-full':
            isChecked && size === 'sm',
        },
      ]"
      @click="toggle"
    ></span>
  </label>
</template>

<script>
export default {
  name: 'UIToggle',
  props: {
    modelValue: {
      type: [Boolean, Number],
      default: false,
    },
    size: {
      type: String,
      validator(v) {
        return ['sm', 'md'].includes(v);
      },
      default: 'md',
    },
  },

  data() {
    return {
      id: null,
    };
  },

  methods: {
    toggle() {
      this.$emit('update:modelValue', !this.modelValue);
    },
  },

  computed: {
    isChecked() {
      return this.modelValue;
    },
  },

  mounted() {
    this.id = this._uid;
  },
};
</script>
