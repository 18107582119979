<template>
  <VTooltip :triggers="['click']">
    <slot />
    <template #popper>
      <div class="flex">
        <div class="px-[8px] py-[4px]">{{ text }}</div>
        <button
          class="border-x border-lgmx_black-100 px-[9px] py-[5px]"
          @click="$emit('onConfirm')"
        >
          <CheckIcon class="h-[14px] w-[14px]" />
        </button>
        <button class="px-[9px] py-[5px]" @click="$emit('onCancel')">
          <XMarkIcon class="h-[14px] w-[14px]" />
        </button>
      </div>
    </template>
  </VTooltip>
</template>

<script>
import { CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline';

export default {
  emits: ['onConfirm', 'onCancel'],
  props: {
    text: {
      type: String,
      default: 'Are you sure',
    },
    onConfirmText: {
      type: String,
      default: 'Yes',
    },
    onCancelText: {
      type: String,
      default: 'No',
    },
  },
  components: { CheckIcon, XMarkIcon },
};
</script>
