<template>
  <div>
    <label
      v-if="label"
      class="mb-[3px] flex text-gpx_xs font-medium text-gpx_gray-200"
      :for="id"
    >
      {{ label }}
      <span v-if="required" class="text-lgmx_red-200">*</span>
      <div v-if="isLoading" class="ml-2 h-px translate-y-[2px]">
        <UISpinner size="xs" />
      </div>
    </label>
    <div class="flex">
      <input
        v-maska="vMaska"
        :data-maska="dataMaska"
        :data-maska-tokens="dataMaskaTokens"
        :data-maska-eager="dataMaskaEager"
        :id="id"
        :type="type"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="modelValue"
        @blur="$emit('blur')"
        @input="$emit('update:modelValue', $event.target.value)"
        :readonly="readonly"
        class="w-full rounded-[6px] border-0 bg-bg-3 px-[22px] py-[14px] text-gpx_sm text-lms_black-100 placeholder:text-gpx_gray-300 focus:outline-0 focus:ring-0 disabled:text-gray-700"
        :class="[
          inputClass,
          {
            '!bg-white drop-shadow-[2px_4px_8px_0_rgba(232,_234,_237,_0.2)]':
              light,
          },
          { 'bg-red-150': hasError },
          { 'cursor-not-allowed': disabled },
        ]"
      />
    </div>
  </div>
</template>

<script>
import { vMaska } from 'maska';
export default {
  directives: { maska: vMaska },
  emits: ['blur', 'update:modelValue'],

  props: {
    isLoading: Boolean,
    light: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      required: false,
      default: '',
      type: String,
    },
    modelValue: {
      required: false,
      default: '',
      type: [String, Number],
    },
    label: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    vMaska: String,
    dataMaska: String,
    dataMaskaTokens: String,
    dataMaskaEager: Boolean,
  },

  data() {
    return {
      id: null,
    };
  },

  mounted() {
    this.id = this._uid;
  },

  methods: {
    handleChange(e) {
      this.$emit('input', e.target.value);
    },
  },
};
</script>
