function getDrawStyles() {
  return {
    color: '#22cb17',
    weight: 2,
    opacity: 0.7,
    fillColor: '#fff',
    fillOpacity: 0.7,
  };
}
export function getDrawOptions() {
  return {
    polyline: false,
    marker: false,
    circlemarker: false,
    rectangle: false,
    circle: {
      shapeOptions: getDrawStyles(),
    },
    polygon: {
      shapeOptions: getDrawStyles(),
    },
  };
}
