<script>
import * as _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import PaginatedTableVue from '@/components/_common/Data/mixins/PaginatedTable.vue';
import { formatTime } from '@/filters';
import { useDevice } from '@/composables/useDevice';

export default {
  props: {
    accountId: {
      required: true,
      type: Number,
    },
  },

  mixins: [PaginatedTableVue],

  data() {
    return {
      title: 'Subscriptions',
      subtitle: 'View, search and edit subscriptions',
      columns: [
        'Device Name',
        'Serial Number',
        'Next Renewal',
        'Active Since',
        'Status',
      ],
      filter: {
        type: 'status',
        value: null,
        values: [
          { name: 'active', indicator: 'green' },
          { name: 'pending', indicator: 'blue' },
          { name: 'pending-renewal', indicator: 'yellow' },
          { name: 'cancelled', indicator: 'red' },
          { name: 'blocked', indicator: 'red' },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      itemsByIds: 'subscription/byIds',
    }),
  },

  watch: {
    accountId() {
      this.loadPage();
    },
  },

  setup() {
    const { getById } = useDevice();
    return {
      getById,
    };
  },

  methods: {
    formatTime,
    ...mapActions({
      fetchSubscriptionsByAccountId: 'subscription/fetchByAccountId',
    }),
    fetchSubscriptions(params) {
      return this.fetchSubscriptionsByAccountId({
        accountId: this.accountId,
        params,
      });
    },
    fetchItems(pagination) {
      const params = _.assign({}, pagination, {
        include: 'devices,devices.model',
      });
      return this.fetchSubscriptions(params);
    },
    formatItemIntoRow(subscription) {
      const device = this.getById(subscription.devices[0]?.id);
      return {
        id: subscription.id,
        name: device ? _.truncate(device.name, { length: 60 }) : '-',
        serial: device ? device.serial : '-',
        renewal: subscription.renewal_date
          ? this.formatTime(subscription.renewal_date)
          : '-',
        since: subscription.start_at
          ? this.formatTime(subscription.start_at)
          : '-',
        status: {
          status: _.get(subscription, 'status'),
        },
      };
    },
    onItemClick(item) {
      this.$router.push({
        name: 'ManageSubscriptionView',
        params: { id: item.id },
      });
    },
  },
};
</script>
