import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import {
  VITE_APP_BUGSNAG_KEY,
  VITE_APP_BUGSNAG_STAGE,
  VITE_BUGSNAG_APP_VERSION
} from './config/environment';

const ignorePatterns = [
  'Request failed with status code 401',
  'Request failed with status code 404',
  'Request aborted',
  'Network Error',
];

export function startBugsnag() {
  Bugsnag.start({
    apiKey: VITE_APP_BUGSNAG_KEY,
    appVersion: VITE_BUGSNAG_APP_VERSION,
    releaseStage: VITE_APP_BUGSNAG_STAGE,
    enabledReleaseStages: ['production', 'staging'],
    logger: null,
    plugins: [new BugsnagPluginVue()],
    onError: (event) => {
      const matches = ignorePatterns.some((p) =>
        p.includes(event.errors[0].errorMessage),
      );
      if (matches) {
        return false;
      }
    },
  });
  return Bugsnag.getPlugin('vue');
}
