<template>
  <div :id="id" class="z-0 h-full w-full bg-gray-500" />
</template>

<script>
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-draw';
import { toRaw } from 'vue';
import { mapCenter } from '@/config/constants';
import * as layerUtils from '@/utils/map/layer';
import * as markerUtils from '@/components/geofence/map/utils/marker';
import * as shapeUtils from '@/components/geofence/map/utils/shape';
import { mapGetters } from 'vuex';

export default {
  emits: ['update:bleAnchorCoords', 'update:mapViewOptions'],
  props: {
    id: String,
    googleCoords: Object,
    bleAnchorRadius: Number,
    isCustom: Boolean,
    geofence: Object,
    address: Object,
    mapViewOptions: Object,
  },

  data() {
    return {
      zoom: 5,
      dbClickTimer: null,
      center: mapCenter,
      map: null,
      mainLayers: [],
      marker: null,
      shapes: L.featureGroup(),
      shape: null,
      layerControl: null,
    };
  },

  computed: {
    ...mapGetters({
      bleAnchors: 'bleAnchor/all',
    }),
  },

  watch: {
    googleCoords: {
      handler(n) {
        this.setAddressView(n);
        this.createTempAnchor(n);
        this.createTempShape(n, this.bleAnchorRadius);
      },
    },
    bleAnchorRadius: {
      handler(n) {
        const currentAnchorCoordinates = this.marker?.getLatLng();
        if (currentAnchorCoordinates) {
          this.createTempShape(currentAnchorCoordinates, n);
        }
      },
    },
    address: {
      handler(n) {
        if (!n) return;
        if (!n.lat || !n.lng) return;
        this.setAddressView(n);
      },
    },
  },

  beforeMount() {},

  mounted() {
    this.$nextTick(() => {
      this.setupLeafletMap();
      this.setupShapes();
    });
  },

  methods: {
    setupLeafletMap() {
      if (this.mapViewOptions) {
        const { center, zoom } = this.mapViewOptions;
        this.center = center;
        this.zoom = zoom;
      }

      const mainLayers = layerUtils.getLayers();
      const { id } = layerUtils.useLocalStorageLayer();
      this.mainLayers = mainLayers;

      this.map = L.map(this.id, {
        layers: [toRaw(this.mainLayers)[id]],
        center: toRaw(this.center),
        zoomControl: false,
        zoom: toRaw(this.zoom),
        scrollWheelZoom: false,
      });

      L.control.zoom({ position: 'topright' }).addTo(toRaw(this.map));
      this.layerControl = L.control
        .layers(toRaw(this.mainLayers), null, { position: 'topleft' })
        .addTo(toRaw(this.map));

      this.listenMapClick();
      this.listenMove();
    },

    setAddressView(newAddress) {
      toRaw(this.map).flyTo([newAddress.lat, newAddress.lng], 16, {
        duration: 0.8,
      });
    },

    setupShapes() {
      this.bleAnchors.forEach((bleAnchor) => this.createShape(bleAnchor));
      toRaw(this.map).addLayer(toRaw(this.shapes));
    },

    createShape(bleAnchor) {
      if (!bleAnchor) return;
      const coordinates = {
        lat: bleAnchor.lat,
        lng: bleAnchor.lng,
      };
      const radius = bleAnchor.radius;
      const shape = shapeUtils.createBleShape(coordinates, radius);
      shape.addTo(toRaw(this.shapes));
    },

    createTempAnchor(coordinates) {
      if (this.marker) {
        toRaw(this.marker).setLatLng(coordinates);
        return;
      }
      this.marker = markerUtils.createDraggableBleAnchor(coordinates);
      this.marker.on('drag', (event) =>
        this.createTempShape(event.latlng, this.bleAnchorRadius),
      );
      this.marker.on('dragend', (event) => {
        const latLng = event.target.getLatLng();
        this.$emit('update:bleAnchorCoords', latLng);
      });
      toRaw(this.marker).addTo(toRaw(this.map));
    },

    createTempShape(coordinates, radius) {
      if (this.shape) {
        toRaw(this.map).removeLayer(this.shape);
        this.shape = null;
      }
      this.shape = shapeUtils.createBleShape(coordinates, radius);
      toRaw(this.shape).addTo(toRaw(this.map));
    },

    listenMapClick() {
      toRaw(this.map)
        .on('click', (event) => {
          if (this.dbClickTimer !== null) {
            return;
          }
          const latLng = event.latlng;
          this.dbClickTimer = setTimeout(() => {
            this.dbClickTimer = null;
            this.createTempAnchor(latLng);
            this.createTempShape(latLng, this.bleAnchorRadius);
            this.$emit('update:bleAnchorCoords', latLng);
          }, 200);
        })
        .on('dblclick', () => {
          clearTimeout(this.dbClickTimer);
          this.dbClickTimer = null;
        });
    },
    listenMove() {
      toRaw(this.map).on('moveend', () => {
        const center = Object.values(toRaw(this.map).getCenter());
        const zoom = toRaw(this.map).getZoom();
        this.$emit('update:mapViewOptions', { center, zoom });
      });
    },
  },
};
</script>

<style></style>
