<template>
  <div class="z-30 flex h-full w-full items-center justify-center">
    <UISpinner v-bind="$props" />
  </div>
</template>

<script>
import UISpinner from './UISpinner.vue';

export default {
  components: { UISpinner },
  name: 'UILoader',
  props: {
    ...UISpinner.props,
  },
};
</script>
