<template>
  <VInput size="sm" type="text" label="Range Start" placeholder="001" />
  <VInput size="sm" type="text" label="Range End" placeholder="002" />
  <VInput size="sm" type="text" label="Command" placeholder="MODE#" />
  <VInput
    size="sm"
    type="text"
    label="Translation"
    placeholder="Config: update"
  />
  <VCheckbox>
    <span class="flex-1">Send as SMS</span>
  </VCheckbox>
  <VButton type="submit" class="w-full" variant="primary">
    Send Command
  </VButton>
</template>

<script>
export default {
  name: 'UtilitySendBulkCommandForm',
};
</script>
