<template>
  <div>
    <label
      v-if="label"
      class="mb-[3px] block text-gpx_xs font-medium text-gpx_gray-200"
      :for="id"
    >
      {{ label }}
      <span v-if="required" class="text-lgmx_red-200"> * </span>
    </label>
    <div class="flex">
      <input
        :id="id"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="modelValue.label"
        @blur="blur"
        :readonly="readonly"
        class="w-full rounded-[6px] border-0 bg-bg-3 px-[22px] py-[14px] text-gpx_sm text-lms_black-100 placeholder:text-gpx_gray-300 focus:outline-0 focus:ring-0"
        :class="[
          {
            '!bg-white drop-shadow-[2px_4px_8px_0_rgba(232,_234,_237,_0.2)]':
              light,
          },
          { 'bg-red-150': hasError },
          { 'cursor-not-allowed': disabled },
        ]"
      />
    </div>
  </div>
</template>

<script>
export default {
  emits: ['blur', 'update:modelValue', 'callback'],

  props: {
    id: String,
    light: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      required: false,
      default: '',
      type: String,
    },
    modelValue: {
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    if (this.readonly) return;
    this.initGooglePlacesAutocomplete();
  },

  methods: {
    change(event) {
      this.$emit('update:modelValue', {
        label: event.target.value,
        lat: null,
        lng: null,
      });
    },
    initGooglePlacesAutocomplete() {
      const input = document.getElementById(this.id);
      const autocomplete = new window.google.maps.places.Autocomplete(input, {
        fields: ['geometry', 'formatted_address', 'address_components'],
      });
      input.addEventListener('keydown', function (e) {
        if (e.keyCode == 13) {
          e.preventDefault();
        }
      });
      window.google.maps.event.addListener(
        autocomplete,
        'place_changed',
        () => {
          const place = autocomplete.getPlace();
          const lat = place.geometry?.location.lat();
          const lng = place.geometry?.location.lng();
          const fullAddress = {
            label: place.formatted_address,
            lat,
            lng,
            place,
          };
          this.$emit('update:modelValue', fullAddress);
          this.$emit('callback', fullAddress);
        },
      );
    },
  },
};
</script>
