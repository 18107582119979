<template>
  <div
    class="flex w-full flex-col items-center justify-center gap-3 rounded-lg bg-gpx_gray-900 py-10"
  >
    <div
      class="flex h-14 w-14 items-center justify-center rounded-full bg-white"
    >
      <AdjustmentsHorizontalIcon class="h-8 w-8" />
    </div>
    <p class="font-semibold">{{ main }}</p>
    <p v-if="sub" class="text-sm text-gpx_gray-300">{{ sub }}</p>
  </div>
</template>
<script>
import { AdjustmentsHorizontalIcon } from '@heroicons/vue/24/outline';
export default {
  components: {
    AdjustmentsHorizontalIcon,
  },
  props: {
    main: {
      type: String,
      required: false,
      default: 'We found nothing',
    },
    sub: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>
