<template>
  <router-link :to="`manage/subscriptions/${subscriptionId}`">
    <UIBadgeNew :variant="variant">
      <template #default>{{ label }}</template>
    </UIBadgeNew>
  </router-link>
</template>

<script>
export default {
  props: {
    subscriptionId: Number,
    subscriptionStatus: String,
    updatedAt: String,
  },

  computed: {
    variant() {
      if (this.subscriptionStatus === 'pending-renewal') return 'error';
      if (this.subscriptionStatus === 'pending') return 'default';
      if (!this.updatedAt) return 'default';
      return null;
    },
    label() {
      if (this.subscriptionStatus === 'pending-renewal') {
        return 'Pending Renewal';
      }
      if (this.subscriptionStatus === 'pending') return 'Provisioned';
      if (!this.updatedAt) return 'No Data';
      return null;
    },
  },
};
</script>
