<script>
import * as _ from 'lodash';
import { mapActions } from 'vuex';
import PaginatedTableVue from '@/components/_common/Data/mixins/PaginatedTable.vue';
import { formatTime } from '@/filters';

export default {
  props: {
    subscriptionId: {
      required: true,
      type: Number,
    },
  },

  mixins: [PaginatedTableVue],

  data() {
    return {
      title: 'Related Orders',
      subtitle: 'View related orders',
      columns: ['Order Number', 'Date', 'Status'],
      data: [],
    };
  },

  methods: {
    formatTime,
    ...mapActions({
      fetchOrdersBySubscriptionId: 'order/fetchBySubscriptionId',
    }),
    fetchOrders(params) {
      return this.fetchOrdersBySubscriptionId({
        subscriptionId: this.subscriptionId,
        params,
      });
    },
    fetchItems(pagination) {
      const params = _.assign({}, pagination);
      return this.fetchOrders(params).then((res) => {
        this.data = res.data.data;
        return res;
      });
    },
    itemsByIds(ids) {
      return ids.map((id) => this.data.find((item) => item.id === id));
    },
    formatItemIntoRow(order) {
      return {
        id: order?.id,
        number: `#${order?.id}`,
        date: formatTime(order?.created_at),
        status: {
          status: order?.status,
        },
        edit: false,
      };
    },
    onItemClick(item) {
      this.$router.push({
        name: 'ManageOrderView',
        params: { id: item.id },
      });
    },
  },
};
</script>

<style></style>
