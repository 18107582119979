<script setup lang="ts">
import { useStore } from 'vuex';
import Step3Map from './Step3Map.vue';
import UIGoogleInput from '@/components/ui/UIGoogleInput.vue';
import { computed, nextTick, onMounted, reactive, watch } from 'vue';

const store = useStore();

const emit = defineEmits(['onConfirm', 'onCancel', 'update:coordinates', 'update:geofence']);

const props = defineProps<{
  bleAnchor: any;
  initialMapViewOptions: any;
  initialAddressLabel: string;
}>();

const data = reactive({
  mounted: false,
  address: {},
  googleCoords: null,
  geofence: null,
  bleAnchorCoords: null,
  bleAnchorRadius: { value: 30, label: '30' },
  mapViewOptions: null,
  isFetchingGeofence: false,
});

const radiusOptions = computed(() => [
  { value: 30, label: '30' },
  { value: 40, label: '40' },
  { value: 50, label: '50' },
  { value: 60, label: '60' },
  { value: 70, label: '70' },
  { value: 80, label: '80' },
]);

watch(
  () => data.bleAnchorCoords,
  (n) => fetchNearestGeofence(n),
);

onMounted(() => {
  data.mapViewOptions = props.initialMapViewOptions;
  data.address = {
    label: props.initialAddressLabel,
    lat: 1,
    lng: 1,
  };
  if (props.bleAnchor?.lat && props.bleAnchor?.lng) {
    data.address.lat = props.bleAnchor.lat;
    data.address.lng = props.bleAnchor.lng;
    data.bleAnchorRadius = {
      value: props.bleAnchor.radius,
      label: props.bleAnchor.radius,
    };
    nextTick(function () {
      handleSelectAddress(data.address);
    });
  }
  data.mounted = true;
});

// findNearest: 'geofence/findNearest',

const fetchNearestGeofence = (latLng) => {
  data.isFetchingGeofence = true;
  data.geofence = {};
  store
    .dispatch('geofence/findNearest', { params: { ...latLng } })
    .then((res) => {
      if (!res) return;
      data.geofence = res.data.data;
    })
    .finally(() => (data.isFetchingGeofence = false));
};

const handleSelectAddress = (address) => {
  const latLng = { lat: address.lat, lng: address.lng };
  data.googleCoords = latLng;
  data.bleAnchorCoords = latLng;
};

const handleContinueClick = () => {
  emit('onConfirm', {
    geofence: data.geofence,
    address: data.address?.label || '-',
    coordinates: data.bleAnchorCoords,
    radius: data.bleAnchorRadius.value,
    mapViewOptions: data.mapViewOptions,
  });
};
</script>
<template>
  <div class="mb-[24px] rounded-b-[10px] bg-white px-[24px] sm:px-[40px]">
    <UIInput
      disabled
      class="mb-3"
      label="Geofence"
      placeholder="Geofence"
      :modelValue="data.geofence?.name"
      :isLoading="data.isFetchingGeofence"
    />
    <UIGoogleInput
      v-if="data.mounted"
      class="mb-3"
      id="address"
      name="address"
      label="Address"
      v-model="data.address"
      placeholder="Enter address or place a pin"
      @callback="handleSelectAddress"
    />
    <UISelect
      class="mb-5"
      label="Radius, feet"
      placeholder="30"
      :current="data.bleAnchorRadius"
      v-model="data.bleAnchorRadius"
      :options="radiusOptions"
      :disabled="!data.bleAnchorCoords"
      noCaret
    />
    <div
      class="relative mb-3 h-[260px] overflow-hidden rounded-md bg-slate-500"
    >
      <Step3Map
        id="step-3-map"
        :googleCoords="data.googleCoords"
        v-model:bleAnchorCoords="data.bleAnchorCoords"
        v-model:mapViewOptions="data.mapViewOptions"
        :bleAnchorRadius="data.bleAnchorRadius.value"
      />
    </div>

    <div class="flex justify-between gap-x-4 pb-[32px] pt-6">
      <UIButtonNew
        variant="tertiary"
        size="lg"
        class="w-[124px]"
        @click="$emit('onCancel')"
      >
        Back
      </UIButtonNew>
      <UIButtonNew
        :disabled="
          !data.bleAnchorCoords ||
          !data.bleAnchorRadius.value ||
          data.isFetchingGeofence
        "
        @click="handleContinueClick"
        class="w-full"
      >
        Continue
      </UIButtonNew>
    </div>
  </div>
</template>
