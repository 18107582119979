import * as L from 'leaflet';

export const createLegend = () => {
  const legend = L.control({ position: 'bottomleft' });

  legend.onAdd = function () {
    const div = L.DomUtil.create('div', 'legend');
    div.innerHTML += `
    <div class="md:flex bg-gray-100 px-2 py-[2px] text-[10px] sm:text-[11px] rounded-md -translate-x-1/2">
      <div class="mr-4 flex items-center">
        <div class="h-2 sm:h-3 w-3 mr-1 rounded-sm bg-green-500"></div>
        <div>Inside geofence</div>
      </div>
      <div class="mr-4 flex items-center">
        <div class="h-2 sm:h-3 w-3 mr-1 rounded-sm bg-blue-500"></div>
        <div>Connected</div>
      </div>
      <div class="mr-4 flex items-center">
        <div class="h-2 sm:h-3 w-3 mr-1 rounded-sm bg-gray-700"></div>
        <div>Missed last report</div>
      </div>
      <div class="mr-4 flex items-center">
        <div class="h-2 sm:h-3 w-3 mr-1 rounded-sm bg-yellow-600"></div>
        <div>Hasn't moved in 14 days</div>
      </div>
      <div class="mr-4 flex items-center">
        <div class="h-2 sm:h-3 w-3 mr-1 rounded-sm bg-red-750"></div>
        <div>Hasn't moved in 28 days</div>
      </div>
    </div>`;
    return div;
  };
  return legend;
};
