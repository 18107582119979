<template>
  <div class="group flex cursor-pointer items-center gap-x-[12px]">
    <div
      class="flex h-[32px] w-[32px] items-center justify-center rounded-full bg-lms_red-900"
    >
      <PlusIcon class="heroicon-stroke-w-1.8 h-[16px] w-[16px] text-white" />
    </div>
    <span
      class="text-gpx_xs font-semibold text-lms_black-100 transition-colors group-hover:text-lms_red-900"
    >
      {{ label }}</span
    >
  </div>
</template>

<script>
import { PlusIcon } from '@heroicons/vue/24/outline';

export default {
  name: 'RulesButton',
  components: { PlusIcon },
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
