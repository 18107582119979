<template>
  <UIInput
    :name="name"
    :modelValue="inputValue"
    @input="handleChange"
    @blur="handleBlur"
    :hasError="meta.touched && !meta.valid"
    :required="required"
    :v-maska="vMaska"
    :data-maska="dataMaska"
    :data-maska-tokens="dataMaskaTokens"
    :data-maska-eager="dataMaskaEager"
  />
</template>

<script>
import { useField } from 'vee-validate';
import { toRef } from 'vue';

export default {
  props: {
    value: [String, Number],
    name: String,
    validateFn: Function,
    required: Boolean,
    vMaska: String,
    dataMaska: String,
    dataMaskaTokens: String,
    dataMaskaEager: Boolean,
  },

  setup(props) {
    const name = toRef(props, 'name');

    const {
      value: inputValue,
      handleBlur,
      handleChange,
      meta,
    } = useField(name, props.validateFn, {
      initialValue: props.value,
    });

    return {
      handleChange,
      handleBlur,
      inputValue,
      meta,
    };
  },
};
</script>

<style></style>
