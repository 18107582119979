import _ from 'lodash';

import store from './index';
import schema from './schema';

import { normalize } from 'normalizr';
import {
  BATCH_ADD_OR_UPDATE,
  BATCH_REVERSED_ADD_OR_UPDATE,
  RESET_CONTROLLER,
  SET_CONTROLLER,
} from './mutation-types';

const itemExists = (items, item, key) => {
  return _.findIndex(items, (existing) => existing[key] === item[key]) > -1;
};

const listWithUpdatedItem = (items, item, key) => {
  return _.map(items, (existing) => {
    if (existing[key] === item[key]) {
      return Object.assign({}, existing, item);
    }
    return existing;
  });
};

export const updateItem = (state, item, key = 'id', node = 'items') => {
  if (itemExists(state[node], item, key)) {
    state[node] = listWithUpdatedItem(state[node], item, key);
  }
};

export const addOrUpdateItem = (
  state,
  item,
  key = 'id',
  node = 'items',
  options = {},
) => {
  if (itemExists(state[node], item, key)) {
    state[node] = listWithUpdatedItem(state[node], item, key);
  } else {
    if (options.reversed) {
      state[node] = [item, ...state[node]];
    } else {
      state[node] = [...state[node], item];
    }
  }
};

export const addOrUpdateItems = (
  state,
  items,
  key = 'id',
  node = 'items',
  options,
) => {
  _.each(items, (item) => addOrUpdateItem(state, item, key, node, options));
};

export const removeItem = (state, item, key = 'id', node = 'items') => {
  const index = _.findIndex(
    state[node],
    (existing) => existing[key] === item[key],
  );

  if (index > -1) {
    state[node].splice(index, 1);
  }
};

export const resolveIdAndParams = (param) => {
  return typeof param === 'object'
    ? [param.id, param.params || null]
    : [param, null];
};

export const processEntities = (items, entity, options = {}) => {
  if (!Array.isArray(items)) items = [items];

  const { result, entities } = normalize(items, [schema[entity]]);

  // retrieve the original sorting
  if (entities[entity]) {
    const mainDataRequested = entities[entity];
    entities[entity] = result.map((id) => {
      return mainDataRequested[id];
    });
  }

  _.each(entities, (items, name) => {
    let mutation = `${_.camelCase(name)}/${BATCH_ADD_OR_UPDATE}`;
    if (options.reversed) {
      mutation = `${_.camelCase(name)}/${BATCH_REVERSED_ADD_OR_UPDATE}`;
    }

    if (_.has(store._mutations, mutation)) {
      store.commit(mutation, items);
    }
  });
};

export const processAndReturn = (res, entity, options) => {
  if (_.isUndefined(res.data.data)) {
    throw Error('No data object in the response');
  }
  processEntities(res.data.data, entity, options);
  return res;
};

export const all = (state) => state.items;
export const byId = (state) => (id) => {
  return state.items.find((item) => item.id === id);
};
/**
 * get models by ids while preserving the order
 */
export const byIds = (state) => (ids) => {
  return ids.map((id) => state.items.find((item) => item.id === id));
};
export const controller = (state) => state.controller;

export const markAsArchivedAndReturn = ({ items }) => {
  return items.map((item) => ({
    ...item,
    archived_at: new Date().toISOString(),
  }));
};
export const markAsUnarchivedAndReturn = ({ items }) => {
  return items.map((item) => ({
    ...item,
    archived_at: null,
  }));
};

export const getAbortMutations = (entity) => ({
  [`${entity}/${SET_CONTROLLER}`]: (state, controller) =>
    (state.controller = controller),
  [`${entity}/${RESET_CONTROLLER}`]: (state) => (state.controller = null),
});

export const setAbortController = ({ entity, store }) => {
  let controller = store.getters['controller'];
  if (controller) {
    controller.abort();
    store.commit(`${_.camelCase(entity)}/${RESET_CONTROLLER}`);
  }
  controller = new AbortController();
  store.commit(`${_.camelCase(entity)}/${SET_CONTROLLER}`, controller);
  return controller;
};
