<template>
  <label class="inline-flex items-center gap-x-2">
    <input
      class="h-[13px] w-[13px] appearance-none rounded-[3px] border-gray-950 checked:bg-red-500 hover:border-gray-750 hover:checked:bg-red-750 focus:ring-red-400/25 focus:checked:bg-red-500 focus:hover:checked:bg-red-750"
      type="checkbox"
      :value="value"
      v-model="model"
    />
    <slot />
  </label>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    modelValue: Array,
  },

  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },

  methods: {
    handleChange(event) {
      this.$emit('update:modelValue', event.targe.value);
    },
  },
};
</script>

<style></style>
