const files = import.meta.globEager('./modules/*.js');

const modules = {};
for (const key in files) {
  const name = key.replace(/\.\/modules\/|\.js/g, '');
  const module = { ...files[key] };
  if (module.namespaced === undefined) {
    module.namespaced = true;
  }
  modules[name] = module;
}
export default modules;
