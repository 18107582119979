<template>
  <UIGoogleInput
    :id="name"
    v-model="inputValue"
    @blur="handleBlur"
    :hasError="meta.touched && !meta.valid"
    :required="required"
  />
</template>

<script setup>
import { useField } from 'vee-validate';
import { toRef, watch } from 'vue';
import UIGoogleInput from '../ui/UIGoogleInput.vue';

const emits = defineEmits(['callback']);
const props = defineProps({
  name: String,
  value: Object,
  validateFn: Function,
  required: Boolean,
});

const name = toRef(props, 'name');
const {
  value: inputValue,
  handleBlur,
  meta,
} = useField(name, props.validateFn, {
  initialValue: props.value || { label: '', lat: null, lng: null },
});

watch(inputValue, (n) => {
  emits('callback', n);
});
</script>
