import store from '@/store';
import { useDevice } from '@/composables/useDevice';
import { useGroup } from '@/composables/useGroup';
import { usePosition } from '@/composables/usePosition';

export async function fetchDeviceById(accountId, deviceId, params) {
  const { fetchByAccountIdAndId } = useDevice();
  const { addPosition } = usePosition();

  const result = await fetchByAccountIdAndId(accountId, deviceId, params);

  const position = result?.current_position;
  if (position) {
    position.accuracy = position.accuracy / 1000 ?? 1;
    addPosition(position);
  }

  return result;
}

export async function fetchPositionsByAccount(accountId) {
  const { fetchPositionsByAccount: fetchPositionsByAccountApi } = usePosition();

  return fetchPositionsByAccountApi(accountId);
}

export async function fetchGeofences({ accountId }) {
  return store.dispatch('geofence/fetchByAccountId', {
    accountId,
  });
}
export async function fetchGeofence({ accountId, id }) {
  return store.dispatch('geofence/fetchByAccountIdAndId', {
    accountId,
    id,
  });
}

export async function fetchBleAnchors({ accountId }) {
  return store.dispatch('bleAnchor/fetchByAccountId', { accountId });
}

export async function fetchGroups({ accountId }) {
  const { fetchByAccountId } = useGroup();
  return fetchByAccountId(accountId, {
    include: 'devices,devices.subscription',
  });
}

export async function fetchGroup({ accountId, id }) {
  const { fetchByAccountIdAndId } = useGroup();
  return fetchByAccountIdAndId(accountId, id, {
    include: 'devices,devices.subscription',
  });
}

export async function fetchDevicesAndIccids({ accountId }) {
  if (!accountId) return;
  const p = Promise.all([
    store.dispatch('simReplacement/fetchDevices', { accountId }),
    store.dispatch('simReplacement/fetchIccids', { accountId }),
  ]);
  return p;
}
