<template>
  <div class="fixed right-0 top-0 z-50 w-full bg-bg-1 text-gpx_xs font-medium">
    <div
      class="mx-auto flex w-full max-w-[1200px] items-center justify-between py-[8px]"
    >
      <div>
        <span class="font-normal text-gpx_gray-100">{{ label }}&nbsp;</span>
        <span>{{ value }}</span>
      </div>
      <div class="flex items-center gap-x-[16px]">
        <span>{{ desc }}</span>
        <a
          :href="button.route"
          class="inline-flex rounded-[6px] border border-lms_black-500 px-[19px] py-[10px]"
          target="_blank"
        >
          <span class="font-semibold text-lms_black-500">
            {{ button.value }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    desc: {
      type: String,
      default: null,
    },
    button: {
      type: Object,
      required: false,
      default: null,
    },
  },
};
</script>
