<template>
  <nav class="flex text-red-400">
    <template v-for="page in pages" :key="page">
      <button
        class="-ml-px border border-gray-400 px-3 py-2 text-gray-750"
        :class="{
          'rounded-l-[3px]': page === LEFT_PAGE,
          'rounded-r-[3px]': page === RIGHT_PAGE,
          'hover:bg-gray-300 hover:text-red-900': !isActive(page),
          'pointer-events-none bg-red-400 px-3 py-2 text-white': isActive(page),
        }"
        @click="handleClick(page)"
        :disabled="isActive(page)"
      >
        <template v-if="page === LEFT_PAGE">‹</template>
        <template v-else-if="page === RIGHT_PAGE">›</template>
        <template v-else>{{ page }}</template>
      </button>
    </template>
  </nav>
</template>
<script>
import { LEFT_PAGE, RIGHT_PAGE } from '@/config/constants';
export default {
  name: 'VPagination',
  emits: ['update:modelValue'],
  props: {
    totalPages: {
      type: Number,
      required: true,
    },
    modelValue: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return { LEFT_PAGE, RIGHT_PAGE };
  },
  computed: {
    pages() {
      return this.fetchPageNumbers({
        totalPages: this.totalPages,
        pageNeighbours: 1,
        currentPage: this.modelValue,
      });
    },
  },
  methods: {
    range(from, to, step = 1) {
      let i = from;
      const range = [];

      while (i <= to) {
        range.push(i);
        i += step;
      }

      return range;
    },
    fetchPageNumbers({ totalPages, pageNeighbours, currentPage }) {
      const totalNumbers = pageNeighbours * 2 + 3;
      const totalBlocks = totalNumbers + 2;

      if (totalPages <= totalBlocks) {
        return this.range(1, totalPages);
      }

      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
      let pages = this.range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = this.range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = this.range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    },
    isActive(page) {
      return page === this.modelValue;
    },
    handleClick(page) {
      if (page === LEFT_PAGE) {
        this.handlePrevClick();
        return;
      }

      if (page === RIGHT_PAGE) {
        this.handleNextClick();
        return;
      }

      this.handlePageClick(page);
    },
    handlePrevClick() {
      this.$emit('update:modelValue', this.modelValue - 1);
    },
    handleNextClick() {
      this.$emit('update:modelValue', this.modelValue + 1);
    },
    handlePageClick(page) {
      this.$emit('update:modelValue', page);
    },
  },
};
</script>
