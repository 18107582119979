<template>
  <div
    class="pl-6 pr-4 sm:w-4/12 sm:border-r sm:border-lgmx_gray-100 md:pl-12 md:pr-6"
  >
    <GeofenceDetailsNavBar class="h-[64px] sm:h-[48px] sm:translate-y-2" />
  </div>
  <div class="hidden justify-between px-4 py-2 sm:flex sm:w-8/12 md:pr-12">
    <div class="flex gap-2">
      <UIButtonNew size="sm" variant="tertiary" @click="onEditClick">
        Edit
      </UIButtonNew>
      <UIButtonNew size="sm" variant="destructive" @click="onDeleteClick">
        Delete
      </UIButtonNew>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import GeofenceDetailsNavBar from './GeofenceDetailsNavBar.vue';
import { COMMON_DELETE_ERROR, COMMON_DELETE_SUCCESS } from '@/config/constants';

export default {
  components: { GeofenceDetailsNavBar },

  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
      geofenceById: 'geofence/byId',
    }),
    id() {
      return +this.$route.params.id;
    },
    geofence() {
      return this.geofenceById(this.id);
    },
  },

  methods: {
    ...mapActions({
      openModal: 'modal/open',
      delete: 'geofence/delete',
    }),
    onEditClick() {
      this.$router.push({
        name: 'GeofenceEditView',
        params: { id: this.$route.params.id },
      });
    },
    onDeleteClick() {
      this.openModal({
        name: 'CommonConfirmationModal',
        params: {
          actionLabel: 'Delete',
          title: this.geofence.name,
          body: 'Are you sure you want to delete this geofence?',
          onOk: () => this.delete({ accountId: this.accountId, id: this.id }),
          callback: () => this.$router.replace({ name: 'GeofenceListView' }),
          successMessage: COMMON_DELETE_SUCCESS,
          errorMessage: COMMON_DELETE_ERROR,
        },
      });
    },
  },
};
</script>
