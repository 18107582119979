<template>
  <div
    class="inline-flex items-center gap-x-[2px] rounded-[6px] border px-[7px] py-[2px] text-[11px] font-medium leading-[18px]"
    :class="[
      {
        'border-lgmx_red-100 bg-lgmx_red-100 text-lgmx_red-300':
          variant === BADGE_VARIANT.error,
        'border-lgmx_yellow-100 bg-lgmx_yellow-100 text-lgmx_yellow-300':
          variant === BADGE_VARIANT.warning,
        'border-lgmx_green-100 bg-lgmx_green-100 text-lgmx_green-300':
          variant === BADGE_VARIANT.success,
        'border-lgmx_gray-200 bg-white text-lgmx_black-100':
          variant === BADGE_VARIANT.default,
        'border-lgmx_gray-200 bg-lgmx_gray-200 text-lgmx_black-100':
          variant === BADGE_VARIANT.unset,
      },
    ]"
  >
    <slot name="icon" />
    <slot />
  </div>
</template>

<script>
import { BADGE_VARIANT } from './utils';

export default {
  props: {
    variant: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      BADGE_VARIANT: BADGE_VARIANT,
    };
  },
};
</script>
