<template>
  <AppHeader />
  <div class="relative z-[1] flex w-full flex-col sm:flex-row">
    <ReportDetailsBar
      :isPublic="true"
      :isLoading="isDownloadingPDF"
      @downloadPDF="downloadPDF"
    />
  </div>
  <main class="sm:flex">
    <div
      class="hidden h-[calc(100dvh-32px)] overflow-scroll sm:block sm:h-[calc(100dvh-92px)] sm:w-4/12 md:h-[calc(100dvh-112px)]"
      :class="[{ '!block': mobileActiveTab === 'left' }]"
    >
      <CommonSearchBarSafeArea />
      <CommonCollapse title="Report Info" class="mb-1" :initial-open="true">
        <CommonProperty name="Tracker">{{ deviceName }}</CommonProperty>
        <CommonProperty name="From">{{ startAt }}</CommonProperty>
        <CommonProperty name="To">{{ endAt }}</CommonProperty>
      </CommonCollapse>
      <CommonCollapse
        title="Addresses"
        class="mb-1"
        contentClass="!p-0"
        :initial-open="true"
      >
        <template v-if="positionsHistory?.length" #button>
          <UITooltip text="Download spreadsheet" class="inline">
            <VButton
              class="ml-4"
              @click.stop="downloadAddresses"
              size="xs"
              variant="outline-white"
            >
              Download
            </VButton>
          </UITooltip>
        </template>
        <template v-if="positionsHistory?.length">
          <div class="max-h-[300px] overflow-y-scroll">
            <div
              v-for="address in positionsHistory"
              :key="address.timestamp"
              class="border-b border-[#dee2e6] px-5 py-[12px] odd:bg-[#f2f2f2] last-of-type:border-b-0"
            >
              <div>{{ formatReportDate(address.timestamp) }}</div>
              <div>{{ address.address }}</div>
            </div>
          </div>
        </template>
        <div v-else class="bg-[#f2f2f2] px-5 py-[12px]">
          No addresses for this date range
        </div>
      </CommonCollapse>
      <CommonCollapse title="Alerts" contentClass="!p-0" :initial-open="true">
        <template v-if="events.length" #button>
          <UITooltip text="Download spreadsheet">
            <VButton
              class="ml-4"
              @click.stop="downloadAlerts"
              size="xs"
              variant="outline-white"
            >
              Download
            </VButton>
          </UITooltip>
        </template>
        <template v-if="events.length">
          <div class="max-h-[300px] overflow-y-scroll">
            <div
              v-for="alert in events"
              :key="alert.id"
              class="border-b border-[#dee2e6] px-5 py-[12px] odd:bg-[#f2f2f2] last-of-type:border-b-0"
            >
              <div>{{ formatReportDate(alert.created_at) }}</div>
              <div>{{ alert.description }}</div>
              <div>{{ alert.event_type }}</div>
            </div>
          </div>
        </template>
        <div v-else class="bg-[#f2f2f2] px-5 py-[12px]">
          No alerts for this date range
        </div>
      </CommonCollapse>
    </div>
    <div
      class="hidden h-[calc(100dvh-32px)] sm:block sm:h-[calc(100dvh-92px)] sm:w-8/12 md:h-[calc(100dvh-112px)]"
      :class="[{ '!block': mobileActiveTab === 'right' }]"
    >
      <ReportMap
        :positionsHistory="positionsHistory"
        :stopsHistory="stopsHistory"
      />
    </div>
    <MobileLayoutSwitch type="report" />
  </main>
</template>

<script>
import * as _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import AppHeader from '@/components/_common/AppHeader.vue';
import ReportDetailsBar from '@/components/report/details/ReportDetailsBar.vue';
import CommonProperty from '@/components/_common/CommonProperty.vue';
import ReportMap from '@/components/report/map/ReportMap.vue';
import store from '@/store';
import { formatTime } from '@/filters';
import { REPORT_MESSAGE404 } from '@/config/constants';
import { setTitle } from '@/utils/title';
import { getFileName } from '@/utils/blob';
import CommonSearchBarSafeArea from '@/components/_common/CommonSearchBarSafeArea.vue';
import CommonCollapse from '@/components/_common/CommonCollapse.vue';
import MobileLayoutSwitch from '@/components/_common/MobileLayoutSwitch.vue';
import * as events from '@/events';

const toast = useToast();

export default {
  components: {
    ReportDetailsBar,
    CommonProperty,
    ReportMap,
    AppHeader,
    CommonSearchBarSafeArea,
    CommonCollapse,
    MobileLayoutSwitch,
  },

  data() {
    return {
      report: null,
      isDownloadingPDF: false,
    };
  },

  computed: {
    ...mapGetters({
      mobileActiveTab: 'report/mobileActiveTab',
    }),
    id() {
      return this.report.id;
    },
    uuid() {
      return this.$route.params.uuid;
    },
    accountId() {
      return this.report.device.account_id;
    },
    deviceName() {
      return _.get(this.report, 'device.name', 'No name');
    },
    startAt() {
      const startAt = _.get(this.report, 'start_at', null);
      if (startAt) {
        return this.formatReportDate(startAt);
      }
      return '-';
    },
    endAt() {
      const endAt = _.get(this.report, 'end_at', null);
      if (endAt) {
        return this.formatReportDate(endAt);
      }
      return '-';
    },
    events() {
      return _.get(this.report, 'events', []);
    },
    positionsHistory() {
      return _.get(this.report, 'positions', []);
    },
    stopsHistory() {
      return _.get(this.report, 'stops', []);
    },
  },

  beforeRouteEnter(to, from, next) {
    const uuid = to.params.uuid;

    store
      .dispatch('report/fetchByUuid', {
        uuid,
        params: {
          include: 'device',
        },
      })
      .then((res) => {
        next((vm) => {
          vm.report = res.data.data;
          setTitle(`Report ${vm.report.device.name}`);
        });
      })
      .catch(() => {
        toast.error(REPORT_MESSAGE404, { timeout: 15000 });
      });
  },

  mounted() {
    events.trigger(events.names.REPORT_VIEWED, {
      report: this.report,
    });
  },

  methods: {
    ...mapActions({
      download: 'report/publicDownload',
    }),
    downloadPDF() {
      this.isDownloadingPDF = true;
      this.download({
        uuid: this.uuid,
        type: 'pdf',
        params: {
          offset: new Date().getTimezoneOffset(),
        },
      }).finally(() => (this.isDownloadingPDF = false));
    },
    downloadAddresses() {
      this.download({
        uuid: this.uuid,
        type: 'positions',
        fileName: getFileName({
          prefix: `addresses_${this.deviceName}`,
          dateFrom: this.report.start_at,
          dateTo: this.report.end_at,
        }),
        params: {
          offset: new Date().getTimezoneOffset(),
        },
      });
    },
    downloadAlerts() {
      this.download({
        uuid: this.uuid,
        type: 'events',
        fileName: getFileName({
          prefix: `alerts_${this.deviceName}`,
          dateFrom: this.report.start_at,
          dateTo: this.report.end_at,
        }),
        params: {
          offset: new Date().getTimezoneOffset(),
        },
      });
    },
    formatReportDate(date) {
      if (!date) return '-';
      return formatTime(date, 'E, MMM do, yyyy h:mmaaa');
    },
  },
};
</script>
