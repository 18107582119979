<template>
  <div class="pt-[25px]">
    <div class="mb-[14px] text-[13px] leading-5 text-lgmx_black-200">
      Grab your new AT&T SIM card. You have three options for SIM sizes. You’ll
      want to pop out the smallest one that has the text on it.
    </div>

    <div class="mb-[18px] flex justify-center">
      <img :src="svg1" alt="prepare SIM card" />
    </div>

    <div class="mb-[14px] text-[13px] leading-5 text-lgmx_black-200">
      Your ICCID is a 19 or 20 digit number located on the front of the SIM
      card. Select the ICCID you'd like to use from the drop down and verify it
      matches the SIM card we sent you.
    </div>

    <div class="mb-[18px] flex justify-center">
      <img :src="svg2" alt="prepare ICCID" />
    </div>

    <div class="mb-4">
      <UICombobox
        label="Select SIM by ICCID"
        :options="options"
        v-model="innerIccid"
        openOnFocus
      />
    </div>
    <div class="mb-[6px] text-[11px] leading-4 text-lgmx_gray-900">
      You can fill in the last 4 digits of ICCID instead of a full number and
      choose the desired SIM from the dropdown.
    </div>

    <div class="flex justify-between gap-x-4 pb-[18px] pt-[26px]">
      <UIButtonNew
        variant="tertiary"
        size="lg"
        class="w-[124px]"
        @click="$emit('onCancel')"
      >
        Back
      </UIButtonNew>
      <UIButtonNew
        :disabled="!innerIccid"
        @click="handleConfirm"
        class="w-full"
      >
        Next
      </UIButtonNew>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Step4Svg1 from '../_common/assets/step-sim-1.svg';
import Step4Svg2 from '../_common/assets/step-sim-2.svg';

export default {
  emits: ['onConfirm', 'onCancel'],

  data() {
    return {
      innerIccid: null,
    };
  },

  computed: {
    ...mapGetters({
      iccids: 'simReplacement/iccids',
    }),
    options() {
      return this.iccids.map((item) => ({
        label: item,
        value: item,
      }));
    },
    svg1() {
      return Step4Svg1;
    },
    svg2() {
      return Step4Svg2;
    },
  },

  methods: {
    handleConfirm() {
      this.$emit('onConfirm', this.innerIccid);
    },
  },
};
</script>
