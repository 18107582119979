<template>
  <div class="flex items-end justify-evenly pb-4 pt-1">
    <div class="flex items-center">
      <select
        class="mr-1 rounded py-0 pl-[6px] pr-[26px] text-[10px] leading-5 focus:border-gray-600 focus:ring-0"
        :value="hours[0]"
        @change="updateFromHours"
      >
        <option v-for="h in hoursArray" :key="h.value" :value="h.value">
          {{ h.text }}
        </option>
      </select>
      <select
        class="rounded py-0 pl-[6px] pr-[26px] text-[10px] leading-5 focus:border-gray-600 focus:ring-0"
        :value="minutes[0]"
        @change="updateFromMinutes"
      >
        <option v-for="m in minutesArray" :key="m.value" :value="m.value">
          {{ m.text }}
        </option>
      </select>
    </div>
    <UITooltip text="Reset time">
      <VButton
        variant="outline-secondary"
        size="sm"
        class="!h-5 border-none !px-4 !text-[8px] focus:!ring-0"
        @click="resetTime"
      >
        ✕
      </VButton>
    </UITooltip>
    <div class="flex items-center">
      <select
        class="mr-1 rounded py-0 pl-[6px] pr-[26px] text-[10px] leading-5 focus:border-gray-600 focus:ring-0"
        :value="hours[1]"
        @change="updateToHours"
      >
        <option v-for="h in hoursArray" :key="h.value" :value="h.value">
          {{ h.text }}
        </option>
      </select>
      <select
        class="rounded py-0 pl-[6px] pr-[26px] text-[10px] leading-5 focus:border-gray-600 focus:ring-0"
        :value="minutes[1]"
        @change="updateToMinutes"
      >
        <option v-for="m in minutesArray" :key="m.value" :value="m.value">
          {{ m.text }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  emits: ['updateTime'],
  props: {
    updateTime: Function,
    hoursIncrement: { type: [Number, String], default: 1 },
    minutesIncrement: { type: [Number, String], default: 1 },
    is24: { type: Boolean, default: true },
    hoursGridIncrement: { type: [String, Number], default: 1 },
    minutesGridIncrement: { type: [String, Number], default: 5 },
    range: { type: Boolean, default: true },
    filters: { type: Object, default: () => ({}) },
    minTime: { type: Object, default: () => ({}) },
    maxTime: { type: Object, default: () => ({}) },
    timePicker: { type: Boolean, default: false },
    enableSeconds: { type: Boolean, default: true },
    hours: { type: [Number, Array], default: 0 },
    minutes: { type: [Number, Array], default: 0 },
    seconds: { type: [Number, Array], default: 0 },
    customProps: { type: Object, default: null },
  },
  setup(_, { emit }) {
    const hoursArray = computed(() => {
      const arr = [];
      for (let i = 0; i < 24; i++) {
        arr.push({ text: i < 10 ? `0${i}` : i, value: i });
      }
      return arr;
    });

    const minutesArray = computed(() => {
      const arr = [];
      for (let i = 0; i < 60; i++) {
        arr.push({ text: i < 10 ? `0${i}` : i, value: i });
      }
      return arr;
    });

    function updateFromHours($event) {
      emit('updateTime', [+$event.target.value, this.hours[1]], true, false);
    }
    function updateToHours($event) {
      emit('updateTime', [this.hours[0], +$event.target.value], true, false);
    }

    function updateFromMinutes($event) {
      emit('updateTime', [+$event.target.value, this.minutes[1]], false, false);
      emit('updateTime', [0, 0], false, true);
    }
    function updateToMinutes($event) {
      emit('updateTime', [this.minutes[0], +$event.target.value], false, false);
      emit('updateTime', [0, 0], false, true);
    }

    function resetTime() {
      emit('updateTime', [0, 23], true, false);
      emit('updateTime', [0, 59], false, false);
      emit('updateTime', [0, 59], false, true);
    }

    return {
      hoursArray,
      minutesArray,
      updateFromHours,
      updateToHours,
      updateFromMinutes,
      updateToMinutes,
      resetTime,
    };
  },
});
</script>

<style></style>
