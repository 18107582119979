import _ from 'lodash';

const processStrategy = (deviceMeta) => {
  const relations = {};
  const omit = [];
  return {
    ..._.omit(deviceMeta, omit),
    ..._.pickBy(relations, _.identity),
  };
};

export default {
  key: 'deviceMeta',
  definition: {},
  processStrategy,
};
