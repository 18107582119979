import * as _ from 'lodash';

export const state = {
  queue: [],
};

export const getters = {
  active: (state) => (state.queue.length > 0 ? state.queue[0] : null),
};

export const mutations = {
  OPEN: (state, { name, params }) => {
    state.queue.unshift({ name, params });
  },
  CLOSE: (state, name) => {
    state.queue = _.filter(state.queue, (modal) => modal.name !== name);
  },
};

export const actions = {
  open: (store, { name, params }) => {
    store.commit('OPEN', { name, params });
  },
  close: (store, name) => {
    store.commit('CLOSE', name);
  },
};
