import service from '@/services';

export const state = {
  devices: [],
  iccids: [],
};

export const getters = {
  devices: (state) => state.devices,
  iccids: (state) => state.iccids,
  canReplace: (state) => Boolean(state.iccids.length),
};

export const mutations = {
  SET_DEVICES: (state, items) => (state.devices = items),
  SET_ICCIDS: (state, items) => (state.iccids = items),
};

export const actions = {
  fetchDevices: (store, { accountId }) => {
    return service
      .get(`/accounts/${accountId}/sim-replacement/devices`)
      .then((res) => store.commit('SET_DEVICES', res.data.data));
  },
  fetchIccids: (store, { accountId }) => {
    return service
      .get(`/accounts/${accountId}/sim-replacement/iccids`)
      .then((res) => store.commit('SET_ICCIDS', res.data));
  },
  replace: (store, { accountId, params }) => {
    return service.post(`/accounts/${accountId}/sim-replace`, params);
  },
};
