<template>
  <button
    class="w-full rounded-[40px] py-[10px] text-center text-gpx_label font-semibold uppercase text-lms_black-100 drop-shadow-[2px_4px_4px_0_rgba(237,_239,_242,_1)] transition-colors duration-300 ease-out"
    :class="{ 'bg-white !text-lms_black-100': index === selected }"
    @click="select"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'UITab',
  props: {
    label: {
      type: String,
      required: true,
    },
    index: Number,
    selected: Number,
  },
  methods: {
    select() {
      this.$emit('select', this.index);
    },
  },
};
</script>
