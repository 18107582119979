<template>
  <BaseLayout>
    <div class="flex flex-wrap">
      <div class="mb-5 w-full sm:mb-0 sm:w-1/4">
        <h4 class="text-2xl font-medium leading-tight">Notifications</h4>
      </div>
      <div class="w-full sm:w-3/4 sm:max-w-[450px] sm:pl-8">
        <AlertsNotificationsForm />
      </div>
    </div>

    <VDivider class="mb-4 mt-9" />

    <div class="flex flex-wrap">
      <div class="mb-5 w-full sm:mb-0 sm:w-1/4">
        <h4 class="text-2xl font-medium leading-tight">Recent Alerts</h4>
      </div>
      <div class="w-full sm:w-3/4 sm:max-w-[630px] sm:pl-8">
        <div v-if="isLoading">Loading events...</div>
        <NoData
          v-else-if="!isLoading && !deviceEventsItems.length"
          title="No events yet"
        >
        </NoData>
        <template v-else>
          <DeviceEvent
            v-for="event in deviceEventsItems"
            :key="event.id"
            :description="event.description"
            :date="event.created_at"
            class="mb-2"
          />
          <CommonPagination
            :totalPages="totalPages"
            v-model="page"
            class="mt-4"
          />
        </template>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import { formatTimeAgo } from '@/filters';
import { mapGetters, mapActions } from 'vuex';
import BaseLayout from '@/layouts/BaseLayout.vue';
import VDivider from '@/components/ui/VDivider.vue';
import NoData from '@/components/_common/NoData.vue';
import CommonPagination from '@/components/_common/CommonPagination.vue';
import AlertsNotificationsForm from '@/components/alerts/_common/AlertsNotificationsForm.vue';
import DeviceEvent from '@/components/device/_common/DeviceEvent.vue';
import { setTitle } from '@/utils/title';

export default {
  components: {
    VDivider,
    NoData,
    BaseLayout,
    CommonPagination,
    AlertsNotificationsForm,
    DeviceEvent,
  },
  data() {
    return {
      isLoading: false,
      ids: [],
      page: 1,
      totalPages: 1,
    };
  },
  created() {
    setTitle('Alerts');
  },
  beforeMount() {
    this.loadDeviceEvents();
  },
  computed: {
    ...mapGetters({
      deviceEventsByIds: 'deviceEvent/byIds',
      accountId: 'auth/accountId',
    }),
    deviceEventsItems() {
      return this.deviceEventsByIds(this.ids);
    },
  },
  watch: {
    page() {
      this.loadDeviceEvents();
    },
  },
  methods: {
    formatTimeAgo,
    ...mapActions({
      fetchDeviceEvents: 'deviceEvent/fetchByAccountId',
    }),
    loadDeviceEvents() {
      this.isLoading = true;
      this.fetchDeviceEvents({
        accountId: this.accountId,
        params: {
          page: this.page,
          per_page: 10,
        },
      })
        .then((res) => {
          this.ids = res.data.data.map((item) => item.id);
          this.totalPages = res.data.meta.last_page;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
