<template>
  <div class="z-0 size-full bg-gray-500" id="map" />
</template>

<script>
import * as _ from 'lodash';
import { toRaw } from 'vue';
import L from 'leaflet';
import { antPath } from 'leaflet-ant-path';
import * as geojsonUtils from './utils/geo-json';
import * as antPathUtils from './utils/ant-path';
import * as circleUtils from './utils/circle';
import * as layerUtils from '../../../utils/map/layer';
import Geohash from '@/utils/geo-hash';
import { reverseCoords } from '@/utils/coords';
import { mapCenter } from '@/config/constants';

export default {
  props: {
    stopsHistory: Object,
    positionsHistory: Object,
  },

  data() {
    return {
      dbClickTimer: null,
      center: mapCenter,
      map: null,
      mainLayers: [],
      overlays: {},
      layerControl: null,
      tracksPath: null,
      trackPoints: L.layerGroup([]),
      tracksGroup: L.layerGroup([]),
      stopsGroup: L.layerGroup([]),
    };
  },

  watch: {
    positionsHistory: {
      handler(n, o) {
        if (_.isUndefined(n)) return;
        if (_.isEqual(n, o)) return;
        this.setTracksData();
      },
    },
    stopsHistory: {
      handler(n, o) {
        if (_.isUndefined(n)) return;
        if (_.isEqual(n, o)) return;
        this.setStopsData();
      },
    },
  },

  mounted() {
    this.setupLeafletMap();
    this.setTracksData();
    this.setStopsData();
  },

  methods: {
    setupLeafletMap() {
      const mainLayers = layerUtils.getLayers();
      const { id } = layerUtils.useLocalStorageLayer();
      this.mainLayers = mainLayers;

      const overlays = {
        'Show tracks': toRaw(this.tracksGroup),
        'Show stops': toRaw(this.stopsGroup),
      };
      this.overlays = overlays;
      this.map = L.map('map', {
        layers: [mainLayers[id]],
        center: toRaw(this.center),
        zoomControl: false,
        zoom: 5,
        minZoom: 1,
      });

      L.control.zoom({ position: 'topright' }).addTo(toRaw(this.map));

      this.layerControl = L.control
        .layers(toRaw(this.mainLayers), toRaw(overlays), {
          position: 'topleft',
        })
        .addTo(toRaw(this.map));

      this.setupTracksOverlay();
      this.setupStopsOverlay();

      this.listenOverLayChange(overlays);
      this.listenLayerChange(toRaw(this.mainLayers));
    },
    setupTracksOverlay() {
      const { is } = layerUtils.useLocalStorageOverlay('tracks');
      if (is) {
        toRaw(this.tracksGroup).addTo(toRaw(this.map));
      }
    },
    setupStopsOverlay() {
      const { is } = layerUtils.useLocalStorageOverlay('stops');
      if (is) {
        toRaw(this.stopsGroup).addTo(toRaw(this.map));
      }
    },

    setTracksView(is) {
      if (is) {
        toRaw(this.map).fitBounds(toRaw(this.tracksPath).getBounds());
      }
    },

    setPositionFixes(positionsHistory) {
      const currentFixes = {};
      const fixes = L.featureGroup([]);

      positionsHistory.forEach((position) => {
        const [lat, lng] = reverseCoords(position.location);
        const hash = Geohash.encode(lat, lng, 9);
        if (!currentFixes[hash]) {
          if (position.fix === 'cell') {
            currentFixes[hash] = true;
            const fix = circleUtils.createCellCircle(position);
            fixes.addLayer(fix);
          }
          if (position.fix === 'wifi') {
            currentFixes[hash] = true;
            const fix = circleUtils.createWifiCircle(position);
            fixes.addLayer(fix);
          }
        }
      });
      fixes.addTo(toRaw(this.tracksGroup));
    },

    setTracksData() {
      const map = toRaw(this.map);
      const mainLayers = toRaw(this.mainLayers);
      if (_.isEmpty(this.positionsHistory)) {
        toRaw(this.tracksGroup).clearLayers();
        toRaw(this.trackPoints).clearLayers();
        return;
      }

      toRaw(this.tracksGroup).clearLayers();
      const coordsForAntPath = this.positionsHistory.map((h) =>
        reverseCoords(h.location),
      );
      this.tracksPath = antPath(coordsForAntPath, {
        ...antPathUtils.getStyle(toRaw(this.map), toRaw(this.mainLayers)),
        delay: 4e3,
        weight: 4,
        hardwareAccelerated: true,
      });

      this.setPositionFixes(this.positionsHistory);
      const featureCollection = geojsonUtils.convertToPositionPointFC({
        path: this.positionsHistory,
      });
      this.trackPoints = L.geoJSON(featureCollection, {
        onEachFeature: geojsonUtils.onEachPositionPointFeature,
        pointToLayer: (point, latlng) =>
          geojsonUtils.createPosition(point, latlng, { map, mainLayers }),
      });
      toRaw(this.trackPoints).addTo(toRaw(this.tracksGroup));
      toRaw(this.tracksPath).addTo(toRaw(this.tracksGroup));
      this.setTracksView(true);
    },

    setStopsData() {
      if (_.isNil(this.stopsHistory)) return;
      if (_.isEmpty(this.stopsHistory)) {
        toRaw(this.stopsGroup).clearLayers();
        return;
      }
      toRaw(this.stopsGroup).clearLayers();

      const featureCollection = geojsonUtils.convertToStopCircleFC(
        this.stopsHistory,
      );
      this.stopPoints = L.geoJSON(featureCollection, {
        onEachFeature: geojsonUtils.onEachStopCircleFeature,
        pointToLayer: (point, latlng) => geojsonUtils.createStop(point, latlng),
      });
      toRaw(this.stopPoints).addTo(toRaw(this.stopsGroup));
    },

    /*
     * Map Listeners
     */
    listenLayerChange(layers) {
      const { set } = layerUtils.useLocalStorageLayer();
      toRaw(this.map).on('baselayerchange', (e) => {
        set(e.name);

        if (this.tracksPath) {
          const style = antPathUtils.getStyle(toRaw(this.map), layers);
          this.tracksPath.setStyle(style);
        }
        if (this.trackPoints) {
          toRaw(this.trackPoints).eachLayer(function (layer) {
            const { name, speed } = layer.feature.properties;
            if (name === 'Position') {
              layer.setStyle(geojsonUtils.getPositionStyle(e.name, speed));
            }
          });
        }
      });
    },
    listenOverLayChange() {
      const { set: setStopsValue } = layerUtils.useLocalStorageOverlay('stops');
      const { set: setTracksValue } =
        layerUtils.useLocalStorageOverlay('tracks');

      toRaw(this.map).on('overlayadd', (e) => {
        if (e.name === 'Show tracks') {
          setTracksValue(true);
          if (this.tracksPath) {
            this.setTracksView(true);
          }
        }
        if (e.name === 'Show stops') {
          setStopsValue(true);
        }
      });
      toRaw(this.map).on('overlayremove', (e) => {
        if (e.name === 'Show tracks') {
          setTracksValue(false);
        }
        if (e.name === 'Show stops') {
          setStopsValue(false);
        }
      });
    },
  },
};
</script>

<style></style>
