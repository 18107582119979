<template>
  <div class="relative flex">
    <input
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :type="type"
      :readonly="true"
      :class="inputClass"
      class="!rounded-r-none !border-r-0 !pr-14"
    />
    <VButton
      class="absolute right-0 !rounded-l-none"
      size="sm"
      variant="secondary"
      :disabled="disabled"
      @click="copyToClipboard"
      >{{ label }}</VButton
    >
  </div>
</template>

<script>
export default {
  name: 'VInputCopy',

  data() {
    return {
      label: 'Copy',
    };
  },

  emits: ['update:modelValue'],
  props: ['modelValue', 'type', 'size', 'readOnly', 'disabled'],

  computed: {
    inputClass() {
      return `${this.type} ${this.size}`;
    },
  },
  methods: {
    copyToClipboard() {
      const defaultLabel = this.label;
      if (window.isSecureContext && navigator.clipboard) {
        navigator.clipboard.writeText(this.modelValue);
        this.label = 'Copied!';
        setTimeout(() => {
          this.label = defaultLabel;
        }, 2000);
      } else {
        console.error('Connection is not secure.');
      }
    },
  },
};
</script>

<style scoped>
.text,
.tel,
.email,
.url,
.date {
  @apply text-ellipsis px-4 py-1 h-10 rounded-md border-gray-600 text-base text-gray-900 placeholder-gray-750 w-full shadow-inner transition-all outline-none focus:ring-red-400/25 focus:ring-3 focus:border-red-250;
}
.text:read-only,
.tel:read-only,
.email:read-only,
.url:read-only,
.date:read-only {
  @apply border-[#ced4da] bg-[#e9ecef] focus:ring-0;
}
.sm {
  @apply px-2 h-8 rounded-[3px] text-sm;
}
</style>
