import L from 'leaflet';
import * as _ from 'lodash';
import { shipmentStatusStrokeMap } from '@/config/constants';
import { formatSpeed, formatTime } from '@/filters';
import { getGoogleMapsLink } from '../../../../utils/map/google';

export function onEachPositionPointFeature(feature, layer) {
  if (feature.properties && feature.properties.popupContent) {
    layer.bindPopup(feature.properties.popupContent, {
      closeButton: false,
      minWidth: 200,
    });
  }
}

export function convertShipmentToGeoJson({ shipment }) {
  const { path, status } = shipment;
  return L.geoJSON(
    {
      type: 'LineString',
      coordinates: path.map((p) => p.location),
    },
    {
      style: {
        className: shipmentStatusStrokeMap[status],
        weight: 2,
        opacity: 0.8,
      },
    },
  );
}

export function convertToPositionPointFC({ path }) {
  const features = path.map((point) => ({
    geometry: {
      type: 'Point',
      coordinates: point.location,
    },
    type: 'Feature',
    properties: {
      popupContent: getPositionPopupContent(point),
      speed: formatSpeed(point.speed),
    },
    id: point.timestamp,
  }));
  return {
    type: 'FeatureCollection',
    features,
  };
}

function getPositionPopupContent(point) {
  const updatedAt = formatTime(point.timestamp, 'E MMM d, yyyy h:mm aaa');
  const speed = formatSpeed(point.speed);
  const fix = point.fix ?? 'unknown';
  const location = _.get(point, 'location', []);

  return `
  <div>
    <div class="text-[10px]">
      <div>
        <a href=${getGoogleMapsLink({
          lat: location[1],
          lng: location[0],
        })} target="_blank">${point.address}</a>
      </div>
      <div><span>${updatedAt}</span>,
      <span>${speed}</span>mph
      <span>(${fix})</span>
      </div>
    </div>
  </div>
  `;
}

export function createPosition(point, latlng) {
  const { speed } = point.properties;
  const fillColor = speed <= 0.5 ? '#C10202' : speed <= 4 ? '#FF9A35' : '#222';
  return L.circleMarker(latlng, {
    stroke: true,
    color: '#fff',
    opacity: 0.9,
    weight: 1,
    radius: 6,
    fill: true,
    fillColor,
    fillOpacity: 0.8,
    pane: 'markerPane',
  });
}
