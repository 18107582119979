<template>
  <CommonGoBack label="All Reports" @click="goBack" />
</template>

<script setup>
import { useRouter } from 'vue-router';
import CommonGoBack from '@/components/_common/CommonGoBack.vue';

const router = useRouter();
function goBack() {
  router.push({
    name: 'ReportListView',
  });
}
</script>
