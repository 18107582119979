<template>
  <div
    v-if="shouldShow"
    class="flex min-w-[19px] items-center justify-center rounded-md bg-bg-1 px-[6px] text-[11px] font-medium leading-5 text-lgmx_slate-800"
  >
    {{ count }}
  </div>
</template>

<script>
export default {
  props: {
    count: Number,
  },
  computed: {
    shouldShow() {
      return typeof this.count === 'number';
    },
  },
};
</script>
