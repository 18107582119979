<template>
  <div
    v-if="isImitating"
    class="absolute right-0 top-0 z-[1000] inline-flex w-full justify-center bg-bg-1/70 text-xs font-medium transition-all hover:backdrop-blur-sm"
  >
    <div class="flex transition-all hover:py-[22px]">
      <div class="hidden sm:mr-5 sm:block">
        <span class="font-normal text-gpx_gray-100">Imitating User:&nbsp;</span>
        <span>{{ imitateDisplayName }}</span>
      </div>
      <button
        :disabled="isDestroyingImitation"
        @click="destroyImitation"
        class="px-10 text-gpx_porto-300 hover:text-gpx_porto-600"
      >
        <span class="flex items-center">
          <UISpinnerNew
            v-show="isDestroyingImitation"
            size="xs"
            variant="porto"
            class="mr-1"
          />
          <ArrowRightOnRectangleIcon
            v-if="!isDestroyingImitation"
            class="mr-1 h-[14px]"
          />
          {{
            isDestroyingImitation ? 'Quitting Imitation...' : 'Quit Imitation'
          }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import { ArrowRightOnRectangleIcon } from '@heroicons/vue/24/outline';
import { getCookie } from '@/utils/cookie';

export default {
  components: {
    ArrowRightOnRectangleIcon,
  },

  data() {
    return {
      isDestroyingImitation: false,
    };
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    isImitating() {
      return getCookie('imitation_token') ? true : false;
    },
    imitateDisplayName() {
      return `${this.user.name ? this.user.name : ''} ${
        this.user.email ? '(' + this.user.email + ')' : ''
      }`;
    },
  },

  methods: {
    ...mapActions({
      logoutImitation: 'auth/logoutImitation',
    }),
    destroyImitation() {
      const toast = useToast();
      this.isDestroyingImitation = true;
      this.logoutImitation().catch(() => {
        this.isDestroyingImitation = false;
        toast.error('An error ocurred, please try again later');
      });
    },
  },
};
</script>

<style></style>
