<template>
  <div class="pt-[31px]">
    <div class="mb-[14px] flex justify-center">
      <img :src="svg1" alt="Put back the cover" />
    </div>

    <div class="text-[13px] leading-5 text-lgmx_black-200">
      Replace your car charger cover by inserting it and pushing down firmly.
    </div>

    <div class="flex justify-between gap-x-4 pb-[18px] pt-[26px]">
      <UIButtonNew
        variant="tertiary"
        size="lg"
        class="w-[124px]"
        @click="$emit('onCancel')"
      >
        Back
      </UIButtonNew>
      <UIButtonNew @click="$emit('onConfirm')" class="w-full">
        Next
      </UIButtonNew>
    </div>
  </div>
</template>

<script>
import Step6Svg1 from './assets/step-6.svg';

export default {
  emits: ['onConfirm', 'onCancel'],

  computed: {
    svg1() {
      return Step6Svg1;
    },
  },
};
</script>
