import { VITE_APP_CLOUDINARY_URL } from '@/config/environment';

/**
 * https://github.com/mayvenstudios/gpx-legacy-app/blob/master/src/utils/cloudinaryHandler.js
 */

export const getImageUrl = ({
  publicId,
  height = 0,
  width = 0,
  extension = '.png',
  crop = false,
  removeBackground = false,
  color = '', // Accepts hash or color name
  backgroundColor = '', // Accepts hash only
}) => {
  let modifiers = 'q_auto';
  if (color.length > 0) {
    if (color.indexOf('#') >= 0) {
      // remove hash and use hex value
      modifiers += `,e_colorize,co_rgb:${color.slice(1)}`;
    } else {
      modifiers += `,e_colorize,co_${color}`;
    }
  }
  if (crop) {
    modifiers += ',c_crop';
  }
  if (removeBackground) {
    modifiers += ',e_bgremoval';
  }
  const onlyOneDimensionProvided =
    (height > 0 && width === 0) || (width > 0 && height === 0);
  const formatDimension = (dim) => Math.floor(dim).toString();
  if (width > 0) {
    modifiers += `,w_${formatDimension(width)}`;
  }
  if (height > 0) {
    modifiers += `,h_${formatDimension(height)}`;
  }
  // When only one dimension then resize image to fill based on that dimension
  // Unless crop is provided then stick with crop
  if (!crop && onlyOneDimensionProvided) {
    modifiers += ',c_fill';
  }
  if (backgroundColor) {
    modifiers += `,b_rgb:${backgroundColor.slice(1)}`;
  }
  modifiers += '/';
  return VITE_APP_CLOUDINARY_URL + modifiers + publicId + extension;
};
