<template>
  <div
    class="pl-6 pr-4 sm:w-4/12 sm:border-r sm:border-lgmx_gray-100 md:pl-12 md:pr-6"
  >
    <GeofenceCreateNavBar class="h-[64px] sm:h-[48px] sm:translate-y-2" />
  </div>
</template>

<script>
import GeofenceCreateNavBar from './GeofenceCreateNavBar.vue';

export default {
  components: {
    GeofenceCreateNavBar,
  },
};
</script>
