<template>
  <VCombobox label="Customer" size="sm" placeholder="Select..." :options="[]" />
  <VInput size="sm" type="text" label="Subscription" />
  <VInput size="sm" type="text" label="Range Start" />
  <VInput size="sm" type="text" label="Range End" />
  <VButton type="submit" class="w-full" variant="primary">
    Assign Range
  </VButton>
</template>

<script>
import VCombobox from '@/components/ui/VCombobox.vue';
export default {
  name: 'UtilityAssignRangeForm',
  components: {
    VCombobox,
  },
};
</script>
