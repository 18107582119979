<template>
  <label class="inline-flex items-start gap-x-2">
    <input
      class="appearance-none rounded-[3px] border-gpx_gray-700 outline-none ring-transparent checked:bg-red-500 hover:checked:bg-red-750 focus:ring-0 focus:checked:bg-red-500 focus:hover:checked:bg-red-750"
      :class="[
        { 'mt-[5px] ': $slots.default },
        { 'h-[13px] w-[13px]': size === 'small' },
        { 'h-5 w-5': size === 'large' },
      ]"
      type="checkbox"
      :checked="modelValue"
      :mode="mode"
      @change="handleChange"
    />
    <slot />
  </label>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: 'boolean',
    },
    modelValue: {
      type: [Boolean, Number],
      default: false,
    },
    size: {
      type: String,
      validator(value) {
        return ['small', 'large'].includes(value);
      },
      default: 'small',
    },
  },

  computed: {
    value() {
      if (this.mode === 'boolean') {
        return Boolean(this.modelValue);
      }
      if (this.mode === 'number') {
        return Number(this.modelValue);
      }
      throw Error('Wrong mode');
    },
  },

  methods: {
    handleChange(event) {
      if (this.mode === 'boolean') {
        return this.$emit('update:modelValue', Boolean(event.target.checked));
      }
      if (this.mode === 'number') {
        return this.$emit('update:modelValue', Number(event.target.checked));
      }
      throw Error('Wrong mode');
    },
  },
};
</script>

<style></style>
