<template>
  <div class="inline-flex overflow-hidden rounded-md bg-lgmx_gray-100">
    <button
      v-for="value in values"
      :key="value"
      class="flex items-center py-1 pl-[3px] pr-1 first:pl-1"
      :class="[
        {
          'relative bg-lgmx_gray-100': value === modelValue,
        },
      ]"
      @click="$emit('update:modelValue', value)"
    >
      <span
        class="relative rounded-[4px] px-[10px] py-[3px] text-[13px] font-medium leading-4 text-lgmx_slate-600"
        :class="[
          {
            ' bg-white !text-lgmx_black-200 shadow-sm': value === modelValue,
          },
          {
            'after:absolute after:right-[-5px] after:top-1/2 after:h-[10px] after:w-px after:bg-lgmx_gray-300 after:-translate-y-1/2':
              value !== modelValue,
          },
        ]"
      >
        {{ value }}
      </span>
    </button>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: [Number, String],
      required: true,
    },
    values: {
      type: Array,
      validator: (value) => {
        return value.length && value.every((v) => Boolean(v));
      },
    },
  },
};
</script>
