import _ from 'lodash';

const processStrategy = (simReplacement) => {
  const relations = {};
  const omit = [];
  return {
    ..._.omit(simReplacement, omit),
    ..._.pickBy(relations, _.identity),
  };
};

export default {
  key: 'simReplacement',
  definition: {},
  processStrategy,
};
