<template>
  <CommonGoBack :label="backLabel" @click="goBack" />
</template>

<script setup>
import { usePrevRoute } from '@/use/use-prev-route';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import CommonGoBack from '@/components/_common/CommonGoBack.vue';
import { useDevice } from '@/composables/useDevice';

const router = useRouter();
const store = useStore();
const prevRoute = usePrevRoute();
const { getById } = useDevice();

const backLabel = computed(() => {
  if (prevRoute.name === 'DeviceDetailsView') {
    const deviceName = getById(+prevRoute.params.id)?.name;
    return deviceName || 'Back';
  }

  return 'All Geofences';
});

function goBack() {
  if (prevRoute.name === 'DeviceDetailsView') {
    router.back();
    return;
  }
  router.push({
    name: 'GeofenceListView',
    query: { search: store.getters['geofence/search'] },
  });
}
</script>
