<template>
  <div
    class="inline-block whitespace-nowrap rounded px-2 py-1 text-[11px] font-medium capitalize leading-none text-white"
    :class="bgColor"
  >
    {{ label }}
  </div>
</template>

<script>
import { shipmentStatusColorMap } from '@/config/constants';

export default {
  name: 'VBadge',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    bgColor() {
      return shipmentStatusColorMap[this.status];
    },
    label() {
      return this.status.replace('_', ' ');
    },
  },
};
</script>
