<template>
  <div class="pb-[11px]">
    <div class="mb-[16px] flex justify-center pt-[30px]">
      <UISpinnerNew size="xl" variant="default" />
    </div>

    <div class="text-center text-[15px] leading-6 text-lgmx_black-400">
      We're swapping your SIM.
      <br />
      Please be patient as this can take a few minutes to confirm.
    </div>
  </div>
</template>

<script>
export default {
  emits: ['onConfirm', 'onCancel'],
};
</script>
