<script>
import * as _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { formatTime, formatMoney } from '@/filters';
import PaginatedTable from '@/components/_common/Data/mixins/PaginatedTable.vue';
import { useDevice } from '@/composables/useDevice';

export default {
  props: {
    orderId: {
      required: true,
      type: Number,
    },
  },
  mixins: [PaginatedTable],

  data() {
    return {
      title: 'Subscriptions',
      subtitle: 'View order subscriptions',
      columns: ['Subscription', 'Status', 'Next payment', 'Total'],
    };
  },

  computed: {
    ...mapGetters({
      itemsByIds: 'subscription/byIds',
      subscriptionById: 'subscription/byId',
    }),
  },

  setup() {
    const { getById } = useDevice();
    return {
      getById,
    };
  },

  methods: {
    formatTime,
    ...mapActions({
      fetchSubscriptionByOrderId: 'subscription/fetchByOrderId',
      openModal: 'modal/open',
    }),
    fetchSubscriptions(params) {
      return this.fetchSubscriptionByOrderId({ orderId: this.orderId, params });
    },
    fetchItems(pagination) {
      const params = _.assign({}, pagination, {
        include: 'devices',
      });
      return this.fetchSubscriptions(params);
    },
    formatItemIntoRow(subscription) {
      const device = this.getById(subscription.devices[0]?.id);
      return {
        id: subscription.id,
        name: device?.name,
        status: {
          status: subscription.status,
        },
        nextRenewal: subscription.renewal_date
          ? this.formatTime(subscription.renewal_date)
          : '-',
        mee: '$' + formatMoney(subscription.actual_price),
      };
    },
    onItemClick(item) {
      this.$router.push({
        name: 'ManageSubscriptionView',
        params: { id: item.id },
      });
    },
  },
};
</script>
