<template>
  <div class="flex flex-col items-center">
    <UIIndicator variant="success" class="mb-[21px] pt-[2px]" />
    <div class="mb-[12px] text-center text-[19px] font-semibold leading-6">
      Your SIM has been successfully swapped!
    </div>
    <div class="text-center text-[15px] leading-6 text-lgmx_gray-900">
      We have added 2 free audio hours to your account.
    </div>
    <div class="flex w-full justify-between gap-x-4 pb-[10px] pt-[26px]">
      <UIButtonNew
        variant="tertiary"
        size="lg"
        class="w-[124px]"
        @click="$emit('onCancel')"
      >
        Close
      </UIButtonNew>
      <UIButtonNew size="lg" @click="$emit('onConfirm')" class="w-full">
        Replace Next
      </UIButtonNew>
    </div>
  </div>
</template>
