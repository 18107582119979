/**
 * replace the new host name with the current host name and append tokens
 *
 * @param string redirectTo
 * @returns string
 */
export const redirectToWithToken = (redirectTo, token, account) => {
  const redirectToUrl = new URL(redirectTo);

  const currentUrl = new URL(document.location.href);
  currentUrl.host = redirectToUrl.host;
  currentUrl.searchParams.append('token', token);
  currentUrl.searchParams.append('account', account?.id);

  return currentUrl.toString();
};

export const getTokenFromURL = () => {
  const params = new URLSearchParams(window.location.search);

  if (!params.has('token')) {
    return false;
  }

  return params.get('token');
};
