import * as _ from 'lodash';
import { schema } from 'normalizr';

const files = import.meta.globEager('./schema/*.js');

const protoModules = {};
for (const key in files) {
  const module = { ...files[key] };
  const name = key.replace(/\.\/schema\/|\.js/g, '');

  const entity = new schema.Entity(
    module.default.key,
    {},
    { processStrategy: module.default.processStrategy },
  );
  protoModules[name] = {
    entity,
    definitionTemplate: module.default.definition,
  };
}

export default _.mapValues(protoModules, ({ entity, definitionTemplate }) => {
  const definition = {};
  _.each(Object.keys(definitionTemplate), (key) => {
    definition[key] = Array.isArray(definitionTemplate[key])
      ? [protoModules[definitionTemplate[key][0]].entity]
      : protoModules[definitionTemplate[key]].entity;
  });

  entity.define(definition);
  return entity;
});
