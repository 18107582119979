<template>
  <ModalLayout title="Order Payment" large>
    <div class="rounded-t-[10px] bg-white pt-[41px]">
      <div
        @click.prevent="close"
        class="absolute right-[24px] top-[36px] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full border border-gpx_gray-800 sm:right-[40px]"
      >
        <XMarkIcon
          class="heroicon-stroke-w-1.4 h-[20px] w-[20px] text-gpx_gray-200"
        />
      </div>
      <div
        class="mx-[24px] border-b border-b-gpx_gray-800 pb-[18px] sm:mx-[40px]"
      >
        <div class="mb-[2px] text-gpx_sm font-medium text-gpx_gray-200">
          Payment details
        </div>
        <div class="text-gpx_2xl font-semibold text-lms_black-500">
          Order #{{ order.id }}
        </div>
      </div>
      <div class="mt-[36px] px-[40px]">
        <UITabGroup class="w-full" :tabs="tabs" v-model="tabIndex" />
      </div>
      <div v-show="tabIndex === 0" class="mt-[26px]">
        <div class="flex flex-col gap-y-[3px] px-[24px] sm:px-[40px]">
          <div class="text-gpx_base font-semibold text-lms_black-400">
            Card Details
          </div>
          <div class="text-gpx_sm text-gpx_gray-100">
            Enter your payment details
          </div>
        </div>
        <div
          id="payment-form"
          class="mt-[22px] flex w-full flex-col px-[24px] sm:px-[40px]"
        />
      </div>
      <div v-if="tabIndex === 1" class="mt-[26px]">
        <div class="flex flex-col gap-y-[3px] sm:px-[40px]">
          <div class="text-gpx_base font-semibold text-lms_black-400">
            Bank Details
          </div>
          <div class="text-gpx_sm text-gpx_gray-100">
            Enter your payment details
          </div>
        </div>
        <div
          class="mt-[22px] flex flex-col gap-y-[27px] px-[24px] sm:px-[40px]"
        >
          <UIInput
            v-model="achRoutingNumber.value"
            :hasError="achRoutingNumber.hasError"
            label="Bank Routing Number"
          />
          <UIInput
            v-model="achAccountNumber.value"
            :hasError="achAccountNumber.hasError"
            label="Account Number"
          />
        </div>
      </div>

      <div class="mt-[27px] px-[24px] pt-[1px] sm:px-[40px]">
        <UICheckbox :label="acceptanceText" v-model="acceptance.value" />
      </div>
    </div>
    <div class="rounded-b-[10px] bg-white px-[24px] py-[32px] sm:px-[40px]">
      <button
        @click="approveOrder"
        role="button"
        class="inline-flex w-full justify-center rounded-[6px] bg-lms_red-900 px-[24px] py-[12px] text-gpx_button font-semibold text-white disabled:cursor-not-allowed sm:px-[40px]"
      >
        <template v-if="paymentInProgress">Payment in progress...</template>
        <template v-else>Submit Payment</template>
      </button>
    </div>
  </ModalLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import ModalLayout from '@/layouts/ModalLayout.vue';
import { formatMoney } from '@/filters';
import { VITE_APP_STRIPE_KEY } from '@/config/environment';
import { BILLING_TURN_MONTHLY } from '@/config/constants'

export default {
  name: 'OrderPayment',
  props: {
    order: {
      required: true,
      type: Object,
    },
    upfrontPayment: Object,
    regularPayment: Object,
    regularPaymentsNumber: Number,
  },
  components: {
    XMarkIcon,
    ModalLayout,
  },
  data: () => {
    return {
      paymentInProgress: false,
      stripe: null,
      stripeElements: null,
      acceptance: {
        value: false,
        hasError: false,
      },
      tabs: ['Credit Card', 'ACH'],
      tabIndex: 0,
      achAccountNumber: {
        value: '',
        hasError: false,
      },
      achRoutingNumber: {
        value: '',
        hasError: false,
      },
    };
  },
  mounted() {
    this.stripe = window.Stripe(VITE_APP_STRIPE_KEY);
    this.getPaymentIntent(this.order.id).then((paymentIntent) => {
      this.stripeElements = this.stripe.elements({
        clientSecret: paymentIntent.client_secret,
        appearance: {
          variables: {
            fontFamily: 'Inter, sans-serif',
            colorText: '#81959c',
            spacingGridRow: '27px',
            spacingGridColumn: '32px',
          },
          rules: {
            '.Label': {
              marginBottom: '3px',
              color: '#818c9c',
              fontSize: '12px',
              fontWeight: '500',
              letterSpacing: '0.01em',
              lineHeight: '18px',
            },
            '.Input': {
              padding: '14px 22px',
              border: 'none',
              borderRadius: '6px',
              backgroundColor: '#f4f4f6',
              color: '#3b3a3a',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '20px',
            },
          },
        },
      });
      this.paymentElement = this.stripeElements.create('payment');
      this.paymentElement.mount('#payment-form');
      this.loaded = true;
    });
  },
  methods: {
    ...mapActions({
      getPaymentIntent: 'orders/getPaymentIntent',
      requestACHPayment: 'orders/requestACHPayment',
    }),
    close() {
      this.$emit('close');
    },
    approveOrder() {
      if (!this.acceptance.value) {
        this.acceptance.hasError = true;
        return;
      }
      this.acceptance.hasError = false;

      if (this.tabIndex === 0) {
        this.handleStripePayment();
        return;
      }
      this.handleACHPayment();
    },
    handleStripePayment() {
      if (this.paymentInProgress) return false;
      this.paymentInProgress = true;

      this.stripe
        .confirmPayment({
          elements: this.stripeElements,
          confirmParams: {
            return_url: `${window.location.origin}/approve-order-success`,
          },
        })
        .then(() => {
          this.paymentInProgress = false;
        });
    },
    async handleACHPayment() {
      if (this.paymentInProgress) {
        return;
      }

      let hasError = false;
      if (this.achRoutingNumber.value.length === 0) {
        this.achRoutingNumber.hasError = true;
        hasError = true;
      }

      if (this.achAccountNumber.value.length === 0) {
        this.achAccountNumber.hasError = true;
        hasError = true;
      }

      if (hasError) {
        return;
      }

      this.paymentInProgress = true;
      try {
        await this.requestACHPayment({
          orderId: this.order.id,
          paymentId: this.nextPayment.id,
          params: {
            ach_routing_number: this.achRoutingNumber.value,
            ach_account_number: this.achAccountNumber.value,
            confirm: 1,
          },
        });
        window.location.replace('/approve-order-success');
      } catch (err) {
        console.log(err);
      }
      this.paymentInProgress = false;
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
    }),
    nextPayment() {
      return this.upfrontPayment || this.regularPayment;
    },
    acceptanceText() {
      let text = 'I agree to be charged ';
      if (this.upfrontPayment) {
        text += `$${formatMoney(this.upfrontPayment.amount)} up front`;
        if (this.regularPayment) text += ' and an additional ';
      }
      if (this.regularPayment) {
        let part = this.order.billingTerm === BILLING_TURN_MONTHLY ? 'monthly' : 'yearly';
        text += `${
          this.regularPaymentsNumber
        } ${part} payments of $${formatMoney(this.regularPayment.amount)}.`;
      }
      return text;
    },
  },
};
</script>
