<template>
  <div>
    <component
      v-if="activeModal"
      :is="activeModal.name"
      :params="activeModal.params"
      @close="close()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CommonConfirmationModal from '@/components/_common/modals/CommonConfirmationModal.vue';
import DownloadConfirmationModal from '@/components/_common/modals/DownloadConfirmationModal.vue';
import DeviceGroupModal from '@/components/device/modals/group/DeviceGroupModal.vue';
import DeviceSimReplaceModal from '@/components/device/modals/sim-replace/DeviceSimReplaceModal.vue';
import ManageAddressAddNewModal from '@/components/manage/address/modals/add/ManageAddressAddNewModal.vue';
import ManagePaymentAddNewModal from '@/components/manage/payment/modals/ManagePaymentAddNewModal.vue';
import ManageUserInviteModal from '@/components/manage/user/modals/ManageUserInviteModal.vue';
import ManageUserEditModal from '@/components/manage/user/modals/ManageUserEditModal.vue';
import ManageSubscriptionCancelModal from '@/components/manage/subscription/modals/ManageSubscriptionCancelModal.vue';
import ManageSubscriptionActivateModal from '@/components/manage/subscription/modals/ManageSubscriptionActivateModal.vue';
import ManageSubscriptionReactivateModal from '@/components/manage/subscription/modals/ManageSubscriptionReactivateModal.vue';
import ManageOrderInvoiceModal from '@/components/manage/order/modals/ManageOrderInvoiceModal.vue';
import ManageOrderPaymentModal from '@/components/manage/order/modals/ManageOrderPaymentModal.vue';
import UserAccountSwitchModal from '@/components/user-account/modals/switch/UserAccountSwitchModal.vue';
import DashboardsDwellHowItWorksModal from '@/components/dashboards/dwell/modals/DashboardsDwellHowItWorksModal.vue';
import GeofenceCreateBleAnchorModal from '@/components/geofence/modals/ble-anchor/GeofenceCreateBleAnchorModal.vue';
import DeviceDownloadPositionsModal from '@/components/device/modals/DeviceDownloadPositionsModal.vue';

export default {
  components: {
    CommonConfirmationModal,
    DownloadConfirmationModal,
    DeviceDownloadPositionsModal,
    DeviceGroupModal,
    ManageAddressAddNewModal,
    ManagePaymentAddNewModal,
    ManageUserInviteModal,
    ManageUserEditModal,
    ManageSubscriptionCancelModal,
    ManageSubscriptionActivateModal,
    ManageSubscriptionReactivateModal,
    ManageOrderInvoiceModal,
    ManageOrderPaymentModal,
    UserAccountSwitchModal,
    DeviceSimReplaceModal,
    DashboardsDwellHowItWorksModal,
    GeofenceCreateBleAnchorModal,
  },

  computed: {
    ...mapGetters({
      activeModal: 'modal/active',
    }),
  },

  methods: {
    ...mapActions({
      closeModal: 'modal/close',
    }),
    close() {
      this.closeModal(this.activeModal.name);
    },
  },
};
</script>
