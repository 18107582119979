<template>
  <div class="absolute bottom-[23px] right-[22px] flex opacity-90 sm:hidden">
    <UIButtonNew
      @click="click('left')"
      size="sm"
      variant="quinary"
      :disabled="mobileActiveTab === 'left'"
      class="w-full rounded-r-none border-r-transparent !p-[10px]"
    >
      <ListBulletIcon class="h-[18px]" />
    </UIButtonNew>
    <UIButtonNew
      @click="click('right')"
      size="sm"
      variant="quinary"
      :disabled="mobileActiveTab === 'right'"
      class="w-full rounded-l-none border-l-transparent !p-[10px] -translate-x-px"
    >
      <MapIcon class="h-[18px]" />
    </UIButtonNew>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
  },
  methods: {
    click(tab) {
      this.$store.dispatch(`${this.type}/setMobileActiveTab`, tab);
    },
  },
  computed: {
    mobileActiveTab() {
      return this.$store.getters[`${this.type}/mobileActiveTab`];
    },
  },
};
</script>
