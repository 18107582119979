<template>
  <div
    ref="buttonRef"
    class="relative"
    v-click-outside="() => (this.isOpen = false)"
  >
    <div class="flex">
      <UIButtonNew
        :size="size"
        :variant="variant"
        :icon="mainIcon"
        class="rounded-r-none border-r border-r-lgmx_gray-100"
        :class="[{ 'w-full': fullWidth }]"
        :disabled="disabled"
        @click="$emit('click')"
      >
        {{ mainLabel }}
      </UIButtonNew>
      <UIButtonNew
        :size="size"
        :variant="variant"
        :disabled="disabled"
        @click="isOpen = !isOpen"
        class="rounded-l-none !px-[10px]"
      >
        <XMarkIcon v-if="isOpen" class="h-[18px] w-[18px]" />
        <ChevronDownIcon v-else class="h-[18px] w-[18px]" />
      </UIButtonNew>
    </div>
    <div
      v-if="isOpen"
      class="absolute right-0 z-50 min-w-[200px] rounded-[6px] border border-gpx_gray-900 bg-white px-[14px] py-3 drop-shadow-ui"
      :class="[
        { 'top-full drop-shadow-ui': direction === 'bottom' },
        { 'top-0 drop-shadow-ui-inversed': direction === 'top' },
      ]"
      :style="translateYOffsetStyle"
    >
      <UITooltip
        v-for="item in options"
        :key="item.label"
        class="w-full"
        :text="item.tooltip"
        placement="bottom"
        :disabled="!item.tooltip"
      >
        <div
          :id="item.label"
          @click="doAction(item.action)"
          class="group flex w-full cursor-pointer rounded-[4px] px-[10px] py-[4px] hover:bg-bg-1"
        >
          <div class="mr-[12px]">
            <component
              :is="item.icon"
              class="heroicon-stroke-w-1.2 h-[20px] w-[20px] text-lgmx_slate-400 group-hover:text-lgmx_slate-600"
            />
          </div>
          <div
            class="whitespace-nowrap text-[13px] font-medium text-lgmx_black-300"
          >
            {{ item.label }}
          </div>
        </div>
      </UITooltip>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
import {
  ArrowDownOnSquareIcon,
  ChevronDoubleDownIcon,
  ChevronDownIcon,
  ClipboardDocumentListIcon,
  CurrencyDollarIcon,
  PauseIcon,
  PlayIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import UIButtonNew from './UIButtonNew/UIButtonNew.vue';

const containerHeight = 38;
const optionHeight = 32;

export default {
  components: {
    ArrowDownOnSquareIcon,
    ChevronDoubleDownIcon,
    ChevronDownIcon,
    ClipboardDocumentListIcon,
    CurrencyDollarIcon,
    PauseIcon,
    PlayIcon,
    PlusIcon,
    XMarkIcon,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  emits: ['click'],

  props: {
    ...UIButtonNew.props,
    disabled: {
      type: Boolean,
      default: false,
    },
    mainLabel: {
      type: String,
      required: true,
    },
    mainIcon: {
      type: String,
      default: 'SquaresPlusIcon',
    },
    options: {
      type: Array,
      required: true,
    },
    variant: {
      type: String,
    },
    fullWidth: Boolean,
  },

  data() {
    return {
      isOpen: false,
      direction: 'bottom',
    };
  },

  computed: {
    translateYOffsetStyle() {
      if (this.direction === 'bottom') {
        return 'transform: translateY(8px)';
      }
      if (this.direction === 'top') {
        return `transform: translateY(-${this.menuOffset}px)`;
      }
      return '';
    },
    windowHeight() {
      return window.innerHeight;
    },
    containerWithOptionsHeight() {
      return containerHeight + this.options.length * optionHeight;
    },
    menuOffset() {
      // for direction === bottom there is a translateY -8px
      return this.containerWithOptionsHeight + 8;
    },
    buttonBottomYCoords() {
      return this.$refs.buttonRef.getBoundingClientRect().bottom;
    },
  },
  mounted() {
    if (
      this.buttonBottomYCoords + this.containerWithOptionsHeight >
      this.windowHeight
    ) {
      this.direction = 'top';
    }
  },

  methods: {
    doAction(action) {
      this.isOpen = false;
      if (action.mode === 'modal') {
        this.$parent.$emit('open-modal', action.modal);
      }
      if (action.mode === 'route') {
        this.$router.push(action.route.replace(':id', this.editItem));
      }
      if (action.mode === 'fn') {
        return action.fn(this.editItem);
      }
    },
  },
};
</script>

<style></style>
