import { useIntervalFn } from '@vueuse/core';
import { AUTO_REFRESH_MS } from '@/config/constants';
import { usePersonalLocalStorage } from './use-personal-local-storage';

export function useAutoRefresh(fn) {
  if (typeof fn !== 'function') throw Error('Please provide a function');

  const isEnabled = usePersonalLocalStorage('auto-refresh', false);

  const { pause, resume } = useIntervalFn(
    () => {
      fn();
    },
    AUTO_REFRESH_MS,
    { immediate: isEnabled.value },
  );

  const enable = () => {
    isEnabled.value = true;
    resume();
  };
  const disable = () => {
    isEnabled.value = false;
    pause();
  };

  const forcedRefresh = () => {
    pause();
    fn().then(() => {
      if (isEnabled.value) {
        resume();
      }
    });
  };

  return {
    enable,
    disable,
    pause,
    resume,
    isEnabled,
    forcedRefresh,
  };
}
