export const SPINNER_SIZE = {
  xs: 'xs',
  sm: 'sm',
  base: 'base',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};
export const SPINNER_VARIANT = {
  default: 'default',
  white: 'white',
  red: 'red',
  porto: 'porto',
};
export const spinnerSizes = Object.keys(SPINNER_SIZE);
export const spinnerVariants = Object.keys(SPINNER_VARIANT);

export function getSpinnerSize(spinnerSize) {
  if (spinnerSize === SPINNER_SIZE.xs) return 14;
  if (spinnerSize === SPINNER_SIZE.sm) return 16;
  if (spinnerSize === SPINNER_SIZE.base) return 18;
  if (spinnerSize === SPINNER_SIZE.md) return 24;
  if (spinnerSize === SPINNER_SIZE.lg) return 32;
  if (spinnerSize === SPINNER_SIZE.xl) return 48;
  return 14;
}

export function getSpinnerStrokeWidth(spinnerSize) {
  if (spinnerSize === SPINNER_SIZE.xs) return 2;
  if (spinnerSize === SPINNER_SIZE.sm) return 2;
  if (spinnerSize === SPINNER_SIZE.base) return 2;
  if (spinnerSize === SPINNER_SIZE.md) return 2;
  if (spinnerSize === SPINNER_SIZE.lg) return 3;
  if (spinnerSize === SPINNER_SIZE.xl) return 4;
  return 2;
}

export function getSpinnerStrokeColorClass(spinnerVariant) {
  if (spinnerVariant === SPINNER_VARIANT.default) {
    return 'stroke-lgmx_slate-700';
  }
  if (spinnerVariant === SPINNER_VARIANT.white) {
    return 'stroke-white';
  }
  if (spinnerVariant === SPINNER_VARIANT.red) {
    return 'stroke-lgmx_terracota-800';
  }
  if (spinnerVariant === SPINNER_VARIANT.porto) {
    return 'stroke-gpx_porto-400';
  }
}
