<template>
  <ModalLayout title="New Payment Method" large>
    <div class="rounded-t-[10px] bg-white pt-[41px]">
      <div
        @click.prevent="$emit('close')"
        class="absolute right-[24px] top-[36px] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full border border-gpx_gray-800 sm:right-[40px]"
      >
        <XMarkIcon
          class="heroicon-stroke-w-1.4 h-[20px] w-[20px] text-gpx_gray-200"
        />
      </div>
      <div
        class="mx-[24px] border-b border-b-gpx_gray-800 pb-[18px] sm:mx-[40px]"
      >
        <div class="mb-[2px] text-gpx_sm font-medium text-gpx_gray-200">
          Adding new
        </div>
        <div class="text-gpx_2xl font-semibold text-lms_black-500">
          Payment Method
        </div>
      </div>
      <div class="mt-[30px] flex flex-col gap-y-[3px] px-[24px] sm:px-[40px]">
        <div class="text-gpx_base font-semibold text-lms_black-400">
          Card Details
        </div>
        <div class="text-gpx_sm text-gpx_gray-100">
          Enter your payment details
        </div>
      </div>
      <div
        class="mt-[26px] bg-white px-[24px] text-center sm:px-[40px]"
        id="card-element"
      >
        <UISpinner size="lg" class="mx-auto" />
      </div>
    </div>
    <div class="rounded-b-[10px] bg-white px-[24px] py-[32px] sm:px-[40px]">
      <button
        role="button"
        class="inline-flex w-full items-center justify-center space-x-2 rounded-[6px] bg-lms_red-900 px-[40px] py-[12px] text-gpx_button font-semibold text-white"
        :disabled="submitting"
        @click="handleCreatePaymentMethod"
      >
        <UISpinner size="sm" v-if="submitting" light />
        <span>{{ submitting ? 'Adding...' : 'Add Method' }}</span>
      </button>
    </div>
  </ModalLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import FiveHundredError from '@/components/_common/FiveHundredError.vue';
import ModalLayout from '@/layouts/ModalLayout.vue';
import { VITE_APP_STRIPE_KEY } from '@/config/environment';

const toast = useToast();

export default {
  components: {
    XMarkIcon,
    ModalLayout,
  },

  data() {
    return {
      countries: [],
      loadingStripeElement: false,
      submitting: false,
      stripe: null,
      stripeElements: null,
      cardElement: null,
    };
  },

  mounted() {
    this.loadingStripeElement = true;
    this.getPaymentMethodSetupIntent(this.accountId)
      .then(this.mountCardElement.bind(this))
      .finally(() => {
        this.loadingStripeElement = true;
      });
  },

  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
    }),
  },

  methods: {
    ...mapActions({
      createPaymentMethod: 'paymentMethod/create',
      getPaymentMethodSetupIntent: 'paymentMethod/create',
    }),
    mountCardElement(setupIntent) {
      this.stripe = window.Stripe(VITE_APP_STRIPE_KEY);
      this.stripeElements = this.stripe.elements({
        clientSecret: setupIntent.client_secret,
        appearance: {
          variables: {
            fontFamily: 'Inter, sans-serif',
            colorText: '#81959c',
            spacingGridRow: '27px',
            spacingGridColumn: '32px',
          },
          rules: {
            '.Label': {
              marginBottom: '3px',
              color: '#818c9c',
              fontSize: '12px',
              fontWeight: '500',
              letterSpacing: '0.01em',
              lineHeight: '18px',
            },
            '.Input': {
              padding: '14px 22px',
              border: 'none',
              borderRadius: '6px',
              backgroundColor: '#f4f4f6',
              color: '#3b3a3a',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '20px',
            },
          },
        },
      });
      this.cardElement = this.stripeElements.create('payment');
      this.cardElement.mount('#card-element');
    },
    handleCreatePaymentMethod() {
      if (!this.stripe) {
        toast.error(FiveHundredError, { timeout: 15000 });
        return;
      }
      this.submitting = true;
      this.stripe
        .confirmSetup({
          elements: this.stripeElements,
          confirmParams: {
            return_url: `${window.location.origin}/manage/payment`,
          },
        })
        .then((res) => {
          if (res.error) {
            toast.error(res.error.message);
            return;
          }
          toast.error('Payment method has been added successfully');
          this.$emit('close');
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    handleCountryChange(option) {
      this.country = option;
    },
  },
};
</script>
