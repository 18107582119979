<template>
  <CommonPaginator
    :total="props.total"
    :current-page="props.currentPage"
    :page-count="props.pageCount"
    :current-page-size="props.currentPageSize"
    :page-size-options="LIST_RESULTS_PER_PAGE"
    :is-first-page="props.isFirstPage"
    :is-last-page="props.isLastPage"
    @change-page-size="(pageSize) => emits('update:currentPageSize', pageSize)"
    @change-page="(page) => emits('update:currentPage', page)"
  />
</template>

<script setup>
import CommonPaginator from '@/components/_common/CommonPaginator.vue';
import { LIST_RESULTS_PER_PAGE } from '@/config/constants';

const emits = defineEmits(['update:currentPage', 'update:currentPageSize']);
const props = defineProps({
  total: {
    type: Number,
    required: true,
  },
  currentPage: {
    type: Number,
    required: true,
  },
  currentPageSize: {
    type: Number,
    required: true,
  },
  pageCount: {
    type: Number,
    required: true,
  },
  isFirstPage: Boolean,
  isLastPage: Boolean,
  prev: Function,
  next: Function,
});
</script>
