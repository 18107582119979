<template>
  <div class="flex rounded-[2px] bg-[#e6e6e6]">
    <div class="py-[3px] pl-[6px] pr-[3px] text-[13px] text-[#333333]">
      {{ label }}
    </div>
    <div
      class="group flex cursor-pointer items-center rounded-[2px] p-[4px] hover:bg-[#ffbdad]"
      @click="$emit('remove', value)"
    >
      <XMarkIcon
        class="h-[12px] w-[12px] text-black group-hover:text-[#de350b]"
      />
    </div>
  </div>
</template>

<script>
import { XMarkIcon } from '@heroicons/vue/24/outline';

export default {
  name: 'VRemove',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    label: String,
  },
  components: {
    XMarkIcon,
  },
};
</script>
