<template>
  <NotFoundLayout>
    <div
      class="relative flex h-[calc(100dvh-57px)] items-center justify-center overflow-hidden"
    >
      <div class="relative z-10 flex flex-col items-center">
        <div class="relative">
          <span
            class="text-shadow mb-[2px] select-none text-[120px] font-black leading-[145px] tracking-[.01em] text-white"
            style="
              font-feature-settings:
                'salt' on,
                'ss02' on;
            "
          >
            404
          </span>
          <span
            class="absolute left-0 top-0 z-10 select-none bg-maintenance-pattern bg-clip-text bg-repeat text-[120px] font-black leading-[145px] tracking-[.01em] text-transparent"
            style="
              font-feature-settings:
                'salt' on,
                'ss02' on;
            "
            >404</span
          >
        </div>
        <div
          class="mb-[10px] text-center text-gpx_2xl font-bold text-lms_black-500"
        >
          Looks like this page <br />
          does not exist
        </div>
        <div class="mb-[43px] text-gpx_sm text-lms_black-100">
          Maybe someday it will, but not now.
        </div>
        <UIButton
          label="Back to Home"
          @click="$router.push({ name: 'DeviceListView' })"
        />
      </div>
    </div>
  </NotFoundLayout>
</template>

<script>
import NotFoundLayout from '@/layouts/NotFoundLayout.vue';
import { setTitle } from '@/utils/title';
export default {
  components: {
    NotFoundLayout,
  },
  created() {
    setTitle();
  },
};
</script>
