import * as _ from 'lodash';
import service from '@/services';
import {
  addOrUpdateItems,
  controller,
  getAbortMutations,
  removeItem,
  setAbortController,
} from '../helpers';
import {
  ADD,
  BATCH_ADD_OR_UPDATE,
  BATCH_REVERSED_ADD_OR_UPDATE,
  REMOVE,
} from '../mutation-types';

export const state = {
  controller: null,
  items: [],
  sort: {
    sortField: null,
    sortOrder: null,
  },
};

export const getters = {
  controller,
  all: (state) => state.items,
  byId: (state) => (id) => state.items.find((item) => item.id === id),
  byIds: (state) => (ids) =>
    ids.map((id) => state.items.find((item) => item.id === id)),
  sort: (state) => state.sort,
};

export const mutations = {
  ...getAbortMutations('dashboardDwell'),
  [ADD]: (state, items) => (state.items = items),
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [BATCH_REVERSED_ADD_OR_UPDATE]: (state, items) =>
    addOrUpdateItems(state, items, undefined, undefined, { reversed: true }),
  [REMOVE]: (state, item) => removeItem(state, item),
  SET_SORT: (state, { sortField, sortOrder }) => {
    const field = sortField;
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : null;
    state.sort = { sortField: field, sortOrder: order };
  },
  ATTACH_DEVICES: (state, { geofenceId, devices }) => {
    const geofence = state.items.find((item) => item.id === geofenceId);
    if (geofence) {
      geofence.devices = devices;
    }
  },
  UPDATE_DWELL: (state, { field, update }) => {
    const geofence = state.items.find((item) => item.id === update.id);
    const fields = ['allowable_dwell_days', 'min_assets', 'max_assets'];
    const fieldsToExtract = fields.filter((item) => item !== field);
    const cleanedUpdate = _.omit(update, fieldsToExtract);

    if (geofence) {
      _.assign(geofence, cleanedUpdate);
    }
  },
};

export const actions = {
  setSort: (store, sort) => store.commit('SET_SORT', sort),
  fetchByAccountId: (store, { accountId, params }) => {
    const controller = setAbortController({
      entity: 'dashboardDwell',
      store,
    });

    return service
      .get(`/accounts/${accountId}/dwell/geofences`, {
        params,
        signal: controller.signal,
      })
      .then((res) => {
        if (res && res.status === 499) return res;
        store.commit(ADD, res.data.data);
        return res;
      });
  },
  fetchByGeofenceId: (store, { accountId, geofenceId, field, params }) => {
    return service
      .get(`/accounts/${accountId}/dwell/geofences/${geofenceId}`, {
        params,
      })
      .then((res) => {
        store.commit('UPDATE_DWELL', { field, update: res.data.data });
        return res;
      });
  },
  fetchDevicesByGeofenceId: (store, { accountId, geofenceId, params }) => {
    return service
      .get(`/accounts/${accountId}/dwell/geofences/${geofenceId}/devices`, {
        params,
      })
      .then((res) => {
        store.commit('ATTACH_DEVICES', { geofenceId, devices: res.data.data });
        return res;
      });
  },
};
