<template>
  <span class="relative">
    <button
      @click="isOpen = true"
      class="text-[13px] hover:cursor-pointer"
      :class="[
        { 'text-lgmx_slate-500': isOpen },
        {
          'text-lgmx_terracota-700 after:absolute after:bottom-[-3px] after:left-0 after:h-px after:w-full after:bg-lgmx_terracota-200':
            !isOpen,
        },
      ]"
    >
      {{ text }}
    </button>
    <div
      v-if="isOpen"
      v-click-outside="() => (isOpen = false)"
      class="absolute bottom-full left-0 w-[310px] rounded-md border border-gpx_gray-900 bg-white px-7 pb-[19px] pt-[23px] shadow-sm"
    >
      <UIIconButton
        tooltip="Close"
        icon="XMarkIcon"
        class="absolute right-2 top-2"
        @click="isOpen = false"
      />
      <div>
        <h3 class="mb-[7px] text-[15px] font-semibold text-lgmx_black-300">
          <slot name="title"></slot>
        </h3>
        <div class="text-[13px] font-normal text-lgmx_black-100">
          <slot name="description"> </slot>
        </div>
      </div>
      <slot />
    </div>
  </span>
</template>

<script>
import UIIconButton from '../ui/UIIconButton.vue';

export default {
  components: { UIIconButton },

  props: {
    text: {
      type: String,
      default: 'Help',
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },
};
</script>
