<script setup lang="ts">
import AppHeader from '@/components/_common/AppHeader.vue';
import DeviceMap from '@/components/device/map/DeviceMap.vue';
import MobileLayoutSwitch from '@/components/_common/MobileLayoutSwitch.vue';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useDevice } from '@/composables/useDevice.ts';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useGroup } from '@/composables/useGroup.ts';
import { useMap } from '@/composables/useMap.ts';

const mapView = ref('cluster');
const setMapView = (type) => {
  mapView.value = type;
};

const route = useRoute();

const deviceStore = useDevice();
const { mobileActiveTab } = storeToRefs(deviceStore);

const groupStore = useGroup();
const { searchedIds } = storeToRefs(groupStore);
const { getById } = groupStore;

const { setViewType } = useMap();

const isBottomButton = computed(() => {
  if (route.name === 'GroupListView') {
    if (searchedIds.value.length) {
      return true;
    }
  }
  if (route.name === 'GroupDetailsView') {
    const group = getById(+route.params.id);
    if (group?.devices_count > 0) {
      return true;
    }
  }
  return false;
});

function configureMap(view) {
  switch (view) {
    case 'DeviceListView':
      setMapView('cluster');
      setViewType('deviceView');
      break;
    case 'GroupListView':
      setMapView('cluster');
      setViewType('groupList');
      break;
    case 'GroupDetailsView':
      setMapView('cluster');
      setViewType('groupDetail');
      break;
    case 'DeviceDetailsView':
      setMapView('single');
      setViewType('deviceView');
      break;
    case 'GpxAdminDeviceDetailsView':
      setMapView('single');
      setViewType('deviceView');
      break;
  }
}

watch(
  () => route.name,
  (view) => {
    configureMap(view);
  },
);

onBeforeMount(() => {
  configureMap(route.name);
});
</script>

<template>
  <AppHeader />
  <div class="relative z-[1] flex w-full flex-col sm:flex-row">
    <router-view name="bar" />
  </div>
  <main class="sm:flex">
    <div
      class="h-layout-mobile sm:h-layout-tablet md:h-layout-desktop hidden overflow-scroll sm:block sm:w-4/12"
      :class="[
        { '!block': mobileActiveTab === 'left' },
        {
          'pb-[86px] md:pb-[102px]': isBottomButton,
        },
      ]"
    >
      <router-view name="default" />
    </div>
    <div
      class="h-layout-mobile sm:h-layout-tablet md:h-layout-desktop hidden sm:block sm:w-8/12"
      :class="[{ '!block': mobileActiveTab === 'right' }]"
    >
      <DeviceMap :view="mapView" />
    </div>
    <MobileLayoutSwitch type="device" />
  </main>
</template>
