<template>
  <ModalLayout title="Switch Account" large>
    <div class="rounded-t-[10px] bg-white pt-[41px]">
      <div
        @click.prevent="$emit('close')"
        class="absolute right-[24px] top-[36px] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full border border-gpx_gray-800 sm:right-[40px]"
      >
        <XMarkIcon
          class="heroicon-stroke-w-1.4 h-[20px] w-[20px] text-gpx_gray-200"
        />
      </div>
      <div
        class="mx-[24px] border-b border-b-gpx_gray-800 pb-[18px] sm:mx-[40px]"
      >
        <div class="mb-[2px] text-gpx_sm font-medium text-gpx_gray-200">
          Switching
        </div>
        <div class="text-gpx_2xl font-semibold text-lms_black-500">Account</div>
      </div>
      <div class="mt-[30px] flex flex-col gap-y-[3px] px-[24px] sm:px-[40px]">
        <div class="text-gpx_base font-semibold text-lms_black-400">
          Accounts List
        </div>
        <div class="text-gpx_sm text-gpx_gray-100">
          Select the account you have access to
        </div>
      </div>
      <div
        class="mt-[27px] px-[24px] sm:gap-x-[24px] sm:gap-y-[27px] sm:px-[40px]"
      >
        <UserAccountSwitchModalList
          :accounts="accounts"
          :selected="selected"
          @onChange="handleAccountChange"
        />
      </div>
    </div>
    <div class="rounded-b-[10px] bg-white pt-[40px] sm:pt-[64px]">
      <button
        role="button"
        class="inline-flex w-full justify-center rounded-b-[6px] bg-lms_red-900 px-[24px] py-[16px] text-gpx_sm font-semibold leading-[24px] text-white"
        @click="switchAccount"
      >
        Switch Account
      </button>
    </div>
  </ModalLayout>
</template>

<script>
import * as _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import ModalLayout from '@/layouts/ModalLayout.vue';
import UserAccountSwitchModalList from './_common/UserAccountSwitchModalList.vue';

export default {
  components: {
    ModalLayout,
    XMarkIcon,
    UserAccountSwitchModalList,
  },

  data() {
    return {
      selected: null,
    };
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      accountId: 'auth/accountId',
    }),
    accounts() {
      return _.map(this.user.accounts, (account) => {
        const userRole = this.user.account_roles
          ? this.user.account_roles[account.id]
          : '';
        return {
          id: account.id,
          name: account.title,
          desc: userRole,
        };
      });
    },
  },

  watch: {
    accountId: {
      handler(accountId) {
        this.selected = accountId;
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      setCurrentAccountId: 'auth/setCurrentAccountId',
    }),
    handleAccountChange(account) {
      this.selected = account.id;
    },
    switchAccount() {
      this.setCurrentAccountId({ accountId: this.selected });
      this.$emit('close');
      window.location.reload();
    },
  },
};
</script>
