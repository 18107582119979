<template>
  <ModalLayout title="Cancel Subscription" small>
    <div
      v-if="!isActivated"
      class="rounded-t-[10px] bg-white px-[24px] pt-[41px] sm:px-[40px]"
    >
      <div
        @click.prevent="$emit('close')"
        class="absolute right-[24px] top-[36px] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full border border-gpx_gray-800 sm:right-[40px]"
      >
        <XMarkIcon
          class="heroicon-stroke-w-1.4 h-[20px] w-[20px] text-gpx_gray-200"
        />
      </div>
      <div class="border-b border-b-gpx_gray-800 pb-[18px]">
        <div class="mb-[2px] text-sm font-medium text-gpx_gray-200">
          Activating
        </div>
        <div class="text-gpx_black-100 text-2xl font-semibold">
          Subscription
        </div>
      </div>
      <div class="mt-[25px] text-sm text-gpx_gray-100">
        Are you sure you want to activate your subscription?
      </div>
    </div>
    <div
      v-if="!isActivated"
      class="flex w-full gap-x-[16px] rounded-b-[10px] bg-white px-[24px] py-[32px] sm:px-[40px]"
    >
      <VButton
        type="submit"
        variant="outline-primary"
        class="w-full"
        v-if="!isActivating"
        @click="closeModal"
      >
        Cancel
      </VButton>
      <VButton
        type="submit"
        variant="primary"
        class="w-full"
        @click="handleActivateSubscription"
        :isDisabled="isActivating"
        :isLoading="isActivating"
      >
        Confirm
      </VButton>
    </div>
    <div
      v-if="isActivated"
      class="flex flex-col items-center rounded-[10px] bg-white pb-[32px] pt-[48px]"
    >
      <div
        class="mb-[17px] flex h-[48px] w-[48px] items-center justify-center rounded-full bg-gpx_green-300"
      >
        <CheckIcon
          class="heroicon-stroke-w-1.4 h-[32px] w-[32px] text-gpx_green-200"
        />
      </div>
      <div class="text-center text-sm font-medium text-gpx_gray-200">
        Your subscription was <br />
        successfully activated
      </div>
      <div class="w-full px-10 pt-8">
        <VButton type="submit" class="w-full" @click="closeModal">Okay</VButton>
      </div>
    </div>
  </ModalLayout>
</template>

<script>
import { XMarkIcon, CheckIcon } from '@heroicons/vue/24/outline';
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import ModalLayout from '@/layouts/ModalLayout.vue';

export default {
  components: {
    ModalLayout,
    XMarkIcon,
    CheckIcon,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  data() {
    return {
      isActivating: false,
      isActivated: false,
    };
  },
  computed: {
    ...mapGetters({
      activeModal: 'modal/active',
    }),
    subscriptionId() {
      return parseInt(this.activeModal.params.subscriptionId, 10);
    },
  },
  methods: {
    ...mapActions({
      updateSubscriptionStatus: 'subscription/updateStatus',
    }),
    handleActivateSubscription() {
      this.isActivating = true;
      this.updateSubscriptionStatus({
        id: this.subscriptionId,
        status: 'active',
      })
        .then(() => {
          this.toast.success('Subscription has been activated.');
          this.isActivating = false;
          this.isActivated = true;
        })
        .catch((err) => {
          this.isActivating = false;
          this.toast.error(err.response.data.message);
        });
    },
    closeModal() {
      this.isActivating = false;
      this.isActivated = false;
      this.$emit('close');
    },
  },
};
</script>
