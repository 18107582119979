export const BATCH_ADD_OR_UPDATE = 'batch_add_or_update';
export const BATCH_REVERSED_ADD_OR_UPDATE = 'batch_reversed_add_or_update';
export const ADD_OR_UPDATE = 'add_or_update';
export const UPDATE = 'update';
export const ADD = 'add';
export const REMOVE = 'remove';
export const SET_CURRENT_USER = 'set_auth_token';
export const SET_COUNT = 'set_count';
export const SET_DEFAULT = 'set_default';
export const UNSET_DEFAULT = 'unset_default';
export const SET_SEARCH = 'set_search';
export const SET_FILTER = 'set_filter';
export const UPDATE_RESULTS = 'UPDATE_RESULTS';
export const SET_ACTIVE_TAB = 'set_active_tab';
export const SET_MOBILE_ACTIVE_TAB = 'set_mobile_active_tab';
export const SET_SELECTED_IDS = 'set_selected_ids';
export const SET_CONTROLLER = 'set_controller';
export const RESET_CONTROLLER = 'reset_controller';
