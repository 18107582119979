<template>
  <div
    v-if="isB2B"
    class="cursor-pointer text-lg hover:text-lgmx_terracota-700 md:text-lgmx_15 md:text-lgmx_black-100"
    id="intercom_launcher"
  >
    <span @click="getHelpClicked" class="relative">
      Get Help
    </span>
  </div>
</template>

<script>
import * as events from '@/events';
import { is_b2b_platform } from '@/utils/b2b-account';
import { mapGetters } from 'vuex';
import Intercom, { show } from '@intercom/messenger-js-sdk';

export default {
  computed: {
    ...mapGetters({
      intercomHash: 'auth/intercomHash',
      user: 'auth/user',
    }),

    isB2B() {
      return is_b2b_platform;
    },
  },
  methods: {
    getHelpClicked() {
      events.trigger(events.names.GET_HELP_CLICKED);

      Intercom({
        app_id: 'py5j5uyx',
        user_id: this.user.id,
        name: this.user.name,
        email: this.user.email,
        created_at: this.user.created_at,
        user_hash: this.intercomHash,
      });

      show();
    },
  },
};
</script>
