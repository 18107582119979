<template>
  <div class="z-0 h-full w-full bg-gray-500" :id="id" />
</template>

<script>
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-draw';
import { toRaw } from 'vue';
import { mapGetters } from 'vuex';
import { mapCenter } from '@/config/constants';
import * as shapeUtils from './utils/shape';
import * as layerUtils from '../../../utils/map/layer';

export default {
  props: {
    id: String,
    isCustom: Boolean,
    geofence: Object,
    address: Object,
  },

  data() {
    return {
      center: mapCenter,
      map: null,
      mainLayers: [],
      shape: null,
      layerControl: null,
    };
  },

  watch: {
    isCustom: {
      handler(n, o) {
        if (n && this.shape) {
          toRaw(this.map).removeLayer(toRaw(this.shape));
        }
        if (n === false && o === true) {
          if (this.geofence) {
            this.setGeofenceView(this.geofence);
          }
        }
      },
    },
    address: {
      handler(n) {
        if (!n) return;
        if (!n.lat || !n.lng) return;
        this.setAddressView(n);
      },
    },
    geofence: {
      handler(n) {
        if (!n) {
          if (this.shape) {
            toRaw(this.map).removeLayer(toRaw(this.shape));
          }
          return;
        }
        this.setGeofenceView(n);
      },
    },
  },

  computed: {
    ...mapGetters({
      geofenceById: 'geofence/byId',
    }),
  },

  mounted() {
    this.setupLeafletMap();
  },

  methods: {
    createNewShape(newGeofence) {
      if (this.shape) {
        toRaw(this.map).removeLayer(toRaw(this.shape));
      }
      const id = newGeofence.value;
      const geofence = this.geofenceById(id);
      this.shape = shapeUtils.createShape({
        geofenceId: id,
        geofence,
      });
      toRaw(this.map).addLayer(toRaw(this.shape));
    },
    setAddressView(newAddress) {
      toRaw(this.map).flyTo([newAddress.lat, newAddress.lng], 15, {
        duration: 0.8,
      });
    },
    setGeofenceView(newGeofence) {
      this.createNewShape(newGeofence);
      const bounds = toRaw(this.shape).getBounds();
      toRaw(this.map).flyToBounds(bounds, {
        duration: 0.8,
      });
    },
    setupLeafletMap() {
      const mainLayers = layerUtils.getLayers();
      const { id } = layerUtils.useLocalStorageLayer();
      this.mainLayers = mainLayers;

      this.map = L.map(this.id, {
        layers: [toRaw(this.mainLayers)[id]],
        center: toRaw(this.center),
        zoomControl: false,
        zoom: 5,
        scrollWheelZoom: false,
      });

      L.control.zoom({ position: 'topright' }).addTo(toRaw(this.map));
      this.layerControl = L.control
        .layers(toRaw(this.mainLayers), null, { position: 'topleft' })
        .addTo(toRaw(this.map));
    },
  },
};
</script>

<style></style>
