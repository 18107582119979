<template>
  <div>
    <label
      v-if="label"
      class="mb-[3px] block text-xs font-medium text-gpx_gray-200"
      :for="id"
    >
      {{ label }}
    </label>
    <textarea
      :disabled="disabled"
      :id="id"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :readonly="readonly"
      class="text-gpx_black-500 max-h-[120px] min-h-[96px] w-full rounded-[6px] border-0 bg-bg-3 pb-[19px] pl-[22px] pr-[32px] pt-[17px] text-sm placeholder:text-gpx_gray-300 focus:outline-0 focus:ring-0 disabled:cursor-not-allowed disabled:text-gray-700"
      :class="{
        '!bg-white drop-shadow-[2px_4px_8px_0_rgba(232,_234,_237,_0.2)]': light,
      }"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
export default {
  name: 'UITextarea',
  emits: ['update:modelValue'],
  props: {
    disabled: Boolean,
    light: {
      required: false,
      default: false,
      type: Boolean,
    },
    placeholder: {
      required: false,
      default: '',
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      required: false,
      default: '',
      type: String,
    },
    label: {},
  },

  data() {
    return {
      id: null,
    };
  },

  mounted() {
    this.id = this._uid;
  },
};
</script>
