<template>
  <SlideBar :is-first-active="canUseBleFeature ? isSearchOpen : true">
    <template #first>
      <template v-if="activeTab === GEOFENCE_CATEGORY.geofences">
        <CommonListSearch
          ref="geofencesSearchRef"
          placeholder="Search Geofences..."
          v-model="geofencesSearchQuery"
          @close="closeGeofencesSearch"
        />
      </template>
      <template v-if="activeTab === GEOFENCE_CATEGORY.bleAnchors">
        <CommonListSearch
          ref="bleAnchorsSearchRef"
          placeholder="Search Anchors..."
          v-model="bleAnchorsSearchModel"
          @close="closeBleTagsSearch"
        />
      </template>
    </template>
    <template #second v-if="canUseBleFeature">
      <UITabs :items="tabs" v-model="activeTab" no-border />
      <UIIconButton
        v-if="activeTab === GEOFENCE_CATEGORY.geofences"
        icon="MagnifyingGlassIcon"
        class="ml-auto"
        tooltip="Search geofences"
        @click="openGeofencesSearch"
      />
      <UIIconButton
        v-if="activeTab === GEOFENCE_CATEGORY.bleAnchors"
        icon="MagnifyingGlassIcon"
        class="ml-auto"
        tooltip="Search Anchors"
        @click="openBleTagsSearch"
      />
    </template>
  </SlideBar>
</template>

<script setup>
import * as _ from 'lodash';
import { ref, watch, watchEffect, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouteQuery } from '@vueuse/router';
import SlideBar from '@/components/_common/SlideBar.vue';
import { GEOFENCE_CATEGORY } from '@/config/constants';
import * as events from '@/events';
import CommonListSearch from '@/components/_common/CommonListSearch.vue';

const store = useStore();
const canUseBleFeature = store.getters['auth/canUseBleFeature'];

// Tabs
const tabs = [
  { key: GEOFENCE_CATEGORY.geofences, label: 'Geofences' },
  { key: GEOFENCE_CATEGORY.bleAnchors, label: 'BLE Anchors' },
];
const activeTab = ref(store.getters['geofence/activeTab']);
watch(activeTab, (n) => {
  store.dispatch('geofence/setActiveTab', n);
});

// Geofences Search (with address bar query handling)
const geofencesSearchRef = ref(null);
const isGeofencesSearchOpen = ref(false);
const geofencesSearchQuery = useRouteQuery('search', '');
watchEffect(() => {
  if (canUseBleFeature) {
    geofencesSearchRef.value?.inputRef.focus();
  }
});
watch(geofencesSearchQuery, _.debounce(handleGeofencesSearch, 100), {
  immediate: true,
  flush: 'post',
});
function handleGeofencesSearch(value) {
  if (value && !isGeofencesSearchOpen.value) {
    openGeofencesSearch();
  }
  store.dispatch('geofence/setSearch', value);
  geofencesSearchQuery.value = value;
  events.trigger(events.names.GEOFENCE_SEARCHED, { query: value });
}
function openGeofencesSearch() {
  isGeofencesSearchOpen.value = true;
}
function closeGeofencesSearch() {
  isGeofencesSearchOpen.value = false;
  geofencesSearchQuery.value = '';
}

// BLE Anchors Search (without browser query)
const bleAnchorsSearchRef = ref(null);
const isBleTagsSearchOpen = ref(false);
const bleAnchorsSearchModel = ref(store.getters['bleAnchor/search']);
watch(bleAnchorsSearchModel, _.debounce(handleBleTagsSearch, 100), {
  immediate: true,
});
watchEffect(() => {
  bleAnchorsSearchRef.value?.inputRef.focus();
});
function handleBleTagsSearch(value) {
  store.dispatch('bleAnchor/setSearch', value);
}
function openBleTagsSearch() {
  isBleTagsSearchOpen.value = true;
}
function closeBleTagsSearch() {
  isBleTagsSearchOpen.value = false;
  bleAnchorsSearchModel.value = '';
}

const isSearchOpen = computed(
  () => isGeofencesSearchOpen.value || isBleTagsSearchOpen.value,
);
</script>
