<template>
  <div class="relative" v-click-outside="() => (this.isOpen = false)">
    <VButton
      :variant="variant"
      :size="size"
      :isDisabled="isDisabled"
      :isLoading="isLoading"
      @click="isOpen = !isOpen"
    >
      {{ label }}
    </VButton>
    <div
      class="absolute right-0 top-full z-50 min-w-[200px] rounded-[6px] border border-gpx_gray-900 bg-white px-[14px] pb-[18px] pt-[19px] drop-shadow-ui translate-y-[8px]"
      v-if="isOpen"
    >
      <div
        v-for="item in options"
        :key="item.label"
        @click="doAction(item.action)"
        class="flex cursor-pointer rounded-[4px] px-[10px] py-[6px] hover:bg-bg-2"
      >
        <div class="mr-[12px]">
          <component
            :is="item.icon"
            class="heroicon-stroke-w-1.2 h-[20px] w-[20px] text-gpx_gray-200 group-hover:text-gpx_porto-400"
          />
        </div>
        <div class="text-gpx_black-300 whitespace-nowrap text-sm font-medium">
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
import {
  PauseIcon,
  PlayIcon,
  XMarkIcon,
  PlusIcon,
  ArrowDownOnSquareIcon,
} from '@heroicons/vue/24/outline';
import VButton from './VButton.vue';

export default {
  components: {
    PauseIcon,
    PlayIcon,
    XMarkIcon,
    PlusIcon,
    ArrowDownOnSquareIcon,
    VButton,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    ...VButton.props,
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    doAction(action) {
      this.isOpen = false;
      if (action.mode === 'modal') {
        this.$parent.$emit('open-modal', action.modal);
      }
      if (action.mode === 'route') {
        this.$router.push(action.route.replace(':id', this.editItem));
      }
      if (action.mode === 'fn') {
        return action.fn(this.editItem);
      }
    },
  },
};
</script>
