import axios from 'axios';
import qs from 'qs';
import { useToast } from 'vue-toastification';
import router from '@/router';
import store from '@/store';
import { VITE_APP_API_URL } from '@/config/environment';
import FiveHundredError from '@/components/_common/FiveHundredError.vue';
import { getCookie } from '@/utils/cookie';
import { isWhiteListedURL } from './white-list-404';

const toast = useToast();

const publicService = axios.create({
  baseURL: `${VITE_APP_API_URL}/api`,
});

const service = axios.create({
  baseURL: `${VITE_APP_API_URL}/api`,
  withCredentials: true,
  timeout: 100000,
});

service.interceptors.request.use((config) => {
  const token = getCookie('token');
  const imitation_token = getCookie('imitation_token');

  const headers = {
    Accept: 'application/json',
    'Content-Type': config.headers || 'application/json',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  if (token) {
    headers.Authorization = config.headers.Authorization || `Bearer ${token}`;
  }

  // Imitation Token set from Admin App using the imitation feature.
  if (imitation_token) {
    headers.Authorization =
      config.headers.Authorization || `Bearer ${imitation_token}`;
  }

  return {
    ...config,
    headers,
  };
});

service.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === 'ERR_CANCELED') {
      return Promise.resolve({ status: 499 });
    }
    const { response = {} } = error;
    if (response.status === 401) {
      store.dispatch('auth/resetCurrentUser');
      router.replace({ name: 'LoginView' }).catch(() => {});
    }
    if (response.status === 403) {
      router.replace({ name: 'ForbiddenView' }).catch(() => {});
    }
    if (response.status === 404) {
      if (isWhiteListedURL(error?.config?.url)) return;
      router.replace({ name: 'NotFoundView' }).catch(() => {});
    }
    if (response.status === 500) {
      toast.error(FiveHundredError, { timeout: 15000 });
    }

    return Promise.reject(error);
  },
);

service.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, {
    indices: false,
    encode: false,
    skipNulls: true,
  });
};

export { publicService };
export default service;
