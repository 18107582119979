import { nextTick, ref } from 'vue';

/**
 * DynamicScroller stacking issue https://github.com/Akryum/vue-virtual-scroller/issues/319
 * Please see example of implementation on DeviceList or GroupList
 * Don't forget to add class "ds-${TYPE}-list" with "!overflow-visible" (style section)
 */
export function useSetItemIdOnDynamicList(type) {
  const editItemId = ref(null);
  function setEditItemId(id) {
    if (editItemId.value) {
      const element = document.querySelector(
        `#${type}-actions-${editItemId.value}`,
      );
      const dsItem = element.closest(`.ds-${type}-list-item`);
      dsItem.style.zIndex = 'auto';
    }
    editItemId.value = id;
    nextTick(() => {
      const element = document.querySelector(`#${type}-actions-${id}`);
      const dsItem = element.closest(`.ds-${type}-list-item`);
      dsItem.style.zIndex = '1';
    });
  }
  function resetEditItemId() {
    const element = document.querySelector(
      `#${type}-actions-${editItemId.value}`,
    );
    if (element) {
      const dsItem = element.closest(`.ds-${type}-list-item`);
      dsItem.style.zIndex = 'unset';
      editItemId.value = null;
    }
  }
  return {
    editItemId,
    setEditItemId,
    resetEditItemId,
  };
}
