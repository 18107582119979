<template>
  <button
    @click="isAccountOpen = !isAccountOpen"
    ref="accountButton"
    class="ml-1"
  >
    <div class="flex cursor-pointer items-center">
      <img
        :src="user.avatar"
        alt="avatar"
        class="mr-[12px] h-[40px] w-[40px] grayscale"
      />
      <div class="flex flex-col items-start justify-center">
        <div class="min-w-[92px] self-start text-lgmx_11 text-lgmx_gray-900">
          Personal account
        </div>
        <div
          class="flex items-center text-lgmx_15 font-medium text-lgmx_black-300"
        >
          <div class="mr-1">{{ name }}</div>
          <div>
            <ChevronDownIcon
              class="h-[14px] w-[14px] text-lgmx_slate-300 transition-all duration-100 translate-y-px"
              :class="[{ 'rotate-180': isAccountOpen }]"
            />
          </div>
        </div>
      </div>
    </div>
  </button>

  <button
    v-if="isAccountOpen"
    @click="isAccountOpen = false"
    class="hidden bg-transparent md:fixed md:inset-0 md:block"
  />

  <div
    class="right-0 mx-5 rounded border-lgmx_gray-100 bg-white py-2 md:absolute md:top-full md:w-48 md:border"
    v-show="isAccountOpen"
  >
    <router-link
      to="/account"
      class="block p-2 text-black/50 hover:bg-gray-100 hover:text-gray-1000 md:px-6 md:py-1 md:text-gray-1000"
      block
    >
      Settings
    </router-link>
    <button
      v-if="canSwitchAccounts"
      @click="openSwitchAccountModal"
      class="block w-full p-2 text-left text-black/50 hover:bg-gray-100 hover:text-gray-1000 md:px-6 md:py-1 md:text-gray-1000"
    >
      Switch Accounts
    </button>
    <VDivider class="my-2" />
    <button
      class="w-full p-2 text-left text-black/50 hover:bg-gray-100 hover:text-gray-1000 md:px-6 md:py-1 md:text-gray-1000"
      @click="handleLogout"
    >
      Logout
    </button>
  </div>
</template>

<script>
import * as _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import NProgress from 'nprogress';
import VDivider from '@/components/ui/VDivider.vue';

export default {
  components: {
    VDivider,
  },

  data() {
    return {
      isAccountOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    name() {
      return _.get(this.user, 'name', 'No Name');
    },
    canSwitchAccounts() {
      return this.user.accounts?.length > 1;
    },
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      openModal: 'modal/open',
    }),
    openSwitchAccountModal() {
      this.openModal({ name: 'UserAccountSwitchModal' });
    },
    handleLogout() {
      NProgress.start();
      this.logout();
    },
  },
};
</script>

<style scoped>
a.router-link-active {
  @apply text-white bg-red-400 hover:text-white;
}
</style>
