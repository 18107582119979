<template>
  <div class="pb-4">
    <div class="flex items-center justify-between pb-0">
      <VSelect
        v-if="!sendCustomCommand"
        class="mr-3"
        size="sm"
        :options="commandOptions"
        v-model="selectedCommandId"
      >
      </VSelect>
      <VInput
        v-else
        type="text"
        size="sm"
        class="mr-3 w-full border"
        v-model="customCommand"
      />
      <VButton size="sm" @click="sendCommand">Send</VButton>
    </div>
    <div class="flex items-center pb-0 pt-2" v-if="customCommandsEnabled">
      <UIToggle v-model="sendCustomCommand"></UIToggle>
      <span class="ml-1">Custom command</span>
      <template v-if="sendCustomCommand">
        <UIRadioGroupOption
          class="border-t-0"
          :narrow="true"
          v-model="sendVia"
          :selected="'sms'"
          :label="'SMS'"
        />
        <UIRadioGroupOption
          class="border-t-0"
          :narrow="true"
          v-model="sendVia"
          :selected="'data'"
          :label="'Data'"
        />
      </template>
    </div>
    <div
      v-if="
        !sendCustomCommand && selectedCommand && selectedCommand.has_arguments
      "
    >
      <CommonProperty
        v-for="argument in selectedCommandArguments"
        :key="argument"
        :name="argument"
      >
        <VInput
          type="text"
          size="sm"
          class="mr-2 flex-1"
          v-model="commandArguments[argument]"
        />
      </CommonProperty>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import CommonProperty from '@/components/_common/CommonProperty.vue';
import VInput from '@/components/ui/VInput.vue';
import UIToggle from '@/components/ui/UIToggle.vue';
import UIRadioGroupOption from '@/components/ui/RadioGroup/UIRadioGroupOption.vue';
import * as events from '@/events';

export default {
  components: {
    CommonProperty,
    UIToggle,
    UIRadioGroupOption,
    VInput,
  },

  props: {
    deviceId: {
      required: true,
    },
  },

  data() {
    return {
      selectedCommandId: null,
      commandArguments: {},
      sendCustomCommand: false,
      customCommand: '',
      sendVia: 'sms',
    };
  },

  beforeMount() {
    this.fetchCommandsByDeviceId({ deviceId: this.deviceId });
  },

  computed: {
    ...mapGetters({
      commandsByModelId: 'command/byModelId',
      commandById: 'command/byId',
      deviceMetaById: 'deviceMeta/byId',
      account: 'auth/account',
      accountSettingIsOn: 'auth/accountSettingIsOn',
    }),
    selectedCommand() {
      return this.commandById(+this.selectedCommandId);
    },
    selectedCommandArguments() {
      return _.uniq(this.selectedCommand.commands.match(/__(\w+)__/gi));
    },
    deviceMeta() {
      return this.deviceMetaById(this.deviceId);
    },
    commandOptions() {
      const filteredCommands = _.filter(
        this.commandsByModelId(this.deviceMeta?.model.id),
        (command) => !command.is_category,
      );
      return _.map(filteredCommands, (command) => {
        return {
          value: command.id,
          label:
            `${command.title}` +
            (command.description ? `(${command.description})` : ''),
        };
      });
    },
    customCommandsEnabled() {
      return this.accountSettingIsOn('custom_commands_available');
    },
  },
  methods: {
    ...mapActions({
      fetchCommandsByDeviceId: 'command/fetchByDeviceId',
      sendCommandToDeviceId: 'message/sendToDevice',
    }),
    sendCommand() {
      const toast = useToast();
      if (!this.selectedCommandId && !this.sendCustomCommand) {
        return toast.error('Please select a command to send');
      }
      if (!this.customCommand && this.sendCustomCommand) {
        return toast.error('Please provide a command to send');
      }

      let payload = {
        command: '',
        send_via: [],
      };
      if (this.sendCustomCommand) {
        payload.command = `{"${this.customCommand}"}`;
        payload.send_via = [this.sendVia];
      } else {
        payload.translation = this.selectedCommand.translation;
        if (this.selectedCommand.is_sent_via_data)
          payload.send_via.push('data');
        if (this.selectedCommand.is_sent_via_sms) payload.send_via.push('sms');
        if (this.selectedCommand.is_sent_via_sos) payload.send_via.push('sos');

        payload.command = this.selectedCommand.commands;
        if (this.selectedCommand.has_arguments) {
          for (let i in this.selectedCommandArguments) {
            const argument = this.selectedCommandArguments[i];
            const value = _.get(this.commandArguments, argument, '');
            payload.command = payload.command.replaceAll(argument, value);
          }
        }
      }

      this.sendCommandToDeviceId({
        id: this.deviceMeta.id,
        payload,
      })
        .then(() => {
          events.trigger(events.names.COMMAND_SENT_TO_TRACKER, payload);
          toast.success('Command sent');
        })
        .catch((err) => {
          const message = _.get(
            err,
            'response.data.message',
            'Error occurred while sending a command',
          );
          toast.error(message);
        });
    },
  },
};
</script>
