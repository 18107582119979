<template>
  <div class="w-full">
    <button
      class="flex w-full items-center justify-between bg-lgmx_terracota-700 px-5 py-2 font-medium text-white"
      :disabled="props.isLoading"
      @click.prevent.stop="isExpanded = !isExpanded"
    >
      <span>{{ props.title }} <slot name="button" /></span>
      <ChevronDownIcon
        class="ml-auto h-5 w-5 transition-all duration-300 rotate-x-0"
        :class="[{ 'rotate-x-180': isExpanded }]"
      />
    </button>
    <Collapse :when="isExpanded" class="transition-[height] duration-200">
      <div class="relative px-5 pb-[34px] pt-[23px]" :class="contentClass">
        <div
          v-if="props.isLoading"
          class="absolute left-0 top-0 h-full w-full bg-white/40"
        />
        <slot />
      </div>
    </Collapse>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { Collapse } from 'vue-collapsed';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  initialOpen: {
    type: Boolean,
    default: false,
  },
  isLoading: Boolean,
  contentClass: {
    type: String,
    default: '',
  },
});

const isExpanded = ref(props.initialOpen);
</script>
