<template>
  <div class="inline-block" :style="cssVars">
    <svg
      class="spinner"
      :width="`${size}px`"
      :height="`${size}px`"
      :viewBox="`0 0 ${size} ${size}`"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        fill="none"
        class="path"
        :class="strokeColorClass"
        :stroke-width="strokeWidth"
        stroke-linecap="square"
        :cx="center"
        :cy="center"
        :r="radius"
      ></circle>
    </svg>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import {
  spinnerSizes,
  spinnerVariants,
  getSpinnerSize,
  getSpinnerStrokeWidth,
  getSpinnerStrokeColorClass,
} from './utils';

const props = defineProps({
  size: {
    type: String,
    default: 'xs',
    validator: (size) => spinnerSizes.includes(size),
  },
  variant: {
    type: String,
    default: 'default',
    validator: (variant) => spinnerVariants.includes(variant),
  },
});

const size = computed(() => getSpinnerSize(props.size));
const strokeWidth = computed(() => getSpinnerStrokeWidth(props.size));
const center = computed(() => size.value / 2);
const radius = computed(() => size.value / 2 - strokeWidth.value / 2);
const circumference = computed(() => 2 * Math.PI * radius.value * 1.2);
const cssVars = computed(() => ({
  '--circumference': circumference.value,
  '--quarter': circumference.value / 4,
}));
const strokeColorClass = computed(() =>
  getSpinnerStrokeColorClass(props.variant),
);
</script>

<style scoped>
.spinner {
  animation: rotator 1.4s linear infinite;
}

.path {
  stroke-dasharray: var(--circumference);
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: var(--circumference);
  }
  50% {
    stroke-dashoffset: var(--quarter);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: var(--circumference);
    transform: rotate(450deg);
  }
}
</style>
