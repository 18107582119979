<template>
  <VSelect size="sm" :options="[]">
    <span class="mb-2 inline-block">Type</span>
  </VSelect>
  <VInput size="sm" type="number" label="Serial Number" placeholder="000000" />
  <VInput size="sm" type="text" label="IMEI" placeholder="IMEI" />
  <VInput size="sm" type="text" label="ICCID" placeholder="ICCID" />
  <VSelect size="sm" :options="[]">
    <span class="mb-2 inline-block">SIM Provider</span>
  </VSelect>
  <VCheckbox>
    <span class="flex-1">For Amazon</span>
  </VCheckbox>
  <VButton type="submit" class="w-full" variant="primary">
    Create device
  </VButton>
</template>

<script>
export default {
  name: 'UtilityProvisionForm',
};
</script>
