import { defineStore, storeToRefs } from 'pinia';
import service from '@/services';
import { setAbortController } from '@/composables/useAbortController';
import { computed, ref, unref } from 'vue';
import { downloadFile } from '@/utils/blob.js';
import { useFilter } from '@/composables/useFilter.ts';

export const usePositionHistory = defineStore('positionHistory', () => {
  const filterStore = useFilter();
  const isLoading = ref(false);
  const historyPositionsByDevice = ref(new Map<string, any>());
  const historyPositions = ref([]);

  const { isVisibleOverlay } = filterStore;

  const fetchHistoryPositionsByAccountAndDevice = async (
    accountId: number,
    deviceId: number,
    dateFrom?: string,
    dateTo?: string,
  ) => {
    const { dateFilter } = storeToRefs(filterStore);

    isLoading.value = true;
    const controller = setAbortController('devicePositionHistory');

    if (!dateFrom) {
      dateFrom = dateFilter.value?.dateFrom;
      dateTo = dateFilter.value?.dateTo;
    }

    const response = await service.get(
      `/accounts/${accountId}/devices/${deviceId}/positions`,
      {
        params: { date_from: dateFrom, date_to: dateTo },
        signal: controller.signal,
      },
    );

    if ((response && response.status === 499) || !response?.data?.data) {
      isLoading.value = false;
      return response;
    }

    historyPositionsByDevice.value.set(
      `${deviceId}$${dateFrom}$${dateTo}`,
      response.data.data,
    );

    isLoading.value = false;

    return response;
  };

  const fetchHistoryPositionsByAccountAndDevices = async (
    accountId: number,
    ids?: number[],
    dateFrom?: string,
    dateTo?: string,
  ) => {
    const { dateFilter } = storeToRefs(filterStore);

    isLoading.value = true;
    const controller = setAbortController('devicesPositionHistory');

    if (!dateFrom) {
      dateFrom = dateFilter.value?.dateFrom;
      dateTo = dateFilter.value?.dateTo;
    }

    const params = {
      date_from: dateFrom,
      date_to: dateTo,
      device_ids: ids ?? [],
    };

    const response = await service.post(
      `/accounts/${accountId}/positions`,
      params,
      {
        signal: controller.signal,
      },
    );

    if ((response && response.status === 499) || !response?.data?.data) {
      isLoading.value = false;
      return response;
    }

    historyPositions.value = response.data.data;

    isLoading.value = false;

    return response;
  };

  const downloadAll = (
    accountId: number,
    deviceId: number,
    fileName: string,
    params: any,
  ) => {
    return service
      .get(`/accounts/${accountId}/devices/${deviceId}/positions`, {
        responseType: 'blob',
        params,
      })
      .then((res) => downloadFile({ res, fileName, extension: 'csv' }));
  };

  const getHistoryPositionsByDateForDeviceId = computed(
    () => (deviceId: number, dateFrom: string, dateTo: string) => {
      const positions = historyPositionsByDevice.value.get(
        `${deviceId}$${dateFrom}$${dateTo}`,
      );

      if (!positions) {
        return null;
      }

      return positions.filter((position) => isVisibleOverlay(position.fix));
    },
  );

  const getHistoryPositionsByDateForAllDevices = () => {
    const result = [];
    historyPositions.value.forEach((origDevice) => {
      const device = Object.assign({}, origDevice);
      device.path = origDevice.path.filter((position) =>
        isVisibleOverlay(position.fix),
      );
      result.push(device);
    });
    return result;
  };

  return {
    isLoading,
    historyPositions,
    historyPositionsByDevice,

    fetchHistoryPositionsByAccountAndDevice,
    fetchHistoryPositionsByAccountAndDevices,

    getHistoryPositionsByDateForDeviceId,
    getHistoryPositionsByDateForAllDevices,

    downloadAll,
    clearHistoryPositions: () => (historyPositions.value = []),
  };
});
