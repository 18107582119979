<template>
  <div>
    <HeadingSection
      class="mb-[35px] pt-[25px]"
      :heading="title"
      :desc="subtitle"
      :button="topButton"
      :search="search"
      :filter="filter"
    />
    <DataTable
      :loading="loading"
      :list="rows"
      :columns="columns"
      :editActions="editActions"
      @itemClick="onItemClick"
    />
    <DataTableNav v-if="meta" v-model="pagination" :meta="meta" />
  </div>
</template>

<script>
import * as _ from 'lodash';
import HeadingSection from '@/components/_common/Heading/HeadingSection.vue';
import DataTable from '@/components/_common/Data/DataTable.vue';
import DataTableNav from '@/components/_common/Data/DataTableNav.vue';
import { TABLE_RESULTS_PER_PAGE } from '@/config/constants';

export default {
  components: {
    HeadingSection,
    DataTable,
    DataTableNav,
  },

  data() {
    return {
      loading: false,
      title: '',
      subtitle: '',
      columns: [],
      meta: {},
      pagination: { per_page: TABLE_RESULTS_PER_PAGE[0], page: 1 },
      search: {},
      filter: {},
      ids: [],
      editActions: [],
    };
  },

  computed: {
    rows() {
      return _.map(this.itemsByIds(this.ids), (item, $index) => {
        return this.formatItemIntoRow(item, $index);
      });
    },
    topButton() {
      return null;
    },
  },

  watch: {
    pagination: {
      deep: true,
      handler() {
        this.loadPage();
      },
    },
    search: {
      deep: true,
      handler() {
        this.pagination = { per_page: TABLE_RESULTS_PER_PAGE[0], page: 1 };
      },
    },
    filter: {
      deep: true,
      handler() {
        this.pagination = { per_page: TABLE_RESULTS_PER_PAGE[0], page: 1 };
      },
    },
  },

  mounted() {
    this.loadPage();
  },

  methods: {
    loadPage() {
      this.loading = true;
      const pagination = this.pagination;
      const search = { query: this.search.value || null };
      const filter = {
        search: { [this.filter.type]: { is: this.filter.value } },
      };

      this.fetchItems({ ...pagination, ...search, ...filter })
        .then((res) => {
          this.ids = _.map(res.data.data, (el) => el.id);
          this.meta = res.data.meta;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onItemClick(item) {
      this.onItemClick(item);
    },
  },
};
</script>
