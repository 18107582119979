<script>
import * as _ from 'lodash';
import { useToast } from 'vue-toastification';
import { mapActions, mapGetters } from 'vuex';
import PaginatedTableVue from '@/components/_common/Data/mixins/PaginatedTable.vue';

export default {
  props: {
    accountId: {
      required: true,
      type: Number,
    },
  },

  mixins: [PaginatedTableVue],

  setup() {
    const toast = useToast();

    return { toast };
  },

  data() {
    return {
      title: 'Users',
      subtitle: 'View, edit or remove users',
      columns: ['User Info', 'Role', 'Devices'],
      editActions: [
        {
          icon: 'PencilSquareIcon',
          label: 'Edit User',
          action: {
            mode: 'fn',
            fn: (userId) => {
              this.openModal({
                name: 'ManageUserEditModal',
                params: {
                  userId: userId,
                  onSuccess: this.loadPage.bind(this),
                },
              });
            },
          },
        },
        {
          icon: 'XMarkIcon',
          label: 'Remove',
          action: {
            mode: 'async-fn',
            fn: (userId) => this.actionRemoveUser(userId),
          },
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      itemsByIds: 'user/byIds',
      activeModal: 'modal/active',
    }),
    topButton() {
      return {
        label: 'Invite User',
        action: {
          mode: 'callback',
          callback: () => {
            this.openModal({
              name: 'ManageUserInviteModal',
              params: { onSuccess: this.loadPage.bind(this) },
            });
          },
        },
      };
    },
  },

  watch: {
    accountId() {
      this.loadPage();
    },
  },

  methods: {
    ...mapActions({
      fetch: 'user/fetch',
      deleteUserFromAccount: 'user/deleteFromAccount',
      openModal: 'modal/open',
    }),
    fetchUsers(params) {
      params.include = 'accounts,devicesCount';
      return this.fetch({
        params,
      });
    },
    fetchItems(pagination) {
      const params = _.assign({}, pagination);
      return this.fetchUsers(params);
    },
    formatItemIntoRow(user) {
      const userRole = user.account_roles
        ? user.account_roles[this.accountId]
        : '';
      return {
        id: user.id,
        userInfo: [user.name, user.email],
        role: this.getRoleText(userRole),
        devices: this.getDevicesText(userRole, user.devices_count),
        edit: true,
      };
    },
    getRoleText(role) {
      if (role === 'all') {
        return 'Access All';
      }

      if (role === 'devices') {
        return 'Specific Devices';
      }

      return _.capitalize(role);
    },
    getDevicesText(role, count) {
      if (role !== 'devices') {
        return 'All Devices';
      }

      if (count === 1) {
        return '1 Device';
      }

      if (count === null) {
        return 'Loading';
      }

      return `${count} Devices`;
    },
    onItemClick(item) {
      return item;
    },
    actionEditUser(userId) {
      return userId;
    },
    actionRemoveUser(userId) {
      this.loading = true;
      return this.deleteUserFromAccount({
        userId,
      })
        .catch((e) => {
          this.toast.error(e.response?.data?.message || 'An error occurred');
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style></style>
