import _ from 'lodash';

const processStrategy = (product) => {
  const relations = {};
  const omit = [];
  return { ..._.omit(product, omit), ..._.pickBy(relations, _.identity) };
};

export default {
  key: 'product',
  definition: {},
  processStrategy,
};
