<template>
  <CommonNotification
    v-if="notification"
    :label="notification.label"
    :value="notification.value"
    :desc="notification.desc"
    :button="notification.button"
  />
  <AppHeader />
  <main class="container sm:px-8">
    <slot />
  </main>
</template>

<script>
import AppHeader from '@/components/_common/AppHeader.vue';
import CommonNotification from '@/components/_common/CommonNotification.vue';

export default {
  components: {
    AppHeader,
    CommonNotification,
  },

  props: {
    notification: {
      type: Object,
      required: false,
      default: null,
    },
  },
};
</script>
