<template>
  <div class="inline-block" :style="cssVars">
    <svg
      class="spinner"
      :width="`${spinner.size}px`"
      :height="`${spinner.size}px`"
      :viewBox="`0 0 ${spinner.size} ${spinner.size}`"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        class="path stroke-lms_red-100"
        fill="none"
        :stroke-width="spinner.strokeWidth"
        stroke-linecap="square"
        :cx="center"
        :cy="center"
        :r="radius"
      ></circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'UISpinner',
  props: {
    size: {
      validator(value) {
        return ['xs', 'sm', 'base', 'md', 'lg', 'xl'].includes(value);
      },
      required: true,
    },
    strokeWidth: {
      type: Number,
    },
  },
  computed: {
    spinner() {
      let size = Number,
        strokeWidth = Number;
      switch (this.size) {
        case 'xs':
          size = 14;
          strokeWidth = 2;
          break;
        case 'sm':
          size = 16;
          strokeWidth = 2;
          break;
        case 'base':
          size = 18;
          strokeWidth = 2;
          break;
        case 'md':
          size = 24;
          strokeWidth = 2;
          break;
        case 'lg':
          size = 32;
          strokeWidth = 3;
          break;
        case 'xl':
          size = 48;
          strokeWidth = 4;
      }
      return {
        size,
        strokeWidth,
      };
    },
    center() {
      return this.spinner.size / 2;
    },
    radius() {
      return this.spinner.size / 2 - this.spinner.strokeWidth / 2;
    },
    circumference() {
      return 2 * Math.PI * this.radius * 1.2;
    },
    cssVars() {
      return {
        '--circumference': this.circumference,
        '--quarter': this.circumference / 4,
      };
    },
  },
};
</script>

<style scoped>
.spinner {
  animation: rotator 1.4s linear infinite;
}

.path {
  stroke-dasharray: var(--circumference);
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: var(--circumference);
  }
  50% {
    stroke-dashoffset: var(--quarter);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: var(--circumference);
    transform: rotate(450deg);
  }
}
</style>
