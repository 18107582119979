<template>
  <div class="flex flex-col items-center">
    <UIIndicator variant="success" class="mb-[21px] pt-[2px]" />
    <div class="mb-[12px] text-center text-[19px] font-semibold leading-6">
      Your SIM has been successfully swapped!
    </div>
    <div class="text-center text-[15px] leading-6 text-lgmx_gray-900">
      We have added 2 free audio hours to your account.
    </div>
    <div class="flex justify-center pb-[10px] pt-[26px]">
      <UIButtonNew
        size="lg"
        @click="$emit('onConfirm')"
        variant="secondary"
        class="w-[114px]"
      >
        Okay
      </UIButtonNew>
    </div>
  </div>
</template>
