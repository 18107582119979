export default {
  LOGGED_IN: 'Login',
  USER_LOADED: 'User Loaded',
  LOGGED_OUT: 'Logout',
  SHIPMENT_SEARCHED: 'Searched Shipments',
  SHIPMENT_CREATED: 'Shipment Created',
  SHIPMENT_EDITED: 'Shipment Edited',
  SHIPMENT_DELETED: 'Shipment Deleted',
  SHIPMENT_ENDED: 'Shipment Ended',
  SHIPMENTS_BULK_ARCHIVED: 'Bulk Archive Shipments',
  SHIPMENTS_BULK_UNARCHIVED: 'Bulk Unarchive Shipments',
  SHIPMENTS_BULK_DELETED: 'Bulk Delete Shipments',
  SHIPMENT_LIST_VIEWED: 'Shipment List Viewed',
  SHIPMENT_ARCHIVED_LIST_VIEWED: 'Shipment Archived List Viewed',
  SHIPMENT_VIEWED: 'Shipment Viewed',
  RULE_CREATED: 'Rule Created',
  TRACKERS_SEARCHED: 'Searched Trackers',
  TRACKERS_FILTERED: 'Filtered Trackers',
  TRACKER_MAP_VIEW_CHANGED: 'Change Tracker Map View',
  GROUPS_SEARCHED: 'Searched Groups',
  ALL_TRACKERS_VIEWED: 'Tracker List Viewed',
  TRACKER_NAME_EDITED: 'Tracker Name Edited',
  TRACKER_NAME_EDIT_CANCELLED: 'Edit Tracker Name Cancelled',
  COMMAND_SENT_TO_TRACKER: 'Command Sent to Tracker',
  TRACKER_VIEWED: 'Tracker Viewed',
  ALL_GROUPS_VIEWED: 'Groups List Viewed',
  GROUP_CREATED: 'Created Group',
  GROUP_VIEWED: 'Group Viewd',
  DEVICES_ADDED_TO_GROUP: 'Added Devices to Group',
  GROUP_DEVICES_SWAPPED: 'Swapped Group Devices',
  GROUP_DEVICES_REMOVED: 'Removed Devices From Group',
  GROUP_EDITED: 'Updated Group',
  GROUP_DELETED: 'Deleted Group',
  GEOFENCE_SEARCHED: 'Searched Geofences',
  GEOFENCE_CREATED: 'Geofence Created',
  GEOFENCE_LIST_VIEWED: 'Geofence List Viewed',
  GEOFENCE_VIEWED: 'Geofence Viewed',
  GEOFENCE_DELETED: 'Geofence Deleted',
  GET_HELP_CLICKED: 'Get Help Clicked',
  PAGE_VIEWED: 'Page Viewed',
  CURRENT_ACCOUNT_CHANGED: 'Selected Account',

  ALL_BLE_ANCHORS_VIEWED: 'BLE Anchors List Viewed',
  BLE_ANCHOUR_CREATED: 'Created BLE Anchor',
  BLE_ANCHOUR_EDITED: 'Edited BLE Anchor',
  BLE_ANCHOUR_DELETED: 'Deleted BLE Anchor',

  REPORT_CREATED: 'Created Report',
  REPORT_VIEWED: 'Viewed Report',
  REPORT_EDITED: 'Edited Report',
  REPORT_DELETED: 'Deleted Report',

  RULE_EDITED: 'Edited Rule',
  RULE_DELETED: 'Deleted Rule',

  USER_INVITED: 'Invited User',
  USER_REMOVED: 'Removed User From Account',
  USER_EDITED: 'Updated User Permissions',

  ADDRESS_CREATED: 'Created Address',
  ADDRESS_SET_AS_DEFAULT: 'Set Address As Default',
  ADDRESS_DELETED: 'Deleted Address',

  PAYMENT_METHOD_CREATION_INITIATED: 'Initiated Creating Payment Method',
  PAYMENT_METHOD_DELETED: 'Deleted Payment Method',
  PAYMENT_METHOD_SET_AS_DEFAULT: 'Set Payment Method As Default',
};
