<template>
  <div class="flex flex-col rounded-[6px] border border-gpx_gray-800">
    <UserAccountSwitchModalListItem
      v-for="account in accountList"
      :key="account.id"
      :account="account"
      :selected="selected"
      @click="handleSelected(account)"
    />
  </div>
</template>

<script>
import UserAccountSwitchModalListItem from './UserAccountSwitchModalListItem.vue';

export default {
  components: {
    UserAccountSwitchModalListItem,
  },

  emits: ['onChange'],
  props: {
    accounts: {
      type: Array,
      required: true,
    },
    selected: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      accountList: [],
    };
  },

  beforeMount() {
    this.accountList = this.accounts;
  },

  methods: {
    handleSelected(account) {
      this.$emit('onChange', account);
    },
  },
};
</script>
