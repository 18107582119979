import * as _ from 'lodash';
import * as L from 'leaflet';
import { GPS_ICONS } from '@/utils/map/markers';
import { BLE_ANCHOR_ICONS } from '@/utils/map/new-markers';

export const clusterOptions = {
  maxClusterRadius: 30,
  polygonOptions: {
    fillColor: '#9894df99',
    color: '#9494df',
    weight: 1,
  },
};

export function createMarker(geofence) {
  return L.marker([geofence.lat, geofence.lng], {
    id: geofence.id,
    icon: GPS_ICONS['gray'],
  });
}

export function getPopupContent({ geofence }) {
  return `
  <div>
    <div class="font-medium text-xs">${geofence?.name}</div>
    <div class="text-[10px]">${geofence?.address}</div>
  </div>
  `;
}

function getImages(images) {
  let html = '<div class="flex relative overflow-auto">';
  for (const image of images) {
    html += `<img onclick="window.open('${image.path}')" height="45" class="object-cover object-center h-[45px] m-[3px]" src="${image.path}">`;
  }

  return html + '</div>';
}

export function createBleAnchor(bleAnchor) {
  return L.marker([bleAnchor.lat, bleAnchor.lng], {
    id: bleAnchor.id,
    icon: BLE_ANCHOR_ICONS['blue'],
  });
}

export function getBleAnchorPopupContent({ bleAnchor }) {
  const name = _.get(bleAnchor, 'name', '-');
  const serial = _.get(bleAnchor, 'device.serial', '-');
  const geofenceName = _.get(bleAnchor, 'geofence.name', null);
  const location = _.get(bleAnchor, 'address', '-');
  const radius = _.get(bleAnchor, 'radius', '-');
  const images = getImages(_.get(bleAnchor, 'images', []));

  return `
  <div>
    <div class="px-[12px] pt-[9px] pb-[15px] flex items-center justify-between border-b border-b-gpx_gray-800">
      <div class="text-[13px] text-gpx_black-100 font-semibold">
        ${name}
      </div>
    </div>
    <div class="flex flex-col gap-y-[8px] px-[12px] pt-[12px] pb-[16px]">
      <div class="leaflet-row-container">
        <div class="leaflet-row-label">
          Device serial
        </div>
        <div class="leaflet-row-value">
          ${serial}
        </div>
      </div>
      ${
        geofenceName
          ? `
            <div class="leaflet-row-container">
              <div class="leaflet-row-label">
                Geofence
              </div>
              <div class="leaflet-row-value">
                ${geofenceName}
              </div>
            </div>`
          : ''
      }
      <div class="leaflet-row-container">
        <div class="leaflet-row-label">
          Location
        </div>
        <div class="leaflet-row-value uppercase">
          ${location}
        </div>
      </div>
      <div class="leaflet-row-container">
        <div class="leaflet-row-label">
          Radius, feet
        </div>
        <div class="leaflet-row-value">
          ${radius}
        </div>
      </div>
      ${images}
    </div>
  </div>
  `;
}

export function createDraggableBleAnchor(coordinates) {
  return L.marker([coordinates.lat, coordinates.lng], {
    icon: BLE_ANCHOR_ICONS['blue'],
    draggable: true,
  });
}
