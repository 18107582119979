<script setup lang="ts">
import { map } from 'lodash';
import { useStore } from 'vuex';
import UICombobox from '@/components/ui/UICombobox.vue';
import { onMounted, ref } from 'vue';
import { useDevice } from '@/composables/useDevice.ts';

const store = useStore();

const deviceStore = useDevice();
const { fetchByCurrentAccountId } = deviceStore;
const accountId = store.getters['auth/accountId'];

const props = defineProps<{
  selected: any[];
}>();

const emit = defineEmits(['onChange']);

const trackersSelect = ref(null);

onMounted(() => {
  fetchAndSetDevicesAsSelected(props.selected);
});

const onTrackersSelected = (trackers) => {
  emit('onChange', trackers);
};

const options = ({ query }) => {
  const params = {
    query,
    search: {
      status: { is: 'active' },
    },
  };
  return fetchByCurrentAccountId(accountId, params).then((res) => {
    return map(res.data.data, (device) => {
      return { value: device.id, label: device.name };
    });
  });
};

const fetchAndSetDevicesAsSelected = (ids) => {
  if (!ids) {
    return;
  }

  const params = {
    ids: ids.join(','),
  };
  return fetchByCurrentAccountId(accountId, params).then((res) => {
    res.data.data.forEach((device) => {
      trackersSelect.value?.setSelectedOptionQueitly({
        value: device.id,
        label: device.name,
      });
    });
    return res;
  });
};
</script>
<template>
  <UICombobox
    ref="trackersSelect"
    label="Value(s)"
    placeholder="Tracker name contains"
    multiple
    :options="options"
    @update:modelValue="onTrackersSelected"
  />
</template>
