<template>
  <ModalLayout title="Sim Replacement">
    <div
      class="flex rounded-t-[10px] bg-white px-[24px] pt-[28px] translate-y-px sm:px-[40px] sm:pt-[30px]"
      :class="[{ 'pb-[26px]': shouldShowHeader }]"
    >
      <div
        v-if="shouldShowHeader && shouldShowCircleProgress"
        class="relative mr-[15px] h-[50px] w-[50px] shrink-0"
      >
        <div
          class="absolute left-1/2 top-1/2 w-full text-center -translate-x-1/2 -translate-y-1/2"
        >
          <span class="text-[19px] font-semibold leading-7 text-lgmx_black-200">
            {{ step }}
          </span>
          <span class="text-[13px] leading-5 text-lgmx_gray-900"
            >/ {{ steps }}</span
          >
        </div>
        <CircleProgress
          :percent="100 * (step / steps)"
          :size="50"
          :border-width="6"
          :border-bg-width="6"
          fill-color="#77D770"
          empty-color="#F1F3F5"
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </div>
      <div v-if="shouldShowHeader" class="mr-[24px] overflow-hidden">
        <div class="text-[13px] leading-5 text-lgmx_gray-800">
          Replacing device SIM
        </div>
        <div class="text-ellipsis whitespace-nowrap">
          <div
            class="overflow-hidden text-ellipsis text-[24px] font-semibold leading-7 text-lgmx_black-600"
          >
            {{ title }}
          </div>
        </div>
      </div>
      <button
        v-if="shouldShowHeader && shouldShowXButton"
        class="ml-auto flex h-[32px] w-[32px] shrink-0 cursor-pointer items-center justify-center rounded-full bg-lgmx_gray-100"
        @click="$emit('close')"
      >
        <XMarkIcon
          class="heroicon-stroke-w-1.4 h-[16px] w-[16px] text-lgmx_gray-800"
        />
      </button>
    </div>
    <Step0
      v-show="step === 0"
      v-model="selectedDevice"
      @on-cancel="$emit('close')"
      @on-confirm="step = 1"
      class="bg-white px-[24px] sm:px-[40px]"
    />
    <StepReplacing
      v-show="onReplacingStep"
      class="border-t border-lgmx_gray-100 bg-white px-[24px] sm:px-[40px]"
    />
    <StepSuccess
      v-show="step === -1"
      class="bg-white px-[24px] sm:px-[40px]"
      @on-confirm="handleSuccess"
    />
    <StepReplaceMore
      v-show="step === -2"
      @on-cancel="$emit('close')"
      @on-confirm="handleReplaceMore"
      class="bg-white px-[24px] sm:px-[40px]"
    />
    <StepError
      v-show="step === -3"
      class="bg-white px-[24px] sm:px-[40px]"
      @on-cancel="handleNeedHelp"
      @on-confirm="handleTryAgain"
    />
    <Mobile200Wizard
      v-if="shouldShowMobile200Wizard"
      v-model:step="step"
      v-model:device="selectedDevice"
      v-model:iccid="selectedIccid"
      @replace-and-rearm="replaceAndRearm"
      class="border-t border-lgmx_gray-100 bg-white px-[24px] sm:px-[40px]"
    />
    <CarChargerWizard
      v-if="shouldShowCarChargerWizard"
      v-model:step="step"
      v-model:device="selectedDevice"
      v-model:iccid="selectedIccid"
      @replace-and-rearm="replaceAndRearm"
      class="border-t border-lgmx_gray-100 bg-white px-[24px] sm:px-[40px]"
    />
    <div
      class="mb-[24px] rounded-b-[10px] bg-white px-[24px] pb-[22px] text-center -translate-y-px sm:px-[40px]"
    >
      <a
        v-if="shouldShowHelpLink"
        class="text-[13px] font-medium leading-5 text-lgmx_slate-600"
        target="_blank"
        href="https://logistimatics.com/knowledge-base"
      >
        Need help? Contact us!
      </a>
    </div>
  </ModalLayout>
</template>

<script>
import * as _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import CircleProgress from 'vue3-circle-progress';
import ModalLayout from '@/layouts/ModalLayout.vue';
import Step0 from './_common/Step0.vue';
import Mobile200Wizard from './mobile-200/Mobile200Wizard.vue';
import CarChargerWizard from './car-charger/CarChargerWizard.vue';
import StepSuccess from './_common/StepSuccess.vue';
import StepReplaceMore from './_common/StepReplaceMore.vue';
import StepError from './_common/StepError.vue';
import StepReplacing from './_common/StepReplacing.vue';
import 'vue3-circle-progress/dist/circle-progress.css';
import { DEVICE_TYPE } from '@/components/device/constants.js';

const toast = useToast();

export default {
  components: {
    ModalLayout,
    Step0,
    Mobile200Wizard,
    CarChargerWizard,
    CircleProgress,
    StepSuccess,
    StepReplaceMore,
    StepError,
    StepReplacing,
  },
  emits: ['close'],

  data() {
    return {
      step: 0,
      selectedIccid: null,
      selectedDevice: null,
    };
  },

  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
      devices: 'simReplacement/devices',
      canReplace: 'simReplacement/canReplace',
    }),
    DEVICE_TYPE() {
      return DEVICE_TYPE;
    },
    shouldShowMobile200Wizard() {
      return (
        this.step > 0 && this.step < 9 && this.type === DEVICE_TYPE.MOBILE_200
      );
    },
    shouldShowCarChargerWizard() {
      return (
        this.step > 0 && this.step < 8 && this.type === DEVICE_TYPE.CAR_CHARGER
      );
    },
    onReplacingStep() {
      return (
        (this.type === DEVICE_TYPE.MOBILE_200 && this.step === 9) ||
        (this.type === DEVICE_TYPE.CAR_CHARGER && this.step === 8)
      );
    },
    shouldShowCircleProgress() {
      return this.step > 0;
    },
    shouldShowHeader() {
      return this.step >= 0;
    },
    shouldShowXButton() {
      return this.step !== this.steps && this.step >= 0;
    },
    shouldShowHelpLink() {
      return this.step !== this.steps && this.step >= 0;
    },
    title() {
      return _.get(this.selectedDevice, 'name', 'Choose tracker');
    },
    type() {
      if (!this.selectedDevice) return null;
      return _.get(this.selectedDevice, 'model.device_type', 'unknown');
    },
    steps() {
      if (!this.type) return null;
      if (this.type === DEVICE_TYPE.MOBILE_200) {
        return 9;
      }
      if (this.type === DEVICE_TYPE.CAR_CHARGER) {
        return 8;
      }
      throw Error(`No steps for "${this.type}" device`);
    },
  },

  watch: {
    type: {
      handler(n) {
        if (_.isNull(n)) return;
        if (![DEVICE_TYPE.MOBILE_200, DEVICE_TYPE.CAR_CHARGER].includes(n)) {
          const errorMessage = `"${n}" is not supported`;
          toast.error(errorMessage);
          this.$emit('close');
        }
      },
    },
  },

  mounted() {
    if (this.devices.length === 1) {
      this.selectedDevice = this.devices[0];
      this.step = 1;
    }
  },

  methods: {
    ...mapActions({
      openModal: 'modal/open',
      replaceSim: 'simReplacement/replace',
      fetchDevices: 'simReplacement/fetchDevices',
      fetchIccids: 'simReplacement/fetchIccids',
    }),
    replace() {
      return this.replaceSim({
        accountId: this.accountId,
        params: {
          device_id: this.selectedDevice.value || this.selectedDevice.id,
          iccid: this.selectedIccid.value,
        },
      });
    },
    rearm() {
      return Promise.all([
        this.fetchDevices({ accountId: this.accountId }),
        this.fetchIccids({ accountId: this.accountId }),
      ]);
    },
    replaceAndRearm() {
      this.replace()
        .then(() => {
          this.rearm().then(() => {
            this.selectedDevice = null;
            this.selectedIccid = null;
            if (this.canReplace) {
              // StepReplaceMore === -2
              this.step = -2;
            } else {
              // StepSuccess === -1
              this.step = -1;
            }
          });
        })
        .catch(() => {
          // StepError === -3
          this.step = -3;
        });
    },
    handleNeedHelp() {
      window.open('https://logistimatics.com/knowledge-base/');
    },
    handleTryAgain() {
      if (this.type === DEVICE_TYPE.MOBILE_200) {
        this.step = 9;
      }
      if (this.type === DEVICE_TYPE.CAR_CHARGER) {
        this.step = 8;
      }
      this.replaceAndRearm();
    },
    handleSuccess() {
      this.$emit('close');
    },
    handleReplaceMore() {
      this.$emit('close');
      setTimeout(() => {
        this.openModal({ name: 'DeviceSimReplaceModal' });
      }, 200);
    },
  },
};
</script>
