<template>
  <NoData
    v-if="geofences.length === 0"
    title="There are no geofences"
    subtitle="Create the first one"
  >
    <UIButtonNew icon="SquaresPlusIcon" @click="handleCreateClick">
      Create Geofence
    </UIButtonNew>
  </NoData>
  <div class="h-full" v-else>
    <CommonSearchBarSafeArea />
    <div class="h-12 bg-lgmx_gray-100"></div>
    <DynamicScroller
      class="h-[calc(100%-48px)] sm:h-[calc(100%-64px)]"
      :items="props.geofences"
      :min-item-size="98"
      key-field="id"
      listClass="ds-geofence-list"
      itemClass="ds-geofence-list-item"
    >
      <template v-slot="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :size-dependencies="[item.name, item.address]"
          :data-index="index"
        >
          <div class="border-b border-gray-100">
            <VLink
              block
              :to="`/geofences/${item.id}`"
              class="bg-white hover:bg-gray-100"
            >
              <div class="flex py-[15px] pl-6 pr-[48px] md:pl-12 md:pr-[56px]">
                <GeofenceListItem
                  class="w-full"
                  :geofence="item"
                  :edit-item-id="editItemId"
                  @setEditItemId="setEditItemId"
                  @resetEditItemId="resetEditItemId"
                />
              </div>
            </VLink>
          </div>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
  </div>
  <CommonBottomButtonSection v-if="isBottomButton">
    <UIButtonNew
      class="w-full"
      icon="SquaresPlusIcon"
      @click="router.push({ name: 'GeofenceCreateView' })"
    >
      New Geofence
    </UIButtonNew>
  </CommonBottomButtonSection>
</template>

<script setup>
import VLink from '@/components/ui/VLink.vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import GeofenceListItem from './GeofenceListItem.vue';
import NoData from '@/components/_common/NoData.vue';
import CommonSearchBarSafeArea from '@/components/_common/CommonSearchBarSafeArea.vue';
import CommonBottomButtonSection from '@/components/_common/CommonBottomButtonSection.vue';
import { useSetItemIdOnDynamicList } from '@/use/use-set-item-id-on-recycle-list';

const props = defineProps({
  geofences: Array,
});

const store = useStore();
const router = useRouter();
const isBottomButton = computed(() => store.getters['geofence/isBottomButton']);

const { editItemId, setEditItemId, resetEditItemId } =
  useSetItemIdOnDynamicList('geofence');

function handleCreateClick() {
  router.push({ name: 'GeofenceCreateView' });
}
</script>

<style>
.ds-geofence-list {
  @apply !overflow-visible;
}
</style>
