<template>
  <button
    role="button"
    class="inline-flex items-center justify-center rounded-[6px] text-gpx_button font-semibold"
    :disabled="disabled || loading"
    :class="[
      { 'bg-lms_red-900 text-white': type === 'solid' },
      { '!bg-gpx_gray-400': type === 'solid' && disabled && !loading },
      { 'border border-gpx_gray-700 text-lms_black-100': type === 'outline' },
      { '!text-gpx_gray-300': type === 'outline' && disabled && !loading },
      { 'bg-gpx_porto-900 text-gpx_porto-300': type === 'secondary' },
      {
        '!bg-gpx_gray-800 !text-gpx_gray-200':
          type === 'secondary' && disabled && !loading,
      },
      { 'w-full rounded-t-none py-[16px]': size === 'modal' },
      { 'cursor-not-allowed': disabled || loading },
    ]"
  >
    <div
      v-show="loading"
      :class="[
        { 'pl-[16px]': size === 'large' },
        { 'pl-[24px]': size === 'medium' },
      ]"
    >
      <Spinner class="h-[16px] w-[16px]" />
    </div>
    <div class="flex">
      <span
        :class="[
          { 'px-[16px] py-[12px]': size === 'large' },
          { 'px-[20px] py-[8px] !text-xs !leading-[24px]': size === 'medium' },
          { 'px-[16px]': size === 'modal' },
        ]"
      >
        <slot name="icon" />
        {{ label }}
      </span>
    </div>
  </button>
</template>

<script>
import Spinner from '@/components/_common/Spinner.vue';

export default {
  name: 'UIButton',
  components: {
    Spinner,
  },
  props: {
    type: {
      type: String,
      validator(value) {
        return ['solid', 'secondary', 'outline'].includes(value);
      },
      default: 'solid',
    },
    size: {
      type: String,
      validator(value) {
        return ['large', 'medium', 'modal'].includes(value);
      },
      default: 'large',
    },
    color: {
      type: String,
      validator(value) {
        return ['red'].includes(value);
      },
      default: 'red',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
};
</script>
