<template>
  <header
    class="sticky top-0 z-10 bg-white px-4 py-2 sm:relative sm:flex sm:px-8"
  >
    <div class="mr-6 flex items-center justify-between">
      <VLink class="py-2" to="/">
        <AppLogo class="max-w-none" />
      </VLink>
    </div>
  </header>
  <div class="container xs:pt-24">
    <slot />
  </div>
</template>

<script>
import AppLogo from '@/components/_common/AppLogo.vue';

export default {
  components: {
    AppLogo,
  },
};
</script>
