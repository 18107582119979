<template>
  <ModalLayout title="Creating new Anchor" large>
    <div
      class="flex rounded-t-[10px] bg-white px-[24px] pb-[26px] pt-[28px] sm:px-[40px] sm:pt-[30px]"
    >
      <div class="relative mr-[15px] h-[50px] w-[50px] shrink-0">
        <div
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          <span class="text-[19px] font-semibold leading-7 text-lgmx_black-200">
            {{ step }}
          </span>
          <span class="text-[13px] leading-5 text-lgmx_gray-900">/4</span>
        </div>
        <CircleProgress
          :percent="circlePercentage"
          :size="50"
          :border-width="6"
          :border-bg-width="6"
          fill-color="#77D770"
          empty-color="#F1F3F5"
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </div>
      <div class="mr-[24px] overflow-hidden">
        <div class="text-[13px] leading-5 text-lgmx_gray-800">
          <span v-if="step === 1">Creating new</span>
          <span v-if="step === 2">Creating new</span>
          <span v-if="step === 3">Creating new</span>
          <span v-if="step === 4">Creating new</span>
        </div>
        <div class="text-ellipsis whitespace-nowrap">
          <div
            class="overflow-hidden text-ellipsis text-[24px] font-semibold leading-7 text-lgmx_black-600"
          >
            {{ name || 'Anchor' }}
          </div>
        </div>
      </div>
      <UITooltipConfirm
        :disabled="!name.length || isSavingBleAnchor"
        text="Discard all changes?"
        class="ml-auto"
        :shown="shouldShowCloseTooltip"
        @on-confirm="confirmOnTooltip"
        @on-cancel="discardOnTooltip"
      >
        <button
          class="flex h-[32px] w-[32px] shrink-0 cursor-pointer items-center justify-center rounded-full bg-lgmx_gray-100"
          @click="safeCloseClick"
        >
          <XMarkIcon
            class="heroicon-stroke-w-1.4 h-[16px] w-[16px] text-lgmx_gray-800"
          />
        </button>
      </UITooltipConfirm>
    </div>
    <Step1
      v-if="isLoaded && step === 1"
      v-model:name="name"
      :device="device"
      :editing="!!bleAnchor"
      @on-confirm="confirmStepOne"
    />
    <Step2
      v-if="step === 2"
      :name="name"
      :device="device"
      @on-confirm="confirmStepTwo"
      @on-cancel="discardStepTwo"
    />
    <Step3
      v-if="step === 3"
      :initial-address-label="address"
      :initial-map-view-options="mapViewOptions"
      :ble-anchor="bleAnchor"
      @on-confirm="confirmStepThree"
      @on-cancel="discardStepThree"
    />
    <Step4
      v-if="step === 4"
      :is-saving-ble-anchor="isSavingBleAnchor"
      :is-uploading-images="isUploadingImages"
      :ble-anchor-has-been-saved="bleAnchorHasBeenSaved"
      :ble-anchor="bleAnchor"
      @on-confirm-save-and-add-more="confirmStepFourAndAddMore"
      @on-confirm-save-and-close="confirmStepFourAndClose"
      @on-cancel="discardStepFour"
    />
  </ModalLayout>
</template>

<script>
import * as _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import 'vue3-circle-progress/dist/circle-progress.css';
import CircleProgress from 'vue3-circle-progress';
import ModalLayout from '@/layouts/ModalLayout.vue';
import Step1 from './Step1.vue';
import Step2 from './Step2.vue';
import Step3 from './Step3.vue';
import Step4 from './Step4.vue';
import { useToast } from 'vue-toastification';
import { COMMON_SAVE_ERROR, COMMON_SAVE_SUCCESS } from '@/config/constants';
import { sleep } from '@/utils/sleep';

const toast = useToast();

export default {
  components: {
    ModalLayout,
    CircleProgress,
    Step1,
    Step2,
    Step3,
    Step4,
  },
  emits: ['close'],
  props: {
    params: Object,
  },

  data() {
    return {
      isSavingBleAnchor: false,
      bleAnchorHasBeenSaved: false,
      isUploadingImages: false,
      shouldShowCloseTooltip: false,
      isLoaded: false,
      step: 1,
      name: '',
      device: null,
      coordinates: {},
      address: '',
      geofence: null,
      radius: null,
      images: [],
      deletedImageIds: [],
      notes: '',
      mapViewOptions: null,
      bleAnchor: null,
    };
  },

  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
    }),
    circlePercentage() {
      if (this.step === 1) return 25;
      if (this.step === 2) return 50;
      if (this.step === 3) return 75;
      if (this.step === 4) return 100;
      return 0;
    },
  },

  mounted() {
    this.bleAnchor = _.get(this.params, 'bleAnchor', null);
    this.name = _.get(this.params, 'bleAnchor.name', '');
    this.mapViewOptions = _.get(this.params, 'mapViewOptions', null);
    this.address = _.get(this.params, 'bleAnchor.address', '');
    this.device = _.get(this.params, 'bleAnchor.device', null);
    this.step = _.get(this.params, 'step', 1);
    this.isLoaded = true;
  },

  methods: {
    ...mapActions({
      store: 'bleAnchor/store',
      update: 'bleAnchor/update',
      storeImages: 'bleAnchor/storeImages',
      removeImage: 'bleAnchor/removeImage',
      close: 'modal/close',
      open: 'modal/open',
    }),
    closeModal() {
      this.close('GeofenceCreateBleAnchorModal');
    },
    safeCloseClick() {
      if (this.name.length) {
        this.shouldShowCloseTooltip = true;
        return;
      }
      this.closeModal();
    },
    confirmOnTooltip() {
      this.shouldShowCloseTooltip = false;
      this.closeModal();
    },
    discardOnTooltip() {
      this.shouldShowCloseTooltip = false;
    },
    toggleCloseTooltip(value) {
      this.shouldShowCloseTooltip = value;
    },
    goToStep(step) {
      this.step = step;
    },
    confirmStepOne(device) {
      this.device = device;
      this.goToStep(2);
    },
    confirmStepTwo() {
      this.goToStep(3);
    },
    confirmStepThree({
      coordinates,
      address,
      radius,
      geofence,
      mapViewOptions,
    }) {
      this.geofence = geofence;
      this.coordinates = coordinates;
      this.address = address;
      this.radius = radius;
      this.mapViewOptions = mapViewOptions;
      this.goToStep(4);
    },
    confirmStepFour({ images, notes, deletedImageIds }) {
      this.images = images;
      this.notes = notes;
      this.deletedImageIds = deletedImageIds;
    },
    confirmStepFourAndAddMore({ images, notes, deletedImageIds }) {
      this.confirmStepFour({ images, notes, deletedImageIds });
      let result;
      if (this.bleAnchor) {
        result = this.updateAndProcessImage();
      } else {
        result = this.saveAndUpload();
      }
      result.then(async () => {
        const mapViewOptions = this.mapViewOptions;
        const address = this.address;
        this.closeModal();
        await sleep(500);
        this.open({
          name: 'GeofenceCreateBleAnchorModal',
          params: { mapViewOptions, address },
        });
      });
    },
    confirmStepFourAndClose({ images, notes, deletedImageIds }) {
      this.confirmStepFour({ images, notes, deletedImageIds });
      if (this.bleAnchor) {
        this.updateAndProcessImage().then(() => this.closeModal());
      } else {
        this.saveAndUpload().then(() => this.closeModal());
      }
    },
    prepareAnchorData() {
      return {
        name: this.name,
        serial: this.device.serial,
        geofence_id: this.geofence.id,
        address: this.address,
        radius: this.radius,
        lat: this.coordinates.lat,
        lng: this.coordinates.lng,
        notes: this.notes,
      };
    },
    saveAndUpload() {
      this.isSavingBleAnchor = true;
      return this.store({ params: this.prepareAnchorData() })
        .then((res) => {
          this.bleAnchorHasBeenSaved = true;
          const anchorId = res.data.data.id;
          this.isSavingBleAnchor = false;
          this.isUploadingImages = true;
          const promise = Promise.all(
            this.images.map((image) =>
              this.storeImages({ anchorId, params: { image } }),
            ),
          );
          promise.finally(() => {
            this.isUploadingImages = false;
            toast.success(COMMON_SAVE_SUCCESS);
          });
          return promise;
        })
        .catch((e) => {
          this.isSavingBleAnchor = false;
          toast.error(e.response?.data?.message || COMMON_SAVE_ERROR);
        });
    },
    async updateAndProcessImage() {
      this.isSavingBleAnchor = true;
      this.isUploadingImages = true;

      Promise.all([
        Promise.all(
          this.images.map((image) =>
            this.storeImages({
              anchorId: this.bleAnchor.id,
              params: { image },
            }),
          ),
        ),
        Promise.all(
          this.deletedImageIds.map((imageId) =>
            this.removeImage({ anchorId: this.bleAnchor.id, imageId }),
          ),
        ),
      ]).then(() =>
        this.update({
          params: {
            ...this.prepareAnchorData(),
            id: this.bleAnchor.id,
          },
        })
          .then(() => {
            this.isUploadingImages = false;
            this.bleAnchorHasBeenSaved = true;
            this.isSavingBleAnchor = false;
          })
          .catch((e) => {
            this.isSavingBleAnchor = false;
            toast.error(e.response?.data?.message || COMMON_SAVE_ERROR);
          }),
      );
    },
    discardStepTwo() {
      this.goToStep(1);
    },
    discardStepThree() {
      this.goToStep(2);
    },
    discardStepFour() {
      this.goToStep(3);
    },
  },
};
</script>
