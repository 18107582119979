<template>
  <VeeForm @submit="submit" v-slot="{ setFieldError, values }">
    <div>
      <HeadingSection
        class="mb-[30px]"
        heading="General"
        desc="Enter main shipment data"
      />
      <div class="grid grid-cols-2 gap-x-[24px] gap-y-[31px]">
        <VeeInput
          name="name"
          label="Shipment Name"
          placeholder="Shipment name"
          :validateFn="validateShipmentName"
        />
        <VeeInput name="carrier" label="Carrier" placeholder="Carrier" />
        <VeeCalendarSingle
          name="start_at"
          label="Start Date"
          placeholder="00/00/0000"
          :validateFn="validateDate"
        />
        <VeeCalendarSingle
          name="end_at"
          label="End Date"
          placeholder="00/00/0000"
          :validateFn="validateDate"
        />
        <VeeCombobox
          name="device"
          class="col-span-2"
          label="Tracker"
          placeholder="Select Tracker"
          :options="options"
          :validateFn="validateDevice"
          openOnFocus
        />
      </div>
    </div>
    <div class="mt-[51px]">
      <HeadingSection
        class="mb-[30px]"
        heading="Locations"
        desc="Choose start and ending locations"
      />
      <VeeCombobox
        name="from_geofence"
        class="col-span-2"
        label="Start Location"
        placeholder="Select Geofence"
        :options="geofences"
        :disabled="isCustomStart"
        :validateFn="validateFromGeofence"
        openOnFocus
      />
      <UICheckbox
        class="mt-[16px]"
        label="Use an address instead"
        v-model="isCustomStart"
        @click.stop="setFieldError('from_geofence', null)"
      />
      <VeeGoogleAutocomplete
        v-if="isCustomStart"
        name="from_address"
        class="col-span-2 mt-[19px]"
        label="Custom Address"
        placeholder="Enter your address"
        :validateFn="validateFromAddress"
      />
      <div
        class="mb-[27px] mt-[24px] h-[320px] overflow-hidden rounded-md bg-slate-500"
      >
        <ShipmentMapSmall
          id="from_address"
          :isCustom="isCustomStart"
          :geofence="values.from_geofence"
          :address="values.from_address"
        />
      </div>
      <VeeCombobox
        name="to_geofence"
        class="col-span-2"
        label="Ending Location"
        placeholder="Select Geofence"
        :options="geofences"
        :disabled="isCustomEnd"
        :validateFn="validateToGeofence"
        openOnFocus
      />
      <UICheckbox
        class="mt-[16px]"
        label="Use an address instead"
        v-model="isCustomEnd"
        @click.stop="setFieldError('to_geofence', null)"
      />
      <VeeGoogleAutocomplete
        v-if="isCustomEnd"
        name="to_address"
        class="col-span-2 mt-[19px]"
        label="Custom Address"
        placeholder="Enter your address"
        :validateFn="validateToAddress"
      />
      <div
        class="mb-[27px] mt-[24px] h-[320px] overflow-hidden rounded-md bg-slate-500"
      >
        <ShipmentMapSmall
          id="to_address"
          :isCustom="isCustomEnd"
          :geofence="values.to_geofence"
          :address="values.to_address"
        />
      </div>
    </div>
    <div class="mt-[51px]">
      <HeadingSection
        class="mb-[30px]"
        heading="Notifications"
        desc="Manage shipment notifications"
      />
      <div class="flex flex-col gap-y-[27px]">
        <VeeSelect
          name="notify_at"
          label="Notify before shipment end"
          placeholder="Select One"
          :initialValue="notificaitonOptionsJSON[0]"
          :options="notificaitonOptionsJSON"
          :validateFn="validateNotificationOption"
        />
        <VeeCombobox
          name="notification_emails"
          label="Add more notification emails"
          placeholder="Select"
          :options="emails"
          openOnFocus
          multiple
        />
        <VeeCombobox
          name="notification_phone_numbers"
          label="Add more notification phone numbers"
          placeholder="Select"
          :options="phones"
          openOnFocus
          multiple
        />
      </div>
    </div>
    <div class="mt-[40px] flex gap-x-[16px] sm:justify-end">
      <UIButtonNew
        class="w-full sm:w-auto"
        variant="destructive"
        @click="onCancel"
      >
        Cancel
      </UIButtonNew>
      <UIButtonNew
        class="w-full !px-12 sm:w-auto"
        type="submit"
        :is-loading="isSubmitting"
        is-loading-text="Saving"
      >
        Save
      </UIButtonNew>
    </div>
  </VeeForm>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import HeadingSection from '@/components/_common/Heading/HeadingSection.vue';
import VeeInput from '@/components/vee/VeeInput.vue';
import VeeCalendarSingle from '@/components/vee/VeeCalendarSingle.vue';
import VeeCombobox from '@/components/vee/VeeCombobox.vue';
import VeeGoogleAutocomplete from '@/components/vee/VeeGoogleAutocomplete.vue';
import VeeSelect from '@/components/vee/VeeSelect.vue';
import notificaitonOptionsJSON from '@/assets/shipment-notification-options.json';
import ShipmentMapSmall from '../map/ShipmentMapSmall.vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useDevice } from '@/composables/useDevice';
import { storeToRefs } from 'pinia';

const store = useStore();
const router = useRouter();
const toast = useToast();
const isSubmitting = ref(false);

const deviceStore = useDevice();
const { fetchByAccountId } = deviceStore;
const { devices } = storeToRefs(deviceStore);

const accountId = store.getters['auth/accountId'];

onMounted(() =>
  fetchByAccountId(accountId, {
    subscription_status: 'active',
  }),
);
const options = computed(() => {
  const result = [];
  devices.value.forEach((device) => {
    result.push({
      value: device.id,
      label: device.name,
    });
  });
  return result;
});

onMounted(() => store.dispatch('geofence/fetch'));
const geofences = computed(() =>
  store.getters['geofence/all'].map((item) => ({
    value: item.id,
    label: item.name,
  })),
);

const isCustomStart = ref(false);
const isCustomEnd = ref(false);

onMounted(() => store.dispatch('user/fetch'));
const emails = computed(() =>
  store.getters['user/all']
    .filter((item) => item.email)
    .map((item) => ({
      value: item.email,
      label: item.email,
    })),
);
const phones = computed(() =>
  store.getters['user/all']
    .filter((item) => item.phone)
    .map((item) => ({
      value: item.phone,
      label: item.phone,
    })),
);

const submit = (values) => {
  const {
    name,
    carrier,
    start_at,
    end_at,
    device,
    from_geofence,
    from_address,
    to_geofence,
    to_address,
    notify_at,
    notification_emails,
    notification_phone_numbers,
  } = values;

  const params = {
    name,
    carrier,
    start_at: new Date(start_at).toISOString(),
    end_at: new Date(end_at).toISOString(),
    device_id: device.value,
    ...(isCustomStart.value
      ? {
          from_address: {
            name: from_address.label,
            lat: from_address.lat,
            lng: from_address.lng,
          },
        }
      : { from_geofence_id: from_geofence.value }),
    ...(isCustomEnd.value
      ? {
          to_address: {
            name: to_address.label,
            lat: to_address.lat,
            lng: to_address.lng,
          },
        }
      : { to_geofence_id: to_geofence.value }),
    notify_at,
    notification_emails: notification_emails?.map((e) => e.value),
    notification_phone_numbers: notification_phone_numbers?.map((e) => e.value),
  };
  isSubmitting.value = true;
  store
    .dispatch('shipment/store', { accountId, params })
    .then((res) => {
      router.replace({
        name: 'ShipmentDetailsView',
        params: { id: res.data.data.id },
      });
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      isSubmitting.value = false;
    });
};

const onCancel = () => {
  router.push({
    name: 'ShipmentListView',
    query: {
      search: store.getters['shipment/search'],
    },
  });
};

const validateShipmentName = (value) => (!value ? 'Name is required' : true);
const validateDate = (value) => (!value ? 'date is required' : true);
const validateDevice = (value) => {
  if (!value) return 'tracker is required';
  if (typeof value.label !== 'string') return 'label is not a string';
  if (typeof value.value !== 'number') return 'id is not a number';
  return true;
};
const validateFromGeofence = (value) => {
  if (isCustomStart.value) return true;
  if (!value) return 'geofence is required';
  if (typeof value.label !== 'string') return 'label is not a string';
  if (typeof value.value !== 'number') return 'id is not a number';
  return true;
};
const validateToGeofence = (value) => {
  if (isCustomEnd.value) return true;
  if (!value) return 'geofence is required';
  if (typeof value.label !== 'string') return 'label is not a string';
  if (typeof value.value !== 'number') return 'id is not a number';
  return true;
};
const validateFromAddress = (value) => {
  if (!isCustomStart.value) return true;
  if (!value) return 'address is required';
  if (typeof value.label !== 'string') return 'label is not a string';
  if (!value.lat || !value.lng) return 'lat and lng are required';
  return true;
};
const validateToAddress = (value) => {
  if (!isCustomEnd.value) return true;
  if (!value) return 'address is required';
  if (typeof value.label !== 'string') return 'label is not a string';
  if (!value.lat || !value.lng) return 'lat and lng are required';
  return true;
};
const validateNotificationOption = (value) => {
  if (!value) return 'option is required';
  if (typeof value.label !== 'string') return 'label is not a string';
  if (typeof value.value !== 'number') return 'id is not a number';
  return true;
};
</script>
