import { fixColorMap } from '@/config/constants';
import { reverseCoords } from '@/utils/coords';
import L from 'leaflet';

export function createCellCircle(position) {
  const radius = position.accuracy / 1000 ?? 1;
  const latlng = reverseCoords(position.location);
  return L.circle(latlng, {
    radius,
    color: fixColorMap.cell,
    weight: 1,
    opacity: 0.2,
    fillColor: fixColorMap.cell,
    fillOpacity: 0.08,
  });
}

export function createWifiCircle(position) {
  const radius = position.accuracy / 1000 ?? 1;
  const latlng = reverseCoords(position.location);
  return L.circle(latlng, {
    radius,
    color: fixColorMap.wifi,
    weight: 1,
    opacity: 0.3,
    fillColor: fixColorMap.wifi,
    fillOpacity: 0.15,
  });
}
