<template>
  <BaseLayout>
    <div class="mx-auto mb-[80px] max-w-[620px] sm:mb-[120px] sm:mt-[56px]">
      <div class="flex justify-between">
        <h1 class="mt-[7px] text-gpx_2xl font-semibold text-lms_black-500">
          Edit Rule
        </h1>
      </div>
      <RuleForm :ruleId="ruleId" />
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout.vue';
import RuleForm from '@/components/rules/RuleForm.vue';

export default {
  name: 'RulesEdit',
  components: {
    BaseLayout,
    RuleForm,
  },
  computed: {
    ruleId() {
      return parseInt(this.$route.params.id, 10);
    },
  },
};
</script>
