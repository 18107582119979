<template>
  <div>
    <span v-if="lastFixTime">Last connected at {{ formattedLastConnect }}</span>
    <span v-else>Not Connected</span>
  </div>
</template>

<script>
import format from 'date-fns/format';
import get from 'lodash/get';

export default {
  props: ['device', 'position'],
  computed: {
    lastFixTime() {
      return this.position?.timestamp;
    },
    formattedLastConnect() {
      return format(new Date(this.lastFixTime), 'MMM do, h:mmaaa');
    },
  },
};
</script>
