import _ from 'lodash';

const processStrategy = (user) => {
  const relations = {};
  const omit = [];
  return { ..._.omit(user, omit), ..._.pickBy(relations, _.identity) };
};

export default {
  key: 'user',
  definition: {},
  processStrategy,
};
