<template>
  <div class="flex">
    <component
      :is="disabled ? 'span' : 'router-link'"
      :to="to"
      class="inline-flex w-full cursor-pointer items-center rounded-l pb-[9px] pl-4 pt-[7px] text-[15px] font-medium leading-6 text-lgmx_slate-500"
      :class="linkClass"
    >
      <component
        v-if="icon"
        :is="icon"
        class="mr-[10px] h-5 w-5 stroke-lgmx_slate-500 stroke-[1px]"
      />
      <slot />
    </component>
    <div
      class="hidden h-0 w-0 border-y-[20px] border-l-[10px] border-y-transparent border-l-lgmx_gray-200"
    />
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    linkClass: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      required: true,
    },
    icon: String,
  },
};
</script>

<style scoped>
a.router-link-active {
  @apply bg-lgmx_gray-200 text-lgmx_black-100;
}
a.router-link-active + div {
  @apply block;
}
a.router-link-active svg {
  @apply stroke-lgmx_slate-700;
}
</style>
