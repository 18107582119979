<template>
  <div
    class="relative grid h-[88px] w-[140px] grid-cols-2 grid-rows-2 rounded-[6px] px-[16px] pb-[11px] pt-[14px]"
    style="font-feature-settings: 'salt' on"
  >
    <img
      class="pointer-events-none absolute -z-10 h-full w-full select-none"
      :src="bgImage"
      :alt="computedType"
    />
    <div :class="{ 'pt-[2px]': computedType === 'visa' }">
      <img :src="brandImage" :alt="computedType" />
    </div>

    <div
      class="col-start-1 row-start-2 self-end text-gpx_sm tracking-[0.04em] text-white"
    >
      {{ valid }}
    </div>
    <div
      class="row-start-2 flex gap-x-[4px] self-end justify-self-end text-gpx_sm tracking-[0.04em] text-white"
    >
      <span>*</span><span>{{ digits }}</span>
    </div>
    <button
      class="absolute right-[4px] top-[2px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center"
      @click="handleDeleteCard"
    >
      <UISpinner v-if="deleting" size="sm" />
      <TrashIcon
        v-else
        class="heroicon-stroke-w-1.2 h-[20px] w-[20px] text-white"
      />
    </button>
  </div>
</template>

<script>
import { TrashIcon } from '@heroicons/vue/24/outline';
import { mapActions, mapGetters } from 'vuex';
import BgVisaCard from '@/assets/payment/bg-visa.png';
import BgMasterCard from '@/assets/payment/bg-mastercard.png';
import BgAmexCard from '@/assets/payment/bg-amex.png';
import BgDiscoverCard from '@/assets/payment/bg-discover.png';
import BrandVisaCard from '@/assets/payment/card-visa.svg';
import BrandMasterCard from '@/assets/payment/card-mastercard.svg';
import BrandAmexCard from '@/assets/payment/card-amex.svg';
import BrandDiscoverCard from '@/assets/payment/card-discover.svg';

export default {
  name: 'CreditCard',
  emits: ['deleteCard'],
  props: {
    cardId: {
      type: String,
      required: true,
    },
    type: {
      type: String, // Visa, Mastercard, AMEX
      required: true,
    },
    valid: {
      type: String,
      required: true,
    },
    digits: {
      type: String,
      required: true,
    },
  },
  components: {
    TrashIcon,
  },
  data() {
    return {
      deleting: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
    }),
    bgImage() {
      const map = {
        visa: BgVisaCard,
        mastercard: BgMasterCard,
        amex: BgAmexCard,
        discover: BgDiscoverCard,
      };
      return map[this.computedType];
    },
    brandImage() {
      const map = {
        visa: BrandVisaCard,
        mastercard: BrandMasterCard,
        amex: BrandAmexCard,
        discover: BrandDiscoverCard,
      };
      return map[this.computedType];
    },
    computedType() {
      return this.type.toLowerCase();
    },
  },
  methods: {
    ...mapActions({
      deleteCard: 'paymentMethod/delete',
    }),
    handleDeleteCard() {
      this.deleting = true;
      this.deleteCard({
        accountId: this.accountId,
        paymentMethodId: this.cardId,
      }).then(() => {
        this.deleting = false;
      });
    },
  },
};
</script>
