<template>
  <VCombobox label="Customer" size="sm" placeholder="Select..." :options="[]" />
  <VInput size="sm" type="number" label="Minutes" placeholder="0" />
  <VButton type="submit" class="w-full" variant="primary">
    Add/Remove Minutes
  </VButton>
</template>

<script>
import VCombobox from '@/components/ui/VCombobox.vue';
export default {
  name: 'UtilityAddRemoveMinutesForm',
  components: {
    VCombobox,
  },
};
</script>
