import * as _ from 'lodash';
import L from 'leaflet';
import { reverseCoords } from '@/utils/coords';
import { shipmentStatusColorMap } from '@/config/constants';
import { getGoogleMapsLink } from '@/utils/map/google';
import * as newMarkersPack from '@/utils/map/new-markers';
import * as markersPack from '@/utils/map/markers';

export function createMarker(shipment, options = {}) {
  const latlng = reverseCoords(shipment.current_position.location);
  const status = shipment.status;
  const fix = _.get(shipment, 'current_position.fix');
  const iconsPack = options.isNewIcon ? newMarkersPack : markersPack;
  const icons =
    fix === 'gps'
      ? iconsPack['GPS_ICONS']
      : fix === 'ble'
        ? iconsPack['BLE_ICONS']
        : iconsPack['CELL_ICONS'];

  let icon;
  if (status === 'In Progress') icon = icons['blue'];
  if (status === 'Completed') icon = icons['green'];
  if (status === 'Not Started') icon = icons['gray'];
  if (status === 'Running Late') icon = icons['red'];
  if (status === 'Completed Late') icon = icons['red'];
  return L.marker(latlng, { id: shipment.id, icon });
}

export function getPopupContent({ shipment }) {
  const name = _.get(shipment, 'name', '-');
  const deviceName = _.get(shipment, 'device.name', '-');
  const status = _.get(shipment, 'status', null);
  const address = _.get(
    shipment,
    'current_position.address',
    'no address data',
  );
  const location = _.get(shipment, 'current_position.location', []);
  const badgeColorClass = shipmentStatusColorMap[status];

  return `
  <div>
    <div class="font-medium text-xs">${name}</div>
    <div class="text-[10px]">
      ${deviceName}
    </div>
    <div class="text-[10px]">
      <a href=${getGoogleMapsLink({
        lat: location[1],
        lng: location[0],
      })} target="_blank">${address}</a>
    </div>
    <div class="text-[9px] inline-block whitespace-nowrap rounded py-[2px] px-1 capitalize leading-none text-white ${badgeColorClass}">
      ${status}
    </div>
  </div>
  `;
}
