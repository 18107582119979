<template>
  <BaseLayout>
    <div class="mx-auto my-[72px] max-w-[560px]">
      <h1
        class="mb-[43px] border-b border-b-gpx_gray-800 pb-[17px] text-gpx_2xl font-semibold text-lms_black-500"
      >
        New Shipment
      </h1>
      <ShipmentCreateForm />
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout.vue';
import ShipmentCreateForm from '@/components/shipment/create/ShipmentCreateForm.vue';

export default {
  components: {
    BaseLayout,
    ShipmentCreateForm,
  },
};
</script>
