<template>
  <CommonGoBack label="Cancel" @click="goBack" />
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { usePrevRoute } from '@/use/use-prev-route';
import CommonGoBack from '@/components/_common/CommonGoBack.vue';

const router = useRouter();
const store = useStore();
const prevRoute = usePrevRoute();

function goBack() {
  if (prevRoute.name === 'GeofenceDetailsView') {
    router.back();
    return;
  }
  router.push({
    name: 'GeofenceListView',
    query: { search: store.getters['geofence/search'] },
  });
}
</script>
