export const legacyRedirects = [
  {
    path: '/dashboard/devices',
    redirect: () => ({
      name: 'DeviceListView',
    }),
  },
  {
    path: '/dashboard/devices/:id',
    redirect: (to) => ({
      name: 'DeviceDetailsView',
      params: { id: to.params.id },
    }),
  },
  {
    path: '/dashboard/geofences',
    redirect: () => ({
      name: 'GeofenceListView',
    }),
  },
  {
    path: '/dashboard/geofences/:id',
    redirect: (to) => ({
      name: 'GeofenceDetailsView',
      params: { id: to.params.id },
    }),
  },
  {
    path: '/dashboard/geofences/new',
    redirect: () => ({
      name: 'GeofenceCreateView',
    }),
  },
  {
    path: '/dashboard/shipments',
    redirect: () => ({
      name: 'ShipmentListView',
    }),
  },
  {
    path: '/dashboard/shipments/:id',
    redirect: (to) => ({
      name: 'ShipmentDetailsView',
      params: { id: to.params.id },
    }),
  },
  {
    path: '/dashboard/shipments/new',
    redirect: () => ({
      name: 'ShipmentCreateView',
    }),
  },
  {
    path: '/dashboard/alerts',
    redirect: () => ({
      name: 'AlertsView',
    }),
  },
  {
    path: '/dashboard/reports',
    redirect: () => ({
      name: 'ReportsView',
    }),
  },
  {
    path: '/dashboard/rules',
    redirect: () => ({
      name: 'RulesManageView',
    }),
  },
  {
    path: '/dashboard/account',
    redirect: () => ({
      name: 'AccountSettingsView',
    }),
  },
  {
    path: '/dashboard/users',
    redirect: () => ({
      name: 'ManageUsersView',
    }),
  },
  {
    path: '/my-account/orders',
    redirect: () => ({
      name: 'ManageOrdersView',
    }),
  },
  {
    path: '/my-account/subscriptions',
    redirect: () => ({
      name: 'ManageSubscriptionsView',
    }),
  },
];
