<template>
  <fieldset class="rounded-[6px] border border-gpx_gray-700">
    <slot></slot>
  </fieldset>
</template>

<script>
export default {
  name: 'UIRadioGroup',
};
</script>
