<template>
  <section>
    <ManageOrdersTable v-if="accountId" :accountId="accountId" />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import ManageOrdersTable from '@/components/manage/order/list/ManageOrdersTable.vue';
import { setTitle } from '@/utils/title';

export default {
  components: { ManageOrdersTable },

  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
    }),
  },

  created() {
    setTitle('Order History');
  },
};
</script>
