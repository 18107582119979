<script>
import * as _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import PaginatedTableVue from '@/components/_common/Data/mixins/PaginatedTable.vue';
import { formatTime } from '@/filters';
import { PAYABLE_ORDER_STATUSES } from '@/config/constants';

export default {
  props: {
    accountId: {
      required: true,
      type: Number,
    },
  },

  mixins: [PaginatedTableVue],

  data() {
    return {
      title: 'Orders',
      subtitle: 'View your order history',
      columns: ['Order Number', 'Date', 'Status'],
      editActions: [
        {
          icon: 'CurrencyDollarIcon',
          label: 'Pay Now',
          shouldShow: (item) =>
            PAYABLE_ORDER_STATUSES.includes(item.status.status),
          action: {
            mode: 'async-fn',
            fn: (orderId) => this.handlePayNow(orderId),
          },
        },
        {
          icon: 'ClipboardDocumentListIcon',
          label: 'View Invoice',
          action: {
            mode: 'async-fn',
            fn: (orderId) => this.handleViewInvoice(orderId),
          },
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      itemsByIds: 'order/byIds',
      paymentsByOrderId: 'payment/byOrderId',
    }),
  },

  watch: {
    accountId() {
      this.loadPage();
    },
  },

  methods: {
    formatTime,
    ...mapActions({
      openModal: 'modal/open',
      fetchOrdersByAccountId: 'order/fetchByAccountId',
      fetchOrderById: 'order/fetchById',
    }),
    fetchOrders(params) {
      params.include = ['orderItemsCount'];
      return this.fetchOrdersByAccountId({
        accountId: this.accountId,
        params,
        include: 'draft',
      });
    },
    fetchItems(pagination) {
      const params = _.assign({}, pagination);
      return this.fetchOrders(params);
    },
    formatItemIntoRow(order) {
      const isEditable = !['cart', 'draft'].includes(order.status);
      return {
        id: order.id,
        number: `#${order.id}`,
        date: formatTime(order.created_at),
        status: {
          status: _.get(order, 'status'),
        },
        edit: isEditable,
      };
    },
    onItemClick(item) {
      this.$router.push({
        name: 'ManageOrderView',
        params: { id: item.id },
      });
    },
    handlePayNow(orderId) {
      return this.fetchOrderById({
        orderId,
        params: {
          include: 'account,billingAddress,invoiceItems,payments',
        },
      }).then(() => {
        this.openModal({
          name: 'ManageOrderInvoiceModal',
          params: {
            mode: 'pay',
            orderId,
          },
        });
      });
    },
    handleViewInvoice(orderId) {
      return this.fetchOrderById({
        orderId,
        params: {
          include: 'account,billingAddress,invoiceItems,payments',
        },
      }).then(() => {
        this.openModal({
          name: 'ManageOrderInvoiceModal',
          params: {
            mode: 'view',
            orderId,
          },
        });
      });
    },
  },
};
</script>

<style></style>
