<template>
  <div class="pt-[25px]">
    <div class="mb-[14px] text-[13px] leading-5 text-lgmx_black-200">
      With the USB ports pointed down, once you open up your Car Charger you’ll
      see a piece of metal covering white SIM card on the right hand side.
    </div>

    <div class="mb-[18px] flex justify-center">
      <img :src="svg1" alt="SIM door location" />
    </div>

    <div class="text-[13px] leading-5 text-lgmx_black-200">
      Using your fingernail, slide the piece of metal down towards the USB
      ports. The door will open and your SIM card should pop out. Set it aside.
    </div>

    <div class="flex justify-between gap-x-4 pb-[18px] pt-[26px]">
      <UIButtonNew
        variant="tertiary"
        size="lg"
        class="w-[124px]"
        @click="$emit('onCancel')"
      >
        Back
      </UIButtonNew>
      <UIButtonNew @click="$emit('onConfirm')" class="w-full">
        Next
      </UIButtonNew>
    </div>
  </div>
</template>

<script>
import Step3Svg1 from './assets/step-3.svg';

export default {
  emits: ['onConfirm', 'onCancel'],

  computed: {
    svg1() {
      return Step3Svg1;
    },
  },
};
</script>
