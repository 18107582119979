<template>
  <div
    class="relative rounded-sm border border-[#f5c6cb] bg-[#f8d7da] px-4 py-3"
  >
    <span class="text-sm font-medium text-red-900">
      <ExclamationTriangleIcon class="mr-1 h-4 w-4 text-red-900" />
      Irreversible Action
    </span>
    <span class="inline-block text-sm"
      >Proceed with caution, this action is irreversible and will remove all
      tracking data.</span
    >
  </div>
  <VCombobox label="Device" size="sm" placeholder="Select..." :options="[]" />
  <VButton type="submit" class="w-full" variant="primary">
    Clear Device
  </VButton>
</template>

<script>
import VCombobox from '@/components/ui/VCombobox.vue';
export default {
  name: 'UtilityClearDeviceForm',
  components: {
    VCombobox,
  },
};
</script>
