<template>
  <div
    class="mx-auto max-w-md rounded-[4px] border-t-[5px] border-red-400 px-4 py-16 shadow xs:px-10"
  >
    <h1 class="mb-5 text-center text-4xl font-normal leading-tight">
      {{ title }}
    </h1>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
};
</script>

<style></style>
