import { computed, ref, watch } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { groupMatchesSearch } from '@/utils/group-filter.js';
import service from '@/services';
import * as events from '@/events';
import { useDevice } from '@/composables/useDevice.ts';
import { getFilterResults } from '@/utils/device-filter.ts';
import debounce from 'lodash/debounce';

export const useGroup = defineStore('group', () => {
  const deviceStore = useDevice();
  const {
    getById: getDeviceById,
    setSelectedIds: setSelectedDeviceIds,
    fetchIdsByAccountIdAndParams,
  } = deviceStore;
  const { isAtLeastOneFilterApplied, filter } = storeToRefs(deviceStore);

  const groups = ref(new Map());
  const search = ref('');
  const accountId = ref(null);
  const selectedIds = ref([]);
  const resultDeviceIds = ref([]);
  const isLoading = ref(false);

  const searchedIds = computed(() => {
    const searchStr = search.value.toLowerCase();
    const foundIds = [];

    groups.value.forEach((group) => {
      if (groupMatchesSearch(group, searchStr)) {
        foundIds.push(group.id);
      }
    });

    foundIds.sort(function (a, b) {
      return b - a;
    });

    return foundIds;
  });

  const getById = (id: number) => {
    return groups.value.get(id);
  };

  const getByIds = (ids: number[]) => ids.map((id) => getById(id));

  const updateGroupInfo = (groupResource) => {
    groupResource.devices_count = groupResource?.device_ids.length;
    groups.value.set(groupResource.id, groupResource);
  };

  const fillStoreGroupFromResponse = (
    mapGroups: Map<number, any>,
    rawGroups?: any,
    isReset: boolean = false,
  ) => {
    if (isReset) {
      mapGroups.clear();
    }
    rawGroups = rawGroups || [];
    for (const group of rawGroups) {
      mapGroups.set(group.id, group);
    }

    return mapGroups;
  };

  const fetchByAccountId = async (accountId: number, params?: any) => {
    return service
      .get(`/accounts/${accountId}/groups`, { params })
      .then((res) => {
        fillStoreGroupFromResponse(groups.value, res.data.data, true);
        return res;
      });
  };

  const fetchByAccountIdAndId = async (
    accountId: number,
    id: number,
    params?: any,
  ) => {
    return service
      .get(`/accounts/${accountId}/groups/${id}`, { params })
      .then((res) => {
        fillStoreGroupFromResponse(groups.value, [res.data.data], true);
        return res;
      });
  };

  const storeGroup = async (accountId: number, params: any) => {
    return service.post(`/accounts/${accountId}/groups`, params).then((res) => {
      const group = res.data.data;

      updateGroupInfo(group);

      events.trigger(events.names.GROUP_CREATED, {
        group,
      });
      return res;
    });
  };

  const updateGroup = async (accountId: number, id: number, params: any) => {
    return service
      .put(`/accounts/${accountId}/groups/${id}`, params)
      .then((res) => {
        events.trigger(events.names.GROUP_EDITED, {
          params,
          group: res.data.data,
        });

        groups.value.set(id, res.data.data);
      });
  };

  const addDevices = async (accountId: number, id: number, params: any) => {
    return service
      .post(`/accounts/${accountId}/groups/${id}/devices`, params)
      .then((res) => {
        const group = res.data.data;
        updateGroupInfo(group);

        events.trigger(events.names.DEVICES_ADDED_TO_GROUP, {
          group,
          params,
        });
        return res;
      });
  };

  const swapDevices = async (accountId: number, id: number, params: any) => {
    return service
      .post(`/accounts/${accountId}/groups/${id}/devices-swap`, params)
      .then((res) => {
        const group = res.data.data;
        updateGroupInfo(group);

        events.trigger(events.names.GROUP_DEVICES_SWAPPED, {
          group,
          params,
        });
        return res;
      });
  };

  const removeDevices = async (accountId: number, id: number, params: any) => {
    return service
      .delete(`/accounts/${accountId}/groups/${id}/devices`, { data: params })
      .then((res) => {
        const group = res.data.data;
        updateGroupInfo(group);

        setSelectedDeviceIds([]);
        events.trigger(events.names.GROUP_DEVICES_REMOVED, {
          group,
          params,
        });

        return res;
      });
  };

  const deleteGroup = async (accountId: number, id: number) => {
    return service.delete(`/accounts/${accountId}/groups/${id}`).then((res) => {
      groups.value.delete(id);
      events.trigger(events.names.GROUP_DELETED, {
        group: id,
      });
      return res;
    });
  };

  const getDeviceIdsByGroup = async (accountId: number, group_ids: any[]) => {
    return fetchIdsByAccountIdAndParams(accountId, { group_ids });
  };

  const loadDeviceIdsByGroups = debounce(async (ids: number[]) => {
    isLoading.value = true;
    resultDeviceIds.value =
      (await getDeviceIdsByGroup(accountId.value, ids)) ?? [];
    isLoading.value = false;
  }, 150);

  watch(selectedIds, loadDeviceIdsByGroups);

  return {
    groups,
    search,
    selectedIds,
    searchedIds,
    resultDeviceIds,
    isLoading,

    getByIds,
    getById,
    storeGroup,
    setSearch: (val: string) => (search.value = val),
    setAccountId: (val: number) => (accountId.value = val),
    setSelectedIds: (ids: number[]) => (selectedIds.value = ids),
    clearResultDeviceIds: () => (resultDeviceIds.value = []),
    fetchByAccountId,
    fetchByAccountIdAndId,
    addDevices,
    updateGroup,
    swapDevices,
    removeDevices,
    deleteGroup,
    getDeviceIdsByGroup,
    loadDeviceIdsByGroups,
  };
});
