import service from '@/services';
import { BATCH_ADD_OR_UPDATE, REMOVE, ADD } from '../mutation-types';
import { addOrUpdateItems, processAndReturn, removeItem } from '../helpers';
import * as events from '@/events';

export const state = {
  items: [],
};

export const getters = {
  all: (state) => state.items,
  byId: (state) => (id) => state.items.find((item) => item.id === id),
  byIds: (state) => (ids) =>
    state.items.filter((item) => ids.indexOf(item.id) > -1),
};

export const mutations = {
  [ADD]: (state, items) => (state.items = items),
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [REMOVE]: (state, item) => removeItem(state, item),
};

export const actions = {
  invite: (store, { params }) => {
    const accountId = store.rootGetters['auth/accountId'];
    return service
      .post(`/accounts/${accountId}/users/invite`, params)
      .then(() => {
        events.trigger(events.names.USER_INVITED, {
          user: params,
        });
      });
  },
  findByAccountId: (store, { userId, params }) => {
    const accountId = store.rootGetters['auth/accountId'];
    return service
      .get(`/accounts/${accountId}/users/${userId}`, { params })
      .then((res) => processAndReturn(res, 'user'));
  },
  fetch: (store, { params } = {}) => {
    const accountId = store.rootGetters['auth/accountId'];
    return service
      .get(`/accounts/${accountId}/users`, { params })
      .then((res) => processAndReturn(res, 'user'));
  },
  updateUserPermissions: (store, { params }) => {
    const accountId = store.rootGetters['auth/accountId'];
    return service.put(`/accounts/${accountId}/users`, params).then(() => {
      events.trigger(events.names.USER_EDITED, {
        params,
      });
    });
  },
  deleteFromAccount: (store, { userId }) => {
    const accountId = store.rootGetters['auth/accountId'];
    return service
      .delete(`/accounts/${accountId}/users/${userId}`)
      .then((res) => {
        store.commit(REMOVE, { id: userId });
        events.trigger(events.names.USER_REMOVED, {
          user: userId,
        });
        return res;
      });
  },
};
