const dictionary = {
  device: {
    singular: 'device',
    plural: 'devices',
  },
  shipment: {
    singular: 'shipment',
    plural: 'shipments',
  },
};

export function getNoun(array, word) {
  if (!dictionary[word]) {
    throw Error('Add to dictionary');
  }
  if (!Array.isArray(array)) {
    throw Error('Provide an array');
  }
  if (array.length === 1) {
    return dictionary[word].singular;
  }
  if (array.length > 1) {
    return dictionary[word].plural;
  }
}

export function getPronoun(array) {
  if (!Array.isArray(array)) {
    throw Error('Provide an array');
  }
  if (array.length === 1) {
    return 'this';
  }
  if (array.length > 1) {
    return 'these';
  }
}
