<template>
  <div class="rounded-lg bg-lgmx_gray-200 p-4">
    <div class="text-[15px] font-semibold leading-6 text-lgmx_black-300">
      Geofence Details
    </div>
    <div class="text-[13px] leading-5 text-lgmx_black-200">
      <p class="mb-2">Geofence details shows trackers currently in geofence.</p>
      <p>
        Other trackers that have exited are included in the dwell calculation
        but may not be displayed.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
