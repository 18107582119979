<template>
  <UITextarea
    :name="name"
    :modelValue="inputValue"
    @input="handleChange"
    @blur="handleBlur"
    :hasError="meta.touched && !meta.valid"
  />
</template>

<script>
import { useField } from 'vee-validate';
import { toRef } from 'vue';

export default {
  props: {
    value: String,
    name: String,
    vMaska: String,
    validateFn: Function,
  },

  setup(props) {
    const name = toRef(props, 'name');

    const {
      value: inputValue,
      handleBlur,
      handleChange,
      meta,
    } = useField(name, props.validateFn, {
      initialValue: props.value,
    });

    return {
      handleChange,
      handleBlur,
      inputValue,
      meta,
    };
  },
};
</script>

<style></style>
