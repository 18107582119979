<template>
  <VeeForm @submit="submit">
    <div
      class="mt-[22px] flex flex-col gap-x-[32px] gap-y-[27px] px-[24px] sm:px-[40px] md:grid md:grid-cols-6"
    >
      <VeeInput
        name="line1"
        class="col-span-full"
        :placeholder="'Address'"
        label="Address"
        :validateFn="validateLine1"
      />
      <VeeInput
        name="line2"
        class="col-span-full"
        :placeholder="'Apartment, suite, etc. (optional)'"
        label="Apartment, suite, etc. (optional)"
      />

      <VeeInput
        name="city"
        class="col-span-2"
        :placeholder="'Montgomery'"
        label="City"
        :validateFn="validateCity"
      />

      <VeeSelect
        name="state"
        :initialValue="usStates[0]"
        :options="usStates"
        class="col-span-2"
        :placeholder="'Texas'"
        label="State"
      />

      <VeeInput
        name="zip"
        class="col-span-2"
        :placeholder="'36107'"
        label="ZIP Code"
        data-maska="#####"
        :validateFn="validateZip"
      />
    </div>
    <div class="mt-[31px] px-[24px] sm:px-[40px]">
      <VeeCheckbox
        name="is_default"
        label="Use as default"
        size="medium"
        :initialValue="true"
      />
    </div>
    <div class="bg-white px-[24px] py-[32px] sm:px-[40px]">
      <button
        role="button"
        class="inline-flex w-full items-center justify-center rounded-[6px] bg-lms_red-900 px-[40px] py-[12px] text-gpx_button font-semibold text-white"
        type="submit"
      >
        <UISpinner size="xs" class="mr-2" v-if="isSubmitting" />
        <span v-if="isSubmitting">Adding</span>
        <span v-else>Add Billing Address</span>
      </button>
    </div>
  </VeeForm>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import VeeInput from '@/components/vee/VeeInput.vue';
import VeeSelect from '@/components/vee/VeeSelect.vue';
import VeeCheckbox from '@/components/vee/VeeCheckbox.vue';
import usStatesJSON from '@/assets/us-states.json';

const isSubmitting = ref(false);
const store = useStore();
const toast = useToast();

const usStates = usStatesJSON;

const emit = defineEmits(['close']);

const submit = (values, actions) => {
  isSubmitting.value = true;

  const accountId = computed(() => store.getters['auth/accountId']);

  const { is_default: isDefaultBool, ...rest } = values;
  const country = 'US';
  const category = 'billing';
  const is_default = isDefaultBool ? 1 : 0;
  const payload = { country, category, is_default, ...rest };

  store
    .dispatch('address/storeUnderAccountId', {
      accountId: accountId.value,
      params: payload,
    })
    .then(() => {
      actions.resetForm();
      toast.success('New billing address added');
      emit('close');
    })
    .catch((e) => {
      toast.error(e.response?.data?.message || 'An error occurred');
    })
    .finally(() => (isSubmitting.value = false));
};

const validateLine1 = (value) => {
  if (!value) {
    return 'line1 is required';
  }
  return true;
};
const validateCity = (value) => {
  if (!value) {
    return 'city is required';
  }
  return true;
};
const validateZip = (value) => {
  if (!value) {
    return 'zip is required';
  }
  if (value.length < 5) {
    return 'min 5 chars';
  }
  return true;
};
</script>

<style></style>
