import * as _ from 'lodash';
import { formatWithOptions } from 'date-fns/fp';
import { formatDistance, formatDistanceStrict } from 'date-fns';

/**
 * Formats money the way you want to
 * @param {number} amount Amount of money in cents
 * @param {boolean} split Should we get main part and dec part separately
 * @param {number} [decimalCount = 2] Quantity of decimals
 * @param {string} [decimal = '.'] Decimal separator
 * @param {string} [thousands = '.'] Thousands separator
 * @example
 *  formatMoney(10000)
 */
export const formatMoney = (
  amount,
  split = false,
  decimalCount = 2,
  decimal = '.',
  thousands = ',',
) => {
  let newDecimalCount;
  let newAmount;
  newDecimalCount = Math.abs(decimalCount);
  newDecimalCount = isNaN(decimalCount) ? 2 : decimalCount;

  const negativeSign = amount < 0 ? '-' : '';

  const i = parseInt(
    (newAmount = Math.abs(Number(amount / 100) || 0).toFixed(newDecimalCount)),
    10,
  ).toString();
  const j = i.length > 3 ? i.length % 3 : 0;

  const result =
    negativeSign +
    (j ? i.substr(0, j) + thousands : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
    (newDecimalCount
      ? decimal +
        Math.abs(newAmount - i)
          .toFixed(newDecimalCount)
          .slice(2)
      : '');

  if (split) {
    const [main, dec] = result.split(decimal);
    return { main, dec: `${decimal}${dec}` };
  } else {
    return result;
  }
};

/**
 * Gives you "... ago" string
 * @param {string} createdAt Date string
 * @example
 *  formatTimeAgo("2022-06-10T06:56:49.000000Z")
 */
export function formatTimeAgo(createdAt) {
  const createdAtDate = new Date(createdAt);
  return formatDistance(createdAtDate, Date.now(), {
    addSuffix: true,
    includeSeconds: true,
  });
}

/**
 * Gives you "... ago" string with "floor" rounding
 * @param {string} createdAt Date string
 * @example
 *  formatTimeAgoStrict("2022-06-10T06:56:49.000000Z")
 */
export function formatTimeAgoStrict(createdAt, options = {}) {
  const createdAtDate = new Date(createdAt);
  return formatDistanceStrict(createdAtDate, Date.now(), {
    addSuffix: true,
    roundingMethod: 'floor',
    ...options,
  });
}

/**
 * Gives you duration string
 * @param {string} startAt Date string
 * @param {string} endAt Date string
 * @example
 *  formatTimeAgo("2022-06-10T06:56:49.000000Z")
 */
export function formatDuration(startAt, endAt) {
  let s = startAt;
  let e = endAt;

  if (s.length === 19) s = startAt.replace(/-/g, '/');
  if (e.length === 19) e = endAt.replace(/-/g, '/');

  return formatDistance(new Date(s), new Date(e));
}

/**
 * Formats time
 * @param {string} string Date String
 * @param {string} format String
 * @example
 *  formatTime("2022-06-10T06:56:49.000000Z")
 */
export const formatTime = (dateString, format = 'MMMM d, yyyy') => {
  if (_.isUndefined(dateString)) return;
  if (typeof dateString === 'object' && dateString instanceof Date) {
    dateString = dateString.toISOString();
  }
  let date = new Date(dateString);
  if (/apple/i.test(navigator.vendor)) {
    const safariSanitizedDateString = dateString.replace(' ', 'T');
    date = new Date(safariSanitizedDateString);
  }
  if (isNaN(date.getMonth())) {
    return '-';
  }
  return formatWithOptions({}, format)(date);
};

/**
 * Formats speed to MPH
 * Note: backend returns ms value multiplied by 1000
 * @param {number} speedInMs Speed in m/s
 * @example
 *  formatSpeed(100)
 */
export const formatSpeed = (speedInMs) => {
  if (!speedInMs) return 0;
  return _.round((speedInMs / 1000) * 2.23694, 1);
};
export const formatSubscriptionType = (months) => {
  if (months === 1) {
    return 'Monthly';
  }
  if (months === 12) {
    return 'Annual';
  }
  return `${months} month plan`;
};
