<template>
  <BaseLayout>
    <div class="mx-auto mb-[80px] max-w-[620px] sm:mb-[120px] sm:mt-[56px]">
      <div class="flex justify-between">
        <h1 class="mt-[7px] text-gpx_2xl font-semibold text-lms_black-500">
          Create New Rule
        </h1>
      </div>
      <RuleForm />
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout.vue';
import RuleForm from '@/components/rules/RuleForm.vue';

export default {
  name: 'RulesCreate',
  components: {
    BaseLayout,
    RuleForm,
  },
};
</script>
