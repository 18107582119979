import service from '@/services';
import { BATCH_ADD_OR_UPDATE, ADD, REMOVE } from '../mutation-types';
import {
  all,
  byId,
  byIds,
  processAndReturn,
  addOrUpdateItems,
  removeItem,
} from '../helpers';
import * as events from '@/events';

export const state = {
  items: [],
};

export const getters = { all, byId, byIds };

export const mutations = {
  [ADD]: (state, items) => (state.items = items),
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [REMOVE]: (state, item) => removeItem(state, item),
};

export const actions = {
  fetch: (store, params) => {
    params.search = { account_id: { is: store.rootGetters['auth/accountId'] } };

    return service
      .get('/rules-engine', { params })
      .then((res) => processAndReturn(res, 'rule'));
  },
  fetchById: (store, id) => {
    return service
      .get(`/rules-engine/${id}`)
      .then((res) => processAndReturn(res, 'rule'));
  },
  create: (store, params) => {
    params.account_id = store.rootGetters['auth/accountId'];

    return service.post('/rules-engine', params).then((res) => {
      events.trigger(events.names.RULE_CREATED, params);
      return processAndReturn(res, 'rule');
    });
  },
  update: (store, { id, params }) => {
    params.account_id = store.rootGetters['auth/accountId'];

    return service.put(`/rules-engine/${id}`, params).then((res) => {
      events.trigger(events.names.RULE_EDITED, {
        rule: res.data.data,
      });
      return processAndReturn(res, 'rule');
    });
  },
  delete: (store, id) => {
    return service.delete(`/rules-engine/${id}`).then(() => {
      store.commit(REMOVE, { id });
      events.trigger(events.names.RULE_DELETED, {
        rule: id,
      });
    });
  },
};
