<template>
  <UINotificationNew
    v-if="canReplace && accountId && accountId !== 92"
    variant="warning"
    icon="ExclamationCircleIcon"
  >
    <div class="flex w-full justify-between">
      <span>Replace SIM for {{ qty }} {{ noun }}</span>
      <button
        class="flex items-center gap-x-1 font-semibold"
        @click="openReplaceSimModal"
      >
        <span>Replace Now</span>
        <ArrowUpRightIcon class="size-3" />
      </button>
    </div>
  </UINotificationNew>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getNoun } from '@/utils/noun';

export default {
  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
      iccids: 'simReplacement/iccids',
      canReplace: 'simReplacement/canReplace',
    }),
    qty() {
      return this.iccids.length;
    },
    noun() {
      return getNoun(this.iccids, 'device');
    },
  },

  methods: {
    ...mapActions({
      openModal: 'modal/open',
    }),
    openReplaceSimModal() {
      this.openModal({ name: 'DeviceSimReplaceModal' });
    },
  },
};
</script>
