export const init = (token: string, account: any, user: any) => {
  Vitally.init(token);

  Vitally.account({
    accountId: account.id,
    traits: {
      name: account.title,
    },
  });

  Vitally.user({
    userId: user.id,
    accountId: account.id,
    traits: {
      name: user.name,
      email: user.email,
    },
  });

  Vitally.nps('survey', {
    productName: 'GPX',
    delay: 5000,
  });
};
