import names from './names';

const events = {};

function subscribe(eventName, callbackFn) {
  const uniqueKey = Symbol();
  const eventsHolder = events[eventName] || (events[eventName] = {});

  eventsHolder[uniqueKey] = callbackFn;

  return {
    unsubscribe: () => {
      delete eventsHolder[uniqueKey];
      if (Object.keys(eventsHolder).length === 0) delete events[eventName]; // remove eventHolder if 0 events
    },
  };
}

function trigger(eventName, ...args) {
  const eventsHolder = events[eventName];

  Reflect.ownKeys(eventsHolder || {}).forEach((key) =>
    eventsHolder[key](...args),
  );

  const globalListeners = events['*'];
  Reflect.ownKeys(globalListeners || {}).forEach((key) =>
    globalListeners[key](eventName, ...args),
  );
}

export { trigger, subscribe, names };
