<template>
  <div class="text-[13px]">
    <div>Something went wrong</div>
    <div
      class="cursor-pointer font-semibold hover:text-red-100"
      role="button"
      @click="refresh"
    >
      Click here to refresh the page
    </div>
  </div>
</template>

<script setup>
const refresh = () => location.reload();
</script>
