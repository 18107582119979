<template>
  <div class="px-[15px] text-gray-1000">
    <h1 class="mb-[8px] mt-[24px] items-center text-[24px] leading-[28px]">
      <span>{{ name }}</span>
      <VBadge
        v-if="shouldShowStatus"
        :status="status"
        class="relative top-[-4px] ml-2 inline"
      />
    </h1>
    <table class="table w-full table-fixed text-left">
      <tr>
        <th>Scheduled start</th>
      </tr>
      <tr>
        <td>{{ startAt }}</td>
      </tr>
      <tr>
        <th>Scheduled end</th>
      </tr>
      <tr>
        <td>{{ scheduledEndAt }}</td>
      </tr>
      <tr v-if="!isOngoing">
        <th>End time</th>
      </tr>
      <tr v-if="!isOngoing">
        <td>{{ endedAt }}</td>
      </tr>
      <tr>
        <th>Events</th>
      </tr>
      <tr>
        <td>
          <div class="flex flex-col gap-y-[4px]" v-if="events.length">
            <div v-for="(item, idx) in events" :key="`event-${idx}`">
              <i class="text-gray-700">{{ getEventTimeAgo(item.event_at) }}</i
              ><br />{{ item.description }}
            </div>
          </div>
          <div v-else>No events</div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import * as _ from 'lodash';
import { useToast } from 'vue-toastification';
import store from '@/store';
import VBadge from '@/components/ui/VBadge.vue';
import { TRACK_MESSAGE404 } from '@/config/constants';
import { formatTime, formatTimeAgo } from '@/filters';
import { setTitle } from '@/utils/title';

const toast = useToast();

export default {
  name: 'ShipmentTrackView',
  components: {
    VBadge,
  },

  data() {
    return {
      shipment: null,
    };
  },

  computed: {
    name() {
      return _.get(this.shipment, 'name', '-');
    },
    shouldShowStatus() {
      const allowedStatuses = ['Completed', 'Running Late', 'Completed Late'];
      return Boolean(this.status) && allowedStatuses.includes(this.status);
    },
    status() {
      return _.get(this.shipment, 'status', null);
    },
    startAt() {
      const startAt = _.get(this.shipment, 'start_at', null);
      if (startAt) {
        return formatTime(startAt, 'MMMM do h:mmaaa');
      }
      return '-';
    },
    scheduledEndAt() {
      const endAt = _.get(this.shipment, 'end_at', null);
      if (endAt) {
        return formatTime(endAt, 'MMMM do h:mmaaa');
      }
      return '-';
    },
    endedAt() {
      const endAt = _.get(this.shipment, 'ended_at', null);
      if (endAt) {
        return formatTime(endAt, 'MMMM do h:mmaaa');
      }
      return '-';
    },
    isOngoing() {
      const allowedStatuses = ['In Progress', 'Not Started', 'Running Late'];
      return allowedStatuses.includes(this.status);
    },
    events() {
      return _.get(this.shipment, 'events', []);
    },
  },

  beforeRouteEnter(to, from, next) {
    const uuid = to.params.uuid;

    store
      .dispatch('shipment/fetchByUuid', {
        uuid,
        params: {
          with_geofences: true,
          with_current_position: true,
          with_path: true,
          include: 'device,events',
        },
      })
      .then((res) => {
        next((vm) => {
          vm.shipment = res.data.data;
          setTitle(`Shipment ${vm.shipment.name}`);
        });
      })
      .catch(() => {
        toast.error(TRACK_MESSAGE404, { timeout: 15000 });
      });
  },

  methods: {
    getEventTimeAgo(time) {
      if (!time) return 'unknown';
      return formatTimeAgo(time);
    },
  },
};
</script>

<style scoped>
table th,
table td {
  @apply p-[12px] border border-[#dee2e6];
}
table th {
  @apply bg-[#f2f2f2];
}
</style>
