<template>
  <div class="flex items-center justify-between">
    <div>
      <div class="text-[13px] font-medium leading-4 text-lgmx_gray-800">
        Tracker Group
      </div>
      <div class="text-[19px] font-semibold leading-6 text-lgmx_black-600">
        {{ name }}
      </div>
    </div>
    <div>
      <UIIconButton
        icon="ArrowUturnLeftIcon"
        @click="goBack"
        tooltip="Go back"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import * as _ from 'lodash';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useGroup } from '@/composables/useGroup.ts';
import { storeToRefs } from 'pinia';

const route = useRoute();
const router = useRouter();

const groupStore = useGroup();
const { getById } = groupStore;
const { search } = storeToRefs(groupStore);

const group = computed(() => getById(+route.params.id));
const name = computed(() => _.get(group.value, 'name', 'No Name'));

function goBack() {
  router.push({
    name: 'GroupListView',
    query: { search: search.value },
  });
}
</script>
