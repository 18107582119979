<template>
  <section>
    <HeadingSection
      class="mb-[35px] pt-[25px]"
      desc="Edit addresses or add new"
      heading="Addresses"
      :button="addAddressButton"
    />
    <div
      class="flex flex-col gap-y-[3px] border-t border-t-gpx_gray-800 py-[32px]"
    >
      <div class="flex flex-col justify-between gap-y-[16px] sm:flex-row">
        <div>
          <h3 class="text-gpx_base font-semibold text-lms_black-300">
            Billing Address
          </h3>
          <span class="text-gpx_sm text-gpx_gray-200">
            Select default billing address
          </span>
        </div>
        <ManageAddressSelect
          class="sm:min-w-[730px]"
          :addresses="accountAddresses.billing || []"
        />
      </div>
    </div>
    <div
      class="flex flex-col gap-y-[3px] border-y border-y-gpx_gray-800 py-[32px]"
    >
      <div class="flex flex-col justify-between gap-y-[16px] sm:flex-row">
        <div>
          <h3 class="text-gpx_base font-semibold text-lms_black-300">
            Shipping Address
          </h3>
          <span class="text-gpx_sm text-gpx_gray-200">
            Select default shipping address
          </span>
        </div>
        <ManageAddressSelect
          class="sm:min-w-[730px]"
          :addresses="accountAddresses.shipping || []"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import HeadingSection from '@/components/_common/Heading/HeadingSection.vue';
import ManageAddressSelect from '@/components/manage/address/ManageAddressSelect.vue';
import { setTitle } from '@/utils/title';

export default {
  components: {
    HeadingSection,
    ManageAddressSelect,
  },

  data() {
    return {
      addresses: [
        '2 Court Square, New York, NY12210, United States',
        '767 5th Ave, New York, NY 10153, United States',
        '401 W 14th St, New York, NY 10014, United States',
      ],
    };
  },

  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
      addressesByAccountId: 'address/byAccountId',
    }),
    addAddressButton() {
      return {
        label: 'Add New',
        action: {
          mode: 'callback',
          callback: () => {
            this.openModal({ name: 'ManageAddressAddNewModal' });
          },
        },
      };
    },
    accountAddresses() {
      const addresses = this.addressesByAccountId(this.accountId);
      return {
        shipping: addresses.filter((item) => item.category === 'shipping'),
        billing: addresses.filter((item) => item.category === 'billing'),
      };
    },
  },

  watch: {
    accountId(accountId) {
      this.fetchAddressByAccountId({ accountId });
    },
  },

  created() {
    setTitle('Manage Addresses');
  },

  methods: {
    ...mapActions({
      openModal: 'modal/open',
      fetchAddressByAccountId: 'address/fetchByAccountId',
    }),
  },
};
</script>
