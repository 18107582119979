<template>
  <UISelect
    label="Tracker Type"
    placeholder="Select tracker type"
    ref="modelsSelect"
    :options="modelsItems"
    v-model="selectedModel"
  />
  <UISelect
    v-if="selectedModel"
    label="Command to Send"
    placeholder="Select command"
    ref="commandsSelect"
    :options="commandsItems"
    v-model="selectedCommand"
  />
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'SendCommandField',
  emits: ['onChange'],
  props: {
    model: {
      required: false,
      type: Number,
    },
    command: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      ids: [],
      selectedCommand: null,
      selectedModel: null,
    };
  },
  computed: {
    ...mapGetters({
      models: 'model/all',
      commandsByIds: 'command/byIds',
    }),
    modelsItems() {
      return this.models.map((model) => ({
        value: model.id,
        label: model.title,
      }));
    },
    commandsItems() {
      const commands = this.commandsByIds(this.ids);
      return commands.map((command) => ({
        value: command.id,
        label: command.title,
      }));
    },
  },
  mounted() {
    if (this.model && this.command) {
      this.$nextTick(() => {
        this.populateCommandsSelect();
        this.populateModelsSelect();
      });
      return;
    }
  },
  watch: {
    modelsItems() {
      this.populateModelsSelect();
    },
    commandsItems() {
      this.populateCommandsSelect();
    },
    selectedModel() {
      this.selectedCommand = null;
      this.fetchCommandsByModelId({ modelId: this.selectedModel.value }).then(
        (res) => {
          this.ids = res.data.data.map((item) => item.id);
        },
      );
    },
    selectedCommand() {
      this.onChange();
    },
  },
  methods: {
    ...mapActions({
      fetchCommandsByModelId: 'command/fetchByModelId',
    }),
    populateModelsSelect() {
      if (!this.model || !this.modelsItems.length) {
        return;
      }

      const item = this.modelsItems.find((item) => item.value === this.model);
      if (!item) {
        return;
      }
      this.selectedModel = item;
      this.$refs.modelsSelect?.setSelectedOptionQueitly(item);
    },
    populateCommandsSelect() {
      if (!this.command || !this.commandsItems.length) {
        return;
      }

      const item = this.commandsItems.find(
        (item) => item.value === this.command,
      );
      if (!item) {
        return;
      }
      this.$refs.commandsSelect?.setSelectedOptionQueitly(item);
    },
    onChange() {
      this.$emit('onChange', {
        tracker_type: this.selectedModel?.value || this.model,
        command: this.selectedCommand?.value || this.command,
      });
    },
  },
};
</script>
