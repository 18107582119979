import _ from 'lodash';

const processStrategy = (device) => {
  const relations = {
    subscription: _.get(device, 'subscription'),
  };
  const omit = [];
  return { ..._.omit(device, omit), ..._.pickBy(relations, _.identity) };
};

export default {
  key: 'device',
  definition: {
    subscription: 'subscription',
  },
  processStrategy,
};
