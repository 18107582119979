<template>
  <ModalLayout large title="Download modal">
    <div class="rounded-t-[10px] bg-white px-[24px] pt-[41px] sm:px-[40px]">
      <div
        @click.prevent="$emit('close')"
        class="absolute right-[24px] top-[36px] flex size-[32px] cursor-pointer items-center justify-center rounded-full border border-gpx_gray-800 sm:right-[40px]"
      >
        <XMarkIcon
          class="heroicon-stroke-w-1.4 size-[20px] text-gpx_gray-200"
          @click="$emit('close')"
        />
      </div>
      <div class="border-b border-b-gpx_gray-800 pb-[18px]">
        <div class="text-gpx_black-100 text-2xl font-semibold">
          Download positions
        </div>
      </div>

      <div v-if="params.disabled" class="mt-[25px] text-[15px] text-red-300">
        Controls data can only be downloaded for 90 day periods.
      </div>
      <div v-else class="mt-[25px] text-[15px] text-gpx_gray-200">
        You are downloading data for {{ params.filter }} devices for
        {{ period }}
      </div>
    </div>
    <div
      class="flex w-full gap-x-[16px] rounded-b-[10px] bg-white px-[24px] py-[32px] sm:px-[40px]"
    >
      <UIButton
        :disabled="params.disabled"
        :loading="isLoadingControls"
        label="Controls positions"
        @click="onDownloadControls"
        class="w-full"
      />
      <UIButton
        type="secondary"
        :loading="isLoadingCurrentPositions"
        :disabled="params.disabled"
        label="Current position only"
        @click="onDownloadCurrentPositionOnly"
        class="w-full"
      />
    </div>
  </ModalLayout>
</template>

<script>
import { XMarkIcon } from '@heroicons/vue/24/outline';
import ModalLayout from '@/layouts/ModalLayout.vue';
import UIButton from '@/components/ui/UIButton.vue';

export default {
  components: {
    UIButton,
    XMarkIcon,
    ModalLayout,
  },

  emits: ['close'],
  props: {
    params: Object,
  },
  data: function () {
    return {
      isLoadingControls: false,
      isLoadingCurrentPositions: false,
    };
  },
  computed: {
    period() {
      return this.params.dateFrom + '-' + this.params.dateTo;
    },
  },
  methods: {
    onDownloadControls() {
      this.isLoadingControls = true;
      this.params.onDownloadControls();
    },
    onDownloadCurrentPositionOnly() {
      this.isLoadingCurrentPositions = true;
      this.params.onDownloadCurrentPositionOnly();
      setTimeout(() => {
        this.isLoadingCurrentPositions = false;
      }, 3000);
    },
  },
};
</script>
