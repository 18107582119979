<template>
  <div class="relative max-w-full overflow-x-scroll sm:overflow-visible">
    <table class="table w-full table-auto">
      <thead>
        <tr class="relative">
          <th
            v-for="(column, index) in columns"
            :key="index"
            class="bg-gpx_gray-900 py-[12px] sm:py-0 sm:first-of-type:rounded-tl-[6px]"
          >
            <div
              class="relative cursor-pointer whitespace-nowrap pr-[40px] text-left text-gpx_label font-semibold uppercase leading-8 text-gpx_gray-300 transition-colors duration-200 hover:text-lms_red-900 sm:my-[12px]"
              :class="{
                'pl-[24px] !text-lms_black-100 sm:pl-[32px]': index === 0,
              }"
            >
              {{ column }}
              <ArrowDownIcon
                class="relative top-[-1px] inline-flex h-[14px] w-[14px] text-lms_red-900"
                v-if="index === 0"
              />
            </div>
          </th>
          <th
            class="bg-gpx_gray-900 py-0 pl-0 sm:last-of-type:rounded-tr-[6px]"
          ></th>
        </tr>
      </thead>
      <tbody
        class="relative before:absolute before:right-0 before:top-0 before:h-full before:border-l before:border-l-gpx_gray-800 after:absolute after:left-0 after:top-0 after:h-full after:border-l after:border-l-gpx_gray-800"
      >
        <DataTableItem
          v-for="(item, index) in list"
          :data="item"
          :key="index"
          :editActions="editActions"
          :editItemId="editItemId"
          @setEditItemId="setEditItemId"
          @click="$emit('itemClick', item)"
          class="group"
        />
      </tbody>
    </table>
    <UILoader
      v-if="loading"
      class="absolute right-0 top-0 pt-[56px]"
      size="lg"
    />
  </div>
</template>

<script>
import { ArrowDownIcon } from '@heroicons/vue/24/outline';
import DataTableItem from '@/components/_common/Data/DataTableItem.vue';

export default {
  name: 'DataTable',

  components: {
    DataTableItem,
    ArrowDownIcon,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
    editActions: {
      type: Array,
      required: false,
    },
    routeName: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      editItemId: null,
    };
  },

  methods: {
    setEditItemId(id) {
      this.editItemId = id;
    },
  },
};
</script>
