import service from '@/services';
import { downloadFile } from '@/utils/blob';
import {
  all,
  byId,
  byIds,
  addOrUpdateItems,
  processAndReturn,
} from '../helpers';
import { BATCH_ADD_OR_UPDATE } from '../mutation-types';

export const state = {
  items: [],
};

export const getters = { all, byId, byIds };

export const mutations = {
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
};

export const actions = {
  fetch: (store, { deviceId, params }) => {
    return service
      .get(`/devices/${deviceId}/events`, { params })
      .then((res) => processAndReturn(res, 'deviceEvent'));
  },
  fetchByAccountId: (store, { accountId, params }) => {
    return service
      .get(`/accounts/${accountId}/events`, { params })
      .then((res) => processAndReturn(res, 'deviceEvent'));
  },
  downloadById: (store, { id, fileName, params }) => {
    return service
      .get(`/devices/${id}/events`, {
        responseType: 'blob',
        params,
      })
      .then((res) => downloadFile({ res, fileName, extension: 'csv' }));
  },
};
