import { SPINNER_SIZE, SPINNER_VARIANT } from '../UISpinnerNew/utils';

export const BUTTON_SIZE = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  modal: 'modal',
};
export const BUTTON_VARIANT = {
  main: 'main',
  destructive: 'destructive',
  secondary: 'secondary',
  tertiary: 'tertiary',
  quaternary: 'quaternary',
  quinary: 'quinary',
};
export const buttonSizes = Object.keys(BUTTON_SIZE);
export const buttonVariants = Object.keys(BUTTON_VARIANT);

export function getButtonSpinnerSize(buttonSize) {
  if (buttonSize === BUTTON_SIZE.sm) {
    return SPINNER_SIZE.xs;
  }
  if (buttonSize === BUTTON_SIZE.md) {
    return SPINNER_SIZE.xs;
  }
  if (buttonSize === BUTTON_SIZE.lg) {
    return SPINNER_SIZE.xs;
  }
  if (buttonSize === BUTTON_SIZE.modal) {
    return SPINNER_SIZE.xs;
  }
  return SPINNER_SIZE.xs;
}

export function getButtonSpinnerVariant(buttonVariant) {
  if (buttonVariant === BUTTON_VARIANT.main) {
    return SPINNER_VARIANT.white;
  }
  if (buttonVariant === BUTTON_VARIANT.destructive) {
    return SPINNER_VARIANT.red;
  }
  if (buttonVariant === BUTTON_VARIANT.secondary) {
    return SPINNER_VARIANT.white;
  }
  if (buttonVariant === BUTTON_VARIANT.tertiary) {
    return SPINNER_VARIANT.default;
  }
  if (buttonVariant === BUTTON_VARIANT.quaternary) {
    return SPINNER_VARIANT.default;
  }
  if (buttonVariant === BUTTON_VARIANT.quinary) {
    return SPINNER_VARIANT.white;
  }
  return SPINNER_VARIANT.default;
}
