<template>
  <div class="pt-[31px]">
    <div class="mb-[14px] flex justify-center">
      <img :src="svg1" alt="Insert SIM card" />
    </div>

    <div class="text-[13px] leading-5 text-lgmx_black-200">
      Once you have removed your SIM card, place it so the cut off corner is in
      the top left and the metal on the card faces down. Make sure that the
      numbers on the SIM card are facing you.
    </div>

    <div class="flex justify-between gap-x-4 pb-[18px] pt-[26px]">
      <UIButtonNew
        variant="tertiary"
        size="lg"
        class="w-[124px]"
        @click="$emit('onCancel')"
      >
        Back
      </UIButtonNew>
      <UIButtonNew @click="$emit('onConfirm')" class="w-full">
        Next
      </UIButtonNew>
    </div>
  </div>
</template>

<script>
import Step6Svg1 from './assets/step-6.svg';

export default {
  emits: ['onConfirm', 'onCancel'],

  computed: {
    svg1() {
      return Step6Svg1;
    },
  },
};
</script>
