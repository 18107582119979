<template>
  <div>
    <div class="flex">
      <div>
        <div class="text-[15px] font-semibold text-lms_black-300">
          {{ name }}
        </div>
        <div class="text-[13px] text-lgmx_gray-900">
          <span v-if="address" class="inline-flex gap-x-2">
            <span class="">{{ address }} </span>
          </span>
          <span v-else>Not assigned</span>
        </div>
      </div>
      <div class="ml-auto">
        <div class="flex">
          <UIIconButton
            class="absolute right-[9px] top-[11px] ml-[6px] md:right-[16px]"
            icon="EllipsisVerticalIcon"
            @click="$emit('setEditItemId', props.bleAnchor.id)"
          >
            <UIActions
              v-if="props.editItemId === props.bleAnchor.id"
              :id="'ble-anchor-actions-' + bleAnchor.id"
              v-click-outside="() => $emit('resetEditItemId')"
              :editItemId="props.editItemId"
              :actions="getUIButtonActions()"
            />
          </UIIconButton>
        </div>
      </div>
    </div>
    <DeviceNote v-if="note" :note="note" class="mt-[10px]" />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import DeviceNote from '@/components/device/_common/DeviceNote.vue';
import { COMMON_DELETE_ERROR, COMMON_DELETE_SUCCESS } from '@/config/constants';

defineEmits(['setEditItemId', 'resetEditItemId']);
const props = defineProps({
  bleAnchor: Object,
  editItemId: Number,
});

const store = useStore();
const accountId = store.getters['auth/accountId'];

const name = computed(() => props.bleAnchor.name);
const address = computed(() => props.bleAnchor.address);
const note = computed(() => props.bleAnchor.notes);

function getUIButtonActions() {
  const deleteBleAnchor = {
    icon: 'TrashIcon',
    label: 'Delete',
    action: {
      mode: 'fn',
      fn: (id) => {
        const bleAnchor = store.getters['bleAnchor/byId'](id);
        store.dispatch('modal/open', {
          name: 'CommonConfirmationModal',
          params: {
            actionLabel: 'Delete',
            title: bleAnchor.name,
            body: 'Are you sure you want to delete this anchor?',
            onOk: () => store.dispatch('bleAnchor/delete', { accountId, id }),
            successMessage: COMMON_DELETE_SUCCESS,
            errorMessage: COMMON_DELETE_ERROR,
          },
        });
      },
    },
  };
  const editBleAnchor = {
    icon: 'PencilSquareIcon',
    label: 'Edit',
    action: {
      mode: 'fn',
      fn: (id) => {
        const bleAnchor = store.getters['bleAnchor/byId'](id);
        store.dispatch('modal/open', {
          name: 'GeofenceCreateBleAnchorModal',
          params: {
            actionLabel: 'Edit',
            bleAnchor,
          },
        });
      },
    },
  };
  return [editBleAnchor, deleteBleAnchor];
}
</script>
