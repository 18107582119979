<template>
  <UICheckbox
    :name="name"
    :value="checked"
    v-model="checked"
    :disabled="disabled"
  />
</template>

<script>
import { useField } from 'vee-validate';
import { toRef } from 'vue';

export default {
  props: {
    name: String,
    initialValue: Boolean,
    disabled: Boolean,
  },

  setup(props) {
    const name = toRef(props, 'name');

    const { value: checked, handleChange } = useField(name, undefined, {
      initialValue: props.initialValue,
    });

    return {
      handleChange,
      checked,
    };
  },
};
</script>

<style></style>
