<template>
  <div class="mb-4 gap-4 xs:mb-2 xs:flex sm:block md:flex">
    <strong class="block basis-2/6 sm:block sm:w-full md:basis-4/12">
      {{ name }}
    </strong>

    <div
      class="flex w-full basis-4/6 items-center justify-between whitespace-pre-line text-[15px] md:basis-8/12"
    >
      <slot v-if="!editMode" />
      <div class="flex" v-else>
        <VInput type="text" size="sm" class="mr-2 flex-1" v-model="value" />
        <VButton
          size="sm"
          class="mr-px w-[40px]"
          :class="{ 'w-[90px]': loading }"
          @click="handleSave"
          :disabled="!value || loading"
          :isLoading="loading"
          loadingText="Saving"
        >
          ✓</VButton
        >
        <VButton
          v-if="!loading"
          size="sm"
          class="w-10"
          variant="outline-primary"
          @click="handleCancel"
          >x</VButton
        >
      </div>
      <UIIconButton
        v-if="allowEdit"
        icon="PencilSquareIcon"
        @click="editMode = true"
      />
    </div>
  </div>
</template>

<script>
import VButton from '../ui/VButton.vue';
import VInput from '../ui/VInput.vue';

export default {
  components: { VInput, VButton },

  emits: ['onSave', 'onCancel'],
  props: {
    name: {
      type: String,
      required: true,
    },
    editable: {
      type: String,
      required: false,
    },
    loading: Boolean,
  },

  data() {
    return {
      value: this.editable,
      editMode: false,
    };
  },

  computed: {
    allowEdit() {
      return (this.editable || this.editable === '') && !this.editMode;
    },
  },

  watch: {
    loading: {
      handler(n, o) {
        if (!n && o) {
          this.editMode = false;
        }
      },
    },
  },

  methods: {
    handleSave() {
      this.$emit('onSave', this.value);
    },
    handleCancel() {
      this.$emit('onCancel');
      this.editMode = false;
      this.value = this.editable;
    },
  },
};
</script>

<style></style>
