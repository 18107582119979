<template>
  <div class="relative flex">
    <div
      class="mr-[16px] self-center text-gpx_button font-semibold text-lms_black-100"
    >
      <span>Filter by:</span>
    </div>

    <button class="flex rounded-[4px] bg-bg-1" @click="showDropdown = true">
      <span
        class="px-[24px] py-[11px] text-gpx_xs font-medium text-gpx_gray-200"
        :class="{ 'text-lms_black-400': filter.value }"
        >{{
          filter.value ? startCase(filter.value) : startCase(filter.type)
        }}</span
      >
      <span
        v-if="!filter.value"
        class="border-l border-l-white px-[10px] py-[11px]"
      >
        <ChevronDownIcon
          class="heroicon-stroke-w-1.2 h-[18px] w-[18px] text-gpx_gray-300"
        />
      </span>
      <span
        v-if="filter.value"
        @click.stop="filter.value = null"
        class="border-l border-l-white px-[10px] py-[11px]"
      >
        <XMarkIcon
          class="heroicon-stroke-w-1.2 h-[18px] w-[18px] text-gpx_gray-300"
        />
      </span>
    </button>

    <div
      v-if="showDropdown"
      class="absolute right-0 top-full z-30 min-w-[160px] rounded-[6px] border border-gpx_gray-900 bg-white text-gpx_xs text-lms_black-400 drop-shadow-main translate-y-[8px]"
      v-click-outside="hideDropdown"
    >
      <div
        v-for="value in filter.values"
        :key="value.name"
        @click.stop="setValue(value.name)"
        class="flex cursor-pointer items-center gap-x-[10px] border-b border-b-gpx_gray-900 px-[20px] py-[8px] font-medium last-of-type:border-b-0 hover:bg-gpx_gray-900"
      >
        <div
          class="h-[8px] w-[8px] rounded-full"
          :class="getIndicatorColor(value.name)"
        ></div>
        {{ startCase(value.name) }}
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import vClickOutside from 'click-outside-vue3';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { statusColorMap } from '@/config/constants';

export default {
  name: 'UIDataFilter',
  directives: {
    clickOutside: vClickOutside.directive,
  },

  components: {
    ChevronDownIcon,
    XMarkIcon,
  },

  props: {
    filter: {
      type: Object,
      required: true,
      validator: (filter) => {
        if (!filter.type) {
          return false;
        }
        return filter.values.every((value) => value.name);
      },
    },
  },

  data() {
    return {
      showDropdown: false,
    };
  },

  methods: {
    startCase: _.startCase,
    setValue(value) {
      this.filter.value = value;
      this.hideDropdown();
    },
    getIndicatorColor(status) {
      const color = statusColorMap[status];
      switch (color) {
        case 'red':
          return 'bg-gpx_red-200';
        case 'blue':
          return 'bg-gpx_blue-200';
        case 'yellow':
          return 'bg-gpx_yellow-200';
        case 'green':
          return 'bg-gpx_green-200';
        default:
          return 'bg-gpx_gray-200';
      }
    },
    hideDropdown() {
      this.showDropdown = false;
    },
  },
};
</script>
