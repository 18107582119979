<template>
  <div class="pl-5 pr-3 pt-5 md:px-8">
    <div class="mb-3 flex justify-between md:mb-4 md:items-center">
      <div>
        <h1 class="mb-[2px] text-2xl font-semibold text-lgmx_black-600">
          Dwell Reports
        </h1>
        <span class="text-[13px] leading-5 text-lgmx_slate-800">
          How long an asset stays inside a specific geofence.
          <UILinkButton @click="openHowItWorksModal" class="cursor-help">
            How it works?
          </UILinkButton>
        </span>
      </div>
      <div class="shrink-0">
        <UIButtonNew class="md:hidden" size="sm" @click="onCreateClick">
          New Geofence
        </UIButtonNew>
        <UIButtonNew
          class="hidden md:inline-flex"
          @click="onCreateClick"
          icon="SquaresPlusIcon"
        >
          New Geofence
        </UIButtonNew>
      </div>
    </div>
  </div>
  <div
    v-if="sorted.length === 0 && !searchQuery && !isLoading"
    class="h-full px-3 pb-3"
  >
    <NoData
      title="There are no Geofences"
      subtitle="Create first geofence to use Dwell Reports feature"
      class="!p-0"
    >
      <UIButtonNew icon="SquaresPlusIcon" @click="onCreateClick">
        Add Geofence
      </UIButtonNew>
    </NoData>
  </div>
  <template v-else>
    <DashboardsDwellTableHeader
      class="mb-3 pr-3 md:px-8"
      v-model:search="searchQuery"
      v-model:period="periodQuery"
    />
    <div v-if="sorted.length === 0 && !isLoading" class="pt-28">
      <NoData
        title="No geofences found:"
        :subtitle="route.query.search"
        :bg="false"
        class="!p-0"
      />
    </div>
    <template v-else>
      <div class="h-full overflow-hidden px-3 pt-0">
        <DashboardsDwellTable
          :is-loading="isLoading"
          :period="periodQuery"
          :currentPage="currentPage"
          :currentPageSize="currentPageSize"
          :pageCount="pageCount"
          :data="paginatedData"
          @on-sort-change="handleSortChange"
        />
      </div>
      <div v-if="paginatedData.length" class="relative px-3 pb-3">
        <DashboardsDwellPaginator
          :total="total"
          v-model:currentPage="currentPage"
          v-model:currentPageSize="currentPageSize"
          :pageCount="pageCount"
          :isFirstPage="isFirstPage"
          :isLastPage="isLastPage"
          :prev="prev"
          :next="next"
        />
      </div>
    </template>
  </template>
</template>

<script setup>
import * as _ from 'lodash';
import { useToast } from 'vue-toastification';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useOffsetPagination } from '@vueuse/core';
import { useRouteQuery } from '@vueuse/router';
import NoData from '@/components/_common/NoData.vue';
import DashboardsDwellTable from '@/components/dashboards/dwell/DashboardsDwellTable.vue';
import DashboardsDwellTableHeader from '@/components/dashboards/dwell/DashboardsDwellTableHeader.vue';
import DashboardsDwellPaginator from '@/components/dashboards/dwell/DashboardsDwellPaginator.vue';
import { LIST_RESULTS_PER_PAGE } from '@/config/constants';
import { paginate } from '@/utils/paginate';
import { setTitle } from '@/utils/title';

onMounted(() => {
  setTitle('Dashboards | Dwell');
});

const toast = useToast();
const periods = [30, 60, 90];
const store = useStore();
const route = useRoute();
const router = useRouter();
const accountId = store.getters['auth/accountId'];

const all = computed(() => store.getters['dashboardDwell/all']);
const sort = computed(() => store.getters['dashboardDwell/sort']);
const sorted = computed(() => {
  const { sortField, sortOrder } = sort.value;
  if (sortField === 'name') {
    return _.orderBy(all.value, [sortField], [sortOrder]);
  }
  return _.orderBy(
    all.value,
    [(f) => Number(f[sortField] || null)],
    [sortOrder],
  );
});
const total = computed(() => sorted.value.length);

const isLoading = ref(true);
const searchQuery = useRouteQuery('search', '');
const periodQuery = useRouteQuery('period', periods[0], { transform: Number });

const pageSize = ref(LIST_RESULTS_PER_PAGE[0]);
const paginatedData = computed(() => {
  const result = paginate(sorted.value, {
    page: currentPage.value,
    pageSize: currentPageSize.value,
  });
  return result;
});

const debouncedFetch = _.debounce(fetch, 200);

watch(
  [searchQuery, periodQuery],
  () => {
    debouncedFetch({
      accountId,
      params: {
        search: searchQuery.value,
        period: periodQuery.value,
      },
    });
  },
  { immediate: true },
);

const {
  currentPage,
  currentPageSize,
  pageCount,
  isFirstPage,
  isLastPage,
  prev,
  next,
} = useOffsetPagination({
  total,
  page: 1,
  pageSize,
});

function fetch({ accountId, params }) {
  isLoading.value = true;
  return store
    .dispatch('dashboardDwell/fetchByAccountId', {
      accountId,
      params,
    })
    .catch((e) => {
      toast.error(e.response?.data?.message || 'Error fetching dwell');
    })
    .finally(() => (isLoading.value = false));
}

function handleSortChange(sort) {
  store.dispatch('dashboardDwell/setSort', sort);
}

function openHowItWorksModal() {
  store.dispatch('modal/open', { name: 'DashboardsDwellHowItWorksModal' });
}

function onCreateClick() {
  router.push({ name: 'GeofenceCreateView' });
}
</script>
