import isNull from 'lodash/isNull';
import compact from 'lodash/compact';
import { addMinutes, differenceInDays } from 'date-fns';
import { usePosition } from '@/composables/usePosition';

export function getSearchResults(devices: Map<number, any>, search: string) {
  const { getPositionByDeviceId } = usePosition();
  const foundIds = [];

  devices.forEach((device) => {
    const position = getPositionByDeviceId(device.id);
    if (deviceMatchesSearch(device, search, position)) {
      foundIds.push(device.id);
    }
  });

  return foundIds;
}

export function getFilterResults(devices: Map<number, any>, filter: any) {
  const { getPositionByDeviceId } = usePosition();

  const foundIds = [];
  devices.forEach((device) => {
    if (!device) return;
    const position = getPositionByDeviceId(device.id);
    if (deviceFilterLegacy(device, filter, position)) {
      foundIds.push(device.id);
    }
  });

  return foundIds;
}

export function getFilterResultsByLink(
  ids: any[],
  map: Map<number, any>,
  filter: any,
) {
  const { getPositionByDeviceId } = usePosition();
  const foundIds = [];

  ids.forEach((id) => {
    const position = getPositionByDeviceId(id);
    if (deviceFilterLegacy(map.get(id), filter, position)) {
      foundIds.push(id);
    }
  });

  return foundIds;
}

export function getSearchAndFilterResults(
  devices: Map<number, any>,
  { search, filter },
) {
  const { getPositionByDeviceId } = usePosition();
  const foundIds = [];

  devices.forEach((device) => {
    const position = getPositionByDeviceId(device.id);
    if (
      deviceMatchesSearch(device, search, position) &&
      deviceFilterLegacy(device, filter, position)
    ) {
      foundIds.push(device.id);
    }
  });

  return foundIds;
}

export function deviceFilterLegacy(item, filter, position) {
  if (deviceWithinReportInterval(position, filter?.haveNotReportedDays)) {
    return false;
  } else if (deviceWithinBatteryPercentage(position, filter?.battery)) {
    return false;
  } else if (deviceUpdatedInTime(item, filter?.missedUpdate, position)) {
    return false;
  } else if (
    deviceHaveNotMovedInXDays(
      position,
      filter?.haveMovedDays,
      filter?.includeCellfixes,
    )
  ) {
    return false;
  } else if (
    deviceHaveMovedInXDays(
      position,
      filter?.haveNotMovedDays,
      filter?.includeCellfixes,
    )
  ) {
    return false;
  }
  return true;
}

export function deviceMatchesSearch(device, search, position) {
  const searchFields = compact([
    device.name?.toLowerCase(),
    device.serial,
    position?.address?.toLowerCase(),
  ]);
  return searchFields.some((field) => field.includes(search.toLowerCase()));
}

function deviceWithinReportInterval(position, haveNotReportedDays) {
  if (isNull(haveNotReportedDays)) return false;
  const lastReportedAt = position?.timestamp;
  return lastReportedAt
    ? differenceInDays(new Date(), new Date(lastReportedAt)) >=
        haveNotReportedDays
    : false;
}

function deviceWithinBatteryPercentage(position, battery) {
  if (isNull(battery)) return false;
  const deviceBattery = parseInt(position?.battery, 10);
  if (isNaN(deviceBattery)) return true;
  return deviceBattery > battery;
}

function deviceUpdatedInTime(device, missedUpdate, position) {
  if (isNull(missedUpdate)) return false;
  const expectedReportMinutes = device?.expected_report_minutes;
  if (!expectedReportMinutes) {
    return true;
  }
  const updatedAt = position?.timestamp;
  const lastChanceToUpdateAt = addMinutes(
    new Date(updatedAt),
    expectedReportMinutes,
  );
  return new Date() <= lastChanceToUpdateAt;
}

function deviceHaveNotMovedInXDays(position, x, includeCellfixes) {
  if (isNull(x)) return false;
  const lastMovemementProp = includeCellfixes
    ? 'last_movement_time'
    : 'last_movement_time_without_cell';
  const lastMovementTime = position?.[lastMovemementProp];
  return lastMovementTime
    ? differenceInDays(new Date(), new Date(lastMovementTime)) > x
    : false;
}

function deviceHaveMovedInXDays(position, x, includeCellfixes) {
  if (isNull(x)) return false;
  const lastMovemementProp = includeCellfixes
    ? 'last_movement_time'
    : 'last_movement_time_without_cell';
  const lastMovementTime = position?.[lastMovemementProp];
  return lastMovementTime
    ? differenceInDays(new Date(), new Date(lastMovementTime)) < x
    : false;
}
