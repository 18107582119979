import * as _ from 'lodash';
import service from '@/services';
import {
  BATCH_ADD_OR_UPDATE,
  BATCH_REVERSED_ADD_OR_UPDATE,
  REMOVE,
  ADD,
  SET_SEARCH,
  SET_SELECTED_IDS,
  UPDATE_RESULTS,
} from '../mutation-types';
import {
  addOrUpdateItems,
  processAndReturn,
  processEntities,
  removeItem,
} from '../helpers';
import { DEVICE_CATEGORY } from '@/config/constants';
import { groupMatchesSearch } from '@/utils/group-filter';
import { getFilterResults } from '@/utils/device-filter-legacy';
import * as events from '@/events';

export const state = {
  items: [],
  search: '',
  selectedIds: [],
  results: {
    devices: [],
    device_ids: [],
  },
};

export const getters = {
  all: (state) => state.items,
  results: (state) => state.results,
  searched: (state, getters) => {
    const search = getters['search'].toLowerCase();
    return state.items.filter((item) => groupMatchesSearch(item, search));
  },
  selectedIds: (state) => state.selectedIds,
  byId: (state) => (id) => state.items.find((item) => item.id === id),
  byIds: (state) => (ids) =>
    ids.map((id) => state.items.find((item) => item.id === id)),
  byAccountId: (state) => (id) =>
    state.items.filter((item) => {
      return item.account_id === id;
    }),
  search: (state) => state.search,

  // UI getters
  isBottomButton: (state, getters, rootState, rootGetters) => {
    return (
      getters.filtered.length &&
      rootGetters['device/activeTab'] === DEVICE_CATEGORY.groups
    );
  },
};

export const mutations = {
  [ADD]: (state, items) => (state.items = items),
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [BATCH_REVERSED_ADD_OR_UPDATE]: (state, items) =>
    addOrUpdateItems(state, items, undefined, undefined, { reversed: true }),
  [REMOVE]: (state, item) => removeItem(state, item),
  [SET_SEARCH]: (state, search) => (state.search = search),
  [UPDATE_RESULTS]: (state, results) => (state.results = results),
  [SET_SELECTED_IDS]: (state, ids) => (state.selectedIds = ids),
  ADD_DEVICES: (state, { id, addedDeviceIds }) => {
    const found = state.items.find((item) => item.id === id);
    if (found) {
      found.device_ids = addedDeviceIds;
    }
  },
  SWAP_DEVICES: (state, { id, swappedDeviceIds }) => {
    const found = state.items.find((item) => item.id === id);
    if (found) {
      found.device_ids = swappedDeviceIds;
    }
  },
  REMOVE_DEVICES: (state, { id, removedIds }) => {
    const found = state.items.find((item) => item.id === id);
    if (found) {
      if (!removedIds) {
        found.device_ids = [];
        return;
      }
      found.device_ids = found.device_ids.filter(
        (id) => !removedIds.includes(id),
      );
    }
  },
};

export const actions = {
  setSearch: (store, search) => {
    store.commit(SET_SEARCH, search);
  },
  updateResults: (store) => {
    const groups = store.getters['searched'];
    const deviceIds = groups.map((item) => item.device_ids);
    const uniqueDeviceIds = [...new Set(deviceIds.flat())];
    const uniqueDevices = uniqueDeviceIds.map((id) =>
      store.rootGetters['device/byId'](id),
    );

    const isFilters = store.rootGetters['device/isAtLeastOneFilterApplied'];
    const filter = store.rootGetters['device/filter'];

    let results = {
      devices: uniqueDevices || [],
      device_ids: uniqueDeviceIds || [],
    };

    if (isFilters) {
      results = getFilterResults(uniqueDevices, filter);
    }
    store.commit(UPDATE_RESULTS, results);
  },
  setSelectedIds: (store, ids) => {
    store.commit(SET_SELECTED_IDS, ids);
  },
  fetchByAccountId: (store, { accountId, params }) => {
    return service
      .get(`/accounts/${accountId}/groups`, { params })
      .then((res) => {
        store.commit(ADD, res.data.data);
        return res;
      });
  },
  fetchByAccountIdAndId: (store, { accountId, id, params }) => {
    return service
      .get(`/accounts/${accountId}/groups/${id}`, { params })
      .then((res) => processAndReturn(res, 'group'));
  },
  store: (store, { accountId, params }) => {
    return service.post(`/accounts/${accountId}/groups`, params).then((res) => {
      const group = res.data.data;
      const deviceById = store.rootGetters['device/byId'];

      group.device_ids.forEach((id) => {
        const device = deviceById(id);
        if (device.group_ids) {
          device.group_ids.push(group.id);
        } else {
          device.group_ids = [group.id];
        }
      });

      processEntities(res.data.data, 'group', { reversed: true });

      store.dispatch('updateResults');
      events.trigger(events.names.GROUP_CREATED, {
        group,
      });
      return res;
    });
  },
  update: (store, { accountId, id, params }) => {
    return service
      .put(`/accounts/${accountId}/groups/${id}`, params)
      .then((res) => {
        store.dispatch('updateResults');
        events.trigger(events.names.GROUP_EDITED, {
          params,
          group: res.data.data,
        });
        processAndReturn(res, 'group');
      });
  },
  addDevices: (store, { accountId, id, params }) => {
    return service
      .post(`/accounts/${accountId}/groups/${id}/devices`, params)
      .then((res) => {
        const group = res.data.data;
        const deviceById = store.rootGetters['device/byId'];
        const addedDeviceIds = group.device_ids;
        store.commit('ADD_DEVICES', { id, addedDeviceIds });

        addedDeviceIds.forEach((id) => {
          const device = deviceById(id);
          if (device) {
            if (device.group_ids) {
              device.group_ids.push(group.id);
            } else {
              device.group_ids = [group.id];
            }
          }
        });

        events.trigger(events.names.DEVICES_ADDED_TO_GROUP, {
          group,
          params,
        });
        store.dispatch('updateResults');
        return res;
      });
  },
  swapDevices: (store, { accountId, id, params }) => {
    return service
      .post(`/accounts/${accountId}/groups/${id}/devices-swap`, params)
      .then((res) => {
        const group = res.data.data;
        const allDevices = store.rootGetters['device/all'];
        const swappedDeviceIds = group.device_ids;
        store.commit('SWAP_DEVICES', { id, swappedDeviceIds });

        allDevices.forEach((device) => {
          if (device.group_ids) {
            const hasThatGroup = device.group_ids.find((id) => id === group.id);
            if (hasThatGroup) {
              device.group_ids = device.group_ids.filter(
                (id) => id !== group.id,
              );
              if (swappedDeviceIds.includes(device.id)) {
                device.group_ids.push(group.id);
              }
            }
          } else {
            if (swappedDeviceIds.includes(device.id)) {
              device.group_ids = [group.id];
            }
          }
        });

        events.trigger(events.names.GROUP_DEVICES_SWAPPED, {
          group,
          params,
        });
        store.dispatch('updateResults');
        return res;
      });
  },
  removeDevices: (store, { accountId, id, params }) => {
    const removedIds = params?.device_ids;
    return service
      .delete(`/accounts/${accountId}/groups/${id}/devices`, { data: params })
      .then((res) => {
        const group = res.data.data;
        const groupId = id;
        const deviceById = store.rootGetters['device/byId'];
        const allDevices = store.rootGetters['device/all'];
        store.commit('REMOVE_DEVICES', { id, removedIds });
        store.dispatch('device/setSelectedIds', [], { root: true });
        events.trigger(events.names.GROUP_DEVICES_REMOVED, {
          group,
          params,
        });
        if (_.isEmpty(removedIds)) {
          allDevices.forEach((device) => {
            if (device.group_ids) {
              device.group_ids = device.group_ids.filter(
                (id) => id !== groupId,
              );
            }
          });
          return;
        }

        removedIds.forEach((id) => {
          const device = deviceById(id);
          if (device.group_ids) {
            const hasThatGroup = device.group_ids.find((id) => id === groupId);
            if (hasThatGroup) {
              device.group_ids = device.group_ids.filter(
                (id) => !removedIds.includes(id),
              );
            }
          }
        });

        store.dispatch('updateResults');
        return res;
      });
  },
  delete: (store, { accountId, id }) => {
    return service.delete(`/accounts/${accountId}/groups/${id}`).then((res) => {
      store.commit(REMOVE, { id });
      const allDevices = store.rootGetters['device/all'];
      allDevices.forEach((device) => {
        if (device.group_ids) {
          device.group_ids = device.group_ids.filter(
            (groupId) => groupId !== id,
          );
        }
      });

      store.dispatch('updateResults');
      events.trigger(events.names.GROUP_DELETED, {
        group: id,
      });
      return res;
    });
  },
};
