<template>
  <input
    type="checkbox"
    class="appearance-none rounded-[4px] border-lgmx_gray-400 outline-none ring-transparent checked:border-none checked:bg-lgmx_terracota-700 indeterminate:border-none indeterminate:bg-lgmx_terracota-700 hover:checked:bg-lgmx_terracota-800 indeterminate:hover:bg-lgmx_terracota-700 focus:ring-0 focus:checked:bg-lgmx_terracota-700 focus:hover:checked:bg-lgmx_terracota-800"
    :class="[
      { 'h-[16px] w-[16px]': size === 'small' },
      { 'h-5 w-5': size === 'large' },
    ]"
  />
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      validator(value) {
        return ['small', 'large'].includes(value);
      },
      default: 'small',
    },
  },
};
</script>
