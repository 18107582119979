<template>
  <div
    class="flex justify-center gap-x-[16px] border-t border-t-gpx_gray-900 px-[28px] pb-[21px] pt-[19px]"
  >
    <div class="flex gap-x-[2px] overflow-hidden rounded-[4px]">
      <div class="relative w-full">
        <select
          class="w-full min-w-[64px] cursor-pointer border-0 bg-bg-3 bg-none px-[22px] py-[11px] pl-[16px] pr-[14px] text-xs text-lms_black-200 focus:ring-0"
          :value="hours"
          @change="$emit('update:hours', +$event.target.value)"
        >
          <option v-for="h in hoursArray" :key="h.value" :value="h.value">
            {{ h.text }}
          </option>
        </select>
        <ChevronDownIcon
          class="pointer-events-none absolute right-[14px] top-1/2 h-[14px] w-[14px] text-gpx_gray-300 -translate-y-1/2"
        />
      </div>
      <div class="relative w-full">
        <select
          class="w-full min-w-[64px] cursor-pointer border-0 bg-bg-3 bg-none px-[22px] py-[11px] pl-[16px] pr-[14px] text-xs text-lms_black-200 focus:ring-0"
          :value="minutes"
          @change="$emit('update:minutes', +$event.target.value)"
        >
          <option v-for="m in minutesArray" :key="m.value" :value="m.value">
            {{ m.text }}
          </option>
        </select>
        <ChevronDownIcon
          class="pointer-events-none absolute right-[14px] top-1/2 h-[14px] w-[14px] text-gpx_gray-300 -translate-y-1/2"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/24/outline';

defineEmits(['update:hours', 'update:minutes']);
defineProps({
  hoursIncrement: { type: [Number, String], default: 1 },
  minutesIncrement: { type: [Number, String], default: 1 },
  is24: { type: Boolean, default: true },
  hoursGridIncrement: { type: [String, Number], default: 1 },
  minutesGridIncrement: { type: [String, Number], default: 1 },
  range: { type: Boolean, default: true },
  filters: { type: Object, default: () => ({}) },
  minTime: { type: Object, default: () => ({}) },
  maxTime: { type: Object, default: () => ({}) },
  timePicker: { type: Boolean, default: false },
  enableSeconds: { type: Boolean, default: true },
  hours: { type: [Number, Array], default: 0 },
  minutes: { type: [Number, Array], default: 0 },
  customProps: { type: Object, default: null },
});

const hoursArray = computed(() => {
  const arr = [];
  for (let i = 0; i < 24; i++) {
    arr.push({ text: i < 10 ? `0${i}` : i, value: i });
  }
  return arr;
});

const minutesArray = computed(() => {
  const arr = [];
  for (let i = 0; i < 60; i++) {
    arr.push({ text: i < 10 ? `0${i}` : i, value: i });
  }
  return arr;
});
</script>

<style></style>
