import { BATTERY_THRESHOLD } from '@/config/constants';
import * as _ from 'lodash';
const danger = (charge) => `
    <div class="bg-lgmx_red-100 text-lgmx_red-300 inline-flex items-center gap-x-[4px] pl-[8px] pr-[10px] rounded-[6px]">
      <svg width="8" height="12" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.38826 1.33424C7.43814 1.10144 7.32513 0.864217 7.11291 0.756286C6.90069 0.648355 6.64241 0.696743 6.48366 0.874174L0.53366 7.52417C0.3956 7.67848 0.361124 7.89951 0.445636 8.08853C0.530148 8.27755 0.717861 8.39924 0.924912 8.39924H5.52549L4.61157 12.6642C4.56168 12.897 4.6747 13.1343 4.88691 13.2422C5.09913 13.3501 5.35741 13.3017 5.51616 13.1243L11.4662 6.47431C11.6042 6.32001 11.6387 6.09897 11.5542 5.90995C11.4697 5.72093 11.282 5.59924 11.0749 5.59924H6.47433L7.38826 1.33424Z" fill="currentColor" class="text-lgmx_red-200"/>
      </svg>
      <div class="inline-block text-[10px] leading-[17px] font-semibold">
        ${charge}<span class="font-normal bg-transparent">%</span>
      </div>
    </div>
  `;
const warning = (charge) => `
    <div class="bg-lgmx_yellow-100 text-lgmx_yellow-300 inline-flex items-center gap-x-[4px] pl-[8px] pr-[10px] rounded-[6px]">
      <svg width="8" height="12" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.38826 1.33424C7.43814 1.10144 7.32513 0.864217 7.11291 0.756286C6.90069 0.648355 6.64241 0.696743 6.48366 0.874174L0.53366 7.52417C0.3956 7.67848 0.361124 7.89951 0.445636 8.08853C0.530148 8.27755 0.717861 8.39924 0.924912 8.39924H5.52549L4.61157 12.6642C4.56168 12.897 4.6747 13.1343 4.88691 13.2422C5.09913 13.3501 5.35741 13.3017 5.51616 13.1243L11.4662 6.47431C11.6042 6.32001 11.6387 6.09897 11.5542 5.90995C11.4697 5.72093 11.282 5.59924 11.0749 5.59924H6.47433L7.38826 1.33424Z" fill="currentColor" class="text-lgmx_yellow-200"/>
      </svg>
      <div class="inline-block text-[10px] leading-[17px] font-semibold">
        ${charge}<span class="font-normal bg-transparent">%</span>
      </div>
    </div>
  `;
const normal = (charge) => `
    <div class="bg-lgmx_green-100 text-lgmx_green-300 inline-flex items-center gap-x-[4px] pl-[8px] pr-[10px] rounded-[6px]">
      <svg width="8" height="12" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.38826 1.33424C7.43814 1.10144 7.32513 0.864217 7.11291 0.756286C6.90069 0.648355 6.64241 0.696743 6.48366 0.874174L0.53366 7.52417C0.3956 7.67848 0.361124 7.89951 0.445636 8.08853C0.530148 8.27755 0.717861 8.39924 0.924912 8.39924H5.52549L4.61157 12.6642C4.56168 12.897 4.6747 13.1343 4.88691 13.2422C5.09913 13.3501 5.35741 13.3017 5.51616 13.1243L11.4662 6.47431C11.6042 6.32001 11.6387 6.09897 11.5542 5.90995C11.4697 5.72093 11.282 5.59924 11.0749 5.59924H6.47433L7.38826 1.33424Z" fill="currentColor" class="text-lgmx_green-200"/>
      </svg>
      <div class="inline-block text-[10px] leading-[17px] font-semibold">
        ${charge}<span class="font-normal bg-transparent">%</span>
      </div>
    </div>
  `;

export function getChargeEl(battery) {
  if (!_.isNull(battery) && battery < BATTERY_THRESHOLD.low) {
    return danger(battery);
  }
  if (
    !_.isNull(battery) &&
    battery >= BATTERY_THRESHOLD.low &&
    battery < BATTERY_THRESHOLD.medium
  ) {
    return warning(battery);
  }
  if (!_.isNull(battery) && battery >= BATTERY_THRESHOLD.medium) {
    return normal(battery);
  }
  return '';
}
