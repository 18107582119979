<template>
  <DataTable
    dataKey="id"
    v-model:expandedRows="expandedRows"
    :value="data"
    scrollable
    scrollHeight="flex"
    :loading="props.isLoading"
    tableStyle="min-width: 74rem"
    removableSort
    @sort="(params) => emits('onSortChange', params)"
  >
    <template #loading>
      <UISpinnerNew size="lg" />
    </template>
    <Column expander class="w-12 rounded-l-md">
      <template #body="slotProps">
        <UIIconButton
          v-if="isFetching(slotProps.data.id)"
          icon="none"
          class="h-[28px]"
          :disabled="true"
          :is-loading="true"
        />
        <template
          v-if="!isFetching(slotProps.data.id) && slotProps.data.devices_count"
        >
          <UIIconButton
            v-if="!isExpanded(slotProps.data.id)"
            class="h-[28px]"
            icon="ChevronDownIcon"
            :is-loading="isFetching(slotProps.data.id)"
            @click="fetchDevices(slotProps.data)"
          />
          <UIIconButton
            v-if="isExpanded(slotProps.data.id)"
            class="h-[28px]"
            icon="ChevronUpIcon"
            :is-loading="isFetching(slotProps.data.id)"
            @click="collapseRow(slotProps.data)"
          />
        </template>
      </template>
    </Column>
    <Column field="name" header="GEOFENCE" sortable style="min-width: 190px">
      <template #body="slotProps">
        <UILink
          :title="slotProps.data.name"
          :to="{
            name: 'GeofenceDetailsView',
            params: { id: slotProps.data.id },
          }"
          target="_blank"
        >
          {{ slotProps.data.name }}
        </UILink>
      </template>
    </Column>
    <Column
      field="allowable_dwell_days"
      header="DWELL SLA"
      sortable
      style="width: 110px"
    >
      <template #body="slotProps">
        <div class="w-10">
          <UICellInput
            type="number"
            data-field="allowable_dwell_days"
            @blur="(event) => handleBlur({ event, row: slotProps.data })"
            @wheel="$event.target.blur()"
            @keypress.enter="$event.target.blur()"
            :value="slotProps.data.allowable_dwell_days"
          />
        </div>
      </template>
    </Column>
    <Column field="min_assets" header="MINIMUM # OF ASSETS" sortable>
      <template #body="slotProps">
        <div class="w-10">
          <UICellInput
            type="number"
            data-field="min_assets"
            @blur="(event) => handleBlur({ event, row: slotProps.data })"
            @wheel="$event.target.blur()"
            @keypress.enter="$event.target.blur()"
            :value="slotProps.data.min_assets"
          />
        </div>
      </template>
    </Column>
    <Column field="max_assets" header="MAXIMUM # OF ASSETS" sortable>
      <template #body="slotProps">
        <div class="w-10">
          <UICellInput
            type="number"
            data-field="max_assets"
            @blur="(event) => handleBlur({ event, row: slotProps.data })"
            @wheel="$event.target.blur()"
            @keypress.enter="$event.target.blur()"
            :value="slotProps.data.max_assets"
          />
        </div>
      </template>
    </Column>
    <Column field="devices_count" header="ASSETS IN GEOFENCE" sortable>
      <template #body="slotProps">
        <span
          v-if="_.isNil(slotProps.data.devices_count)"
          class="text-lgmx_gray-900"
        >
          -
        </span>
        <UIDotBadge
          v-else
          :variant="
            badgeVariants.assetsInGeofence({
              sla: slotProps.data.allowable_dwell_days,
              min: slotProps.data.min_assets,
              max: slotProps.data.max_assets,
              current: slotProps.data.devices_count,
            })
          "
        >
          {{ slotProps.data.devices_count }}
        </UIDotBadge>
      </template>
    </Column>
    <Column field="avg_time" header="AVERAGE DAYS ASSETS IN GEOFENCE" sortable>
      <template #body="slotProps">
        <span
          v-if="_.isNil(slotProps.data.avg_time)"
          class="text-lgmx_gray-900"
        >
          -
        </span>
        <UIDotBadge
          v-else
          :variant="
            badgeVariants.averageDaysAssetsInGeofence({
              sla: slotProps.data.allowable_dwell_days,
              percentage: slotProps.data.average_dwell_time_percentage,
            })
          "
        >
          {{ _.round(secondsToDays(slotProps.data.avg_time), 2) }}
        </UIDotBadge>
      </template>
    </Column>
    <Column
      field="average_dwell_time_percentage"
      header="AVERAGE TIME AS % OF SLA"
      :sortable="true"
    >
      <template #body="slotProps">
        <span
          v-if="_.isNil(slotProps.data.average_dwell_time_percentage)"
          class="text-lgmx_gray-900"
        >
          -
        </span>
        <UIDotBadge
          v-else
          :variant="
            badgeVariants.averageDwellTimeAsPercentOfSla({
              sla: slotProps.data.allowable_dwell_days,
              percentage: slotProps.data.average_dwell_time_percentage,
            })
          "
        >
          {{ slotProps.data.average_dwell_time_percentage }}%
        </UIDotBadge>
      </template>
    </Column>
    <Column
      field="assets_exceeding_dwell"
      header="# OF ASSETS EXCEEDING DWELL SLA"
      :sortable="true"
      class="rounded-r-md"
    >
      <template #body="slotProps">
        <span
          v-if="_.isNil(slotProps.data.assets_exceeding_dwell)"
          class="text-lgmx_gray-900"
        >
          -
        </span>
        <UIDotBadge v-else variant="default">
          {{ slotProps.data.assets_exceeding_dwell }}
        </UIDotBadge>
      </template>
    </Column>
    <template #expansion="slotProps">
      <div class="pl-8">
        <DataTable :value="slotProps.data.devices">
          <Column
            field="device.name"
            header="TRACKER"
            :sortable="true"
            style="width: 290px"
          >
            <template #body="slotProps">
              <span :title="slotProps.data.device.name">
                {{ slotProps.data.device.name }}
              </span>
            </template>
          </Column>
          <Column
            :sla="slotProps.data.allowable_dwell_days"
            field="days_spent_since_entry"
            header="DAYS IN GEOFENCE"
            sortable
            style="width: 180px"
          >
            <template #body="slotProps">
              <span
                v-if="_.isNil(slotProps.data.days_spent_since_entry)"
                class="text-lgmx_gray-900"
              >
                -
              </span>
              <UIDotBadge
                v-else
                :variant="
                  badgeVariants.daysInGeofence({
                    sla: slotProps.column.props.sla,
                    days: slotProps.data.days_spent_since_entry,
                  })
                "
              >
                {{ slotProps.data.days_spent_since_entry }}
              </UIDotBadge>
            </template>
          </Column>
          <Column header="TRACKER GROUP" style="width: 240px">
            <template #body="slotProps">
              <span
                v-if="_.isEmpty(slotProps.data.device.groups)"
                class="text-lgmx_gray-900"
              >
                -
              </span>
              <span :title="getGroupNames(slotProps.data.device.groups)">
                {{ getGroupNames(slotProps.data.device.groups) }}
              </span>
            </template>
          </Column>
          <Column
            field="entered_at"
            header="DATE ENTERED"
            sortable
            style="width: 250px"
          >
            <template #body="slotProps">
              <span
                v-if="_.isNil(slotProps.data.entered_at)"
                class="text-lgmx_gray-900"
              >
                -
              </span>
              <span v-else>
                {{
                  formatTime(
                    slotProps.data.entered_at,
                    'MMM d, yyyy @ h:mm aaa',
                  )
                }}
              </span>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <button
                v-if="slotProps.data.device.id"
                class="flex items-center"
                @click.prevent="goToDeviceMap(slotProps.data.device.id)"
              >
                Location
                <ArrowUpRightIcon
                  class="ml-1 size-3 stroke-[2px] text-lgmx_terracota-700"
                />
              </button>
            </template>
          </Column>
        </DataTable>
      </div>
    </template>
  </DataTable>
</template>

<script setup>
import * as _ from 'lodash';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import UIDotBadge from '@/components/ui/UIDotBadge/UIDotBadge.vue';
import * as badgeVariants from './utils/badge-variants';
import { secondsToDays } from '@/utils/seconds-to-days';
import { scrollTable } from '@/utils/primevue/scroll-table';
import { formatTime } from '@/filters';
import { COMMON_UPDATE_SUCCESS } from '@/config/constants';

const emits = defineEmits(['onSortChange']);
const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Array,
    default: () => [],
  },
  period: Number,
  currentPage: Number,
  currentPageSize: Number,
  pageCount: Number,
});

const toast = useToast();
const router = useRouter();
const store = useStore();
const data = computed(() => props.data);
const expandedRows = ref([]);
const accountId = store.getters['auth/accountId'];
const loadingQueue = ref([]);

watch(
  [
    () => props.currentPage,
    () => props.currentPageSize,
    () => props.pageCount,
    () => props.period,
  ],
  () => {
    expandedRows.value = [];
    scrollTable(100);
  },
);

function isFetching(id) {
  return loadingQueue.value.find((_id) => _id === id);
}
function isExpanded(id) {
  return expandedRows.value.find((item) => item.id === id);
}

function addToLoadingQueue(id) {
  loadingQueue.value = [...loadingQueue.value, id];
}
function popFromLoadingQueue(id) {
  loadingQueue.value = [...loadingQueue.value.filter((_id) => _id !== id)];
}

function expandRow(row) {
  expandedRows.value = [...expandedRows.value, row];
}
function collapseRow(row) {
  expandedRows.value = [
    ...expandedRows.value.filter((item) => item.id !== row.id),
  ];
}

async function fetchDevices(row) {
  addToLoadingQueue(row.id);
  try {
    await store.dispatch('dashboardDwell/fetchDevicesByGeofenceId', {
      accountId,
      geofenceId: row.id,
    });
    expandRow(row);
  } catch (e) {
    toast.error("Cannot get device's data");
  } finally {
    popFromLoadingQueue(row.id);
  }
}

async function handleBlur({ event, row }) {
  const field = event.target.getAttribute('data-field');
  const oldValue = row[field];
  const newValue =
    event.target.value === '' ? null : parseInt(event.target.value);
  if (isNaN(newValue)) {
    toast.error('Please enter a number');
    return;
  }
  if (oldValue === newValue) return;
  try {
    const wasExpanded = isExpanded(row.id);
    collapseRow(row);
    addToLoadingQueue(row.id);
    await store.dispatch('geofence/update', {
      accountId,
      id: row.id,
      params: {
        [field]: newValue,
      },
    });
    await store.dispatch('dashboardDwell/fetchByGeofenceId', {
      accountId,
      geofenceId: row.id,
      field,
      params: {
        period: props.period,
      },
    });
    if (wasExpanded) {
      expandRow(row);
    }
    toast.success(COMMON_UPDATE_SUCCESS);
  } catch (e) {
    event.target.value = oldValue;
    if (e.response?.status === 404) {
      toast.error('Sorry, this geofence no longer exists');
      return;
    }
    toast.error(e.response?.data?.message || `Error setting ${field}`);
  } finally {
    popFromLoadingQueue(row.id);
  }
}

function getGroupNames(groups) {
  return groups.map((item) => item.name).join(', ');
}

function goToDeviceMap(id) {
  const routeData = router.resolve({
    name: 'DeviceDetailsView',
    params: { id },
  });
  window.open(routeData.href, '_blank');
}
</script>
