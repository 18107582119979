import service from '@/services';
import {
  addOrUpdateItems,
  processAndReturn,
  processEntities,
  removeItem,
} from '../helpers';
import { GEOFENCE_CATEGORY } from '@/config/constants';
import {
  ADD,
  BATCH_ADD_OR_UPDATE,
  BATCH_REVERSED_ADD_OR_UPDATE,
  REMOVE,
  SET_ACTIVE_TAB,
  SET_MOBILE_ACTIVE_TAB,
} from '../mutation-types';

export const state = {
  viewportObj: null,
  shape: null,
  items: [],
  search: '',
  activeTab: GEOFENCE_CATEGORY.geofences,
  category: GEOFENCE_CATEGORY.geofences,
  mobileActiveTab: 'right',
};

export const getters = {
  all: (state) => state.items,
  filtered: (state, getters) => {
    const search = getters['search'].toLowerCase();
    return getters['all'].filter(
      (item) =>
        item.name?.toLowerCase().includes(search) ||
        item.address?.toLowerCase().includes(search),
    );
  },
  byId: (state) => (id) => state.items.find((item) => item.id === id),
  byIds: (state) => (ids) =>
    ids.map((id) => state.items.find((item) => item.id === id)),
  viewportObj: (state) => state.viewportObj,
  shape: (state) => state.shape,
  search: (state) => state.search,
  category: (state) => state.category,

  // UI getters
  activeTab: (state) => state.activeTab,
  mobileActiveTab: (state) => state.mobileActiveTab,
  isBottomButton: (state, getters) => {
    return (
      getters.filtered.length &&
      getters.activeTab === GEOFENCE_CATEGORY.geofences
    );
  },
};

export const mutations = {
  [ADD]: (state, items) => (state.items = items),
  [BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
  [BATCH_REVERSED_ADD_OR_UPDATE]: (state, items) =>
    addOrUpdateItems(state, items, undefined, undefined, { reversed: true }),
  [REMOVE]: (state, item) => removeItem(state, item),
  SET_VIEWPORT_OBJ: (state, viewportObj) => (state.viewportObj = viewportObj),
  SET_SHAPE: (state, shape) => (state.shape = shape),
  SET_SEARCH: (state, search) => (state.search = search),
  SET_CATEGORY: (state, category) => (state.category = category),
  [SET_ACTIVE_TAB]: (state, tab) => (state.activeTab = tab),
  [SET_MOBILE_ACTIVE_TAB]: (state, tab) => (state.mobileActiveTab = tab),
};

export const actions = {
  setViewportObj: (store, viewportObj) => {
    store.commit('SET_VIEWPORT_OBJ', viewportObj);
  },
  setShape: (store, shape) => {
    store.commit('SET_SHAPE', shape);
  },
  setSearch: (store, search) => {
    store.commit('SET_SEARCH', search);
  },
  setMobileActiveTab: (store, tab) => store.commit(SET_MOBILE_ACTIVE_TAB, tab),
  setActiveTab: (store, tab) => store.commit(SET_ACTIVE_TAB, tab),
  setCategory: (store, category) => {
    store.commit('SET_CATEGORY', category);
  },
  fetch: (store) => {
    const accountId = store.rootGetters['auth/accountId'];
    return service.get(`/accounts/${accountId}/geofences`).then((res) => {
      const validGeofences = res.data.data.filter(
        (item) => item.lat && item.lng,
      );
      store.commit(ADD, validGeofences);
      return res;
    });
  },
  fetchByAccountId: (store, { accountId, params = {} }) => {
    return service
      .get(`/accounts/${accountId}/geofences`, { params })
      .then((res) => {
        const validGeofences = res.data.data.filter(
          (item) => item.lat && item.lng,
        );
        store.commit(ADD, validGeofences);
        return res;
      });
  },
  fetchByAccountIdAndId: (store, { accountId, id, params }) => {
    return service
      .get(`accounts/${accountId}/geofences/${id}`, { params })
      .then((res) => processAndReturn(res, 'geofence'));
  },
  store: (store, { accountId, params }) => {
    return service
      .post(`/accounts/${accountId}/geofences`, params)
      .then((res) => processAndReturn(res, 'geofence', { reversed: true }));
  },
  update: (store, { accountId, id, params }) => {
    return service
      .put(`/accounts/${accountId}/geofences/${id}`, params)
      .then((res) => {
        processEntities(res.data.data, 'geofence');
        return res;
      });
  },
  delete: (store, { accountId, id }) => {
    return service
      .delete(`/accounts/${accountId}/geofences/${id}`)
      .then((res) => {
        store.commit(REMOVE, { id });
        return res;
      });
  },
  downloadAll: (store, { accountId, params }) => {
    return service.get(`/accounts/${accountId}/geofence-events`, {
      params,
    });
  },
  findNearest: (store, { params }) => {
    const accountId = store.rootGetters['auth/accountId'];
    return service.get(`/accounts/${accountId}/geofence/nearest`, {
      params,
    });
  },
};
