import * as _ from 'lodash';
export function assetsInGeofence({ sla, min, max, current }) {
  if (_.isNil(sla)) return 'default';
  if (_.isNil(min) && _.isNil(max)) {
    return 'default';
  }
  if (
    (_.isNil(min) && current <= max) ||
    (_.isNil(max) && current >= min) ||
    (current >= min && current <= max)
  ) {
    return 'success';
  }
  return 'error';
}

export function averageDaysAssetsInGeofence({ sla, percentage }) {
  if (_.isNil(sla)) return 'default';
  if (percentage <= 75) {
    return 'success';
  } else if (percentage <= 100) {
    return 'warning';
  } else {
    return 'error';
  }
}

export function averageDwellTimeAsPercentOfSla({ sla, percentage }) {
  return averageDaysAssetsInGeofence({ sla, percentage });
}

export function daysInGeofence({ sla, days }) {
  if (_.isNil(sla)) return 'default';
  const percentage = (days / sla) * 100;
  if (percentage <= 75) {
    return 'success';
  } else if (percentage <= 100) {
    return 'warning';
  } else {
    return 'error';
  }
}
