<template>
  <div>
    <label
      v-if="label"
      class="mb-[3px] block text-xs font-medium text-gpx_gray-200"
      for=""
    >
      {{ label }}
    </label>
    <div class="relative flex">
      <input
        :placeholder="placeholder"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        class="w-full rounded-[6px] border-0 bg-bg-3 px-[22px] py-[14px] text-sm text-lms_black-100 placeholder:text-gpx_gray-300 focus:outline-0 focus:ring-0"
        :class="[
          {
            '!bg-white drop-shadow-[2px_4px_8px_0_rgba(232,_234,_237,_0.2)]':
              light,
          },
          { 'bg-red-150': hasError },
        ]"
      />
      <div
        class="pointer-events-none absolute right-[22px] top-1/2 flex items-center gap-x-[8px] opacity-[0.64] -translate-y-1/2"
      >
        <img class="max-w-[36px]" src="@/assets/payment/visa.png" alt="visa" />
        <img
          class="max-h-[20px]"
          src="@/assets/payment/mastercard.png"
          alt="mastercard"
        />
        <img class="max-h-[20px]" src="@/assets/payment/amex.png" alt="amex" />
        <img class="max-h-[20px]" src="@/assets/payment/jcb.png" alt="jcb" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UIInput',
  emits: ['blur', 'update:modelValue'],
  props: {
    light: {
      type: Boolean,
      default: false,
    },
    actions: {
      required: false,
      type: [Object || Boolean],
    },
    placeholder: {
      required: false,
      default: '',
      type: String,
    },
    modelValue: {
      required: false,
      default: '',
      type: String,
    },
    label: {
      type: String,
      required: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
